import styled from 'styled-components'

export const StyledFlex = styled.div<{ $gap?: boolean }>`
  display: flex;

  ${({ $gap }) => ($gap ? 'gap: 16px;' : '')}
`

export const StyledColumn = styled(StyledFlex)<{ $gap?: boolean }>`
  flex-direction: column;

  ${({ $gap }) => ($gap ? 'gap: 12px;' : '')}
`

export const StyledGrid = styled.div<{ $gap?: boolean; $columns?: number }>`
  display: grid;

  ${({ $columns }) => ($columns ? `grid-template-columns: repeat(${$columns}, 1fr);` : '')}
  ${({ $gap }) => ($gap ? 'gap: 16px;' : '')}
`

export default StyledFlex
