import { makeAutoObservable, runInAction } from 'mobx'

export default class AppLayoutStore {
  isDarkMode = JSON.parse(localStorage.getItem('mbg_wave_tc')) || false
  infoMessage = null
  severity = null
  navTitle = ''

  constructor() {
    makeAutoObservable(this)
  }

  toggleDarkMode = () => {
    runInAction(() => {
      this.isDarkMode = !this.isDarkMode
      localStorage.setItem('mbg_wave_tc', this.isDarkMode)
    })
  }

  setInfoMessage = (message, severity) => {
    this.infoMessage = message
    this.severity = severity
  }

  setNavTitle = (title) => {
    this.navTitle = title
  }
}
