import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Highlight from 'react-highlight'

import CommonDialogTitle from '../../Common/DialogComponents/CommonDialogTitle'

interface Props {
  onClose(): void
  error: any
}

const ErrorDialog = (props: Props) => (
  <Dialog onClose={props.onClose} open fullWidth maxWidth="md">
    <CommonDialogTitle onClose={props.onClose}>
      <div>Save failed</div>
    </CommonDialogTitle>
    <DialogContent>
      <p>Please send the following error message to administrators:</p>
      <Highlight>{JSON.stringify(props.error, null, 4)}</Highlight>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose}>Close</Button>{' '}
    </DialogActions>
  </Dialog>
)

export default ErrorDialog
