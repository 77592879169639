import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { reset } from 'redux-form'

import { toggleCreateArticleMenu } from 'actions/articleTemplateActions'
import {
  hideErrorMessage,
  loadArticles,
  updateArticle,
  updatePreviewImage,
  archiveArticle,
  toggleArchiveDialog,
  updateEditorsNotes,
  setPublishDT,
  toggleCompressedView,
  toggleCompressedViewItem,
} from 'actions/articlesActions'
import { getEditorsList, getPartnersList } from 'actions/createArticleApiActions'
import FlashMessage from 'ui/FlashMessage'

import 'styles/article_queue.scss'
import { StyledColumn } from '../layouts/StyledLayouts'
import { LoaderContainer } from '../ui/LoaderContainer'
import { ARTICLES_RELOAD } from '../utils/constants/actions'
import InfoMessage from 'components/Articles/InfoMessage'
import ArchiveDialog from 'components/Articles/ArchiveDialog'
import RenderArticles from 'components/Articles/RenderArticles'
import NoResults from 'components/Articles/NoResults'
import SingleImageDialog from 'components/CreateArticle/EditableModalComponents/Images/SingleImageDialog'

const pathsToUpdate = ['/', '/my-articles', '/recipe-queue', '/my-recipes', '/podcasts']

class ArticlesContainer extends React.Component {
  static propTypes = {
    toggleCreateArticleMenu: PropTypes.func,
    getEditorsList: PropTypes.func,
    getPartnersList: PropTypes.func,
    loading: PropTypes.bool,
    errorType: PropTypes.string,
    flashMessage: PropTypes.string,
    hideErrorMessage: PropTypes.func,
    location: PropTypes.object,
    userId: PropTypes.number,
    loadArticles: PropTypes.func,
    articles: PropTypes.object,
    updateArticle: PropTypes.func,
    updatePreviewImage: PropTypes.func,
    toggleArchiveDialog: PropTypes.func,
    archive: PropTypes.object,
    archiveArticle: PropTypes.func,
    updateTitle: PropTypes.func,
    updateUrl: PropTypes.func,
    updateEditorsNotes: PropTypes.func,
    setPublishDT: PropTypes.func,
    updatingArticles: PropTypes.object,
    compressedView: PropTypes.bool,
    toggleCompressedView: PropTypes.func,
    toggleCompressedViewItem: PropTypes.func,
    showFlashMessage: PropTypes.func,
    needsReload: PropTypes.bool,
    showKickUserPrompt: PropTypes.bool,
    kickUser: PropTypes.func,
    userToKick: PropTypes.object,
  }

  state = {
    editPreviewImage: null,
    previewImageDialogOpen: false,
    selectedArticle: null,
  }

  componentDidMount() {
    if (!this.props.articles) {
      this.props.articlesReload()
    }
  }

  UNSAFE_componentWillMount() {
    const { toggleCreateArticleMenu, getEditorsList, getPartnersList, needsReload } = this.props
    toggleCreateArticleMenu(true)
    getEditorsList()
    getPartnersList()

    const { userId, articles, loadArticles } = this.props
    if ((userId && !articles) || needsReload) {
      loadArticles(this.getArticleType())
    }

    this.routeListener = this.props.history.listen((location, action) => {
      if (pathsToUpdate.includes(location.pathname)) {
        this.props.articlesReload()
      }
    })
  }

  componentDidUpdate(prevProps) {
    const {
      userId,
      articles,
      // articleType,
      loadArticles,
      location: { pathname },
    } = this.props
    // profile was loaded, initiate article load if no articles loaded
    if ((!prevProps.userId && userId && !articles) || (prevProps.articles && !articles)) {
      loadArticles(this.getArticleType(pathname))
    }
  }

  componentWillUnmount() {
    const { toggleCreateArticleMenu } = this.props
    toggleCreateArticleMenu(false)
    this.routeListener()
  }

  getArticleType = (pathnameArg) => {
    const {
      location: { pathname },
    } = this.props
    const pathNameToUse = pathnameArg || pathname

    return pathNameToUse.includes('recipe') ? 'recipes' : 'articles'
  }

  updateArticle = (article, index, dateKey) => {
    const { updateArticle } = this.props
    // these fields are not passing validation at this point
    delete article.author
    delete article.editor
    delete article.compressed
    updateArticle(this.getArticleType(), article.id, index, dateKey, article)
  }

  render() {
    const { previewImageDialogOpen, selectedArticle, editPreviewImage } = this.state
    const {
      loading,
      errorType,
      flashMessage,
      hideErrorMessage,
      updatePreviewImage,
      archive,
      toggleArchiveDialog,
      archiveArticle,
      articles,
      updateEditorsNotes,
      setPublishDT,
      updatingArticles,
      compressedView,
      toggleCompressedView,
      toggleCompressedViewItem,
      location: { pathname },
    } = this.props

    const updatePreviewImageHandler = (image) => {
      const [date, index] = selectedArticle
      const updateHero = image.heroImage
      delete image.heroImage
      updatePreviewImage(date, index, image, updateHero)
      this.setState({ previewImageDialogOpen: false })
    }

    const isArticle = this.getArticleType() === 'articles'
    const articleProps = isArticle
      ? {
          pathname: '/article/edit',
        }
      : {
          pathname: '/recipe/edit',
        }

    return (
      <StyledColumn>
        <LoaderContainer isLoading={loading} />
        {previewImageDialogOpen && (
          <SingleImageDialog
            imageTitle="Preview Image"
            show={previewImageDialogOpen}
            namespace="queuePreview"
            initialState={editPreviewImage ? editPreviewImage.toJS() : null}
            onCancel={() => this.setState({ previewImageDialogOpen: false })}
            minWidth={1120}
            crops={[
              {
                width: 1120,
                aspect: 1.5,
              },
              {
                width: 1120,
                aspect: 1.78,
              },
              {
                width: 600,
                aspect: 1.0,
              },
            ]}
            onEditImage={updatePreviewImageHandler}
            onInsertImage={updatePreviewImageHandler}
          />
        )}
        {archive.get('showModal') && (
          <ArchiveDialog
            open={archive.get('showModal')}
            isPublished={archive.get('isPublished')}
            articleId={archive.get('articleId')}
            loading={archive.get('loading')}
            articlePath={archive.get('articlePath')}
            toggleArchiveDialog={toggleArchiveDialog}
            archiveArticle={archiveArticle}
          />
        )}
        {articles && (
          <RenderArticles
            articles={articles}
            setPublishDT={setPublishDT}
            updateEditorsNotes={updateEditorsNotes}
            toggleArchiveDialog={toggleArchiveDialog}
            updatingArticles={updatingArticles}
            updateArticle={this.updateArticle}
            onEditPreviewImage={(image, date, index) => {
              this.setState({
                editPreviewImage: image,
                previewImageDialogOpen: true,
                selectedArticle: [date, index],
              })
            }}
            compressedView={compressedView}
            toggleCompressedView={toggleCompressedView}
            toggleCompressedViewItem={toggleCompressedViewItem}
            showCompressedViewBtn
            {...articleProps}
            isArticle={isArticle}
            showFilter={!pathname.includes('my-')}
          />
        )}
        {!articles && !loading && <NoResults />}
        <FlashMessage
          open={flashMessage !== ''}
          severity={!!errorType ? 'error' : 'info'}
          message={<InfoMessage errorType={errorType} message={flashMessage} />}
          hide={hideErrorMessage}
        />
      </StyledColumn>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    articles: state.articles.get('articles'),
    loading: state.articles.get('loading'),
    errorType: state.articles.get('errorType'),
    flashMessage: state.articles.get('flashMessage'),
    userId: state.profile.getIn(['profile', 'userId']),
    archive: state.articles.get('archive'),
    updatingArticles: state.articles.get('updatingArticles'),
    compressedView: state.articles.get('compressedView'),
    needsReload: state.articles.get('needsReload'),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleCreateArticleMenu: (showBool) => dispatch(toggleCreateArticleMenu(showBool)),
    hideErrorMessage: () => dispatch(hideErrorMessage()),
    getEditorsList: () => dispatch(getEditorsList()),
    getPartnersList: () => dispatch(getPartnersList()),
    loadArticles: (route) => dispatch(loadArticles({ route })),
    updateArticle: (route, id, index, dateKey, article) =>
      dispatch(updateArticle({ route, id, index, dateKey, article })),
    updatePreviewImage: (dateKey, articleIdx, previewImage) => {
      dispatch(updatePreviewImage({ dateKey, articleIdx, previewImage }))
    },
    toggleArchiveDialog: ({ show, id, isPublished, articlePath }) =>
      dispatch(toggleArchiveDialog({ show, id, isPublished, articlePath })),
    archiveArticle: ({ id, articlePath }) => dispatch(archiveArticle({ id, articlePath })),
    updateEditorsNotes: (articleIdx, dateKey, editorsNotes) => {
      return dispatch(updateEditorsNotes({ articleIdx, dateKey, editorsNotes }))
    },
    setPublishDT: (dateTime, dateKey, articleIdx) => dispatch(setPublishDT({ dateTime, dateKey, articleIdx })),
    toggleCompressedView: () => dispatch(toggleCompressedView()),
    toggleCompressedViewItem: (dateKey, index) => dispatch(toggleCompressedViewItem({ dateKey, index })),
    articlesReload: () => {
      dispatch({ type: ARTICLES_RELOAD })
      dispatch(reset('queueFilterForm'))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ArticlesContainer))
