import ReactDOM from 'react-dom'
import moment from 'moment-timezone'

import App from 'App'
import { NYTZ } from './utils/formatDate'

moment.tz.setDefault(NYTZ)

const MOUNT_NODE = document.getElementById('root')

ReactDOM.render(<App />, MOUNT_NODE)
