import { RSAA } from 'redux-api-middleware'

const { REACT_APP_API_URL } = process.env

const apiUrlMiddleware = (store) => (next) => (action) => {
  const values = action[RSAA]
  if (values && !values.endpoint.startsWith('http')) {
    return next({
      [RSAA]: {
        ...values,
        endpoint: `${REACT_APP_API_URL}${values.endpoint}`,
      },
    })
  } else return next(action)
}

export default apiUrlMiddleware
