import { Map, fromJS } from 'immutable'
import {
  LOAD_CONTENTFUL_PROMOTIONAL_UNITS_REQUEST,
  LOAD_CONTENTFUL_PROMOTIONAL_UNITS_SUCCESS,
  LOAD_CONTENTFUL_PROMOTIONAL_UNITS_FAILURE,
} from 'utils/constants/actions'

const tagsReducer = (state = Map(), action) => {
  switch (action.type) {
    case LOAD_CONTENTFUL_PROMOTIONAL_UNITS_REQUEST:
      return state.set('loading', true)

    case LOAD_CONTENTFUL_PROMOTIONAL_UNITS_SUCCESS:
      return state.set('loading', false).set('promotionalUnits', fromJS(action.payload)).set('error', null)

    case LOAD_CONTENTFUL_PROMOTIONAL_UNITS_FAILURE:
      return state.set('loading', false).set('error', action.payload)

    default:
      return state
  }
}

export default tagsReducer
