import { fromJS } from 'immutable'
import moment from 'moment'

export const initialArticlesState = fromJS({
  loading: true,
  errorType: '',
  flashMessage: '',
  articles: null,
  groupedByPublishDate: {},
  dateChanged: false,
  currentArticleIdx: 0,
  showDTpicker: false,
  tempDt: moment().format(),
  sortBy: 'publish_time',
  sortDir: 'DESC',
  archive: {
    showModal: false,
    articleId: null,
    isPublished: false,
    loading: false,
    path: [],
  },
  updatingArticles: {},
  compressedView: false,
})
