import { ADMIN, EDITORIAL_STAFF, COPY_EDITOR, FREELANCE_EDITOR, FREELANCE_WRITER, SALES_TEAM } from './constants/roles'
import {
  CREATE_ARTICLE,
  EDIT_ARTICLE,
  CREATE_MEDIA,
  EDIT_MEDIA,
  VIEW_QUEUE,
  EDIT_FOREIGN_ARTICLE,
  PUBLISH_ARTICLE,
  REPUBLISH_ARTICLE,
  UPDATE_LIVE_ARTICLE,
  CREATE_CONTRIBUTOR,
  MANAGE_CONTRIBUTOR,
  MANAGE_SERIES,
  EDIT_SERIES,
  CREATE_TAGS,
  EDIT_TAGS,
  MANAGE_PODCASTS,
  MANAGE_PARTNERS,
  MANAGE_PRODUCTS,
  KICK_USERS,
  SUPPORT_MANAGEMENT,
} from './constants/privileges'

const waveSalesTeam = [CREATE_ARTICLE, VIEW_QUEUE]
const waveFreelanceWriter = [CREATE_ARTICLE, EDIT_ARTICLE, CREATE_MEDIA, MANAGE_PRODUCTS]
const waveFreelanceEditor = [...waveFreelanceWriter, EDIT_MEDIA]

const waveCopyEditor = [
  // remove MANAGE_PRODUCTS from waveCopyEditor
  ...waveFreelanceEditor.filter((privilege) => privilege !== MANAGE_PRODUCTS),
  VIEW_QUEUE,
  EDIT_FOREIGN_ARTICLE,
  REPUBLISH_ARTICLE,
  UPDATE_LIVE_ARTICLE,
]

const waveEditorialStaff = [
  ...waveCopyEditor,
  PUBLISH_ARTICLE,
  CREATE_CONTRIBUTOR,
  MANAGE_CONTRIBUTOR,
  MANAGE_PODCASTS,
  MANAGE_PARTNERS,
  MANAGE_PRODUCTS,
]
const waveAdmin = [
  ...waveEditorialStaff,
  MANAGE_SERIES,
  EDIT_SERIES,
  CREATE_TAGS,
  EDIT_TAGS,
  KICK_USERS,
  SUPPORT_MANAGEMENT,
]

const privilegesByRole: Record<string, string[]> = {
  [SALES_TEAM]: waveSalesTeam,
  [FREELANCE_WRITER]: waveFreelanceWriter,
  [FREELANCE_EDITOR]: waveFreelanceEditor,
  [COPY_EDITOR]: waveCopyEditor,
  [EDITORIAL_STAFF]: waveEditorialStaff,
  [ADMIN]: waveAdmin,
}

export default function getPrivilegesByRole(role: string) {
  return privilegesByRole[role] || []
}
