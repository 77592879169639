const syndications = [
  'mindbodygreen.com',
  'Daily Newsletter',
  'Facebook',
  'Apple News',
  'Google AMP',
  'Google News',
  'SmartNews',
]

const internalNotesFlags = [{ type: 'creative' }, { type: 'copy' }, { type: 'publication' }]

export function createSydnicationsFormValues(syndFromAPI) {
  return syndications.map((s) => {
    if (syndFromAPI.includes(s)) return s
    else return null
  })
}

export function createInternalNotesFlagsValues(flagsFromAPI) {
  return internalNotesFlags.map((internalFlag) => {
    const findFlag = flagsFromAPI.find((flag) => flag.type === internalFlag.type)
    if (findFlag !== -1) return findFlag
    else return null
  })
}

export function createAnalyticsFormValues(flagsFromAPI) {
  const obj = {}
  flagsFromAPI.forEach((flag) => {
    obj[flag.property] = flag.value
  })
  return obj
}
// export function createFormFlagsValues (flagsFromAPI) {
//   let flags = {}
//   if (!flagsFromAPI.length) return flags

//   flagsFromAPI.map((f, i) => {
//     if (f.type === 'creative' || f.type === 'publication' || f.type === 'copy') flags[f.type] = f
//   })
//   return flags
// }
