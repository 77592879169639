import { Map, fromJS } from 'immutable'
import {
  SEARCH_ARTICLE_ID_REQUEST,
  SEARCH_ARTICLE_ID_SUCCESS,
  SEARCH_ARTICLE_ID_FAILURE,
  SEARCH_ARTICLE_TITLE_REQUEST,
  SEARCH_ARTICLE_TITLE_SUCCESS,
  SEARCH_ARTICLE_TITLE_FAILURE,
  SEARCH_ARTICLE_URL_REQUEST,
  SEARCH_ARTICLE_URL_SUCCESS,
  SEARCH_ARTICLE_URL_FAILURE,
} from 'utils/constants/actions'

const articleSearchReducer = (state = Map(), action) => {
  switch (action.type) {
    case SEARCH_ARTICLE_ID_REQUEST:
    case SEARCH_ARTICLE_TITLE_REQUEST:
    case SEARCH_ARTICLE_URL_REQUEST:
      return state.set('loading', true).set('articles', fromJS([]))
    case SEARCH_ARTICLE_ID_FAILURE:
    case SEARCH_ARTICLE_TITLE_FAILURE:
    case SEARCH_ARTICLE_URL_FAILURE:
      return state.set('loading', false).set('error', action.payload)
    case SEARCH_ARTICLE_ID_SUCCESS:
      return state.set('loading', false).set('articles', fromJS([action.payload]))
    case SEARCH_ARTICLE_TITLE_SUCCESS:
    case SEARCH_ARTICLE_URL_SUCCESS:
      return state.set('loading', false).set('articles', fromJS(action.payload))
    default:
      return state
  }
}

export default articleSearchReducer
