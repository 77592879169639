const errors: Record<string, string> = {
  updatingArticle: 'Cannot save your article',
  loadingArticles: 'Cannot load article list',
}

interface Props {
  errorType: string
  message?: string
}

const InfoMessage = (props: Props) => {
  return (
    <>
      {errors[props.errorType] !== undefined && (
        <>
          {errors[props.errorType]}
          {props.message ? ': ' : undefined}
        </>
      )}

      {props.message}
    </>
  )
}

export default InfoMessage
