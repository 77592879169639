import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import BoldIcon from '@mui/icons-material/FormatBold'
import ItalicIcon from '@mui/icons-material/FormatItalic'
import UnderlinedIcon from '@mui/icons-material/FormatUnderlined'

const BaseToolbar = ({ id, children }) => (
  <Paper id={id} elevation={2}>
    <IconButton className="ql-bold">
      {/* tooltip="Bold"> */}
      <BoldIcon />
    </IconButton>
    <IconButton className="ql-italic">
      {/* tooltip="Italic"> */}
      <ItalicIcon />
    </IconButton>
    <IconButton className="ql-underline">
      {/* tooltip="Underline"> */}
      <UnderlinedIcon />
    </IconButton>
    {children}
  </Paper>
)

BaseToolbar.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
}

export default BaseToolbar
