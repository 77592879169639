import { RSAA } from 'redux-api-middleware'

const analyzeTextMiddleware = (store) => (next) => (action) => {
  const values = action[RSAA]
  if (values && values.endpoint.includes('analyze/text')) {
    const { createArticleBody } = store.getState()
    const components = createArticleBody.getIn(['present', 'components'])
    let text = ''

    components.toJS().forEach((c) => {
      if (c.text) {
        const newText = c.text.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/gi, ' ')
        text = text.concat(` ${newText}`)
      }
    })

    // add space after each punctuation
    text = text.replace(/[,.!?:;](?=\S)/g, '$& ')

    const newAction = { ...values, body: JSON.stringify({ text }) }
    next({
      [RSAA]: {
        ...newAction,
      },
    })
  } else next(action)
}

export default analyzeTextMiddleware
