const supportedFractions = [
  '1/2',
  '1/3',
  '1/4',
  '1/5',
  '1/6',
  '1/8',
  '2/3',
  '2/5',
  '3/4',
  '3/5',
  '3/8',
  '4/5',
  '5/6',
  '5/8',
  '7/8',
]

const convertTextToFraction = (text: string) => {
  if (supportedFractions.includes(text)) {
    const firstChar = text.charAt(0)
    const lastChar = text.charAt(2)

    return `&frac${firstChar}${lastChar};`
  }

  return text
}

export default convertTextToFraction

// ---  Another solution

// const supportedFractions = [
//   '&frac12;',
//   '&frac13;',
//   '&frac14;',
//   '&frac15;',
//   '&frac16;',
//   '&frac18;',
//   '&frac23;',
//   '&frac25;',
//   '&frac34;',
//   '&frac35;',
//   '&frac38;',
//   '&frac45;',
//   '&frac56;',
//   '&frac58;',
//   '&frac78;',
// ]

// const validateFractionText = text => {
//   // check if it's a valid (fraction) text & if text.length === 3
//   const rgxTest = /^(?:\d+ )?\d+\/\d+$|^\d+$/.test(text)
//   if (text.length === 3 && rgxTest) {
//     const firstChar = text.charAt(0)
//     const lastChar = text.charAt(2)

//     if (firstChar === lastChar) return text

//     const frac = `&frac${firstChar}${lastChar};`

//     return supportedFractions.includes(frac) ? frac : text
//   }

//   return text
// }
