import PropTypes from 'prop-types'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'

const CompressToggle = ({ compressedView, toggleCompressedView }) => (
  <div
    style={{
      display: 'inline-flex',
      marginRight: '15px',
    }}
  >
    <FormControlLabel
      label={<Typography sx={{ fontSize: '14px' }}>COMPRESS</Typography>}
      labelPlacement="start"
      control={
        <Switch checked={compressedView} onChange={(e) => toggleCompressedView(e.target.checked)} color="primary" />
      }
    />
  </div>
)

CompressToggle.propTypes = {
  compressedView: PropTypes.bool,
  toggleCompressedView: PropTypes.func,
}

export default CompressToggle
