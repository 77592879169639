import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'

const ChipInputField = (props) => {
  const handleChange = (e, values) => {
    if (props.dataSourceConfig) {
      const config = Object.values(props.dataSourceConfig)
      props.input.onChange(
        values.map((v) =>
          config.reduce((acc, key) => {
            acc[key] = v[key]
            return acc
          }, {})
        )
      )
    } else {
      props.input.onChange(values)
    }
  }

  const {
    meta: { touched, error },
    disableCustomInput,
    dataSource = [],
    dataSourceConfig,
    fullWidth,
    label,
  } = props

  const values = props.input.value || []

  return (
    <Autocomplete
      {...props.inputProps}
      multiple
      disableClearable
      id="internal-tags"
      isOptionEqualToValue={
        dataSourceConfig ? (o, v) => o[dataSourceConfig.value] === v[dataSourceConfig.value] : undefined
      }
      options={dataSource}
      value={values}
      freeSolo={!disableCustomInput}
      onChange={handleChange}
      getOptionLabel={(o) => (dataSourceConfig ? o[dataSourceConfig.text] : o)}
      renderTags={(value, getTagProps) =>
        value?.map((option, index) => (
          <Chip
            variant="outlined"
            label={dataSourceConfig ? option[dataSourceConfig.text] : option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          error={touched && error}
          helperText={touched && error}
          {...params}
          variant="standard"
          label={label}
        />
      )}
      fullWidth={fullWidth}
    />
  )
}

ChipInputField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  disableCustomInput: PropTypes.bool,
}

export default ChipInputField
