import { Map, fromJS } from 'immutable'
import {
  LOAD_SERIES_REQUEST,
  LOAD_SERIES_SUCCESS,
  LOAD_SERIES_FAILURE,
  TOGGLE_SERIES_DIALOG,
  ADD_SERIES_REQUEST,
  ADD_SERIES_SUCCESS,
  ADD_SERIES_FAILURE,
  LOAD_SERIE_REQUEST,
  LOAD_SERIE_SUCCESS,
  LOAD_SERIE_FAILURE,
  UPDATE_SERIE_REQUEST,
  UPDATE_SERIE_SUCCESS,
  UPDATE_SERIE_FAILURE,
  SERIE_HIDE_FLASH_MESSAGE,
} from 'utils/constants/actions'

const tagsReducer = (state = Map(), action) => {
  switch (action.type) {
    case LOAD_SERIES_REQUEST:
      return state.set('loading', true)
    case LOAD_SERIES_FAILURE:
      return state.set('loading', false).set('error', action.payload)
    case LOAD_SERIES_SUCCESS:
      return state.set('loading', false).set('series', fromJS(action.payload))
    case TOGGLE_SERIES_DIALOG:
      return state.set('showDialog', action.show).set('seriesName', action.name)
    case ADD_SERIES_REQUEST:
      return state.set('dialogLoading', true).set('creating', true).set('createError', null)
    case ADD_SERIES_SUCCESS:
      return state
        .set('dialogLoading', false)
        .set('creating', false)
        .update('series', (series) => series.push(fromJS(action.payload)))
        .set('showDialog', false)
    case ADD_SERIES_FAILURE:
      return state
        .set('dialogLoading', false)
        .set('creating', false)
        .set('createError', action.payload)
        .set('snackMessage', `Failed to create a serie: ${action.payload.response.message}`)
    case LOAD_SERIE_REQUEST:
      return state.set('loading', true)
    case LOAD_SERIE_SUCCESS:
      return state.set('loading', false).set('serie', fromJS(action.payload))
    case LOAD_SERIE_FAILURE:
      return state.set('loading', false).set('error', action.payload)
    case UPDATE_SERIE_REQUEST:
      return state.set('updating', true).set('updateError', null)
    case UPDATE_SERIE_SUCCESS:
      return state.set('updating', false)
    case UPDATE_SERIE_FAILURE:
      return state
        .set('updating', false)
        .set('updateError', action.payload)
        .set('snackMessage', `Error updating serie: ${action.payload.response.message}`)
    case SERIE_HIDE_FLASH_MESSAGE:
      return state.set('snackMessage', null)
    default:
      return state
  }
}

export default tagsReducer
