import axios from './axios'

export const getClass = async (id) => {
  const { data } = await axios.get(`classes/${id}?noComposePhases=true`)
  return data
}

const createClassDTO = (classObject) => {
  return {
    ...classObject,
    modules: classObject.modules.map((m) => (typeof m === 'string' ? m : m.id)),
  }
}

const createNewClassDTO = (classObject) => {
  const { id, tools, ...rest } = classObject
  return { ...rest, modules: [] }
}

const createClass = async (newClass) => {
  const { data } = await axios.post('classes/', createNewClassDTO(newClass))
  return data
}

export const editClass = async (mutatedClass) => {
  const { data } = await axios.put(`classes/${mutatedClass.id}`, createClassDTO(mutatedClass))
  return data
}

export const getClasses = async (params) => {
  const { data } = await axios.get(`classes${params ? `?${new URLSearchParams(params).toString()}` : ''}`)
  return data
}

export const createLesson = async (classId, params) => {
  const { data } = await axios.post(`classes/${classId}/lessons`, params)
  return data
}

export const updateLesson = async (classId, lessonId, params) => {
  const { data } = await axios.put(`classes/${classId}/lessons/${lessonId}`, params)
  return data
}

export const createLiveLesson = async (classId, body) => {
  const { data } = await axios.post(`classes/${classId}/lessons`, body)
  return data
}

export const updateLiveLesson = async (classId, lessonId, body) => {
  const { data } = await axios.put(`classes/${classId}/lessons/${lessonId}`, body)
  return data
}

export const createTool = async (classId, body) => {
  const { data } = await axios.post(`classes/${classId}/tools`, body)
  return data
}

export const updateTool = async (classId, toolId, body) => {
  const { data } = await axios.put(`classes/${classId}/tools/${toolId}`, body)
  return data
}

export const uploadFile = async (body) => {
  const { data } = await axios.post('classes/tool-file', body)
  return data
}

export const createModule = async (classId, module) => {
  const { data } = await axios.post(`classes/${classId}/modules`, module)
  return data
}

export const updateModule = async (classId, moduleId, partialModule) => {
  const { data } = await axios.put(`classes/${classId}/modules/${moduleId}`, partialModule)
  return data
}

export const createTerm = async (classId, body) => {
  const { data } = await axios.post(`classes/${classId}/terms`, body)
  return data
}

export const updateTerm = async (classId, termId, body) => {
  const { data } = await axios.put(`classes/${classId}/terms/${termId}`, body)
  return data
}

const getVersions = async (classId, lessonId, body) => {
  const { data } = await axios.get(`classes/${classId}/lessons/${lessonId}/versions`, body)
  return data
}

const createVersion = async (classId, lessonId, body) => {
  const { data } = await axios.post(`classes/${classId}/lessons/${lessonId}/versions`, body)
  return data
}

const updateVersion = async (classId, lessonId, versionId, body) => {
  const { data } = await axios.put(`classes/${classId}/lessons/${lessonId}/versions/${versionId}`, body)
  return data
}

const deleteVersion = async (classId, lessonId, versionId) => {
  const { data } = await axios.delete(`classes/${classId}/lessons/${lessonId}/versions/${versionId}`)
  return data
}

export const getVideoAssignments = async (id) => {
  const { data } = await axios.get('video-assignments')
  return data
}

export const getVideoAssignmentsByClassId = async (id) => {
  const { data } = await axios.get(`video-assignments/by-class-id/${id}`)
  return data
}

export const getVideoAssignmentsByClassAndTermId = async (classId, termId) => {
  const { data } = await axios.get(`video-assignments/by-class-and-term-id/${classId}/${termId ? termId : ''}`)
  return data
}

export const createVideoAssignment = async (body) => {
  const { data } = await axios.post('video-assignments', body)
  return data
}

export const updateVideoAssignment = async (body) => {
  const { data } = await axios.put('video-assignments', body)
  return data
}

export const updateVideoAssignmentSubmission = async (body) => {
  const { data } = await axios.put('video-assignment-submissions', body)
  return data
}

export const updateVideoAssignmentSubmissionVideoStatus = async (body) => {
  const { data } = await axios.patch('video-assignment-submissions/video-status', body)
  return data
}

export const getSubmissionsByUserAndAssignmentId = async (userId, videoAssignmentId) => {
  const { data } = await axios.get(
    `video-assignment-submissions/by-user-and-video-assignment-id/${userId}/${videoAssignmentId}`
  )
  return data
}

export const getSubmissionsByClassAndAssignmentId = async (classId, videoAssignmentId) => {
  const { data } = await axios.get(
    `video-assignment-submissions/by-class-and-video-assignment-id/${classId}/${videoAssignmentId}`
  )
  return data
}

export const classesTransportLayer = {
  getClass,
  createClass,
  editClass,
  getClasses,
  createLesson,
  updateLesson,
  createLiveLesson,
  updateLiveLesson,
  createTool,
  updateTool,
  uploadFile,
  createModule,
  updateModule,
  createTerm,
  updateTerm,
  updateVersion,
  createVersion,
  deleteVersion,
  getVersions,
  getVideoAssignments,
  getVideoAssignmentsByClassId,
  getVideoAssignmentsByClassAndTermId,
  createVideoAssignment,
  updateVideoAssignment,
  updateVideoAssignmentSubmission,
  updateVideoAssignmentSubmissionVideoStatus,
  getSubmissionsByUserAndAssignmentId,
  getSubmissionsByClassAndAssignmentId,
}
