import React, { Component } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

import { GalleryData, SlideData, ImageGrid } from './components'
import SingleImageInsert from './components/SingleImageInsert'
import styled from 'styled-components'
import StyledFlex, { StyledColumn } from '../../../../layouts/StyledLayouts'
import { grey30 } from '../../../../styles/colors'
import CommonDialogTitle from '../../../Common/DialogComponents/CommonDialogTitle'

export const SectionHeader = styled.h4`
  margin-bottom: 0;
  color: ${grey30};
`

const SCREEN_GALLERY_DATA = 'gallery-data'
const SCREEN_SLIDE_MANAGEMENT = 'slide-management'
const SCREEN_SLIDE_REORDER = 'slide-reorder'

const TITLES = {}
TITLES[SCREEN_GALLERY_DATA] = 'Gallery information'
TITLES[SCREEN_SLIDE_MANAGEMENT] = 'Manage slide data'
TITLES[SCREEN_SLIDE_REORDER] = 'Gallery - drag to reorder'

const initialState = {
  screen: SCREEN_GALLERY_DATA,
  title: TITLES[SCREEN_GALLERY_DATA],
  slidesPristine: true, // no slides added yet
  editSlideIndex: -1,
  selectedCropIndex: -1,
  selectedImage: null,
  gallery: null,
  slides: [],
  images: [],
  imageMode: false,
  anchor: shortid.generate(),
}

const getCrops = () => {
  const width = 900
  return [
    {
      width,
      aspect: 1.78,
    },
    {
      width,
      aspect: 1,
    },
  ]
}

class ImageGallery extends Component {
  static propTypes = {
    show: PropTypes.bool,
    index: PropTypes.number,
    editComponent: PropTypes.object,
    toggleEditDialog: PropTypes.func,
    insertComponent: PropTypes.func,
    updateComponent: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = initialState

    const { editComponent } = props
    if (editComponent) {
      this.state.gallery = {
        type: 'gallery',
        title: editComponent.get('title'),
        priority: editComponent.get('priority'),
        layout: editComponent.get('layout'),
        galleryType: editComponent.get('galleryType'),
      }
      this.state.anchor = editComponent.get('anchor')
      this.state.slides = editComponent.get('slides').toJS()
      this.state.images = editComponent
        .get('slides')
        .map((slide) => slide.get('image'))
        .toJS()
      this.state.slidesPristine = false
    }
  }

  // saves gallery metadata form values to local state and proceeds
  // to either slide reordering or adding a new slide, depending on
  // whether any slides have been added in the past
  handleGalleryFormData(data) {
    const screen = this.state.slidesPristine ? SCREEN_SLIDE_MANAGEMENT : SCREEN_SLIDE_REORDER
    this.setState({
      gallery: Object.assign(data, { type: 'gallery' }),
      screen: screen,
      title: TITLES[screen],
    })
  }

  // cancels editing slide info and returns either to gallery data page
  // if no slides have been added yet or to the reorder slides pages
  // if user was adding a new slide or editing an existing one
  cancelSlideManagement() {
    const { slidesPristine } = this.state
    const newScreen = slidesPristine ? SCREEN_GALLERY_DATA : SCREEN_SLIDE_REORDER
    this.setState({
      editSlideIndex: -1,
      screen: newScreen,
      title: TITLES[newScreen],
    })
  }

  // a new slide was added or an existing one was edited
  handleSlideFormData(data) {
    const { slides, editSlideIndex } = this.state

    const newSlides = slides.slice()
    if (editSlideIndex < 0) {
      newSlides.push(data)
      this.setState({
        slides: newSlides,
        slidesPristine: false,
        imageMode: true,
      })
    } else {
      newSlides[editSlideIndex] = data
      this.setState({
        slides: newSlides,
        editSlideIndex: -1,
        screen: SCREEN_SLIDE_REORDER,
        title: TITLES[SCREEN_SLIDE_REORDER],
      })
    }
  }

  // during editing operation of the slide user also requested to
  // edit the image
  handleSlideEditImage() {
    this.setState({
      imageMode: true,
    })
  }

  // user cancelled image edit/upload step, move back to slide editing
  handleImageCancel() {
    const { editSlideIndex, slides } = this.state

    if (editSlideIndex < 0) {
      const newSlides = slides.slice(0, -1)
      this.setState({
        slides: newSlides,
        slidesPristine: newSlides.length === 0,
      })
    }

    this.setState({
      imageMode: false,
      screen: SCREEN_SLIDE_MANAGEMENT,
      title: TITLES[SCREEN_SLIDE_MANAGEMENT],
    })
  }

  // user finished image edit step, update the image and move on
  handleImageEdit(image) {
    const { editSlideIndex, images } = this.state

    const newImages = images.slice()
    const index = editSlideIndex > -1 ? editSlideIndex : newImages.length - 1
    Object.assign(newImages[index], image)

    this.setState({
      images: newImages,
      imageMode: false,
      editSlideIndex: -1,
      screen: SCREEN_SLIDE_REORDER,
      title: TITLES[SCREEN_SLIDE_REORDER],
    })
  }

  // user finished image upload step, save the image and move on
  handleImageInsert(image) {
    const newImages = this.state.images.slice()
    newImages.push(image)

    this.setState({
      images: newImages,
      imageMode: false,
      screen: SCREEN_SLIDE_REORDER,
      title: TITLES[SCREEN_SLIDE_REORDER],
    })
  }

  // re-orders the slides along with images according to the
  // drag feature
  changeSlideOrder(newOrder) {
    this.setState({
      images: newOrder.map((o) => o.image),
      slides: newOrder.map((o) => o.slide),
    })
  }

  // user requested to add a new slide
  addNewSlide() {
    this.setState({
      screen: SCREEN_SLIDE_MANAGEMENT,
      title: TITLES[SCREEN_SLIDE_MANAGEMENT],
    })
  }

  // user has requested to edit slide data
  editSlide(item) {
    this.setState({
      editSlideIndex: item.key,
      screen: SCREEN_SLIDE_MANAGEMENT,
      title: TITLES[SCREEN_SLIDE_MANAGEMENT],
    })
  }

  // user has requested to remove slide data
  removeSlide(item) {
    const { slides, images } = this.state
    const newSlides = slides.slice()
    newSlides.splice(item.key, 1)

    const newImages = images.slice()
    newImages.splice(item.key, 1)

    this.setState({
      slides: newSlides,
      images: newImages,
    })
  }

  updateComponent = (data) => {
    const { index, editComponent, updateComponent, toggleEditDialog } = this.props
    const updatedComponent = Object.assign(editComponent.toJS(), data)
    updateComponent(index, updatedComponent)
    toggleEditDialog(false)
  }

  // user is finished, insert the gallery as a component
  saveGallery() {
    const { editComponent, toggleEditDialog, insertComponent } = this.props

    const data = {
      ...this.state.gallery,
      anchor: this.state.anchor,
      slides: this.state.slides.map((slide, idx) => {
        return {
          ...slide,
          image: this.state.images[idx],
        }
      }),
    }

    if (editComponent !== undefined) this.updateComponent(data)
    else insertComponent('gallery', data)
    toggleEditDialog(false)
  }

  render() {
    const { show, toggleEditDialog, editComponent } = this.props
    const { title, screen, gallery, slides, images, editSlideIndex, imageMode } = this.state

    return (
      <Dialog open={show} fullWidth onClose={() => toggleEditDialog(false)}>
        {imageMode && (
          <SingleImageInsert
            imageTitle="Gallery image"
            initialState={editSlideIndex > -1 ? images[editSlideIndex] : null}
            namespace="gallery"
            onInsertImage={(image) => this.handleImageInsert(image)}
            onEditImage={(image) => this.handleImageEdit(image)}
            onCancel={() => this.handleImageCancel()}
            crops={getCrops()}
            show
          />
        )}
        {!imageMode && (
          <CommonDialogTitle onClose={() => toggleEditDialog(false)}>
            <div>{title}</div>
          </CommonDialogTitle>
        )}
        {!imageMode &&
          (() => {
            switch (screen) {
              case SCREEN_GALLERY_DATA:
                return (
                  <DialogContent>
                    <GalleryData onSubmit={(data) => this.handleGalleryFormData(data)} initialValues={gallery} />
                  </DialogContent>
                )

              case SCREEN_SLIDE_MANAGEMENT:
                return (
                  <DialogContent>
                    <SlideData
                      handleCancel={() => this.cancelSlideManagement()}
                      handleEditImageData={() => this.handleSlideEditImage()}
                      initialValues={editSlideIndex > -1 ? slides[editSlideIndex] : null}
                      image={editSlideIndex > -1 ? images[editSlideIndex] : null}
                      onSubmit={(data) => this.handleSlideFormData(data)}
                      hasLocation={gallery.galleryType === 'Location'}
                    />
                  </DialogContent>
                )

              case SCREEN_SLIDE_REORDER:
                return (
                  <StyledColumn>
                    <ImageGrid
                      onChangeOrder={(order) => this.changeSlideOrder(order)}
                      items={images.map((img, i) => {
                        return {
                          key: i,
                          image: img,
                          slide: slides[i],
                        }
                      })}
                      onEditItem={(item) => this.editSlide(item)}
                      onRemoveItem={(item) => this.removeSlide(item)}
                      removeDisabled={slides.length < 2}
                    />
                    <StyledFlex style={{ justifyContent: 'end' }}>
                      <Button onClick={() => this.addNewSlide()}>Add another slide</Button>
                      <Button onClick={() => this.saveGallery()}>
                        {editComponent ? 'Update gallery' : 'Insert gallery'}
                      </Button>
                    </StyledFlex>
                  </StyledColumn>
                )
              default:
                return null
            }
          })()}
      </Dialog>
    )
  }
}

export default ImageGallery
