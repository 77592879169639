import { RSAA } from 'redux-api-middleware'
import {
  LOAD_SERIES_REQUEST,
  LOAD_SERIES_SUCCESS,
  LOAD_SERIES_FAILURE,
  TOGGLE_SERIES_DIALOG,
  ADD_SERIES_REQUEST,
  ADD_SERIES_SUCCESS,
  ADD_SERIES_FAILURE,
  LOAD_SERIE_REQUEST,
  LOAD_SERIE_SUCCESS,
  LOAD_SERIE_FAILURE,
  UPDATE_SERIE_REQUEST,
  UPDATE_SERIE_SUCCESS,
  UPDATE_SERIE_FAILURE,
  SERIE_HIDE_FLASH_MESSAGE,
} from 'utils/constants/actions'

const { REACT_APP_API_URL } = process.env

export function getSeries() {
  return {
    [RSAA]: {
      endpoint: 'series',
      method: 'GET',
      types: [LOAD_SERIES_REQUEST, LOAD_SERIES_SUCCESS, LOAD_SERIES_FAILURE],
    },
  }
}

export function getSerie(id) {
  return {
    [RSAA]: {
      endpoint: `${REACT_APP_API_URL}series/${id}`,
      method: 'GET',
      types: [LOAD_SERIE_REQUEST, LOAD_SERIE_SUCCESS, LOAD_SERIE_FAILURE],
    },
  }
}

export function toggleSeriesDialog({ show, name }) {
  return {
    type: TOGGLE_SERIES_DIALOG,
    show,
    name,
  }
}

export function addSerie(data) {
  return {
    [RSAA]: {
      endpoint: 'series',
      method: 'POST',
      types: [ADD_SERIES_REQUEST, ADD_SERIES_SUCCESS, ADD_SERIES_FAILURE],
      body: JSON.stringify(data),
    },
  }
}

export function updateSerie(id, data) {
  return {
    [RSAA]: {
      endpoint: `${REACT_APP_API_URL}series/${id}`,
      method: 'PUT',
      types: [UPDATE_SERIE_REQUEST, UPDATE_SERIE_SUCCESS, UPDATE_SERIE_FAILURE],
      body: JSON.stringify(data),
    },
  }
}

export function hideFlashMessage() {
  return {
    type: SERIE_HIDE_FLASH_MESSAGE,
  }
}
