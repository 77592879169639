import { Quill } from 'react-quill'
const Parchment = Quill.import('parchment')

const removeHighlighting = (bodyComponents) => {
  bodyComponents = bodyComponents.map((component) => {
    if (component.text && component.type === 'text') {
      const editorEl = document.createElement('div')
      const editor = new Quill(editorEl)
      editor.setContents(component.delta)
      const links = editor.container.querySelectorAll('a')
      if (links.length) {
        links.forEach((link) => {
          // check if link is a keyword & if it has URL
          const isKeyword = link.getAttribute('data-type') === 'keyword'
          const isDuplicateLink = link.getAttribute('data-type') === 'same-link'
          const url = link.getAttribute('href')
          if ((url === 'no url' || !url) && isKeyword) {
            // find link position
            const linkBlot = Parchment.find(link)
            if (linkBlot) {
              const index = linkBlot.offset(editor.scroll)
              const length = linkBlot.domNode.innerHTML.length
              // remove formatting
              editor.formatText(index, length, {
                link: false,
              })
            }
          }

          if (isDuplicateLink) {
            const linkBlot = Parchment.find(link)
            if (linkBlot) {
              const index = linkBlot.offset(editor.scroll)
              const length = linkBlot.domNode.innerHTML.length
              // remove highlighting
              editor.formatText(index, length, {
                link: {
                  url,
                  sameLink: false,
                },
              })
            }
          }
        })
      }
      component.text = editor.root.innerHTML
      editorEl.remove()
    }
    return component
  })

  return bodyComponents
}

export default removeHighlighting
