import { CREATE_ARTICLE_LOAD_VERSION } from 'utils/constants/actions'

const loadVersion = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_LOAD_VERSION) {
    const { createArticleMetadata } = store.getState()

    const currentVersion = createArticleMetadata.get('currentVersion')
    const loadedArticleVersion = action.payload.metaData.version

    if (currentVersion === loadedArticleVersion) action.versionLoaded = false
    else action.versionLoaded = true
  }

  return next(action)
}

export default loadVersion
