import Dialog from '@mui/material/Dialog'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SingleImageInsert from './components/SingleImageInsert'

class SingleImageDialog extends Component {
  static propTypes = {
    show: PropTypes.bool,
    initialState: PropTypes.object,
    onInsertImage: PropTypes.func,
    onEditImage: PropTypes.func,
    onCancel: PropTypes.func,
    uploadImage: PropTypes.func,
    uploadingImage: PropTypes.bool,
    uploadedImage: PropTypes.object,
    saveImageData: PropTypes.func,
    savingImageData: PropTypes.bool,
    imageId: PropTypes.string,
    resetImageUploadState: PropTypes.func,
    imageType: PropTypes.string,
    imageDialogType: PropTypes.string,
    imageTitle: PropTypes.string,
    selectedFileEvt: PropTypes.object,
    namespace: PropTypes.string,
    minWidth: PropTypes.number,
    crops: PropTypes.array,
    priority: PropTypes.number,
  }

  render() {
    const {
      show,
      initialState,
      onInsertImage,
      onEditImage,
      onCancel,
      uploadImage,
      uploadingImage,
      uploadedImage,
      saveImageData,
      savingImageData,
      imageId,
      resetImageUploadState,
      imageType,
      imageDialogType,
      imageTitle,
      selectedFileEvt,
      namespace,
      minWidth,
      crops,
      priority,
    } = this.props

    return (
      <Dialog fullWidth open={show}>
        <SingleImageInsert
          show={show}
          imageTitle={imageTitle}
          initialState={initialState}
          imageDialogType={imageDialogType}
          onInsertImage={onInsertImage}
          onEditImage={onEditImage}
          onCancel={onCancel}
          uploadImage={uploadImage}
          uploadingImage={uploadingImage}
          uploadedImage={uploadedImage}
          saveImageData={saveImageData}
          savingImageData={savingImageData}
          imageId={imageId}
          resetImageUploadState={resetImageUploadState}
          imageType={imageType}
          namespace={namespace}
          minWidth={minWidth}
          crops={crops}
          selectedFileEvt={selectedFileEvt}
          priority={priority}
        />
      </Dialog>
    )
  }
}

export default SingleImageDialog
