const ifValueIsValid = (value) => {
  if (value === '' || value === undefined || value === null || (value instanceof Object && !Object.keys(value).length))
    return false
  else return true
}

const removeEmptyFromObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    // don't remove empty image crops array
    if (key === 'crops') return obj[key]

    if (!ifValueIsValid(obj[key])) delete obj[key]

    if (Array.isArray(obj[key]) && obj[key].length !== 0) {
      obj[key] = obj[key]
        .map((item) => {
          if (item instanceof Object) return Object.keys(item).length ? removeEmptyFromObject(item) : null
          else return item
        })
        .filter((item) => ifValueIsValid(item))
      if (obj[key].length === 0) {
        delete obj[key]
        return
      }
    } else if (obj[key] instanceof Object) {
      const cleanObj = removeEmptyFromObject(obj[key])
      if (!Object.keys(cleanObj).length) delete obj[key]
      else obj[key] = cleanObj
    }
  })
  return obj
}

export default removeEmptyFromObject
