import { Map, fromJS } from 'immutable'
import {
  LOAD_REVIEWERS_REQUEST,
  LOAD_REVIEWERS_SUCCESS,
  LOAD_REVIEWERS_FAILURE,
  GET_REVIEWER_BY_ID_REQUEST,
  GET_REVIEWER_BY_ID_SUCCESS,
  GET_REVIEWER_BY_ID_FAILURE,
} from 'utils/constants/actions'

const reviewerFieldReducer = (state = Map(), action) => {
  switch (action.type) {
    case LOAD_REVIEWERS_REQUEST:
      return state.set('loading', true)
    case LOAD_REVIEWERS_FAILURE:
      return state.set('loading', false).set('error', action.payload)
    case LOAD_REVIEWERS_SUCCESS:
      return state.set('loading', false).set('reviewers', fromJS(action.payload))
    case GET_REVIEWER_BY_ID_REQUEST:
      return state.set('loading', true)
    case GET_REVIEWER_BY_ID_FAILURE:
      return state.set('loading', false).set('error', action.payload)
    case GET_REVIEWER_BY_ID_SUCCESS:
      return state.set('loading', false).set('reviewers', fromJS([action.payload]))
    default:
      return state
  }
}

export default reviewerFieldReducer
