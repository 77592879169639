import React from 'react'
import Table from '@mui/material/Table'
import styled from 'styled-components'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import useUsers from 'hooks/useUsers'
import { Delete } from '@mui/icons-material'
import { ClassModule } from 'types/ClassModule'

interface Props {
  classData: ClassModule.Data
  versions: ClassModule.Version[]
  setEditDialogData: (data: any) => void
  setEditDialogOpen: (open: boolean) => void
  setDeleteID: (id: string) => void
  terms: ClassModule.Term[]
}

const MainCell = styled(TableCell)`
  max-width: 290px;
`

const List: React.FC<Props> = ({ classData, versions, setEditDialogData, setEditDialogOpen, setDeleteID, terms }) => {
  const usersQuery = useUsers({ userIds: classData.instructors })

  const getTermTitles = (termsInVersion: number[]) => {
    const titles = termsInVersion
      ?.map((termInVersion) => terms.find((term) => term.id === termInVersion)?.title)
      ?.join(', ')

    if (titles?.length) {
      return titles
    }
  }
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left">ID</TableCell>
            <MainCell align="left">Title</MainCell>
            <MainCell align="left">Terms </MainCell>
            <MainCell align="left">Instructor</MainCell>
            <TableCell align="left">Edit</TableCell>
            <TableCell align="left">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="classToolsList">
          {versions?.map((t) => (
            <TableRow key={t.id}>
              <TableCell align="left">{t.id}</TableCell>
              <MainCell align="left">{t.title}</MainCell>
              <MainCell align="left">{getTermTitles(t.terms) || 'Default'}</MainCell>
              <MainCell align="left">
                {usersQuery.data?.find((item: ClassModule.Instructor) => item.userId === t.instructorId)?.name}
              </MainCell>
              <TableCell align="left">
                <IconButton
                  aria-label="edit module"
                  color="primary"
                  onClick={() => {
                    setEditDialogData(t)
                    setEditDialogOpen(true)
                  }}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
              <TableCell align="left">
                <IconButton aria-label="edit module" color="secondary" onClick={() => setDeleteID(t.id)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default List
