import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Autocomplete from '@mui/material/Autocomplete'
import { observer } from 'mobx-react-lite'

import { Form } from '../styles'
import SaveLessonChanges from '../SaveLessonChanges'
import PhaseDropdown from '../FormComponents/PhaseDropdown'
import LessonImageComponent from '../FormComponents/LessonImageComponent'

const generateDefaultFormData = () => ({
  title: '',
  optional: false,
  active: true,
  image: '',
  phase: '',
})

const EditClassRequirement = ({ cls, moduleId, activeLesson, activeLessonIdx }) => {
  const [formData, setFormData] = useState(generateDefaultFormData())

  const [termsInputVal, setTermsInputVal] = useState('')

  useEffect(() => {
    if (activeLesson) {
      setFormData({ ...activeLesson })
      setTermsInputVal(activeLesson.termId ? cls.terms.find((t) => t.id === activeLesson.termId)?.title : '')
    }
  }, [activeLesson, setTermsInputVal, cls, activeLessonIdx])

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value })
  }

  const handleAutocompleteFieldChange = (event, value, fieldName) => {
    setFormData((p) => ({ ...p, [fieldName]: value }))
  }

  const handleChangeNegatedBooolean = (event) => {
    const { name, value, type, checked } = event.target
    setFormData({ ...formData, [name]: type === 'checkbox' ? !checked : value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <SaveLessonChanges
        classId={cls.id}
        moduleId={moduleId}
        lessonId={activeLesson?.id}
        formData={{ ...formData, type: 'Class Requirement', moduleId, classId: cls.id }}
      />
      <Form component="form" id="class-requirement-form" onSubmit={handleSubmit}>
        <Grid item xs={6} style={{ textAlign: 'center', padding: '1rem' }}>
          <LessonImageComponent image={formData.image} setImage={(image) => setFormData((p) => ({ ...p, image }))} />
        </Grid>

        <Grid container spacing={3} direction="row" alignItems="flex-start">
          <Grid container item xs={8} spacing={3}>
            <Grid item xs={12}>
              <TextField
                value={formData.title}
                fullWidth
                name="title"
                type="text"
                label="Title"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'Title' }}
              />
            </Grid>
            {cls.terms?.length > 0 && (
              <Grid item xs={12}>
                <Autocomplete
                  data-testid="videoLessontermsDropdown"
                  name="termId"
                  value={cls.terms.find((t) => t.id === formData.termId) || null}
                  options={cls.terms}
                  getOptionLabel={(option) => option?.title || ''}
                  renderInput={(params) => <TextField {...params} label="Select Term" />}
                  inputValue={termsInputVal}
                  onChange={(e, v) => setFormData({ ...formData, termId: v?.id })}
                  onInputChange={(e, v) => setTermsInputVal(v)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch checked={formData.active} onChange={handleChange} name="active" color="primary" />}
                label="Active"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!formData.optional}
                    onChange={handleChangeNegatedBooolean}
                    name="optional"
                    color="primary"
                  />
                }
                label="Required for completion"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3} direction="row" alignItems="flex-start">
          <Grid item xs={6}>
            <PhaseDropdown
              cls={cls}
              moduleId={moduleId}
              formData={formData}
              handleAutocompleteFieldChange={handleAutocompleteFieldChange}
            />
          </Grid>
        </Grid>
      </Form>
    </>
  )
}

EditClassRequirement.propTypes = {
  cls: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
  activeLesson: PropTypes.object,
  activeLessonIdx: PropTypes.number,
  lessons: PropTypes.array,
}

export default observer(EditClassRequirement)
