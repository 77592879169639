import { useState } from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import useDebounce from '../../../../../hooks/useDebounce'
import useFetchVideos from '../../../../../hooks/useFetchVideos'
import getVideoThumbnailUrl from 'utils/getVideoThumbnailUrl'

const VideoResultsWrapper = styled.div`
  margin: 12px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`

const StyledThumbnail = styled.img`
  max-width: 100%;
`

const VideoResultButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;

  ${(props) =>
    props.isSelected
      ? `
      background-color: #2196f3;
      color: #fff;
      `
      : `
  
  &:hover {
    background-color: #eee;
  }
  `}

  div {
    text-align: left;
  }
`

const VideoDialog = (props) => {
  const [search, setSearch] = useState('')
  const [selectedVideo, setSelectedVideo] = useState()

  const debouncedSearch = useDebounce(search, 500)

  const videoQuery = useFetchVideos({ searchTerm: debouncedSearch })

  return (
    <Dialog fullWidth open onClose={props.onClose}>
      <DialogTitle>Add video</DialogTitle>
      <DialogContent>
        <TextField
          value={search}
          fullWidth
          autoFocus
          name="id"
          type="text"
          label="Id or Keyword"
          onChange={(e) => setSearch(e.currentTarget.value)}
          inputProps={{ 'aria-label': 'Title' }}
        />

        <VideoResultsWrapper>
          {videoQuery.isLoading && (
            <CircularProgress style={{ gridColumnStart: 1, gridColumnEnd: 3, justifySelf: 'center' }} />
          )}
          {videoQuery.data?.map((video) => (
            <VideoResultButton
              onClick={() => setSelectedVideo(video)}
              isSelected={selectedVideo?.key === video.key}
              onDoubleClick={() => props.onConfirm(video)}
            >
              <StyledThumbnail
                src={getVideoThumbnailUrl(video.key)}
                onClick={() => props.onConfirm(video)}
                alt="Video Thumbnail"
              />
              <div>ID: {video.key}</div>
              <div>{video.title}</div>
            </VideoResultButton>
          ))}
        </VideoResultsWrapper>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={() => props.onConfirm(selectedVideo)} disabled={!selectedVideo}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

VideoDialog.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default VideoDialog
