import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AutoSuggestInput from './AutoSuggestInput'
import CircularProgress from '@mui/material/CircularProgress'

let loadTimeout

class AsyncAutoSuggest extends Component {
  static propTypes = {
    input: PropTypes.object,
    dataSource: PropTypes.array,
    loading: PropTypes.bool,
    fetchSuggestions: PropTypes.func,
    width: PropTypes.string,
    inputProps: PropTypes.object,
    getSuggestionById: PropTypes.func,
  }

  constructor(props) {
    super(props)
    if (props.input.value && props.getSuggestionById) {
      props.getSuggestionById(props.input.value)
    }
  }

  render() {
    const { dataSource, loading, fetchSuggestions, inputProps, width, ...custom } = this.props

    return (
      <div style={{ position: 'relative', display: 'inline-block', width: width || '100%' }}>
        {loading && (
          <CircularProgress
            style={{
              position: 'absolute',
              right: 0,
              top: inputProps.helperText ? 20 : 40,
            }}
            size={15}
          />
        )}
        <AutoSuggestInput
          suggestions={dataSource}
          onSuggestionsFetchRequested={({ value, reason }) => {
            if (reason !== 'input-changed') return

            if (value.length < 2) {
              clearTimeout(loadTimeout)
              return
            }

            clearTimeout(loadTimeout)
            loadTimeout = setTimeout(() => {
              fetchSuggestions(value)
            }, 500)
          }}
          inputProps={inputProps}
          dataSource={dataSource}
          {...custom}
        />
      </div>
    )
  }
}

export default AsyncAutoSuggest
