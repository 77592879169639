import { makeAutoObservable, runInAction } from 'mobx'
import { ClassDomainObject } from './ClassStore'
export default class ClassesStore {
  transportLayer
  classes = []
  classesLoading = true
  classesLoaded = false
  error = null

  constructor(transportLayer) {
    makeAutoObservable(this)
    this.transportLayer = transportLayer // Thing that can make server requests.
  }

  fetchClasses = async (params) => {
    this.classesLoading = true
    try {
      const fetchedClasses = await this.transportLayer.getClasses(params)

      runInAction(() => {
        fetchedClasses.forEach((json) => {
          this.updateClassFromServer(json)
        })
        this.classesLoaded = true
        this.classesLoading = false
        this.error = null
      })
    } catch (error) {
      runInAction(() => {
        this.classesLoaded = false
        this.error = error
        this.classesLoading = false
      })
    }
  }

  updateClassFromServer(json) {
    let classItem = this.classes.find((classItem) => {
      return classItem.id === json.id
    })
    if (!classItem) {
      classItem = new ClassDomainObject(this, json.id)
      this.classes.push(classItem)
    }
    if (json.isDeleted) {
      this.removeClass(classItem)
    } else {
      classItem.updateFromJson(json)
    }
  }

  // Creates a fresh Class on the client and the server.
  async createClass(json) {
    const response = await this.transportLayer.createClass(json)
    const classItem = new ClassDomainObject(this, response.id)
    classItem.updateFromJson(response)
    this.classes.push(classItem)
    return classItem
  }

  removeClass(classItem) {
    this.classes.splice(this.classes.indexOf(classItem), 1)
    classItem.dispose()
  }
}
