import { Map, fromJS } from 'immutable'
import shortid from 'shortid'
import {
  CREATE_ARTICLE_INSERT_COMPONENT,
  CREATE_ARTICLE_INSERT_COMPONENT_FROM_PLACEHOLDER,
  CREATE_ARTICLE_INSERT_SPLITTED_COMPONENT,
  CREATE_ARTICLE_REMOVE_COMPONENT,
  CREATE_ARTICLE_REORDER_COMPONENTS,
  CREATE_ARTICLE_ON_EDITOR_CHANGE,
  CREATE_ARTICLE_UPDATE_HEADER,
  CREATE_ARTICLE_UPDATE_HEADER_TITLE_IMAGE,
  CREATE_ARTICLE_DELETE_HERO_IMAGE,
  CREATE_ARTICLE_UPDATE_HERO_IMAGE,
  CREATE_ARTICLE_INSERT_DRAGGED_COMPONENT,
  CREATE_ARTICLE_MERGE_COMPONENTS,
  CREATE_ARTICLE_INSERT_COMPONENT_FROM_MODAL,
  CREATE_ARTICLE_UPDATE_COMPONENT_FROM_MODAL,
  CREATE_ARTICLE_FETCH_ARTICLE_SUCCESS,
  CREATE_ARTICLE_RESET_STATE,
  CREATE_ARTICLE_SHOULD_SAVE_DRAFT,
  CREATE_ARTICLE_DISCARD_DRAFT,
  CREATE_ARTICLE_SAVE_HISTORY,
  CREATE_ARTICLE_UNDO,
  CREATE_ARTICLE_REDO,
  CREATE_PODCAST_FETCH_PODCAST_SUCCESS,
  CREATE_ARTICLE_CONVERT_TO_COMPONENT,
  CREATE_ARTICLE_SET_ACTIVE,
  CREATE_ARTICLE_PRESERVE_QUILL_STATE,
  CREATE_ARTICLE_SUBMIT_ARTICLE,
  CREATE_ARTICLE_UPDATE_COMPONENT_WORD_COUNT,
  AUTHOR_FIELD_GET_AUTHOR_BY_ID_SUCCESS,
  ANALYSE_TEXT_SUCCESS,
  CREATE_ARTICLE_UNHIGHLIGHT_KEYWORDS,
  SELECT_ARTICLE_TEMPLATE,
  CREATE_ARTICLE_REMOVE_DUMMY_FLAG,
  CREATE_ARTICLE_LOAD_VERSION,
  CREATE_ARTICLE_REMOVE_HEADER_DUMMY_FLAG,
  CREATE_ARTICLE_HIGHLIGHT_SAME_LINKS,
  CREATE_ARTICLE_MARK_COMPONENT,
  CREATE_ARTICLE_CONVERT_IMAGE_COMPONENT,
  CREATE_ARTICLE_LINK_SEARCH,
  CREATE_ARTICLE_DUPLICATE_COMPONENT,
  CREATE_ARTICLE_CONVERT_TO_COMPONENT_NEW,
  CREATE_ARTICLE_SPLIT_WITH_DRAGGED_COMPONENT,
  CREATE_ARTICLE_SET_LAST_INSERTED_LINK,
} from 'utils/constants/actions'
import removeHtmlTags from 'utils/removeHtmlTags'
import blueprints from 'store/blueprints'
import updateArticleStats from 'utils/updateArticleStats'

import { initialCreateArticleBodyState } from 'store/initialState/initialCreateArticleBodyState'

const createArticleBodyReducer = (state = Map(), action) => {
  switch (action.type) {
    case CREATE_ARTICLE_INSERT_COMPONENT:
      return state
        .updateIn(['present', 'components'], (components) =>
          components.splice(action.toIndex, 0, fromJS(action.newComponent))
        )
        .set('activeComponentIndex', action.toIndex)

    case CREATE_ARTICLE_INSERT_COMPONENT_FROM_PLACEHOLDER:
      const count = state.getIn(['present', 'components']).count()
      return state
        .updateIn(['present', 'components'], (components) => components.push(fromJS(action.newComponent)))
        .set('activeComponentIndex', count + 1)

    case CREATE_ARTICLE_INSERT_SPLITTED_COMPONENT:
      return state
        .updateIn(['present', 'components'], (components) =>
          components.splice(action.toIndex, 0, fromJS(action.newComponent))
        )
        .set('activeComponentIndex', action.toIndex)

    case CREATE_ARTICLE_REMOVE_COMPONENT:
      const anchor = state.getIn(['present', 'components', action.index, 'anchor'])
      let newState = state
        .updateIn(['present', 'components'], (components) => components.splice(action.index, 1))
        .set('activeComponentIndex', Math.max(action.index - 1, 0))

      const subnavIndex = newState.getIn(['present', 'components']).findIndex((c) => c.get('type') === 'subnavigation')

      if (anchor && subnavIndex > -1) {
        // remove matching subnav item if it exists
        const index = newState
          .getIn(['present', 'components', subnavIndex, 'links'])
          .findIndex((l) => l.get('anchor') === anchor)
        if (index > -1) {
          newState = newState.updateIn(['present', 'components', subnavIndex, 'links'], (links) =>
            links.splice(index, 1)
          )
        }

        // delete subnav if there are no items left
        const count = newState.getIn(['present', 'components', subnavIndex, 'links']).count()
        if (count === 0) {
          newState = newState.updateIn(['present', 'components'], (components) => components.splice(subnavIndex, 1))
        }
      }

      return updateArticleStats(newState)

    case CREATE_ARTICLE_REORDER_COMPONENTS:
      const dragComponent = state.getIn(['present', 'components', action.dragIndex])
      const components = state
        .getIn(['present', 'components'])
        .splice(action.dragIndex, 1)
        .splice(action.hoverIndex, 0, dragComponent)

      return state.setIn(['present', 'components'], components).set('activeComponentIndex', action.dragIndex)

    case CREATE_ARTICLE_ON_EDITOR_CHANGE:
      return state
        .setIn(['present', 'components', action.index, 'text'], action.html)
        .setIn(['present', 'components', action.index, 'delta'], fromJS(action.delta))

    case CREATE_ARTICLE_UPDATE_HEADER:
      return state.setIn(['present', 'components', action.index, action.field], action.html)

    case CREATE_ARTICLE_UPDATE_HEADER_TITLE_IMAGE:
      return state.setIn(['present', 'components', action.index, 'titleImage'], action.path)

    case CREATE_ARTICLE_UPDATE_HERO_IMAGE:
      return state.setIn(['present', 'components', 0, 'image'], fromJS(action.image))

    case CREATE_ARTICLE_DELETE_HERO_IMAGE:
      const header = state.getIn(['present', 'components', 0]).toJS()
      delete header.image
      return state.setIn(['present', 'components', 0], fromJS(header))

    case CREATE_ARTICLE_INSERT_DRAGGED_COMPONENT:
      return state
        .updateIn(['present', 'components'], (components) =>
          components.splice(action.draggedItemIndex, 1).splice(action.toIndex, 0, fromJS(action.draggedComponent))
        )
        .set('activeComponentIndex', action.toIndex)

    case CREATE_ARTICLE_MERGE_COMPONENTS:
      return state.setIn(['present', 'components'], fromJS(action.mergedComponents))

    case CREATE_ARTICLE_INSERT_COMPONENT_FROM_MODAL:
      if (action.newComponent) {
        return state
          .updateIn(['present', 'components'], (components) =>
            components.splice(action.toIndex, 0, fromJS(action.newComponent))
          )
          .set('activeComponentIndex', action.toIndex)
      } else return state

    case CREATE_ARTICLE_CONVERT_TO_COMPONENT:
      if (action.newComponent) {
        if (action.focusedComponent) {
          state = state.updateIn(['present', 'components'], (components) =>
            components.splice(action.focusedComponentIndex, 1, fromJS(action.focusedComponent))
          )
        }
        return state.updateIn(['present', 'components'], (components) =>
          components.splice(action.toIndex, 0, fromJS(action.newComponent))
        )
      } else return state

    case CREATE_ARTICLE_CONVERT_TO_COMPONENT_NEW:
    case CREATE_ARTICLE_SPLIT_WITH_DRAGGED_COMPONENT: {
      // remove dragged component?
      if (action.sourceIndex) {
        state = state.updateIn(['present', 'components'], (components) => components.splice(action.sourceIndex, 1))
        if (action.sourceIndex < action.index) action.index--
      }

      // split target component
      state = state.updateIn(['present', 'components'], (components) =>
        components.splice(action.index, 1, fromJS(action.newComponent))
      )

      if (action.beforeComponent) {
        state = state.updateIn(['present', 'components'], (components) =>
          components.splice(action.index, 0, fromJS(action.beforeComponent))
        )
      }

      if (action.afterComponent) {
        state = state.updateIn(['present', 'components'], (components) =>
          components.splice(action.index + (action.beforeComponent ? 2 : 1), 0, fromJS(action.afterComponent))
        )
      }

      return state
    }

    case CREATE_ARTICLE_UPDATE_COMPONENT_FROM_MODAL:
      return state.updateIn(['present', 'components'], (components) =>
        components.splice(action.index, 1, fromJS(action.component))
      )

    case CREATE_ARTICLE_FETCH_ARTICLE_SUCCESS:
    case CREATE_ARTICLE_LOAD_VERSION:
    case CREATE_PODCAST_FETCH_PODCAST_SUCCESS:
      if (action.payload.bodyComponents) {
        return state.setIn(['present', 'components'], fromJS(action.payload.bodyComponents))
      } else return state

    case CREATE_ARTICLE_RESET_STATE:
      return initialCreateArticleBodyState

    case CREATE_ARTICLE_SHOULD_SAVE_DRAFT:
      if (action.draftComponents) {
        return state.setIn(['present', 'components'], fromJS(action.draftComponents))
      } else {
        return state
      }

    case CREATE_ARTICLE_DISCARD_DRAFT:
      return state.setIn(
        ['present', 'components'],
        fromJS(initialCreateArticleBodyState.getIn(['present', 'components']))
      )

    case CREATE_ARTICLE_SAVE_HISTORY:
      if (action.limitReached) {
        return state.update('past', (past) => past.pop()).update('past', (past) => past.unshift(action.present))
      } else return state.update('past', (past) => past.unshift(action.present))

    case CREATE_ARTICLE_UNDO:
      return updateArticleStats(fromJS(action.state))

    case CREATE_ARTICLE_REDO:
      return updateArticleStats(fromJS(action.state))

    case CREATE_ARTICLE_SET_ACTIVE:
      return state.setIn(['present', 'components'], fromJS(action.updatedComponents))

    case CREATE_ARTICLE_PRESERVE_QUILL_STATE:
      return state.setIn(['present', 'components', action.index, 'preserve'], action.preserve)

    case CREATE_ARTICLE_SUBMIT_ARTICLE:
      const bodyComponents = state.getIn(['present', 'components']).toJS()
      const filtered = bodyComponents.filter((c) => c.type !== 'text' || !(removeHtmlTags(c.text) === '' || !c.text))
      return state.setIn(['present', 'components'], fromJS(filtered))

    case CREATE_ARTICLE_UPDATE_COMPONENT_WORD_COUNT:
      const newState2 = state.setIn(['present', 'components', action.index, 'wordCount'], action.count)
      return updateArticleStats(newState2)

    case AUTHOR_FIELD_GET_AUTHOR_BY_ID_SUCCESS:
      const componentIndex = state.getIn(['present', 'components']).findKey((c) => c.get('type') === 'perspective')
      return state.setIn(
        ['present', 'components', componentIndex, 'perspectives', action.payload.index, 'username'],
        action.payload.author.name
      )

    case ANALYSE_TEXT_SUCCESS:
    case CREATE_ARTICLE_UNHIGHLIGHT_KEYWORDS:
    case CREATE_ARTICLE_CONVERT_IMAGE_COMPONENT:
      return state.setIn(['present', 'components'], fromJS(action.updatedBodyComponents))

    case SELECT_ARTICLE_TEMPLATE:
      return state.setIn(['present', 'components'], fromJS(blueprints[action.templateType]))

    case CREATE_ARTICLE_REMOVE_DUMMY_FLAG:
      return state.setIn(['present', 'components', action.index, 'dummy'], false)

    case CREATE_ARTICLE_REMOVE_HEADER_DUMMY_FLAG:
      return state
        .setIn(['present', 'components', action.index, 'dummy', action.field], false)
        .setIn(['present', 'components', action.index, action.field], '')

    case CREATE_ARTICLE_HIGHLIGHT_SAME_LINKS:
    case CREATE_ARTICLE_LINK_SEARCH:
      return state.setIn(['present', 'components'], fromJS(action.updatedBodyComponents))

    case CREATE_ARTICLE_MARK_COMPONENT:
      return state.set('activeComponentIndex', action.index)

    case CREATE_ARTICLE_DUPLICATE_COMPONENT:
      let component = state
        .getIn(['present', 'components', action.index])
        .set('id', Math.random().toString(36).substring(7))

      if (component.get('anchor')) {
        component = component.set('anchor', shortid.generate())
      }

      return state.updateIn(['present', 'components'], (components) =>
        components.splice(action.index + 1, 0, component)
      )

    case CREATE_ARTICLE_SET_LAST_INSERTED_LINK:
      return state.set('lastInsertedLink', action.link)

    default:
      return state
  }
}

export default createArticleBodyReducer
