import { ADD_SERIES_SUCCESS } from 'utils/constants/actions'
import { change } from 'redux-form'

const addSeries = (store) => (next) => (action) => {
  if (action.type === ADD_SERIES_SUCCESS) {
    if (action.payload.id) {
      store.dispatch(change('createArticleMetadataForm', 'seriesId', action.payload.id))
    }
  }

  return next(action)
}

export default addSeries
