import { useRef } from 'react'
import { Quill } from 'react-quill'
import { UP_ARROW, DOWN_ARROW } from 'utils/keyCodes'

const MoveWithArrows = ({ children }) => {
  const quill = useRef()

  const handleArrowKeys = (moveForward) => {
    const editor = quill.current
    const selection = editor.getSelection()

    if (moveForward && selection.index < editor.getLength() - 1) return true
    if (!moveForward && selection.index > 0) return true

    const quills = Array.from(document.querySelectorAll('.ql-container'))
    const index = quills.findIndex((el) => el === editor.container)
    const nextIndex = moveForward
      ? index === quills.length - 1
        ? 0
        : index + 1
      : index === 0
      ? quills.length - 1
      : index - 1

    quills[nextIndex].querySelector('.ql-editor').focus()
    if (nextIndex < index) {
      const nextQuill = Quill.find(quills[nextIndex])
      nextQuill.setSelection(nextQuill.getLength() - 1)
    }

    return false
  }

  return children({
    onEditorRef: (el) => (quill.current = el),
    keyboardBindings: {
      arrowUp: {
        key: UP_ARROW,
        handler: () => handleArrowKeys(false),
      },
      arrowDown: {
        key: DOWN_ARROW,
        handler: () => handleArrowKeys(true),
      },
    },
  })
}

export default MoveWithArrows
