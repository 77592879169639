import { useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import ListAltIcon from '@mui/icons-material/ListAlt'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import Tooltip from '@mui/material/Tooltip'
import AddIcon from '@mui/icons-material/Add'
import { useStore } from 'store/mobx'
import { Link } from 'react-router-dom'
import { CreateModuleDialog, ModuleEditDialog } from './ModuleEditDialog'

import SaveModuleChanges, { warningTitles } from '../ClassDetails/Form/SaveModuleCanges'
import { observer } from 'mobx-react-lite'
import { CreateButton } from '../../../Common/CreateButton'
import { DragTypesEnum } from '../../../../utils/constants/dragTypes'
import { ClassModule } from '../../../../types/ClassModule'
import DraggableButton from '../../../Common/DraggableButton'
import styled from 'styled-components'
import StyledFlex from '../../../../layouts/StyledLayouts'

export const StyledDragCell = styled(TableCell)`
  position: relative;
  padding-left: 38px;
`

export const StyledDragTableRow = styled(TableRow)`
  .draggableButtonWrapper {
    opacity: 0;
  }

  &:hover {
    .draggableButtonWrapper {
      opacity: 1;
    }
  }
`

interface ModuleItemProps {
  index: number
  module: ClassModule.Module
  classId: number
  setWasModified(wasNotified: boolean): void
  handleDrag(dragIndex: number, hoverIndex: number): void
}

const ModuleItem = observer((props: ModuleItemProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const dragRef = useRef<HTMLButtonElement>(null)
  const dropRef = useRef<HTMLTableRowElement>(null)

  const [_collector, drop] = useDrop(
    () => ({
      accept: DragTypesEnum.classModule,
      hover: (item: any, monitor) => {
        if (!dragRef.current || !dropRef.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = props.index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }

        // Determine rectangle on screen
        const hoverBoundingRect = dropRef.current.getBoundingClientRect()
        // Get vertical middle

        const hoverMiddleY = hoverBoundingRect.top + hoverBoundingRect.height / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        if (!clientOffset) {
          return
        }

        // Get pixels to the top
        const hoverClientY = ~~clientOffset.y

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        // Dragging upwards

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        props.handleDrag(dragIndex, hoverIndex)
        item.index = hoverIndex
      },
    }),
    [props]
  )

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: DragTypesEnum.classModule,
      item: () => ({ index: props.index }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [props]
  )

  drag(dragRef)
  drop(preview(dropRef))

  return (
    <>
      <StyledDragTableRow ref={dropRef} style={{ opacity: isDragging ? 0 : 1 }}>
        <StyledDragCell align="left">
          <StyledFlex
            className="draggableButtonWrapper"
            style={{ alignItems: 'center', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}
          >
            <DraggableButton ref={dragRef} />
          </StyledFlex>
          <span>{props.index + 1}</span>
        </StyledDragCell>
        <TableCell align="left">{props.module.id}</TableCell>
        <TableCell align="left">{props.module.title}</TableCell>
        <TableCell style={{ display: 'flex' }}>
          <IconButton aria-label="edit module" color="primary" onClick={() => setIsDialogOpen(true)}>
            <EditIcon />
          </IconButton>
          <Link to={{ pathname: `/classes/${props.classId}/modules/${props.module.id}` }}>
            <IconButton aria-label="edit module" color="primary">
              <ListAltIcon />
            </IconButton>
          </Link>
        </TableCell>
      </StyledDragTableRow>

      <ModuleEditDialog
        module={props.module}
        classId={props.classId}
        isOpen={isDialogOpen}
        handleDialogClose={() => setIsDialogOpen(false)}
      />
    </>
  )
})

interface ListProps {
  classId: number
  modules: ClassModule.Module[]
}

const List = (props: ListProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [wasModified, setWasModified] = useState(false)

  const { classes } = useStore('classesStore')
  const classItem = classes.find((item: ClassModule.Data) => item.id === props.classId)

  const handleDrag = (dragIndex: number, hoverIndex: number) => {
    const modules = [...props.modules]
    const item = modules.splice(dragIndex, 1)[0]
    modules.splice(hoverIndex, 0, item)

    classItem.updateFromJson({ ...classItem, modules })
    setWasModified(true)
  }

  return (
    <>
      <SaveModuleChanges
        classId={props.classId}
        warningTitle={wasModified ? warningTitles.save : undefined}
        handleDismissWarning={() => setWasModified(false)}
        backUrl="/classes"
      />

      <TableContainer component={Paper}>
        <Table stickyHeader style={{ position: 'relative' }}>
          <TableHead>
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell align="left">ID</TableCell>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="classModulesList">
            {props.modules.map((m, index) => (
              <ModuleItem
                key={m.id}
                index={index}
                module={m}
                classId={props.classId}
                handleDrag={handleDrag}
                setWasModified={setWasModified}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Tooltip title="Create Module">
        <CreateButton onClick={() => setIsDialogOpen(true)} color="primary" aria-label="create module">
          <AddIcon />
        </CreateButton>
      </Tooltip>

      <CreateModuleDialog
        isOpen={isDialogOpen}
        handleDialogClose={() => setIsDialogOpen(false)}
        classId={props.classId}
      />
    </>
  )
}

// List.propTypes = {
//   modules: PropTypes.arrayOf(ModulePropType),
//   classId: PropTypes.number,
// }

export default observer(List)
