import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Button from '@mui/material/Button'
import { pinkRed } from '../../../../../styles/colors'

const Wrapper = styled.div`
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 12px;
`

const SelectedVideoId = styled.div`
  font-size: 16px;

  ${(props) =>
    props.isWarning &&
    css`
      color: ${pinkRed};
    `}
`

const SelectedVideo = (props) => (
  <Wrapper>
    <SelectedVideoId isWarning={!props.video}>
      {props.video?.mediaId ? `Selected Video ID: ${props.video.mediaId}` : 'No video selected.'}
    </SelectedVideoId>
    <Button color="primary" variant="contained" onClick={props.handleOpenVideoInsert}>
      {props.video ? 'Edit' : 'Add'} Video
    </Button>
  </Wrapper>
)

SelectedVideo.propTypes = {
  video: PropTypes.object,
  handleOpenVideoInsert: PropTypes.func,
}

export default SelectedVideo
