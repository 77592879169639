import { makeAutoObservable, runInAction } from 'mobx'
import { uploadFile } from 'api/classes'

async function put(url, data) {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      body: data,
    })

    if (response.ok) {
      return response
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    throw new Error(error.message)
  }
}

export default class FileStore {
  images = []
  fileStoreLoading = false
  error = null
  hasMore = false
  uploadedFile = null

  constructor() {
    makeAutoObservable(this)
  }

  resetUploadedFile = () => {
    this.uploadedFile = null
  }

  uploadFile = async (data) => {
    const payload = {
      fileType: data.fileType,
    }
    this.fileStoreLoading = true
    try {
      const { preSignedUrlforFileUploading, fileName } = await uploadFile(payload)

      const blob = await fetch(data.toolFile).then((res) => res.blob())

      const result = await put(preSignedUrlforFileUploading, blob)

      if (!result.ok) {
        this.error = 'Failed to upload file'
      }

      runInAction(() => {
        this.error = null
        this.uploadedFile = {
          key: fileName,
          url: preSignedUrlforFileUploading.split('?')[0],
        }
      })
    } catch (error) {
      runInAction(() => {
        this.error = error.message // Provide more descriptive error messages
      })

      setTimeout(() => {
        runInAction(() => {
          this.error = null
        })
      }, 3000)
    } finally {
      runInAction(() => {
        this.fileStoreLoading = false
      })
    }
  }
}
