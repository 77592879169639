import { makeAutoObservable, runInAction } from 'mobx'

import { getClassSubscriptions } from 'api/classSubscriptions'

export default class ClassSubscriptionsStore {
  subscriptions = []
  lastEvaluatedKey = null
  loading = true
  error = null
  usingSearchForm = false
  lastFormSearchData = null

  constructor() {
    makeAutoObservable(this)
  }

  get count() {
    return this.subscriptions.length
  }

  fetchClassSubscriptions = async (data, reset, searchForm) => {
    this.loading = true
    let requestData = data || this.lastFormSearchData

    // if new search empty the list
    if (searchForm) {
      this.subscriptions = []
    }

    // if reset empty the list and don't send the form data
    if (reset) {
      this.subscriptions = []
      requestData = null
    }

    this.usingSearchForm = !!(requestData?.email || requestData?.from || requestData?.types?.length)

    try {
      const { subscriptions, lastEvaluatedKey } = await getClassSubscriptions({
        requestData,
        // don't send lastEvaluatedKey if new search or reset
        lastEvaluatedKey: !searchForm && !reset ? this.lastEvaluatedKey : null,
      })
      runInAction(() => {
        // if searching by email - replace the list of subscriptions
        // do the same if resetting
        this.subscriptions = searchForm || reset ? subscriptions : this.subscriptions.concat(subscriptions)

        this.lastEvaluatedKey = lastEvaluatedKey
        this.loading = false
        this.error = null
        this.lastFormSearchData = requestData
      })
    } catch (error) {
      runInAction(() => {
        this.error = error
        this.loading = false
      })
    }
  }

  addClassSubscription(newSub) {
    this.subscriptions.unshift(newSub)
    this.addedNewSub = true
  }

  updateClassSubscription({ id, data }) {
    const index = this.subscriptions.findIndex((s) => s.id === id)
    this.subscriptions.splice(index, 1, data)
  }
}
