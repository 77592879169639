import { fromJS, Map } from 'immutable'
import {
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  SAVE_IMAGE_DATA_REQUEST,
  SAVE_IMAGE_DATA_SUCCESS,
  SAVE_IMAGE_DATA_FAILURE,
  REMOVE_UPLOADED_IMAGE,
} from 'utils/constants/actions'

const imageUploadReducer =
  (namespace) =>
  (state = Map(), action) => {
    if (!action.meta || namespace !== action.meta.namespace) return state

    switch (action.type) {
      case UPLOAD_IMAGE_REQUEST:
        return state.set('uploading', true)

      case UPLOAD_IMAGE_SUCCESS:
        return state.set('uploadedImage', fromJS(action.payload)).set('uploading', false)

      case REMOVE_UPLOADED_IMAGE:
        return state.set('uploadedImage', null)

      case UPLOAD_IMAGE_FAILURE:
        return state.set('error', fromJS(action.payload)).set('uploading', false)

      case SAVE_IMAGE_DATA_REQUEST:
        return state.set('savingData', true)

      case SAVE_IMAGE_DATA_SUCCESS:
        return state.set('imageId', action.payload.id).set('savingData', false)

      case SAVE_IMAGE_DATA_FAILURE:
        return state.set('savingData', false).set('error', fromJS(action.payload))

      default:
        return state
    }
  }

export default imageUploadReducer
