import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import useUsers from '../../../../../hooks/useUsers'

const InstructorsDropdown = (props) => {
  const usersQuery = useUsers({ userIds: props.classInstructorIds })
  return (
    <Autocomplete
      name="Instructor"
      value={usersQuery.data?.find((instr) => instr.userId === props.selectedInstructorId) || null}
      onChange={(e, v) => props.handleChange(e, v?.userId || undefined, 'instructorId')}
      options={usersQuery.data || []}
      getOptionLabel={(option) => option?.name || ''}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label="Instructor"
          placeholder="Class"
          variant="standard"
          error={props.error}
          helperText={props.helperText}
        />
      )}
    />
  )
}

InstructorsDropdown.propTypes = {
  selectedInstructorId: PropTypes.number,
  classInstructorIds: PropTypes.arrayOf(PropTypes.number),
  handleChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
}

export default InstructorsDropdown
