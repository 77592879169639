import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
`

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const FloatingCircularProgress = () => <CircularProgress size={24} thickness={5} />

interface Props {
  isLoading: Boolean
  children: React.ReactNode
}

const WithProgress = ({ isLoading, children, ...props }: Props) => (
  <Wrapper {...props}>
    {children}

    {isLoading && (
      <LoaderWrapper>
        <FloatingCircularProgress />
      </LoaderWrapper>
    )}
  </Wrapper>
)

export default WithProgress
