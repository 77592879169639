import { RSAA } from 'redux-api-middleware'
import {
  SEARCH_ARTICLE_ID_REQUEST,
  SEARCH_ARTICLE_ID_SUCCESS,
  SEARCH_ARTICLE_ID_FAILURE,
  SEARCH_ARTICLE_TITLE_REQUEST,
  SEARCH_ARTICLE_TITLE_SUCCESS,
  SEARCH_ARTICLE_TITLE_FAILURE,
  SEARCH_ARTICLE_URL_REQUEST,
  SEARCH_ARTICLE_URL_SUCCESS,
  SEARCH_ARTICLE_URL_FAILURE,
} from 'utils/constants/actions'

export function searchArticleById(id) {
  return {
    [RSAA]: {
      endpoint: `articles/${id}`,
      method: 'GET',
      types: [SEARCH_ARTICLE_ID_REQUEST, SEARCH_ARTICLE_ID_SUCCESS, SEARCH_ARTICLE_ID_FAILURE],
    },
  }
}

export function searchArticleByTitle(title) {
  return {
    [RSAA]: {
      endpoint: `articles/search?title=${title}`,
      method: 'GET',
      types: [SEARCH_ARTICLE_TITLE_REQUEST, SEARCH_ARTICLE_TITLE_SUCCESS, SEARCH_ARTICLE_TITLE_FAILURE],
    },
  }
}

export function searchArticleByUrl(url) {
  return {
    [RSAA]: {
      endpoint: `articles/search?url=${encodeURIComponent(url)}`,
      method: 'GET',
      types: [SEARCH_ARTICLE_URL_REQUEST, SEARCH_ARTICLE_URL_SUCCESS, SEARCH_ARTICLE_URL_FAILURE],
    },
  }
}
