import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import Paper from '@mui/material/Paper'
import DefaultTextField from './DefaultTextField'
import styled from 'styled-components'
import ErrorBoundary from 'components/ErrorBoundary'

const styles = {
  container: {
    width: '100%',
  },
  suggestionsList: {
    margin: '0',
    padding: '0',
    listStyleType: 'none',
  },
}

const renderSuggestionsContainer = (options) => {
  const { containerProps, children } = options

  return (
    <Paper {...containerProps} sx={{ maxHeight: 200, overflow: 'auto' }}>
      {children}
    </Paper>
  )
}

export const SuggestionStyle = styled.div`
  min-height: 48px;
  padding: 0 16px;

  .inner {
    line-height: 48px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.invertedDark.main};
  }
`

const renderSuggestion = (dataSourceConfig) => (suggestion) => {
  const { value, label } = dataSourceConfig
  return (
    <SuggestionStyle value={suggestion[value]}>
      <div className="inner">{suggestion[label]}</div>
    </SuggestionStyle>
  )
}

const fetchSuggestionsLocalFiltering = (labelKey, suggestions) => (value) => {
  try {
    return suggestions.filter((k) => k[labelKey].toLowerCase().indexOf(value.toLowerCase()) !== -1)
  } catch (e) {
    console.error("Most likely you didn't pass a valid dataSource or dataSourceConfig to AutoSuggestInput")
    console.error('labelKey', labelKey)
    console.error(e)
    return []
  }
}

class AutoSuggestInput extends Component {
  static propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    dataSource: PropTypes.array,
    dataSourceConfig: PropTypes.object,
    inputProps: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      suggestions: [],
    }
  }

  render() {
    const {
      input: { value, onChange },
      meta,
      dataSource,
      dataSourceConfig,
      inputProps,
      ...custom
    } = this.props
    const { searchText, suggestions } = this.state
    const suggestion = dataSource.find((suggestion) => suggestion[dataSourceConfig.value] === value)
    const filter = fetchSuggestionsLocalFiltering(dataSourceConfig.label, dataSource)

    return (
      <ErrorBoundary message="Error in AutoSuggestInput">
        <Autosuggest
          theme={styles}
          suggestions={suggestions}
          onSuggestionSelected={(e, { suggestion }) => {
            if (custom.handleSuggestionSelected) {
              custom.handleSuggestionSelected(suggestion)
            }
            onChange(suggestion[dataSourceConfig.value])
          }}
          getSuggestionValue={(suggestion) => suggestion[dataSourceConfig.label]}
          renderSuggestion={renderSuggestion(dataSourceConfig)}
          renderSuggestionsContainer={renderSuggestionsContainer}
          renderInputComponent={DefaultTextField}
          onSuggestionsFetchRequested={({ value }) =>
            this.setState({
              suggestions: filter(value),
            })
          }
          onSuggestionsClearRequested={() => this.setState({ suggestions: [] })}
          highlightFirstSuggestion
          inputProps={{
            meta,
            type: 'text',
            label: this.props.label,
            value:
              suggestion && suggestion[dataSourceConfig.label]
                ? suggestion[dataSourceConfig.label].replace(/['"]+/g, '')
                : searchText,
            onChange: (e, { newValue, method }) => {
              onChange(null)
              this.setState({
                searchText: newValue,
              })

              if (custom.customOnChange) custom.customOnChange(e, newValue)
            },
            onBlur: (e, { highlightedSuggestion }) => {
              if (custom.blockOnBlur) return

              if (highlightedSuggestion) {
                const suggestionLabel = highlightedSuggestion[dataSourceConfig.label]
                const suggestionValue = highlightedSuggestion[dataSourceConfig.value]
                if (suggestionLabel.toLowerCase() === searchText.toLowerCase()) onChange(suggestionValue)
              }

              if (!suggestion) {
                onChange(null)
                this.setState({ searchText: '' })
              }

              if (custom.customOnBlur) custom.customOnBlur(value, searchText)
            },
            ...inputProps,
          }}
          {...custom}
        />
      </ErrorBoundary>
    )
  }
}

export default AutoSuggestInput
