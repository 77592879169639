import React, { MouseEvent } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import AddIcon from '@mui/icons-material/Add'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import ListSubheader from '@mui/material/ListSubheader'

import { selectArticleTemplate } from 'actions/articleTemplateActions'
import { CreateButton } from './Common/CreateButton'
import { RootDispatch } from '../App'
import { TemplateTypes } from '../store/blueprints'

interface Props extends ReturnType<typeof mapDispatchToProps> {}

const ArticleTemplateMenu = ({ selectArticleTemplate }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const history = useHistory()

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (type: TemplateTypes) => {
    selectArticleTemplate(type)
    history.push('/article/edit')
  }

  return (
    <>
      <CreateButton onClick={handleButtonClick} color="primary" size="medium">
        <AddIcon />
      </CreateButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <ListSubheader>Choose new article template</ListSubheader>
        <Divider />
        <MenuItem onClick={() => handleMenuItemClick(TemplateTypes.blank)}>Blank</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(TemplateTypes.news)}>News Roundup</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(TemplateTypes.shop)}>Shoppable</MenuItem>
      </Menu>
    </>
  )
}

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  selectArticleTemplate: (templateType: TemplateTypes) => dispatch(selectArticleTemplate(templateType)),
})

export default connect(undefined, mapDispatchToProps)(ArticleTemplateMenu)
