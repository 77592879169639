import { fromJS } from 'immutable'

export const initialSeriesState = fromJS({
  loading: false,
  series: [],
  error: null,
  showDialog: false,
  dialogLoading: false,
  seriesName: '',
  serie: null,
  updating: false,
  updateError: null,
  snackMessage: null,
  creating: false,
  createError: null,
})
