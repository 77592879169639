import React, { ErrorInfo, ReactNode } from 'react'

interface Props {
  children?: ReactNode
  message?: string
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('🚀 ~ file: ErrorBoundary ~ line 15 ~ ErrorBoundary ~ componentDidCatch ~ errorInfo', errorInfo)
    console.error('🚀 ~ file: ErrorBoundary ~ line 15 ~ ErrorBoundary ~ componentDidCatch ~ error', error)
  }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Error: {this.props.message} 🥺</h1>
    }

    return this.props.children
  }
}

export default ErrorBoundary
