import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import imageUrl from 'utils/imageUrl'
import { DragTypesEnum } from 'utils/constants/dragTypes'
import arrayMove from 'utils/arrayMove'
import StyledFlex from '../../../../../../layouts/StyledLayouts'
import DraggableButton from '../../../../../Common/DraggableButton'
import BodyActionButton from '../../../../BodyComponentWrapper/BodyActionButton'
import { Container, DraggableContainer, DraggableContainerTaskbar, Image } from './styles'

interface GridItem {
  key: number
  image: any
  product?: any
}

interface ItemBlockProps {
  item: GridItem
  index: number
  onEditItem(item: any): void
  onRemoveItem(item: any): void
  removeDisabled: boolean
  handleChangeOrder(args: { dragIndex: number; hoverIndex: number }): void
}

const ItemBlock = (props: ItemBlockProps) => {
  const dragRef = useRef<HTMLButtonElement>(null)
  const dropRef = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop(
    () => ({
      accept: DragTypesEnum.imageGrid,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        }
      },
      hover: (item: any, monitor) => {
        if (!dragRef.current || !dropRef.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = props.index

        if (dragIndex === hoverIndex) {
          return
        }

        // get item screen position
        const { top, right, bottom, left } = dropRef.current.getBoundingClientRect()

        // get mouse position
        const clientOffset = monitor.getClientOffset()
        if (!clientOffset) {
          return
        }

        const { x, y } = clientOffset

        // if mouse is not in one of the items not being dragged, don't change the order
        if (!(left < x && x < right && top < y && y < bottom)) {
          return
        }

        props.handleChangeOrder({ dragIndex, hoverIndex })

        item.index = hoverIndex
      },
    }),
    [props]
  )

  const [_collected, drag, preview] = useDrag(
    () => ({
      type: DragTypesEnum.imageGrid,
      item: () => ({ index: props.index }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [props]
  )

  drag(dragRef)
  drop(preview(dropRef))

  return (
    <DraggableContainer ref={dropRef} data-handler-id={handlerId}>
      <DraggableContainerTaskbar>
        <DraggableButton ref={dragRef} />
        <StyledFlex>
          <BodyActionButton title="edit" onClick={() => props.onEditItem(props.item)}>
            <EditIcon />
          </BodyActionButton>
          <BodyActionButton
            disabled={props.removeDisabled}
            title="delete"
            onClick={() => props.onRemoveItem(props.item)}
          >
            <DeleteIcon />
          </BodyActionButton>
        </StyledFlex>
      </DraggableContainerTaskbar>
      <Image src={props.item.image.fullUrl ? props.item.image.path : imageUrl(props.item.image.path)} alt="" />
    </DraggableContainer>
  )
}

interface ImageGridProps {
  items: GridItem[]
  removeDisabled: boolean
  baseItem?: GridItem
  onEditBaseItem?(): void
  onChangeOrder(newItems: GridItem[]): void
  onEditItem(item: any): void
  onRemoveItem(item: any): void
}

const ImageGrid = ({ items, onChangeOrder, baseItem, onEditBaseItem, ...props }: ImageGridProps) => {
  const handleChangeOrder = (args: { dragIndex: number; hoverIndex: number }) => {
    const newItems = arrayMove(items, args.dragIndex, args.hoverIndex)
    onChangeOrder(newItems)
  }

  return (
    <div style={{ width: '100%' }}>
      {baseItem && onEditBaseItem && (
        <div>
          <div>Base image</div>
          <DraggableContainer>
            <DraggableContainerTaskbar>
              <div />
              <BodyActionButton title="edit" onClick={onEditBaseItem}>
                <EditIcon />
              </BodyActionButton>
            </DraggableContainerTaskbar>
            <Image src={baseItem.image.fullUrl ? baseItem.image.path : imageUrl(baseItem.image.path)} alt="" />
          </DraggableContainer>
          <div>Items</div>
        </div>
      )}

      <Container>
        {items.map((item, index) => (
          <ItemBlock key={index} item={item} index={index} handleChangeOrder={handleChangeOrder} {...props} />
        ))}
      </Container>
    </div>
  )
}

export default ImageGrid
