import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'

const RemoveInstanceDialog = ({ open, instanceIdx, toggleDialog, removeInstance }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Remove Instance</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this live session instance? Doing so may have ramifications on student
        completion of the class.
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => toggleDialog(false)}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            removeInstance(instanceIdx)
            toggleDialog(false)
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RemoveInstanceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  instanceIdx: PropTypes.number.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  removeInstance: PropTypes.func.isRequired,
}

export default RemoveInstanceDialog
