import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import useBeforeFirstRender from 'hooks/useBeforeFirstRender'
import styled from 'styled-components'

import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.bubble.css'
import 'styles/quill_editor.scss'

import BaseToolbar from './BaseToolbar'

import sanitizeHtml from 'utils/sanitizeHtml'
import removeHtmlTags from 'utils/removeHtmlTags'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 999;
`

Object.assign(Quill.import('ui/icons'), {
  bold: null,
  italic: null,
  underline: null,
  strike: null,
  script: null,
})

/**
 * The base Quill editor component. This component is usually wrapped in a series of
 * Quill plugin components, which extend its basic functionality with new toolbar actions
 * and features. But it can be used as a standalone component as well.
 *
 * To extend this component use the render props pattern: https://reactjs.org/docs/render-props.html
 *
 * Note that react-quill editor must be rendered once and once only, otherwise
 * unexpected behaviour occurs. Thats why we instantiate the modules that are passed to
 * Quill instance only once, in useBeforeFirstRender hook.
 *
 * If you are writing a plugin component that will wrap this component, remember
 * that any custom handler functions you write can only be defined once; if you
 * re-define the handler functions, they will not be updated for the reason mentioned
 * earlier. This effectively means that if your handler function depends on variables
 * from upper scope, those variables will not be updated in the function if they change.
 * A solution is to use a mutable reference to those variables with useRef() hook.
 *
 * See some example plugins in this folder to get the idea.
 */
const QuillField = ({
  toolbar = <BaseToolbar />,
  formats = ['bold', 'italic', 'underline'],
  activeFormat = {},
  handlers = {},
  keyboardBindings,
  disabled,
  autofocus,
  onEditorRef = () => null,
  onClick,
  placeholder,
  placeholderStyles = {},
  value,
  showFlashMessage,
  ...props
}) => {
  const toolbarId = useRef()
  const modules = useRef()
  const quill = useRef()

  useBeforeFirstRender(() => {
    toolbarId.current = `t${shortid.generate()}`
    modules.current = {
      toolbar: toolbar
        ? {
            container: `#${toolbarId.current}`,
            handlers,
          }
        : false,
      keyboard: {
        bindings: {
          ...keyboardBindings,
        },
      },
    }
  })

  // handle auto focus
  useEffect(() => {
    if (autofocus) {
      setTimeout(() => quill.current.focus(), 100)
    }
  }, [autofocus])

  // setup initial formatting
  useEffect(() => {
    if (activeFormat.name) {
      quill.current.format(activeFormat.name, activeFormat.value)
    }

    quill.current.enable(!disabled)
  }, [activeFormat.name, activeFormat.value, disabled])

  // handle editor ref
  const handleRef = (el) => {
    if (!el || quill.current) return
    quill.current = el.getEditor()
    onEditorRef(quill.current)
  }

  // check if it pasting a preview link
  const onPaste = (e) => {
    const tooltip = quill.current.theme.tooltip
    const tooltipHidden = tooltip.root.className.includes('ql-hidden')

    if (!tooltipHidden || !showFlashMessage) {
      return
    }

    const text = e.clipboardData.getData('text/plain')
    const pastingPreviewLink = text.includes('preview.html?preview=true') || text.includes('preview?id=')
    if (pastingPreviewLink) {
      showFlashMessage('You cannot copy the article preview link.')
      e.preventDefault()
      return
    }
  }

  const isEmpty = removeHtmlTags(value || '') === ''

  return (
    <Wrapper onClick={onClick} onPaste={onPaste}>
      <div
        dangerouslySetInnerHTML={sanitizeHtml(placeholder)}
        className="quill-placeholder"
        style={{ visibility: isEmpty ? 'visible' : 'hidden', ...placeholderStyles }}
      />
      {toolbar && React.cloneElement(toolbar, { id: toolbarId.current })}
      <ReactQuill {...props} theme="bubble" value={value} modules={modules.current} formats={formats} ref={handleRef} />
    </Wrapper>
  )
}

QuillField.propTypes = {
  toolbar: PropTypes.object,
  formats: PropTypes.arrayOf(PropTypes.string),
  activeFormat: PropTypes.object,
  handlers: PropTypes.object,
  placeholderStyles: PropTypes.object,
  keyboardBindings: PropTypes.object,
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  onEditorRef: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  showFlashMessage: PropTypes.func,
}

export default QuillField
