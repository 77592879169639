import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

const MBGLinkWarningDialog = ({ open, toggleDialog }) => {
  return (
    <Dialog open={open} onClose={toggleDialog}>
      <DialogContent>
        <h3>
          Some of the mindbodygreen.com links in the text you've pasted have URL parameters in them. Links to other
          mindbodygreen pages generally should not have parameters, so please ensure those links are valid before
          saving. Including unnecessary tracking parameters could cause issues.
        </h3>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={toggleDialog}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MBGLinkWarningDialog.propTypes = {
  open: PropTypes.bool,
  toggleDialog: PropTypes.func,
}

export default MBGLinkWarningDialog
