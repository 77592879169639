import { CREATE_ARTICLE_CONVERT_TO_COMPONENT } from 'utils/constants/actions'
import { isUrlValid } from 'utils/validators'
import deleteSelectedTextInQuill from 'utils/deleteSelectedTextInQuill'
import deltaToHTML from 'utils/deltaToHTML'
import { parse } from 'recipe-ingredient-parser'

const convertToComponent = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_CONVERT_TO_COMPONENT) {
    if (action.componentType === 'introduction') {
      const { createArticleBody, createArticleInteractions } = store.getState()
      const bodyComponents = createArticleBody.getIn(['present', 'components'])
      const focusedComponentIndex = createArticleInteractions.getIn(['focusedComponent', 'index'])
      const introductionExists = bodyComponents.some((c) => c.get('type') === 'introduction')
      if (introductionExists) {
        action.flashMessage = "Article can't have more than one Introduction component."
      } else {
        // delete selected text in a source component
        const focusedComponent = bodyComponents.get(focusedComponentIndex).toJS()
        action.focusedComponent = deleteSelectedTextInQuill(focusedComponent, action.selection)
        action.focusedComponentIndex = focusedComponentIndex
        action.newComponent = {
          id: Math.random().toString(36).substring(7),
          type: action.componentType,
          priority: 2,
          text: deltaToHTML(action.delta),
        }
        action.toIndex = focusedComponentIndex + 1
      }
    }

    if (action.componentType === 'list') {
      const { createArticleInteractions, createArticleBody } = store.getState()
      const focusedComponentIndex = createArticleInteractions.getIn(['focusedComponent', 'index'])

      const items = []
      action.delta.eachLine((line, attributes, i) => {
        if (line.insert.length > 0) {
          items.push({
            text: deltaToHTML(line),
          })
        }
      })

      // delete selected text in a source component
      const focusedComponent = createArticleBody.getIn(['present', 'components', focusedComponentIndex]).toJS()
      action.focusedComponent = deleteSelectedTextInQuill(focusedComponent, action.selection)
      action.focusedComponentIndex = focusedComponentIndex

      action.toIndex = focusedComponentIndex + 1
      action.newComponent = {
        id: Math.random().toString(36).substring(7),
        type: action.componentType,
        priority: 2,
        title: '',
        listType: 'unordered',
        includeBullet: false,
        items,
      }
    }

    if (action.componentType === 'heading') {
      const { createArticleInteractions, createArticleBody } = store.getState()
      const focusedComponentIndex = createArticleInteractions.getIn(['focusedComponent', 'index'])

      // delete selected text in a source component
      const focusedComponent = createArticleBody.getIn(['present', 'components', focusedComponentIndex]).toJS()
      action.focusedComponent = deleteSelectedTextInQuill(focusedComponent, action.selection)
      action.focusedComponentIndex = focusedComponentIndex

      action.toIndex = focusedComponentIndex + 1
      action.newComponent = {
        id: Math.random().toString(36).substring(7),
        type: action.componentType,
        level: 2,
        priority: 2,
        text: action.text,
        anchor: '',
        number: 0,
      }
    }

    if (action.componentType === 'text-and-image') {
      const { createArticleInteractions, createArticleBody } = store.getState()
      const focusedComponentIndex = createArticleInteractions.getIn(['focusedComponent', 'index'])

      // delete selected text in a source component
      const focusedComponent = createArticleBody.getIn(['present', 'components', focusedComponentIndex]).toJS()
      action.focusedComponent = deleteSelectedTextInQuill(focusedComponent, action.selection)
      action.focusedComponentIndex = focusedComponentIndex

      action.toIndex = focusedComponentIndex + 1
      action.newComponent = {
        id: Math.random().toString(36).substring(7),
        type: action.componentType,
        priority: 2,
        heading: '',
        text: deltaToHTML(action.delta),
        image: null,
        isMirrored: false,
      }
    }

    if (action.componentType === 'pullquote' || action.componentType === 'blockquote') {
      const { createArticleInteractions, createArticleBody } = store.getState()
      const focusedComponentIndex = createArticleInteractions.getIn(['focusedComponent', 'index'])

      // delete selected text in a source component
      const focusedComponent = createArticleBody.getIn(['present', 'components', focusedComponentIndex]).toJS()
      action.focusedComponent = deleteSelectedTextInQuill(focusedComponent, action.selection)
      action.focusedComponentIndex = focusedComponentIndex

      // remove all formatting except bold
      const delta = action.delta.ops.map((op) => {
        if (op.attributes) {
          Object.keys(op.attributes).forEach((key) => {
            if (key !== 'bold') delete op.attributes[key]
          })
        }
        return op
      })

      action.toIndex = focusedComponentIndex + 1
      action.newComponent = {
        id: Math.random().toString(36).substring(7),
        priority: 2,
        type: action.componentType,
        text: deltaToHTML(delta),
      }
    }

    if (action.componentType === 'footer') {
      const { createArticleBody, createArticleInteractions } = store.getState()
      const bodyComponents = createArticleBody.getIn(['present', 'components'])
      const focusedComponentIndex = createArticleInteractions.getIn(['focusedComponent', 'index'])
      const footerExists = bodyComponents.some((c) => c.get('type') === 'footer')
      if (footerExists) {
        action.flashMessage = "Article can't have more than one Footer component."
      } else {
        // delete selected text in a source component
        const focusedComponent = bodyComponents.get(focusedComponentIndex).toJS()
        action.focusedComponent = deleteSelectedTextInQuill(focusedComponent, action.selection)
        action.focusedComponentIndex = focusedComponentIndex

        action.toIndex = focusedComponentIndex + 1
        action.newComponent = {
          id: Math.random().toString(36).substring(7),
          type: action.componentType,
          priority: 2,
          text: deltaToHTML(action.delta),
        }
      }
    }

    if (action.componentType === 'recipeSteps') {
      const { createArticleInteractions, createArticleBody } = store.getState()
      const focusedComponentIndex = createArticleInteractions.getIn(['focusedComponent', 'index'])

      const items = []
      action.delta.eachLine((line, attributes, i) => {
        if (line.insert.length > 0) {
          items.push({
            text: deltaToHTML(line),
            image: null,
          })
        }
      })

      // delete selected text in a source component
      const focusedComponent = createArticleBody.getIn(['present', 'components', focusedComponentIndex]).toJS()
      action.focusedComponent = deleteSelectedTextInQuill(focusedComponent, action.selection)
      action.focusedComponentIndex = focusedComponentIndex

      action.newComponent = {
        id: Math.random().toString(36).substring(7),
        type: action.componentType,
        priority: 2,
        title: 'Steps',
        items,
      }
      action.toIndex = focusedComponentIndex + 1
    } else if (action.componentType === 'recipeIngredients') {
      const { createArticleInteractions, createArticleBody } = store.getState()
      const focusedComponentIndex = createArticleInteractions.getIn(['focusedComponent', 'index'])

      const lines = action.text.split('\n')
      const items = lines.map((line) => {
        const item = parse(line)
        line.split(' ').forEach((word, i) => {
          if (isUrlValid(word)) item.url = word
        })
        return item
      })

      // delete selected text in a source component
      const focusedComponent = createArticleBody.getIn(['present', 'components', focusedComponentIndex]).toJS()
      action.focusedComponent = deleteSelectedTextInQuill(focusedComponent, action.selection)
      action.focusedComponentIndex = focusedComponentIndex

      action.newComponent = {
        id: Math.random().toString(36).substring(7),
        type: action.componentType,
        priority: 2,
        title: 'Ingredients',
        items,
      }
      action.toIndex = focusedComponentIndex + 1
    }
  }

  next(action)
}

export default convertToComponent
