import { CREATE_ARTICLE_ADD_KEY_QUOTE } from 'utils/constants/actions'
import { change, formValueSelector } from 'redux-form'

const selector = formValueSelector('createArticleMetadataForm')
const addKeyQuote = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_ADD_KEY_QUOTE) {
    let quotes = selector(store.getState(), 'keyQuotes')
    quotes = quotes ? quotes.slice() : []
    quotes.push(action.quote)
    store.dispatch(change('createArticleMetadataForm', 'keyQuotes', quotes))
  }

  return next(action)
}

export default addKeyQuote
