import { useMutation } from 'react-query'
import { updateAttendance } from '../api/progressTracking'
import { MutationKeys } from '../utils/MutationKeys'

export declare module ClassAttendanceMutation {
  export interface IncorrectTermId {
    email: string
    termIds: number[]
  }

  export interface MultipleTrackingRecord {
    error: string
    userId: string
    email: string
    classId: number
  }

  export interface Success {
    unprocessed?: any[]
    succeeded?: string[]
    failed?: {
      notFound: string[]
      noSubscription: string[]
      incorrectTermId: IncorrectTermId[]
      notificationErrors: { email: string; error: string }[]
      multipleTrackingRecords: MultipleTrackingRecord[]
    }
  }

  export interface Error {
    message: string
    emails?: string[]
  }
}

interface Args {
  lessonId: string
  classId: number
  emailAddresses: string[]
  termId?: number
  activityDate: string // ISO date
  validateOnly: boolean
}

export const useUpdateClassAttendance = () => {
  const mutation = useMutation<ClassAttendanceMutation.Success, ClassAttendanceMutation.Error, Args>(
    MutationKeys.classAttendance,
    (args) => updateAttendance(args)
  )

  return mutation
}
