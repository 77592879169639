import { makeAutoObservable, runInAction } from 'mobx'

import { getOrders, createAdjustment, deleteAdjustment, getClassSubDetails } from 'api/orders'
import { updateClassSubscription } from 'api/classSubscriptions'
import { stores } from './index'

export default class OrdersStore {
  orders = []
  lastEvaluatedKey = null

  loading = true
  error = null

  activeOrder = null
  creating = false
  creatingError = null

  usingSearchForm = false
  lastFormSearchData = null

  activeSub = null

  savingSubscription = false
  savingSubscriptionError = null

  constructor() {
    makeAutoObservable(this)
  }

  get count() {
    return this.orders.length
  }

  setActiveOrder = (order) => {
    const adjustments = order?.adjustments?.sort((a, b) => new Date(b.createDate) - new Date(a.createDate))
    this.activeOrder = { ...this.setRefundValues(order), adjustments }
  }

  setRefundValues = (order) => {
    const { adjustments } = order

    order.items = order.items.map((item) => {
      item.refunded = 0
      item.additionalCharge = 0

      if (adjustments?.length) {
        const itemAdjustments = adjustments.filter((adj) => adj.lineItemId === item.id)

        if (!itemAdjustments.length) {
          return item
        }

        itemAdjustments.forEach((adj) => {
          if (adj.type === 'ADDITIONALCHARGE') {
            item.additionalCharge += adj.amount
          } else {
            item.refunded += adj.amount
          }
        })
      }

      item.refunded = Math.round(item.refunded * 100) / 100
      item.additionalCharge = Math.round(item.additionalCharge * 100) / 100
      return item
    })

    return order
  }

  fetchOrders = async (data, reset, searchForm) => {
    this.loading = true
    let requestData = data || this.lastFormSearchData

    // if new search empty the list
    if (searchForm) {
      this.orders = []
    }

    // if reset empty the list and don't send the form data
    if (reset) {
      this.orders = []
      requestData = null
    }

    this.usingSearchForm = !!(requestData?.email || requestData?.from)

    try {
      const { orders, lastEvaluatedKey } = await getOrders({
        requestData,
        // don't send lastEvaluatedKey if new search or reset
        lastEvaluatedKey: !searchForm && !reset ? this.lastEvaluatedKey : null,
      })
      runInAction(() => {
        // if searching by email - replace the list of orders
        // do the same if resetting
        this.orders = searchForm || reset ? orders : this.orders.concat(orders)

        this.lastEvaluatedKey = lastEvaluatedKey
        this.loading = false
        this.error = null
        this.lastFormSearchData = requestData
      })
    } catch (error) {
      runInAction(() => {
        this.error = error
        this.loading = false
      })
    }
  }

  createAdjustment = async (adjustment) => {
    this.creating = true
    const { id, sk } = this.activeOrder
    try {
      const adjustments = await createAdjustment({ orderId: id, sortKey: sk, adjustment })
      runInAction(() => {
        const index = this.orders.findIndex((o) => o.id === id)
        const order = { ...this.activeOrder, adjustments }
        this.orders.splice(index, 1, order)

        this.setActiveOrder(order)

        this.creating = false
        this.creatingError = null
      })
    } catch (error) {
      runInAction(() => {
        this.creatingError = error
        this.creating = false
      })
    }
  }

  deleteAdjustment = async (createDate) => {
    this.creating = true
    const { id, sk } = this.activeOrder
    try {
      const adjustments = await deleteAdjustment({ orderId: id, sortKey: sk, createDate })
      runInAction(() => {
        const index = this.orders.findIndex((o) => o.id === id)
        const order = { ...this.activeOrder, adjustments }

        this.orders.splice(index, 1, order)
        this.setActiveOrder(order)

        this.creating = false
        this.creatingError = null
      })
    } catch (error) {
      runInAction(() => {
        this.creatingError = error
        this.creating = false
      })
    }
  }

  fetchClassSubDetails = async (data) => {
    this.activeSub = null

    const { id, type, productId, libraryId, email, classTitle } = data
    const fetchSubData = {
      id,
      type,
    }
    // Note that available types of Order items are 'subscription' and 'gift',
    // while class subscription data has 3 possible types - 'library', 'subscription', or 'gift'
    //
    // Here in order to get subscription via the getSubscriptions function, adjust the request data
    // properly by picking up the actual subscription type by looking at the order item data
    if (type === 'library' || libraryId) {
      fetchSubData.libraryId = productId || libraryId
      fetchSubData.classId = null
      // change the type to 'library' only if type is 'subscription'
      // In order words, if type is 'gift', keep it as is
      if (type === 'subscription') fetchSubData.type = 'library'
    } else {
      fetchSubData.classId = productId
    }

    // 5 possible fetchSubData objects:
    // (1) library-type with libraryId
    // (2) subscription-type with classId
    // (3) subscription-type with libraryId
    // (4) gift-type with classId
    // (5) gift-type with libraryId
    this.loading = true

    try {
      const subDetailsData = await getClassSubDetails(fetchSubData)
      runInAction(() => {
        if (subDetailsData) {
          this.activeSub = { ...subDetailsData, email, classTitle }
        }
        this.loading = false
        this.error = null
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false
        this.error = error
      })
    }
  }

  // old function
  // we can delete it later if everything looks good on the orders page
  fetchClassSubDetailsOld = async (data) => {
    const { progressTrackingStore } = stores
    this.activeSub = null
    progressTrackingStore.setAssignments([])
    progressTrackingStore.setProgressTracking(null)

    const { id, type, productId, libraryId, email, classTitle } = data
    const fetchSubData = {
      id,
      type,
    }

    // Note that available types of Order items are 'subscription' and 'gift',
    // while class subscription data has 3 possible types - 'library', 'subscription', or 'gift'
    //
    // Here in order to get subscription via the getSubscriptions function, adjust the request data
    // properly by picking up the actual subscription type by looking at the order item data
    if (type === 'library' || libraryId) {
      fetchSubData.libraryId = productId || libraryId
      fetchSubData.classId = null
      // change the type to 'library' only if type is 'subscription'
      // In order words, if type is 'gift', keep it as is
      if (type === 'subscription') fetchSubData.type = 'library'
    } else {
      fetchSubData.classId = productId
    }

    // 5 possible fetchSubData objects:
    // (1) library-type with libraryId
    // (2) subscription-type with classId
    // (3) subscription-type with libraryId
    // (4) gift-type with classId
    // (5) gift-type with libraryId
    this.loading = true
    try {
      const subDetailsData = await getClassSubDetails(fetchSubData)
      runInAction(() => {
        if (subDetailsData) {
          const { progress, assignments, ...subDetails } = subDetailsData
          this.activeSub = { ...subDetails, email, classTitle }
          progressTrackingStore.setAssignments(assignments)
          progressTrackingStore.setProgressTracking(progress)
        }

        this.loading = false
        this.error = null
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false
        this.error = error
      })
    }
  }

  updateSubscription = async (data) => {
    this.savingSubscription = true
    try {
      const updatedSub = await updateClassSubscription(data)
      runInAction(() => {
        const { progress, assignments } = this.activeSub
        this.activeSub = { progress, assignments, id: updatedSub.id, ...data }

        this.savingSubscription = false
        this.savingSubscriptionError = null
      })
    } catch (error) {
      runInAction(() => {
        this.savingSubscription = false
        this.savingSubscriptionError = error
      })
    }
  }
}
