import getPrivilegesByRole from './getPrivilegesByRole'
import { Privileges } from './constants/privileges'

/* Checks if user is allowed a privilege according to their available roles. */
const grantedByRole = (roles: string | undefined, requiredPrivilege: Privileges) => {
  if (typeof roles !== 'string') {
    return false
  }
  /* loop through user roles and see if any of them includes the privilege required */
  return roles.split('|').some((role) => getPrivilegesByRole(role).includes(requiredPrivilege))
}

export default grantedByRole
