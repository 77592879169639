export default [
  {
    type: 'header',
    title: '<h2>Your news article title</h2>',
    subtitle: '<h4>Your subtitle here</h4>',
    dek: '',
    image: {
      path: 'img/org/mp169qjvkjkns2kq6.jpg',
      width: 1200,
      height: 800,
      crops: [],
      id: '98b77bf0-23b0-4594-8b7f-3f632996b45e',
      seoImageName: 'Test image',
      tags: ['Cliff'],
    },
    dummy: {
      title: true,
      subtitle: true,
    },
  },
  {
    type: 'heading',
    level: 1,
    text: 'Your first heading here',
  },
  {
    type: 'text',
    text:
      'A <strong>news article</strong> discusses current or recent news of ' +
      'either general interest (i.e. daily newspapers) or of a specific topic ' +
      '(i.e. political or trade news magazines, club newsletters, or technology ' +
      'news websites).',
  },
  {
    type: 'heading',
    level: 1,
    text: 'Your second heading here',
  },
  {
    type: 'text',
    text:
      'A news article can include accounts of eyewitnesses to the happening ' +
      'event. It can contain photographs, accounts, statistics, graphs, ' +
      'recollections, interviews, polls, debates on the topic, etc. Headlines can ' +
      'be used to focus the reader’s attention on a particular (or main) part of ' +
      'the article. The writer can also give facts and detailed information ' +
      'following answers to general questions like who, what, when, where, why and how.',
  },
  {
    type: 'heading',
    level: 1,
    text: 'Your third heading here',
  },
  {
    type: 'text',
    text:
      'Quoted references can also be helpful. References to people can also ' +
      'be made through the written accounts of interviews and debates confirming ' +
      'the factuality of the writer’s information and the reliability of his source. ' +
      'The writer can use redirection to ensure that the reader keeps reading the ' +
      'article and to draw her attention to other articles. For example, phrases ' +
      'like "Continued on page 3” redirect the reader to a page where the article ' +
      'is continued.',
  },
  {
    type: 'heading',
    level: 1,
    text: 'Your fourth heading here',
  },
  {
    type: 'text',
    text:
      'While a good conclusion is an important ingredient for newspaper ' +
      'articles, the immediacy of a deadline environment means that copy ' +
      'editing often takes the form of deleting everything past an arbitrary ' +
      'point in the story corresponding to the dictates of available space on ' +
      'a page. Therefore, newspaper reporters are trained to write in inverted ' +
      'pyramid style, with all the most important information in the first ' +
      'paragraph or two. If the less vital details are pushed towards the ' +
      'end of the story, then the potentially destructive impact of draconian ' +
      'copy editing will be minimized.',
  },
  {
    type: 'heading',
    level: 1,
    text: 'Your fifth heading here',
  },
  {
    type: 'text',
    text:
      'The article is usually on a well-defined topic or topics that are ' +
      'related in some way, such as a factual account of a newsworthy event. ' +
      'The writer of a well-written article is seen as objective and showing ' +
      'all sides to an issue. The sources for a news story should be identified ' +
      "and reliable. The technique of show, don't tell is applied.",
  },
].map((o) => ({ ...o, dummy: o.dummy || true, id: Math.random().toString(36).substring(7) }))
