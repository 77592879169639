import { fromJS } from 'immutable'

export const initialArticleQueueFilterState = fromJS({
  open: false,
  filters: {
    category: [],
    people: {
      author: '',
      editor: '',
      partner: '',
    },
    status: [],
  },
})
