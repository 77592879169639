import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

import { LinkConversionDialog } from 'components/CreateArticle/Dialogs'
import convertToAffiliateLink from 'utils/convertToAffiliateLink'
import { getIsNonAffiliateAmazonText } from './QuillTransition/utils/formatAffiliateLink'

const AffiliateUrlField = ({ input, label, meta: { touched, error }, type, ...custom }) => {
  const [showLinkConversionDialog, setShowLinkConversionDialog] = useState(false)
  const [text, setText] = useState('')

  const toggleDialog = () => {
    setShowLinkConversionDialog(!showLinkConversionDialog)
  }

  const handlePaste = (e) => {
    const text = e.clipboardData.getData('text/plain')
    if (getIsNonAffiliateAmazonText(text)) {
      setText(text)
      setShowLinkConversionDialog(true)
    }
  }

  return (
    <div>
      {showLinkConversionDialog && (
        <LinkConversionDialog
          open={showLinkConversionDialog}
          convertCallback={() => input.onChange(convertToAffiliateLink(text))}
          dontConvertCallback={() => input.onChange(text)}
          toggleDialog={toggleDialog}
          amazonLinksFound={1}
        />
      )}
      <TextField
        label={label}
        error={!!(touched && error)}
        variant="standard"
        type={type}
        {...input}
        {...custom}
        onPaste={handlePaste}
      />
    </div>
  )
}

AffiliateUrlField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  type: PropTypes.string
}

export default AffiliateUrlField
