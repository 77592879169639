import axios from './axios'

export const getProgressTracking = async ({ userId, classId, termId }) => {
  const { data } = await axios.get(`progress-tracking?userId=${userId}&classId=${classId}&termId=${termId}`)
  return data
}

export const updateSession = async ({ progressTrackingId, userId, sessionIndex, session }) => {
  const { data } = await axios.put(`progress-tracking/session/${progressTrackingId}`, { userId, sessionIndex, session })
  return data
}

export const resetSessionProgress = async ({ sessionId, body }) => {
  const { data } = await axios.put(`progress-tracking/session/reset/${sessionId}`, body)
  return data
}

export const completeSessionWithoutTrackingData = async ({ sessionId, body }) => {
  const { data } = await axios.put(`progress-tracking/session/complete/${sessionId}`, body)
  return data
}

export const activateAssignment = async ({ assignmentId, userId, active }) => {
  const { data } = await axios.put(`assignments/activate/${assignmentId}`, { userId, active })
  return data
}

export const activateFeedack = async ({ assignmentId, userId, feedbackIndex, feedback }) => {
  const { data } = await axios.put(`assignments/feedback/${assignmentId}`, { userId, feedbackIndex, feedback })
  return data
}

export const updateAttendance = async ({ classId, lessonId, ...body }) => {
  const { data } = await axios.post(`progress-tracking/batch-update?classId=${classId}&lessonId=${lessonId}`, body)
  return data
}
