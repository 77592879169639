import IconButton from '@mui/material/IconButton'
import React from 'react'
import styled from 'styled-components'

export const StyledIconButton = styled(IconButton)`
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`

interface Props {
  title: string
  onClick(): void
  disabled?: boolean

  children: React.ReactNode
}

const BodyActionButton = (props: Props) => (
  <StyledIconButton size="small" disableRipple title={props.title} onClick={props.onClick} disabled={props.disabled}>
    {props.children}
  </StyledIconButton>
)

export default BodyActionButton
