import { RSAA, getJSON } from 'redux-api-middleware'
import {
  ARTICLES_UPDATE_TITLE,
  ARTICLES_UPDATE_URL,
  ARTICLES_UPDATE_EDITORS_NOTES,
  ARTICLES_SET_PUBLISH_DT,
  ARTICLES_UPDATE_PREVIEW_IMAGE,
  ARTICLES_SORT,
  ARTICLES_SHOW_FLASH_MESSAGE,
  ARTICLES_HIDE_ERROR_MESSAGE,
  LOAD_ARTICLES_REQUEST,
  LOAD_ARTICLES_SUCCESS,
  LOAD_ARTICLES_FAILURE,
  UPDATE_ARTICLES_REQUEST,
  UPDATE_ARTICLES_SUCCESS,
  UPDATE_ARTICLES_FAILURE,
  ARTICLES_TOGGLE_ARCHIVE_DIALOG,
  ARTICLES_TOGGLE_COMPRESSED_VIEW,
  ARTICLES_TOGGLE_COMPRESSED_VIEW_ITEM,
  ARTICLES_RESET_STATE,
  ARCHIVE_ARTICLE_REQUEST,
  ARCHIVE_ARTICLE_SUCCESS,
  ARCHIVE_ARTICLE_FAILURE,
  REFRESH_ARTICLE_REQUEST,
  REFRESH_ARTICLE_SUCCESS,
  REFRESH_ARTICLE_FAILURE,
} from 'utils/constants/actions'

export function loadArticles({ route }) {
  return {
    [RSAA]: {
      endpoint: `${route}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [
        LOAD_ARTICLES_REQUEST,
        {
          type: LOAD_ARTICLES_SUCCESS,
          payload: (action, state, res) => getJSON(res),
        },
        LOAD_ARTICLES_FAILURE,
      ],
    },
  }
}

export function updateArticle({ route, id, index, dateKey, article }) {
  return {
    [RSAA]: {
      endpoint: `${route}/${id}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(route === 'articles' ? { article } : { ...article }),
      types: [
        {
          type: UPDATE_ARTICLES_REQUEST,
          payload: (action, state, res) => ({ index, dateKey }),
        },
        {
          type: UPDATE_ARTICLES_SUCCESS,
          payload: (action, state, res) => ({ index, dateKey, route }),
        },
        UPDATE_ARTICLES_FAILURE,
      ],
    },
  }
}

export function archiveArticle({ id, articlePath }) {
  return {
    [RSAA]: {
      endpoint: `articles/${id}/archive`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [
        ARCHIVE_ARTICLE_REQUEST,
        {
          type: ARCHIVE_ARTICLE_SUCCESS,
          payload: () => ({ articlePath }),
        },
        ARCHIVE_ARTICLE_FAILURE,
      ],
    },
  }
}

export function updateTitle({ articleIdx, dateKey, title }) {
  return {
    type: ARTICLES_UPDATE_TITLE,
    articleIdx,
    dateKey,
    title,
  }
}

export function updateUrl({ articleIdx, dateKey, url }) {
  return {
    type: ARTICLES_UPDATE_URL,
    articleIdx,
    dateKey,
    url,
  }
}

export function updateEditorsNotes({ articleIdx, dateKey, editorsNotes }) {
  return {
    type: ARTICLES_UPDATE_EDITORS_NOTES,
    articleIdx,
    dateKey,
    editorsNotes,
  }
}

export function setPublishDT({ dateTime, dateKey, articleIdx }) {
  return {
    type: ARTICLES_SET_PUBLISH_DT,
    dateTime,
    dateKey,
    articleIdx,
  }
}

export function updatePreviewImage({ dateKey, articleIdx, previewImage, updateHero }) {
  return {
    type: ARTICLES_UPDATE_PREVIEW_IMAGE,
    dateKey,
    articleIdx,
    previewImage,
    updateHero,
  }
}

export function sortArticles(sortBy) {
  return {
    type: ARTICLES_SORT,
    sortBy,
  }
}

export function showFlashMessage(flashMessage) {
  return {
    type: ARTICLES_SHOW_FLASH_MESSAGE,
    flashMessage,
  }
}

export function hideErrorMessage() {
  return {
    type: ARTICLES_HIDE_ERROR_MESSAGE,
  }
}

export function toggleArchiveDialog({ show, id, isPublished, articlePath }) {
  return {
    type: ARTICLES_TOGGLE_ARCHIVE_DIALOG,
    show,
    id,
    isPublished,
    articlePath,
  }
}

export function toggleCompressedView() {
  return {
    type: ARTICLES_TOGGLE_COMPRESSED_VIEW,
  }
}

export function toggleCompressedViewItem({ dateKey, index }) {
  return {
    type: ARTICLES_TOGGLE_COMPRESSED_VIEW_ITEM,
    dateKey,
    index,
  }
}

export function resetState() {
  return {
    type: ARTICLES_RESET_STATE,
  }
}

export function refreshArticle(route, id) {
  return {
    [RSAA]: {
      endpoint: `${route}/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [
        REFRESH_ARTICLE_REQUEST,
        {
          type: REFRESH_ARTICLE_SUCCESS,
          payload: (action, state, res) => getJSON(res),
        },
        REFRESH_ARTICLE_FAILURE,
      ],
    },
  }
}
