import { createTheme } from '@mui/material/styles'
//all colors here are also on styles/colors
//types are defined in the declaration file: theme.d.ts
export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    invertedLight: { main: '#fff' },
    invertedDark: { main: '#ccc' },
    cerulean: { main: '#1976d2' }, // primary1Color, we might want to unify these with main mui theme
    //blues
    skyBlue: { main: '#5fccff' },
    darkSkyBlue: { main: '#2db4e5' },
    mediumBlue: { main: '#3054c1' },
    oceanBlue: { main: '#006db2' }, // primary2Color
    // purples
    purple: { main: '#7d5bae' },
    purple30: { main: '#9575CD' },
    // blue greys
    blueGrey40: { main: '#78909C' },
    // greens
    greenApple: { main: '#76c950' },
    green: { main: '#00E676' },
    // reds
    pinkRed: { main: '#ff0066' }, // accent1Color
    red: { main: '#F44336' },
    // yellows
    squash: { main: '#edba19' },
    orange: { main: '#fe8d06' },
    starDustOrange: { main: '#ff8400' },
    dustyOrange: { main: '#e88440' },
    // grey
    grey: { main: '#d8d8d8' },
    grey05: { main: '#424242' },
    grey10: { main: '#4a4a4a' },
    grey15: { main: '#dcdcdc' },
    grey20: { main: '#727272' },
    grey30: { main: '#a8a8a8' },
    grey40: { main: '#fafafa' },
    grey50: { main: '#f2f2f2' },
    grey60: { main: '#eeeeee' },
    // black
    black: { main: '#4a4a4a' },
    podcastTeal: { main: '#36a58a' },
  },
  typography: {
    //example usage: variant="pinkRed"
    pinkRed: {
      color: '#ff0066',
    },
    greenApple: {
      color: '#76c950',
    },
    dustyOrange: {
      color: '#e88440',
    },
  },
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '&+.MuiDialogContent-root': {
            paddingTop: '6px',
          },
        },
      },
    },
  },
})

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    invertedLight: { main: '#000' },
    invertedDark: { main: '#333' },
    cerulean: { main: '#1976d2' },
    //blue
    skyBlue: { main: '#5fccff' },
    darkSkyBlue: { main: '#2db4e5' },
    mediumBlue: { main: '#3054c1' },
    oceanBlue: { main: '#006db2' },
    // purples
    purple: { main: '#7d5bae' },
    purple30: { main: '#9575CD' },
    // blue greys
    blueGrey40: { main: '#78909C' },
    // greens
    greenApple: { main: '#76c950' },
    green: { main: '#00E676' },
    // reds
    pinkRed: { main: '#ff0066' },
    red: { main: '#F44336' },
    // yellows
    squash: { main: '#edba19' },
    orange: { main: '#fe8d06' },
    starDustOrange: { main: '#ff8400' },
    dustyOrange: { main: '#e88440' },
    // grey
    grey: { main: '#d8d8d8' },
    grey05: { main: '#424242' },
    grey10: { main: '#4a4a4a' },
    grey15: { main: '#dcdcdc' },
    grey20: { main: '#727272' },
    grey30: { main: '#a8a8a8' },
    grey40: { main: '#fafafa' },
    grey50: { main: '#f2f2f2' },
    grey60: { main: '#eeeeee' },
    // black
    black: { main: '#4a4a4a' },
    podcastTeal: { main: '#36a58a' },
  },
  typography: {
    pinkRed: {
      color: '#ff0066',
    },
    greenApple: {
      color: '#76c950',
    },
    dustyOrange: {
      color: '#e88440',
    },
  },
})

export type themeType = typeof lightTheme
