const { REACT_APP_CLOUDINARY_BASE_URL } = process.env

const defaultCloudinaryParams = 'c_fill,w_800,q_auto,fl_lossy'
export default function imageUrl(path: string, cloudinaryParams: string | null = defaultCloudinaryParams) {
  if (!path) {
    return
  }

  if (path.startsWith('//res.mindbodygreen.com/')) {
    path = path.replace('//res.mindbodygreen.com/', '')
  }

  if (path.includes('/img/ftr') || path.includes('/img/cls') || path.includes('/img/ptr')) {
    const [, right] = path.split('/img/')
    path = cloudinaryParams
      ? `https://mindbodygreen-res.cloudinary.com/image/upload/${cloudinaryParams}/${right}`
      : path
  } else if (path.includes('res.mindbodygreen.com')) {
    const [left, right] = path.split('/upload/')
    path = cloudinaryParams ? `${left}/upload/${cloudinaryParams}/${right}` : path
  } else if (REACT_APP_CLOUDINARY_BASE_URL && path.startsWith(REACT_APP_CLOUDINARY_BASE_URL)) {
    path = `${REACT_APP_CLOUDINARY_BASE_URL}${cloudinaryParams}/${path.replace(REACT_APP_CLOUDINARY_BASE_URL, '')}`
  } else {
    // this block killed some of the URLs, the above change was an attempt to solve this issue
    path = `${REACT_APP_CLOUDINARY_BASE_URL}${cloudinaryParams || ''}/${path.split('/').splice(1).join('/')}`
  }

  return path.replace('http:', 'https:')
}

export function getConstrainedImageUrl(path: string) {
  return imageUrl(path, 'c_limit,w_400,h_400,q_auto,fl_lossy')
}
