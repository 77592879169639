import { Quill } from 'react-quill'

import { ANALYSE_TEXT_SUCCESS } from 'utils/constants/actions'
import findOccurrencesInString from 'utils/findOccurrencesInString'

const textAnalysis = (store) => (next) => (action) => {
  if (action.type === ANALYSE_TEXT_SUCCESS) {
    const keywords = action.payload.keywords
    const { createArticleBody, createArticleMetadata } = store.getState()
    let bodyComponents = createArticleBody.getIn(['present', 'components']).toJS()

    if (keywords.length) {
      bodyComponents = bodyComponents.map((component) => {
        if (component.text && component.type === 'text') {
          // initiate Quill so we can format the text properly and determine the keyword position most precisely
          const editorEl = document.createElement('div')
          const editor = new Quill(editorEl)
          editor.setContents(component.delta)
          const editorText = editor.getText()
          keywords.forEach((keyword) => {
            const keywordIndices = findOccurrencesInString(editorText, keyword.text)
            if (keywordIndices.length) {
              keywordIndices.forEach((index) => {
                const keywordUrl = editor.getFormat(index, keyword.text.length).link
                  ? editor.getFormat(index, keyword.text.length).link.url
                  : null
                // highlight the text only if it's not a link already
                if (!keywordUrl) {
                  editor.formatText(index, keyword.text.length, {
                    link: {
                      url: 'no url',
                      keyword: true,
                    },
                  })
                }
              })
            }
          })
          component.text = editor.root.innerHTML
          // destroy Quill
          editorEl.remove()
        }
        return component
      })
    }

    // if (keywords.length) {
    //   // search for keywords
    //   keywords.map(keyword => {
    //     bodyComponents = bodyComponents.map(component => {
    //       if (component.text) {
    //         // initiate Quill so we can format the text properly and determine the keyword position most precisely
    //         const editorEl = document.createElement('div')
    //         const editor = new Quill(editorEl)
    //         // this creates an error - "The given range isn't in document."
    //         editor.setContents(component.delta)
    //         const editorText = editor.getText()
    //         const keywordIndices = findOccurrencesInString(editorText, keyword.text)
    //         console.log(keywordIndices)
    //         if (keywordIndices.length) {
    //           keywordIndices.map(index => {
    //             console.log(index, keyword.text)
    //             const keywordUrl = editor.getFormat(index, keyword.text.length).link
    //               ? editor.getFormat(index, keyword.text.length).link.url : null
    //             // highlight the text only if it's not a link already
    //             if (!keywordUrl) {
    //               editor.formatText(index, keyword.text.length, {
    //                 link: {
    //                   url: 'no url',
    //                   highlight: true,
    //                 }
    //               })
    //             }
    //           })
    //         }
    //         component.text = editor.root.innerHTML
    //         // destroy Quill
    //         editorEl.remove()
    //       }
    //       return component
    //     })
    //   })
    // }

    action.updatedBodyComponents = bodyComponents
    // save a list of keywords used in the article
    // will be useful when cleaning unused keywords
    const keywordsInArticle = createArticleMetadata.get('keywordsInArticle').toJS()
    action.keywords = keywordsInArticle.concat(keywords)
  }
  next(action)
}

export default textAnalysis
