import React from 'react'
import Button from '@mui/material/Button'
import FileUploadIcon from '@mui/icons-material/CloudUpload'
import styled from 'styled-components'

const StyledInput = styled.input`
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  z-index: 1000;
`

interface Props {
  label?: string
  multiple?: boolean
  accept?: string
  maxFileSize?: number
  handleFileInput(e: React.ChangeEvent<HTMLInputElement>): void
  fileTooLargeHandler(): void
  style?: React.CSSProperties
  disabled?: boolean
}

const FileInput = ({
  handleFileInput,
  label,
  multiple,
  accept,
  maxFileSize,
  fileTooLargeHandler,
  style,
  disabled,
}: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (maxFileSize && fileTooLargeHandler) {
      if (e.target.files) {
        for (const file of e.target.files) {
          const size = Math.round(file.size / 1000) / 1000
          if (typeof maxFileSize === 'number' && size > maxFileSize) {
            fileTooLargeHandler()
            return
          }
        }
      }
    }

    handleFileInput(e)
  }

  return (
    <Button
      startIcon={<FileUploadIcon color={'invertedLight' as any} />}
      variant="contained"
      style={style}
      disabled={disabled}
    >
      {label}
      <StyledInput
        accept={accept}
        name="fileInput"
        onChange={handleChange}
        type="file"
        multiple={multiple}
        disabled={disabled}
      />
    </Button>
  )
}

export default FileInput
