import { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import Fab from '@mui/material/Fab'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Tooltip } from '@mui/material'

const Wrapper = styled.div`
  position: fixed;
  right: 2rem;
  bottom: 2rem;
`

const CreateLessonButton = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (id) => {
    setAnchorEl(null)
  }

  const handleMenuClick = (id) => {
    history.push(`/classes/${props.classId}/modules/${props.moduleId}/${id}`)
  }

  return (
    <Wrapper>
      <Fab
        color="primary"
        aria-label="create session"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <AddIcon />
      </Fab>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
        {props.lessonTypes.map((l) => {
          const liveLessionDisabled = l.id === 'live-session' && props.disableLiveLesson
          return (
            <Tooltip key={l.id} title={liveLessionDisabled && 'Terms required.'} placement="left">
              <span>
                <MenuItem key={l.id} onClick={() => handleMenuClick(l.id)} disabled={liveLessionDisabled}>
                  {l.title}
                </MenuItem>
              </span>
            </Tooltip>
          )
        })}
      </Menu>
    </Wrapper>
  )
}

CreateLessonButton.propTypes = {
  lessonTypes: PropTypes.arrayOf(PropTypes.object),
  classId: PropTypes.number,
  moduleId: PropTypes.string,
}

export default CreateLessonButton
