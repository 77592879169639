import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Navbar } from 'components/NavBar'
import SideMenu from 'components/SideMenu'
import { ContentWapper } from './layoutStyles'
import { StyledColumn } from './StyledLayouts'

const AdvancedPagesLayout = ({ children, title }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <StyledColumn>
      <Navbar title={title} showLogo toggleSidemenu={() => setIsDrawerOpen((p) => !p)} />
      <SideMenu open={isDrawerOpen} toggleSidemenu={() => setIsDrawerOpen((p) => !p)} />
      <ContentWapper>{children}</ContentWapper>
    </StyledColumn>
  )
}

AdvancedPagesLayout.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    title: state.navbar.getIn(['navbarPrefs', 'title']),
  }
}

export default connect(mapStateToProps)(AdvancedPagesLayout)
