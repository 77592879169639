const transitionStyle = {
  base: {
    position: 'absolute',
    right: '5%',
    top: '10%',
    width: '40%',
    height: '80%',
    overflow: 'visible',
    backgroundColor: '#fff',
    padding: '5px 10px',
    borderRadius: '2px',
    border: '1px solid #dcdcdc',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.2)',
    opacity: 0,
    zIndex: 2,
  },
  appear: {
    opacity: 1,
    transition: 'all 500ms',
  },
}

export { transitionStyle }
