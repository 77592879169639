import { useState } from 'react'
import Alert from '@mui/material/Alert'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import WithProgress from 'ui/WithProgress'
import { Autocomplete, DialogActions, DialogContent } from '@mui/material'

import Loader from 'components/new-pages/Loader'
import { FormikErrors, useFormik } from 'formik'
import CommonDialogTitle from '../../../../../Common/DialogComponents/CommonDialogTitle'
import LessonImageComponent from '../../FormComponents/LessonImageComponent'
import InstructorsDropdown from '../../FormComponents/InstructorsDropdown'
import StyledFlex from 'layouts/StyledLayouts'
import SelectedVideo from '../../FormComponents/SelectedVideo'
import VideoDialog from '../../EditVideoLesson/VideoDialog'
import { ClassModule } from 'types/ClassModule'
import { pinkRed } from 'styles/colors'
import versionValidationSchema from './versionValidationSchema'

interface InitialValues {
  id?: string
  title: string
  image: string
  terms: number[]
  video?: ClassModule.Video
  instructorId?: number
}

interface Props {
  classItem: ClassModule.Data<InitialValues, {}>
  activeLesson: ClassModule.Session
  open: boolean
  setOpen: (a: boolean) => void
  moduleId: string
}

const initialValues: InitialValues = {
  title: '',
  image: '',
  terms: [],
  video: undefined,
  instructorId: undefined,
}

const VersionDialog = ({ classItem, activeLesson, open, setOpen, moduleId }: Props) => {
  const [isVideoInsertOpen, setIsVideoInsertOpen] = useState(false)
  const [_error, setError] = useState('')

  let termsInUse: number[] = []

  activeLesson?.versions?.forEach((item: ClassModule.Version) => {
    if (item?.terms) {
      termsInUse = [...termsInUse, ...item?.terms]
    }
  })

  // a version cannot have previously used terms
  const possibleTerms = classItem.terms.filter((item: ClassModule.Term) => {
    if (item.id === activeLesson?.termId) return false
    if (termsInUse.includes(item.id)) return false
    return true
  })

  const toggleIsVideoInsertOpen = () => {
    setIsVideoInsertOpen((p) => !p)
  }

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: versionValidationSchema,
    validate: (values) => {
      const errors: FormikErrors<{ terms: string }> = {}
      const hasEmptyTerms = activeLesson.versions.some((item) => !item.terms?.length)
      if (hasEmptyTerms && !values.terms.length) {
        errors.terms = 'Please choose at least one term, this lesson already has a default version with no terms'
      }
      return errors
    },
    onSubmit: async (values) => {
      setError('')
      const body = {
        video: values.video,
        image: values.image,
        title: values.title,
        lessonId: activeLesson.id,
        classId: classItem.id,
        terms: values.terms,
        instructorId: values.instructorId,
      }

      try {
        const response = await classItem.createVersion(classItem.id, moduleId, activeLesson.id, body)
        if (response?.id) {
          handleClose()
        }
      } catch (err: any) {
        setError(err.message)
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    setOpen(false)
  }

  const handleVideoConfirm = (video: ClassModule.Video) => {
    formik.setFieldValue('video', {
      duration: parseInt(video.duration, 10),
      mediaId: video.key,
      thumbnail:
        typeof video.custom.thumbnailURL === 'string' && video.custom.thumbnailURL.length
          ? video.custom.thumbnailURL
          : undefined,
      title: video.title,
    })

    setIsVideoInsertOpen(false)
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      aria-labelledby="max-width-dialog-title"
      data-testid="term-dialog"
      onClose={handleClose}
    >
      <CommonDialogTitle onClose={handleClose}>Create Version</CommonDialogTitle>
      <form id="live-lesson-form" onSubmit={formik.handleSubmit}>
        <DialogContent>
          <FormControl sx={{ display: 'flex', gap: '12px' }}>
            {classItem?.error && (
              <Alert severity="error" variant="filled">
                {classItem.error}
              </Alert>
            )}
            {classItem.loading ? <Loader /> : null}

            <LessonImageComponent
              image={formik.values.image}
              setImage={(image) => formik.setFieldValue('image', image)}
            />
            <div style={{ color: pinkRed }}>{formik.errors.image}</div>

            <StyledFlex style={{ flex: 1 }}>
              <SelectedVideo video={formik.values.video} handleOpenVideoInsert={toggleIsVideoInsertOpen} />
            </StyledFlex>

            <FormControl>
              <TextField
                fullWidth
                name="title"
                type="text"
                label="Title"
                onChange={formik.handleChange}
                value={formik.values.title}
                error={!!formik.errors.title}
                helperText={formik.errors.title}
              />
            </FormControl>
            <FormControl>
              <Autocomplete
                value={possibleTerms?.filter((c: ClassModule.Term) => formik.values.terms?.includes(c.id))}
                onChange={(e, value: ClassModule.Term[]) => {
                  formik.setFieldValue(
                    'terms',
                    value.map((item: ClassModule.Term) => item.id)
                  )
                }}
                multiple={true}
                options={possibleTerms}
                disableCloseOnSelect
                getOptionLabel={(option) => option.title}
                limitTags={7}
                disabled={!possibleTerms?.length}
                renderInput={(params) => (
                  <TextField {...params} label="Terms" error={!!formik.errors.terms} helperText={formik.errors.terms} />
                )}
              />
              {!possibleTerms?.length && <p>No terms left, please create one or remove from other versions</p>}
            </FormControl>
            <FormControl>
              <InstructorsDropdown
                selectedInstructorId={formik.values.instructorId}
                classInstructorIds={classItem.instructors}
                handleChange={(e, value, fieldName) => formik.setFieldValue(fieldName, value)}
                error={!!formik.errors.instructorId}
                helperText={formik.errors.instructorId}
              />
            </FormControl>
          </FormControl>
          {isVideoInsertOpen && <VideoDialog onClose={toggleIsVideoInsertOpen} onConfirm={handleVideoConfirm} />}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={formik.isSubmitting}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            sx={{ margin: '12px 0' }}
          >
            <WithProgress isLoading={formik.isSubmitting}>Create</WithProgress>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default VersionDialog
