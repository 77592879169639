import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import articlesReducer from 'reducers/articlesReducer'
import articleQueueFilterReducer from 'reducers/articleQueueFilterReducer'
import articleQueueRangePickerReducer from 'reducers/articleQueueRangePickerReducer'
import articleTemplateReducer from 'reducers/articleTemplateReducer'
import navbarReducer from 'reducers/navbarReducer'
import createArticleLayoutReducer from 'reducers/createArticleLayoutReducer'
import createArticleInteractionsReducer from 'reducers/createArticleInteractionsReducer'
import createArticleMetadataReducer from 'reducers/createArticleMetadataReducer'
import createArticleBodyReducer from 'reducers/createArticleBodyReducer'
import createArticleKeywordsReducer from 'reducers/createArticleKeywordsReducer'
import editorsQueueReducer from 'reducers/editorsQueueReducer'
import generalQueueReducer from 'reducers/generalQueueReducer'
import editorsReducer from 'reducers/editorsReducer'
import embedDataReducer from 'reducers/embedDataReducer'
import profileReducer from 'reducers/profileReducer'
import cognitoUserReducer from 'reducers/cognitoUserReducer'
import authorsReducer from 'reducers/authorsReducer'
import imageUploadReducer from 'reducers/imageUploadReducer'
import articleSearchReducer from 'reducers/articleSearchReducer'
import tagsReducer from 'reducers/tagsReducer'
import seriesReducer from 'reducers/seriesReducer'
import eventsReducer from 'reducers/eventsReducer'
import videosReducer from 'reducers/videosReducer'
import classesReducer from 'reducers/classesReducer'
import textAnalysisReducer from 'reducers/textAnalysisReducer'
import createPodcastMetadataReducer from 'reducers/createPodcastMetadataReducer'
import podcastsReducer from 'reducers/podcastsReducer'
import authorFieldsReducer from 'reducers/authorFieldsReducer'
import confirmDialogReducer from 'reducers/confirmDialogReducer'
import imageReducer from 'reducers/imageReducer'
import internalTagsReducer from 'reducers/internalTags'
import partnersReducer from 'reducers/partnersReducer'
import internetConnectionReducer from 'reducers/internetConnectionReducer'
import reviewerFieldReducer from 'reducers/reviewerFieldReducer'
import shopifyReducer from 'reducers/shopifyReducer'
import recipesReducer from 'reducers/recipesReducer'
import contentfulReducer from 'reducers/contentfulReducer'
import productsReducer from 'reducers/productsReducer'

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    articles: articlesReducer,
    articleQueueFilter: articleQueueFilterReducer,
    articleQueueRangePicker: articleQueueRangePickerReducer,
    articleTemplate: articleTemplateReducer,
    editors: editorsReducer,
    editorsQueue: editorsQueueReducer,
    form: formReducer,
    generalQueue: generalQueueReducer,
    navbar: navbarReducer,
    createArticleKeywords: createArticleKeywordsReducer,
    createArticleLayout: createArticleLayoutReducer,
    createArticleBody: createArticleBodyReducer,
    createArticleMetadata: createArticleMetadataReducer,
    createArticleInteractions: createArticleInteractionsReducer,
    createPodcastMetadata: createPodcastMetadataReducer,
    embed: embedDataReducer,
    profile: profileReducer,
    cognitoUser: cognitoUserReducer,
    authors: authorsReducer,
    imageUpload: combineReducers({
      header: imageUploadReducer('header'),
      social: imageUploadReducer('social'),
      preview: imageUploadReducer('preview'),
      heroVideo: imageUploadReducer('heroVideo'),
      gallery: imageUploadReducer('gallery'),
      heroImage: imageUploadReducer('heroImage'),
      singleImage: imageUploadReducer('singleImage'),
      queuePreview: imageUploadReducer('queuePreview'),
      quoteBackground: imageUploadReducer('quoteBackground'),
      parallax: imageUploadReducer('parallax'),
      serieHeroImage: imageUploadReducer('serieHeroImage'),
      serieLogo: imageUploadReducer('serieLogo'),
      serieOverlay: imageUploadReducer('serieOverlay'),
      shop: imageUploadReducer('shop'),
      textAndImage: imageUploadReducer('textAndImage'),
      podcastPreviewImage: imageUploadReducer('podcastPreviewImage'),
      podcastImages: imageUploadReducer('podcastImages'),
      partnerLogo: imageUploadReducer('partnerLogo'),
      navigationLogo: imageUploadReducer('navigationLogo'),
      partnerPreviewLogo: imageUploadReducer('partnerPreviewLogo'),
      productPlacementComponent: imageUploadReducer('productPlacementComponent'),
      shopLogo: imageUploadReducer('shopLogo'),
      recipeIngredient: imageUploadReducer('recipeIngredient'),
      productPlacementUnit: imageUploadReducer('productPlacementUnit'),
      reviewComponentItem: imageUploadReducer('reviewComponentItem'),
    }),
    confirmDialog: combineReducers({
      unpublish: confirmDialogReducer('unpublish'),
      draft: confirmDialogReducer('draft'),
      deleteComponent: confirmDialogReducer('deleteComponent'),
      deleteHeroImage: confirmDialogReducer('deleteHeroImage'),
      dropComponent: confirmDialogReducer('dropComponent'),
      restoreVersion: confirmDialogReducer('restoreVersion'),
      savePrompt: confirmDialogReducer('savePrompt'),
      unarchive: confirmDialogReducer('unarchive'),
      imageConvert: confirmDialogReducer('imageConvert'),
      kickUser: confirmDialogReducer('kickUser'),
    }),
    articleSearch: articleSearchReducer,
    tags: tagsReducer,
    series: seriesReducer,
    events: eventsReducer,
    videos: videosReducer,
    classes: classesReducer,
    textAnalysis: textAnalysisReducer,
    podcasts: podcastsReducer,
    authorFields: authorFieldsReducer,
    images: imageReducer,
    internalTags: internalTagsReducer,
    partners: partnersReducer,
    internetConnection: internetConnectionReducer,
    reviewerField: reviewerFieldReducer,
    shopify: shopifyReducer,
    recipes: recipesReducer,
    contentful: contentfulReducer,
    products: productsReducer,
    ...asyncReducers,
  })
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
