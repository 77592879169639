import axios from './axios'

export const fetchImages = async (params) => {
  const { data } = await axios.get(`images?${new URLSearchParams(params).toString()}`)
  return data
}

export const uploadImage = async (body) => {
  const { data } = await axios.post('image-file', body)
  return data
}
