import axios from './axios'

export const getClassPromotions = async ({ requestData, lastEvaluatedKey }) => {
  const params = { ...requestData, ...lastEvaluatedKey }

  const { data } = await axios.get(`class-promotions?${new URLSearchParams(params).toString()}`)
  return data
}

export const save = async (body) => {
  const { data } = await axios.post('class-promotions', body)
  return data
}

export const update = async (body) => {
  const { data } = await axios.put(`class-promotions/${body.code}`, body)
  return data
}
