import styled from 'styled-components'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const CloseIconStyled = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

const SaveButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
`

const LiveLessonButtons = ({ classId, moduleId }) => {
  return (
    <>
      <SaveButton variant="contained" type="submit" form="live-lesson-form">
        Save
      </SaveButton>
      <Link to={`/classes/${classId}/modules/${moduleId}`}>
        <IconButton>
          <CloseIconStyled />
        </IconButton>
      </Link>
    </>
  )
}

LiveLessonButtons.propTypes = {
  classId: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired,
}

export default LiveLessonButtons
