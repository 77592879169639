import { apiMiddleware } from 'redux-api-middleware'
import { applyMiddleware, createStore, compose } from 'redux'

import makeRootReducer from './reducers'
import { loadVersionFile } from 'actions/navbarActions'
import articleQueueSort from 'middlewares/articleQueueSort'
import {
  insertComponent,
  mergeComponents,
  moveWithArrows,
  submitArticle,
  submitPodcast,
  submitRecipe,
  redirectAndClearDraft,
  loadDraft,
  saveDraft,
  discardDraft,
  historyMiddleware,
  beforeSubmit,
  convertToComponent,
  setActive,
  textAnalysis,
  unhighlightKeywords,
  loadVersion,
  highlightSameLinks,
  convertImageComponent,
  searchLinks,
  convertToComponentNew,
  splitDraggedComponent,
} from 'middlewares/createArticle'
import initialState from './initialState'
import authMiddleware from 'middlewares/authMiddleware'
// import globalArticleSearch from 'middlewares/globalArticleSearch'
import addSeries from 'middlewares/addSeries'
import articlePreviewImage from 'middlewares/articlePreviewImage'
import analyzeTextMiddleware from 'middlewares/analyzeText'
import authErrorMiddleware from 'middlewares/authErrorMiddleware'
import articleDateChange from 'middlewares/articleDateChange'
import addKeyQuote from 'middlewares/addKeyQuote'
import compressedView from 'middlewares/compressedView'
import loadArticles from 'middlewares/loadArticles'
import apiUrlMiddleware from 'middlewares/apiUrl'
import applyArticlesFilters from 'middlewares/applyArticlesFilters'
import checkCMSVersion from 'middlewares/checkCMSVersion'
import articleRefreshed from 'middlewares/articleRefreshed'

const { REACT_APP_ENVIRONMENT, REACT_APP_VERSION_CHECK_INTERVAL } = process.env

const middlewares = [
  applyArticlesFilters,
  searchLinks,
  authMiddleware,
  analyzeTextMiddleware,
  apiUrlMiddleware,
  apiMiddleware,
  authErrorMiddleware,
  articleRefreshed,
  articleQueueSort,
  highlightSameLinks,
  loadVersion,
  loadArticles,
  compressedView,
  articleDateChange,
  // globalArticleSearch,
  moveWithArrows,
  mergeComponents,
  insertComponent,
  convertToComponent,
  convertToComponentNew,
  setActive,
  textAnalysis,
  unhighlightKeywords,
  submitArticle,
  submitPodcast,
  submitRecipe,
  redirectAndClearDraft,
  addSeries,
  addKeyQuote,
  loadDraft,
  saveDraft,
  beforeSubmit,
  convertToComponent,
  historyMiddleware,
  discardDraft,
  articlePreviewImage,
  checkCMSVersion,
  convertImageComponent,
  splitDraggedComponent,
]

const enhancer =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        maxAge: 20,
        actionsBlacklist: ['@@redux-form/REGISTER_FIELD'],
      })
    : compose

const composeEnhancer =
  REACT_APP_ENVIRONMENT?.toUpperCase() === 'DEV'
    ? enhancer(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares)

const reduxStore = (initialState) => {
  const store = createStore(makeRootReducer(), initialState, composeEnhancer)

  store.asyncReducers = {}

  store.dispatch(loadVersionFile())
  setInterval(() => {
    store.dispatch(loadVersionFile())
  }, REACT_APP_VERSION_CHECK_INTERVAL)

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  return store
}

const store = reduxStore(initialState)

export default store
