import {
  SEARCH_INTERNAL_TAGS_REQUEST,
  SEARCH_INTERNAL_TAGS_SUCCESS,
  SEARCH_INTERNAL_TAGS_FAILURE,
  CREATE_INTERNAL_TAG_REQUEST,
  CREATE_INTERNAL_TAG_SUCCESS,
  CREATE_INTERNAL_TAG_FAILURE,
} from 'utils/constants/actions'

import { RSAA, getJSON } from 'redux-api-middleware'

const { REACT_APP_API_URL } = process.env

export function searchInternalTags(query) {
  return {
    [RSAA]: {
      endpoint: `${REACT_APP_API_URL}internal-tags?pattern=${query}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [
        SEARCH_INTERNAL_TAGS_REQUEST,
        {
          type: SEARCH_INTERNAL_TAGS_SUCCESS,
          payload: (action, state, res) => getJSON(res).then((res) => res.map((t) => t.name)),
        },
        SEARCH_INTERNAL_TAGS_FAILURE,
      ],
    },
  }
}

export function createInternalTag(name) {
  return {
    [RSAA]: {
      endpoint: `${REACT_APP_API_URL}internal-tags`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [
        CREATE_INTERNAL_TAG_REQUEST,
        {
          type: CREATE_INTERNAL_TAG_SUCCESS,
          payload: (action, state, res) => getJSON(res),
        },
        CREATE_INTERNAL_TAG_FAILURE,
      ],
      body: JSON.stringify({ name }),
    },
  }
}
