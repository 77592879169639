import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'

export const StyledDialogTitle = styled(DialogTitle)`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'title closeButton';
  align-items: flex-start;
  gap: 16px;
`
