import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import imageUrl from 'utils/imageUrl'
import styled from 'styled-components'

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 110px;
  object-fit: contain;
  cursor: pointer;
`

const PreviewImage = ({ className, image, articleId, pathname = '/article/edit' }) => {
  const history = useHistory()
  return (
    <StyledImage
      className={className}
      src={
        image
          ? imageUrl(image.get('path'), 'c_fill,w_200,h_110,g_auto,q_auto,fl_lossy')
          : 'https://res.mindbodygreen.com/img/backgrounds/i_need_a_hero.png'
      }
      onClick={() => history.push(`${pathname}/${articleId}`)}
      alt="preview"
    />
  )
}

PreviewImage.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object,
  onEditPreviewImage: PropTypes.func,
  editingDisabled: PropTypes.bool,
  articleId: PropTypes.string,
}

export default PreviewImage
