const joinComponentsByType = (components, type, field) => {
  const toReturn = []
  const last = (a) => a[a.length - 1]

  components.forEach((component) => {
    if (!component) return
    if (toReturn.length && last(toReturn).type === type && component.type === type) {
      last(toReturn)[field] = last(toReturn)[field].concat(component[field])
    } else {
      toReturn.push(component)
    }
  })

  return toReturn
}

export default joinComponentsByType
