import { fromJS } from 'immutable'
import moment from 'moment'

export default function groupByPublishDate(json) {
  let articles = fromJS(Array.isArray(json) ? json : [])
    .filter((article) => !article.getIn(['archiveStatus', 'archived']))
    .groupBy((article) => {
      if (!article.get('publishDate')) return 'No Publish Date'
      return moment(article.get('publishDate')).format('LL')
    })

  const noPublishDateList = articles.find((v, k) => k === 'No Publish Date') || []
  articles = articles.delete('No Publish Date')
  articles = articles.sortBy((a, k) => new Date(k))
  articles = articles.map((list) => list.sortBy((a) => moment(a.get('publishDate'))))
  articles = articles.merge(fromJS({ 'No Publish Date': noPublishDateList }))

  return articles
}
