import { Map, fromJS } from 'immutable'
import {
  ANALYSE_TEXT_REQUEST,
  ANALYSE_TEXT_SUCCESS,
  ANALYSE_TEXT_FAILURE,
  TEXT_ANALYSIS_DIALOG_TOGGLE,
  CREATE_ARTICLE_RESET_STATE,
} from 'utils/constants/actions'

const textAnalysisReducer = (state = Map(), action) => {
  switch (action.type) {
    case ANALYSE_TEXT_REQUEST:
      return state.set('loading', true)
    case ANALYSE_TEXT_SUCCESS:
      return state
        .set('NLUAnalysis', fromJS(action.payload))
        .set('showDialog', true)
        .set('loading', false)
        .set('loaded', true)
    case ANALYSE_TEXT_FAILURE:
      return state.set('showDialog', false).set('loading', false)
    case TEXT_ANALYSIS_DIALOG_TOGGLE:
      return state.set('showDialog', action.open)
    case CREATE_ARTICLE_RESET_STATE:
      return state.set('NLUAnalysis', fromJS({})).set('loaded', false)
    default:
      return state
  }
}

export default textAnalysisReducer
