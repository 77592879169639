import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

const LinkConversionDialog = ({
  open,
  toggleDialog,
  convertCallback,
  dontConvertCallback = () => {},
  amazonLinksFound,
}) => {
  return (
    <Dialog open={open} fullWidth onClose={toggleDialog}>
      <DialogContent>
        <h3>
          There {amazonLinksFound > 1 ? 'are' : 'is'} {amazonLinksFound} Amazon link{amazonLinksFound > 1 && 's'}
          &nbsp;found in the text you just added. Do you want to convert {amazonLinksFound > 1 ? 'them' : 'it'} to
          affiliate link{amazonLinksFound > 1 && 's'}?
        </h3>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dontConvertCallback()
            toggleDialog()
          }}
        >
          No
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            convertCallback()
            toggleDialog()
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

LinkConversionDialog.propTypes = {
  open: PropTypes.bool,
  toggleDialog: PropTypes.func,
  convertCallback: PropTypes.func,
  dontConvertCallback: PropTypes.func,
  amazonLinksFound: PropTypes.number,
}

export default LinkConversionDialog
