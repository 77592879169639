import PropTypes from 'prop-types'
import React from 'react'
import Button from '@mui/material/Button'

import styled from 'styled-components'
import StyledFlex, { StyledColumn } from '../../../../../layouts/StyledLayouts'

const PreviewImage = styled.img`
  max-height: 500px;
  max-width: 100%;
  width: auto;
`

const UploadConfirmation = ({ onCancel, onUpload, imageFile }) => {
  setTimeout(() => window.dispatchEvent(new Event('resize'))) // making the repositionOnUpdate wait
  return (
    <StyledColumn style={{ gap: '12px' }}>
      <StyledFlex style={{ justifyContent: 'center' }}>
        <PreviewImage src={imageFile} alt="preview" />
      </StyledFlex>

      <StyledFlex style={{ justifyContent: 'end' }}>
        <Button onClick={onCancel}>Choose a different image</Button>
        <Button onClick={onUpload}>Upload image</Button>
      </StyledFlex>
    </StyledColumn>
  )
}

UploadConfirmation.propTypes = {
  imageFile: PropTypes.string,
  onCancel: PropTypes.func,
  onUpload: PropTypes.func,
}

export default UploadConfirmation
