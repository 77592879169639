import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import styled from 'styled-components'

import SingleImageDialog from 'components/CreateArticle/EditableModalComponents/Images/SingleImageDialog'
import imageUrl from 'utils/imageUrl'
import StyledFlex, { StyledColumn } from '../layouts/StyledLayouts'

const StyledImage = styled.img`
  width: 100%;
  object-fit: contain;
`

class ImageInput extends Component {
  static propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    dialogTitle: PropTypes.string,
    namespace: PropTypes.string.isRequired,
    width: PropTypes.string,
    crops: PropTypes.array,
  }

  constructor(props) {
    super(props)

    this.state = {
      dialogOpen: false,
    }
  }

  render() {
    const {
      input: { value, onChange },
      label,
      dialogTitle,
      namespace,
      width,
      crops,
    } = this.props
    const { dialogOpen } = this.state

    const setImageHandler = (image) => {
      this.setState({ dialogOpen: false })
      onChange(image)
    }

    return (
      <StyledColumn style={{ maxWidth: width || '100%' }}>
        <SingleImageDialog
          show={dialogOpen}
          initialState={value || null}
          namespace={namespace}
          imageTitle={dialogTitle}
          onCancel={() => this.setState({ dialogOpen: false })}
          onEditImage={setImageHandler}
          onInsertImage={setImageHandler}
          crops={crops}
        />
        {value && (
          <StyledFlex style={{ alignItems: 'center' }}>
            <StyledImage src={imageUrl(value.path)} alt="path" />
          </StyledFlex>
        )}
        <Button onClick={() => this.setState({ dialogOpen: true })}>{`${value ? 'Change' : 'Add'} ${label}`}</Button>
        {value && <Button onClick={() => onChange(null)}>{`Remove ${label}`}</Button>}
      </StyledColumn>
    )
  }
}

export default ImageInput
