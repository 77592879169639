import { CREATE_ARTICLE_SAVE_DRAFT } from 'utils/constants/actions'

const saveDraft = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_SAVE_DRAFT) {
    const { pathname } = window.location

    const key = pathname.split('/')[1]
    if (!key) return next(action)

    if (action.save) {
      if (action.dataType === 'body') {
        sessionStorage.setItem(`${key}.draftArticleComponents`, JSON.stringify(action.data))
      } else if (action.dataType === 'metadata') {
        sessionStorage.setItem(`${key}.draftArticleMetadata`, JSON.stringify(action.data))
      }
      action.showDiscardBtn = true
    } else {
      if (action.dataType === 'body') {
        sessionStorage.removeItem(`${key}.draftArticleComponents`)
      } else if (action.dataType === 'metadata') {
        sessionStorage.removeItem(`${key}.draftArticleMetadata`)
      }
      action.showDiscardBtn = false
    }
  }

  return next(action)
}

export default saveDraft
