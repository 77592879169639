export const SET_NAVBAR_TITLE = 'SET_NAVBAR_TITLE'
export const TOGGLE_SEARCHBAR = 'TOGGLE_SEARCHBAR'

export const LOAD_ARTICLES_FAILURE = 'LOAD_ARTICLES_FAILURE'
export const LOAD_ARTICLES_REQUEST = 'LOAD_ARTICLES_REQUEST'
export const LOAD_ARTICLES_SUCCESS = 'LOAD_ARTICLES_SUCCESS'
export const LOAD_MY_ARTICLES_FAILURE = 'LOAD_MY_ARTICLES_FAILURE'
export const LOAD_MY_ARTICLES_REQUEST = 'LOAD_MY_ARTICLES_REQUEST'
export const LOAD_MY_ARTICLES_SUCCESS = 'LOAD_MY_ARTICLES_SUCCESS'
export const ARTICLES_UPDATE_TITLE = 'ARTICLES_UPDATE_TITLE'
export const ARTICLES_UPDATE_URL = 'ARTICLES_UPDATE_URL'
export const ARTICLES_UPDATE_EDITORS_NOTES = 'ARTICLES_UPDATE_EDITORS_NOTES'
export const ARTICLES_TOGGLE_DT_PICKER = 'ARTICLES_TOGGLE_DT_PICKER'
export const ARTICLES_UPDATE_PUBLISH_DATE = 'ARTICLES_UPDATE_PUBLISH_DATE'
export const ARTICLES_UPDATE_PUBLISH_TIME = 'ARTICLES_UPDATE_PUBLISH_TIME'
export const ARTICLES_SET_PUBLISH_DT = 'ARTICLES_SET_PUBLISH_DT'
export const ARTICLES_SORT = 'ARTICLES_SORT'
export const ARTICLES_SHOW_FLASH_MESSAGE = 'ARTICLES_SHOW_FLASH_MESSAGE'
export const ARTICLES_HIDE_ERROR_MESSAGE = 'ARTICLES_HIDE_ERROR_MESSAGE'
export const ARTICLES_UPDATE_PREVIEW_IMAGE = 'ARTICLES_UPDATE_PREVIEW_IMAGE'
export const ARTICLES_TOGGLE_ARCHIVE_DIALOG = 'ARTICLES_TOGGLE_ARCHIVE_DIALOG'
export const ARTICLES_RELOAD = 'ARTICLES_RELOAD'
export const ARTICLES_TOGGLE_COMPRESSED_VIEW = 'ARTICLES_TOGGLE_COMPRESSED_VIEW'
export const ARTICLES_TOGGLE_COMPRESSED_VIEW_ITEM = 'ARTICLES_TOGGLE_COMPRESSED_VIEW_ITEM'
export const ARTICLES_RESET_STATE = 'ARTICLES_RESET_STATE'

export const SELECT_ARTICLE_TEMPLATE = 'OPEN_ARTICLE_FOCUS_DIALOG'
export const TOGGLE_CREATE_ARTICLE_MENU = 'TOGGLE_CREATE_ARTICLE_MENU'

export const TOGGLE_QUEUE_FILTER_POPOVER = 'TOGGLE_QUEUE_FILTER_POPOVER'
export const RANGE_PICKER_ON_DATE_CHANGE = 'RANGE_PICKER_ON_DATE_CHANGE'

export const CREATE_ARTICLE_INSERT_COMPONENT = 'CREATE_ARTICLE_INSERT_COMPONENT'
export const CREATE_ARTICLE_INSERT_COMPONENT_FROM_PLACEHOLDER = 'CREATE_ARTICLE_INSERT_COMPONENT_FROM_PLACEHOLDER'
export const CREATE_ARTICLE_REMOVE_COMPONENT = 'CREATE_ARTICLE_REMOVE_COMPONENT'
export const CREATE_ARTICLE_UPDATE_METADATA = 'CREATE_ARTICLE_UPDATE_METADATA'
export const CREATE_ARTICLE_REORDER_COMPONENTS = 'CREATE_ARTICLE_REORDER_COMPONENTS'
export const CREATE_ARTICLE_ON_EDITOR_CHANGE = 'CREATE_ARTICLE_ON_EDITOR_CHANGE'
export const CREATE_ARTICLE_UPDATE_HEADER = 'CREATE_ARTICLE_UPDATE_HEADER'
export const CREATE_ARTICLE_SAVE_FOCUSED_DATA = 'CREATE_ARTICLE_SAVE_FOCUSED_DATA'
export const CREATE_ARTICLE_INSERT_SPLITTED_COMPONENT = 'CREATE_ARTICLE_INSERT_SPLITTED_COMPONENT'
export const CREATE_ARTICLE_ON_COMPONENT_DROP = 'CREATE_ARTICLE_ON_COMPONENT_DROP'
export const CREATE_ARTICLE_SPLIT_WITH_DRAGGED_COMPONENT = 'CREATE_ARTICLE_SPLIT_WITH_DRAGGED_COMPONENT'
export const CREATE_ARTICLE_INSERT_DRAGGED_COMPONENT = 'CREATE_ARTICLE_INSERT_DRAGGED_COMPONENT'
export const CREATE_ARTICLE_MERGE_COMPONENTS = 'CREATE_ARTICLE_MERGE_COMPONENTS'
export const CREATE_ARTICLE_MARK_AS_INSERTED = 'CREATE_ARTICLE_MARK_AS_INSERTED'
export const CREATE_ARTICLE_MOVE_TO_COMPONENT = 'CREATE_ARTICLE_MOVE_TO_COMPONENT'
export const CREATE_ARTICLE_TOGGLE_EDIT_DIALOG = 'CREATE_ARTICLE_TOGGLE_EDIT_DIALOG'
export const CREATE_ARTICLE_INSERT_COMPONENT_FROM_MODAL = 'CREATE_ARTICLE_INSERT_COMPONENT_FROM_MODAL'
export const CREATE_ARTICLE_UPDATE_COMPONENT_FROM_MODAL = 'CREATE_ARTICLE_UPDATE_COMPONENT_FROM_MODAL'
export const CREATE_ARTICLE_SUBMIT_ARTICLE = 'CREATE_ARTICLE_SUBMIT_ARTICLE'
export const CREATE_ARTICLE_SEND_ARTICLE_REQUEST = 'CREATE_ARTICLE_SEND_ARTICLE_REQUEST'
export const CREATE_ARTICLE_SEND_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SEND_ARTICLE_SUCCESS'
export const CREATE_ARTICLE_SEND_ARTICLE_FAILURE = 'CREATE_ARTICLE_SEND_ARTICLE_FAILURE'
export const CREATE_ARTICLE_FETCH_ARTICLE_REQUEST = 'CREATE_ARTICLE_FETCH_ARTICLE_REQUEST'
export const CREATE_ARTICLE_FETCH_ARTICLE_SUCCESS = 'CREATE_ARTICLE_FETCH_ARTICLE_SUCCESS'
export const CREATE_ARTICLE_FETCH_ARTICLE_FAILURE = 'CREATE_ARTICLE_FETCH_ARTICLE_FAILURE'
export const CREATE_ARTICLE_SHOW_FLASH_MESSAGE = 'CREATE_ARTICLE_SHOW_FLASH_MESSAGE'
export const CREATE_ARTICLE_HIDE_ERROR_MESSAGE = 'CREATE_ARTICLE_HIDE_ERROR_MESSAGE'
export const CREATE_ARTICLE_FORM_ERROR = 'CREATE_ARTICLE_FORM_ERROR'
export const CREATE_ARTICLE_RESET_STATE = 'CREATE_ARTICLE_RESET_STATE'
export const CREATE_ARTICLE_SHOULD_SAVE_DRAFT = 'CREATE_ARTICLE_SHOULD_SAVE_DRAFT'
export const CREATE_ARTICLE_SAVE_DRAFT = 'CREATE_ARTICLE_SAVE_DRAFT'
export const CREATE_ARTICLE_DISCARD_DRAFT = 'CREATE_ARTICLE_DISCARD_DRAFT'
export const CREATE_ARTICLE_RESET_SUBMIT_STATUS = 'CREATE_ARTICLE_RESET_SUBMIT_STATUS'
export const CREATE_ARTICLE_TOGGLE_DRAFT_DIALOG = 'CREATE_ARTICLE_TOGGLE_DRAFT_DIALOG'
export const CREATE_ARTICLE_TOGGLE_PUBLISH_BUTTON = 'CREATE_ARTICLE_TOGGLE_PUBLISH_BUTTON'
export const CREATE_ARTICLE_KEYWORDS_REQUEST = 'CREATE_ARTICLE_KEYWORDS_REQUEST'
export const CREATE_ARTICLE_KEYWORDS_SUCCESS = 'CREATE_ARTICLE_KEYWORDS_SUCCESS'
export const CREATE_ARTICLE_KEYWORDS_FAILURE = 'CREATE_ARTICLE_KEYWORDS_FAILURE'
export const CREATE_ARTICLE_SAVE_HISTORY = 'CREATE_ARTICLE_SAVE_HISTORY'
export const CREATE_ARTICLE_UNDO = 'CREATE_ARTICLE_UNDO'
export const CREATE_ARTICLE_REDO = 'CREATE_ARTICLE_REDO'
export const CREATE_ARTICLE_UPDATE_HEADER_TITLE_IMAGE = 'CREATE_ARTICLE_UPDATE_HEADER_TITLE_IMAGE'
export const CREATE_ARTICLE_ADD_KEY_QUOTE = 'CREATE_ARTICLE_ADD_KEY_QUOTE'
export const CREATE_ARTICLE_SAVE_TRACKED_TIME_START = 'CREATE_ARTICLE_SAVE_TRACKED_TIME_START'
export const CREATE_ARTICLE_SAVE_IDLE_TIME = 'CREATE_ARTICLE_SAVE_IDLE_TIME'
export const CREATE_ARTICLE_UPDATE_TIME_SPENT = 'CREATE_ARTICLE_UPDATE_TIME_SPENT'
export const CREATE_ARTICLE_CONVERT_TO_COMPONENT = 'CREATE_ARTICLE_CONVERT_TO_COMPONENT'
export const CREATE_ARTICLE_SET_ACTIVE = 'CREATE_ARTICLE_SET_ACTIVE'
export const CREATE_ARTICLE_PRESERVE_QUILL_STATE = 'CREATE_ARTICLE_PRESERVE_QUILL_STATE'
export const CREATE_ARTICLE_TOGGLE_HI_DIALOG = 'CREATE_ARTICLE_TOGGLE_HI_DIALOG'
export const CREATE_ARTICLE_DELETE_HERO_IMAGE = 'CREATE_ARTICLE_DELETE_HERO_IMAGE'
export const CREATE_ARTICLE_UPDATE_HERO_IMAGE = 'CREATE_ARTICLE_UPDATE_HERO_IMAGE'
export const CREATE_ARTICLE_TOGGLE_PUBLISH_DIALOG = 'CREATE_ARTICLE_TOGGLE_PUBLISH_DIALOG'
export const CREATE_ARTICLE_PUBLISH_ARTICLE_REQUEST = 'CREATE_ARTICLE_PUBLISH_ARTICLE_REQUEST'
export const CREATE_ARTICLE_PUBLISH_ARTICLE_SUCCESS = 'CREATE_ARTICLE_PUBLISH_ARTICLE_SUCCESS'
export const CREATE_ARTICLE_PUBLISH_ARTICLE_FAILURE = 'CREATE_ARTICLE_PUBLISH_ARTICLE_FAILURE'
export const CREATE_ARTICLE_USER_STARTED_TYPING = 'CREATE_ARTICLE_USER_STARTED_TYPING'
export const CREATE_ARTICLE_UNPUBLISH_ARTICLE_REQUEST = 'CREATE_ARTICLE_UNPUBLISH_ARTICLE_REQUEST'
export const CREATE_ARTICLE_UNPUBLISH_ARTICLE_SUCCESS = 'CREATE_ARTICLE_UNPUBLISH_ARTICLE_SUCCESS'
export const CREATE_ARTICLE_UNPUBLISH_ARTICLE_FAILURE = 'CREATE_ARTICLE_UNPUBLISH_ARTICLE_FAILURE'
export const CREATE_ARTICLE_UPDATE_COMPONENT_WORD_COUNT = 'CREATE_ARTICLE_UPDATE_COMPONENT_WORD_COUNT'
export const CREATE_ARTICLE_SET_INSERTION_TARGET = 'CREATE_ARTICLE_SET_INSERTION_TARGET'
export const CREATE_ARTICLE_UNHIGHLIGHT_KEYWORDS = 'CREATE_ARTICLE_UNHIGHLIGHT_KEYWORDS'
export const CREATE_ARTICLE_LOAD_VERSION = 'CREATE_ARTICLE_LOAD_VERSION'
export const CREATE_ARTICLE_TOGGLE_CONFIRM_DIALOG = 'CREATE_ARTICLE_TOGGLE_CONFIRM_DIALOG'
export const CREATE_ARTICLE_HIGHLIGHT_SAME_LINKS = 'CREATE_ARTICLE_HIGHLIGHT_SAME_LINKS'
export const CREATE_ARTICLE_DUPLICATE_COMPONENT = 'CREATE_ARTICLE_DUPLICATE_COMPONENT'

export const CREATE_ARTICLE_INBOUND_ARTICLES_REQUEST = 'CREATE_ARTICLE_INBOUND_ARTICLES_REQUEST'
export const CREATE_ARTICLE_INBOUND_ARTICLES_SUCCESS = 'CREATE_ARTICLE_INBOUND_ARTICLES_SUCCESS'
export const CREATE_ARTICLE_INBOUND_ARTICLES_FAILURE = 'CREATE_ARTICLE_INBOUND_ARTICLES_FAILURE'

export const SAVE_CURRENT_STATE_BEFORE_HISTORY_CHANGES = 'SAVE_CURRENT_STATE_BEFORE_HISTORY_CHANGES'

export const GET_EMBED_DATA_REQUEST = 'GET_EMBED_DATA_REQUEST'
export const GET_EMBED_DATA_FAILURE = 'GET_EMBED_DATA_FAILURE'
export const GET_EMBED_DATA_SUCCESS = 'GET_EMBED_DATA_SUCCESS'
export const EMBED_RESET = 'EMBED_RESET'

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE'
export const REMOVE_UPLOADED_IMAGE = 'REMOVE_UPLOADED_IMAGE'

export const UPDATE_ARTICLES_REQUEST = 'UPDATE_ARTICLES_REQUEST'
export const UPDATE_ARTICLES_SUCCESS = 'UPDATE_ARTICLES_SUCCESS'
export const UPDATE_ARTICLES_FAILURE = 'ARTICLES_UPDATE_FAILURE'

export const SAVE_IMAGE_DATA_REQUEST = 'SAVE_IMAGE_DATA_REQUEST'
export const SAVE_IMAGE_DATA_SUCCESS = 'SAVE_IMAGE_DATA_SUCCESS'
export const SAVE_IMAGE_DATA_FAILURE = 'SAVE_IMAGE_DATA_FAILURE'

export const LOAD_PARTNERS_REQUEST = 'LOAD_PARTNERS_REQUEST'
export const LOAD_PARTNERS_SUCCESS = 'LOAD_PARTNERS_SUCCESS'
export const LOAD_PARTNERS_FAILURE = 'LOAD_PARTNERS_FAILURE'

export const LOAD_EDITORS_REQUEST = 'LOAD_EDITORS_REQUEST'
export const LOAD_EDITORS_SUCCESS = 'LOAD_EDITORS_SUCCESS'
export const LOAD_EDITORS_FAILURE = 'LOAD_EDITORS_FAILURE'

export const LOAD_PROFILE_REQUEST = 'LOAD_PROFILE_REQUEST'
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS'
export const LOAD_PROFILE_FAILURE = 'LOAD_PROFILE_FAILURE'

export const LOAD_QA = 'LOAD_QA'
export const SET_COGNITO_USER = 'SET_COGNITO_USER'
export const REMOVE_COGNITO_USER = 'REMOVE_COGNITO_USER'

export const LOAD_AUTHORS_REQUEST = 'LOAD_AUTHORS_REQUEST'
export const LOAD_AUTHORS_SUCCESS = 'LOAD_AUTHORS_SUCCESS'
export const LOAD_AUTHORS_FAILURE = 'LOAD_AUTHORS_FAILURE'

export const GET_AUTHOR_BY_ID_REQUEST = 'GET_AUTHOR_BY_ID_REQUEST'
export const GET_AUTHOR_BY_ID_SUCCESS = 'GET_AUTHOR_BY_ID_SUCCESS'
export const GET_AUTHOR_BY_ID_FAILURE = 'GET_AUTHOR_BY_ID_FAILURE'

export const LOAD_SERIES_REQUEST = 'LOAD_SERIES_REQUEST'
export const LOAD_SERIES_SUCCESS = 'LOAD_SERIES_SUCCESS'
export const LOAD_SERIES_FAILURE = 'LOAD_SERIES_FAILURE'
export const TOGGLE_SERIES_DIALOG = 'TOGGLE_SERIES_DIALOG'
export const ADD_SERIES_REQUEST = 'ADD_SERIES_REQUEST'
export const ADD_SERIES_SUCCESS = 'ADD_SERIES_SUCCESS'
export const ADD_SERIES_FAILURE = 'ADD_SERIES_FAILURE'

export const SEARCH_ARTICLE_ID_REQUEST = 'SEARCH_ARTICLE_ID_REQUEST'
export const SEARCH_ARTICLE_ID_SUCCESS = 'SEARCH_ARTICLE_ID_SUCCESS'
export const SEARCH_ARTICLE_ID_FAILURE = 'SEARCH_ARTICLE_ID_FAILURE'

export const SEARCH_ARTICLE_TITLE_REQUEST = 'SEARCH_ARTICLE_TITLE_REQUEST'
export const SEARCH_ARTICLE_TITLE_SUCCESS = 'SEARCH_ARTICLE_TITLE_SUCCESS'
export const SEARCH_ARTICLE_TITLE_FAILURE = 'SEARCH_ARTICLE_TITLE_FAILURE'

export const METADATA_TOGGLE_IMAGE_DIALOG = 'METADATA_TOGGLE_IMAGE_DIALOG'
export const METADATA_TOGGLE_URL_DIALOG = 'METADATA_TOGGLE_URL_DIALOG'
export const METADATA_PREVENT_URL_DIALOG = 'METADATA_PREVENT_URL_DIALOG'

export const LOAD_EVENTS_REQUEST = 'LOAD_EVENTS_REQUEST'
export const LOAD_EVENTS_SUCCESS = 'LOAD_EVENTS_SUCCESS'
export const LOAD_EVENTS_FAILURE = 'LOAD_EVENTS_FAILURE'

export const RESET_IMAGE_UPLOAD_STATE = 'RESET_IMAGE_UPLOAD_STATE'

export const ANALYSE_TEXT_REQUEST = 'ANALYSE_TEXT_REQUEST'
export const ANALYSE_TEXT_SUCCESS = 'ANALYSE_TEXT_SUCCESS'
export const ANALYSE_TEXT_FAILURE = 'ANALYSE_TEXT_FAILURE'

export const TEXT_ANALYSIS_DIALOG_TOGGLE = 'TEXT_ANALYSIS_DIALOG_TOGGLE'

export const SEARCH_VIDEOS_REQUEST = 'SEARCH_VIDEOS_REQUEST'
export const SEARCH_VIDEOS_SUCCESS = 'SEARCH_VIDEOS_SUCCESS'
export const SEARCH_VIDEOS_FAIL = 'SEARCH_VIDEOS_FAIL'

export const LOAD_VIDEO_PLAYERS_REQUEST = 'LOAD_VIDEO_PLAYERS_REQUEST'
export const LOAD_VIDEO_PLAYERS_SUCCESS = 'LOAD_VIDEO_PLAYERS_SUCCESS'
export const LOAD_VIDEO_PLAYERS_FAILURE = 'LOAD_VIDEO_PLAYERS_FAILURE'

export const LOAD_CLASSES_REQUEST = 'LOAD_CLASSES_REQUEST'
export const LOAD_CLASSES_SUCCESS = 'LOAD_CLASSES_SUCCESS'
export const LOAD_CLASSES_FAILURE = 'LOAD_CLASSES_FAILURE'

export const CREATE_PODCAST_SUBMIT_PODCAST = 'CREATE_PODCAST_SUBMIT_PODCAST'

export const LOAD_SERIE_REQUEST = 'LOAD_SERIE_REQUEST'
export const LOAD_SERIE_SUCCESS = 'LOAD_SERIE_SUCCESS'
export const LOAD_SERIE_FAILURE = 'LOAD_SERIE_FAILURE'

export const UPDATE_SERIE_REQUEST = 'UPDATE_SERIE_REQUEST'
export const UPDATE_SERIE_SUCCESS = 'UPDATE_SERIE_SUCCESS'
export const UPDATE_SERIE_FAILURE = 'UPDATE_SERIE_FAILURE'

export const SERIE_HIDE_FLASH_MESSAGE = 'SERIE_HIDE_FLASH_MESSAGE'

export const SUBMIT_RECIPE = 'SUBMIT_RECIPE'
export const SEND_RECIPE_REQUEST = 'SEND_RECIPE_REQUEST'
export const SEND_RECIPE_SUCCESS = 'SEND_RECIPE_SUCCESS'
export const SEND_RECIPE_FAILURE = 'SEND_RECIPE_FAILURE'

export const CREATE_PODCAST_FETCH_PODCAST_REQUEST = 'CREATE_PODCAST_FETCH_PODCAST_REQUEST'
export const CREATE_PODCAST_FETCH_PODCAST_SUCCESS = 'CREATE_PODCAST_FETCH_PODCAST_SUCCESS'
export const CREATE_PODCAST_FETCH_PODCAST_FAILURE = 'CREATE_PODCAST_FETCH_PODCAST_FAILURE'

export const GET_SUBJECT_BY_ID_REQUEST = 'GET_SUBJECT_BY_ID_REQUEST'
export const GET_SUBJECT_BY_ID_SUCCESS = 'GET_SUBJECT_BY_ID_SUCCESS'
export const GET_SUBJECT_BY_ID_FAILURE = 'GET_SUBJECT_BY_ID_FAILURE'

export const LOAD_PODCASTS_REQUEST = 'LOAD_PODCASTS_REQUEST'
export const LOAD_PODCASTS_SUCCESS = 'LOAD_PODCASTS_SUCCESS'
export const LOAD_PODCASTS_FAILURE = 'LOAD_PODCASTS_FAILURE'

export const AUTHOR_FIELD_LOAD_REQUEST = 'AUTHOR_FIELD_LOAD_REQUEST'
export const AUTHOR_FIELD_LOAD_SUCCESS = 'AUTHOR_FIELD_LOAD_SUCCESS'
export const AUTHOR_FIELD_LOAD_FAILURE = 'AUTHOR_FIELD_LOAD_FAILURE'

export const AUTHOR_FIELD_GET_AUTHOR_BY_ID_REQUEST = 'AUTHOR_FIELD_GET_AUTHOR_BY_ID_REQUEST'
export const AUTHOR_FIELD_GET_AUTHOR_BY_ID_SUCCESS = 'AUTHOR_FIELD_GET_AUTHOR_BY_ID_SUCCESS'
export const AUTHOR_FIELD_GET_AUTHOR_BY_ID_FAILURE = 'AUTHOR_FIELD_GET_AUTHOR_BY_ID_FAILURE'

export const SEARCH_IMAGES_REQUEST = 'SEARCH_IMAGES_REQUEST'
export const SEARCH_IMAGES_SUCCESS = 'SEARCH_IMAGES_SUCCESS'
export const SEARCH_IMAGES_FAILURE = 'SEARCH_IMAGES_FAILURE'

export const ARCHIVE_ARTICLE_REQUEST = 'ARCHIVE_ARTICLE_REQUEST'
export const ARCHIVE_ARTICLE_SUCCESS = 'ARCHIVE_ARTICLE_SUCCESS'
export const ARCHIVE_ARTICLE_FAILURE = 'ARCHIVE_ARTICLE_FAILURE'

export const CREATE_ARTICLE_REMOVE_DUMMY_FLAG = 'CREATE_ARTICLE_REMOVE_DUMMY_FLAG'
export const TOGGLE_RANGE_PICKER_WITH_NO_PUBLISH_DATE = 'TOGGLE_RANGE_PICKER_WITH_NO_PUBLISH_DATE'

export const CREATE_ARTICLE_REMOVE_HEADER_DUMMY_FLAG = 'CREATE_ARTICLE_REMOVE_HEADER_DUMMY_FLAG'

export const TOGGLE_INBOUND_LINK_UPDATE_DIALOG = 'TOGGLE_INBOUND_LINK_UPDATE_DIALOG'

export const CREATE_ARTICLE_UPDATE_INBOUND_LINKS_REQUEST = 'CREATE_ARTICLE_UPDATE_INBOUND_LINKS_REQUEST'
export const CREATE_ARTICLE_UPDATE_INBOUND_LINKS_SUCCESS = 'CREATE_ARTICLE_UPDATE_INBOUND_LINKS_SUCCESS'
export const CREATE_ARTICLE_UPDATE_INBOUND_LINKS_FAILURE = 'CREATE_ARTICLE_UPDATE_INBOUND_LINKS_FAILURE'

export const CREATE_ARTICLE_PUBLISH_MULTIPLE_REQUEST = 'CREATE_ARTICLE_PUBLISH_MULTIPLE_REQUEST'
export const CREATE_ARTICLE_PUBLISH_MULTIPLE_SUCCESS = 'CREATE_ARTICLE_PUBLISH_MULTIPLE_SUCCESS'
export const CREATE_ARTICLE_PUBLISH_MULTIPLE_FAILURE = 'CREATE_ARTICLE_PUBLISH_MULTIPLE_FAILURE'

export const TOGGLE_TAGS_DIALOG = 'TOGGLE_TAGS_DIALOG'

export const TOGGLE_SHOW_VERSION_NOTICE = 'TOGGLE_SHOW_VERSION_NOTICE'
export const LOAD_VERSION_FILE_REQUEST = 'LOAD_VERSION_FILE_REQUEST'
export const LOAD_VERSION_FILE_SUCCESS = 'LOAD_VERSION_FILE_SUCCESS'
export const LOAD_VERSION_FILE_FAILURE = 'LOAD_VERSION_FILE_FAILURE'

export const ARCHIVE_ARTICE_REQUEST = 'ARCHIVE_ARTICE_REQUEST'
export const ARCHIVE_ARTICE_SUCCESS = 'ARCHIVE_ARTICE_SUCCESS'
export const ARCHIVE_ARTICE_FAILURE = 'ARCHIVE_ARTICE_FAILURE'

export const UNARCHIVE_ARTICLE_REQUEST = 'UNARCHIVE_ARTICLE_REQUEST'
export const UNARCHIVE_ARTICLE_SUCCESS = 'UNARCHIVE_ARTICLE_SUCCESS'
export const UNARCHIVE_ARTICLE_FAILURE = 'UNARCHIVE_ARTICLE_FAILURE'

export const CREATE_ARTICLE_MARK_COMPONENT = 'CREATE_ARTICLE_MARK_COMPONENT'

export const REFRESH_ARTICLE_REQUEST = 'REFRESH_ARTICLE_REQUEST'
export const REFRESH_ARTICLE_SUCCESS = 'REFRESH_ARTICLE_SUCCESS'
export const REFRESH_ARTICLE_FAILURE = 'REFRESH_ARTICLE_FAILURE'

export const CREATE_ARTICLE_TOGGLE_USER_SUBMIT = 'CREATE_ARTICLE_TOGGLE_USER_SUBMIT'

export const CREATE_ARTICLE_ENABLE_AUTOSAVE = 'CREATE_ARTICLE_ENABLE_AUTOSAVE'
export const CREATE_ARTICLE_DISABLE_AUTOSAVE = 'CREATE_ARTICLE_DISABLE_AUTOSAVE'

export const CREATE_PODCAST_SEND_PODCAST_REQUEST = 'CREATE_PODCAST_SEND_PODCAST_REQUEST'
export const CREATE_PODCAST_SEND_PODCAST_SUCCESS = 'CREATE_PODCAST_SEND_PODCAST_SUCCESS'
export const CREATE_PODCAST_SEND_PODCAST_FAILURE = 'CREATE_PODCAST_SEND_PODCAST_FAILURE'

export const CREATE_ARTICLE_TOGGLE_ERROR_DIALOG = 'CREATE_ARTICLE_TOGGLE_ERROR_DIALOG'

export const PUBLISH_PODCAST_REQUEST = 'PUBLISH_PODCAST_REQUEST'
export const PUBLISH_PODCAST_SUCCESS = 'PUBLISH_PODCAST_SUCCESS'
export const PUBLISH_PODCAST_FAILURE = 'PUBLISH_PODCAST_FAILURE'

export const UNPUBLISH_PODCAST_REQUEST = 'UNPUBLISH_PODCAST_REQUEST'
export const UNPUBLISH_PODCAST_SUCCESS = 'UNPUBLISH_PODCAST_SUCCESS'
export const UNPUBLISH_PODCAST_FAILURE = 'UNPUBLISH_PODCAST_FAILURE'

export const CREATE_ARTICLE_LINK_SEARCH = 'CREATE_ARTICLE_LINK_SEARCH'
export const CREATE_ARTICLE_CHANGE_ACTIVE_SEARCH_LINK = 'CREATE_ARTICLE_CHANGE_ACTIVE_SEARCH_LINK'

export const SEARCH_ARTICLE_URL_REQUEST = 'SEARCH_ARTICLE_URL_REQUEST'
export const SEARCH_ARTICLE_URL_SUCCESS = 'SEARCH_ARTICLE_URL_SUCCESS'
export const SEARCH_ARTICLE_URL_FAILURE = 'SEARCH_ARTICLE_URL_FAILURE'

export const SEARCH_INTERNAL_TAGS_REQUEST = 'SEARCH_INTERNAL_TAGS_REQUEST'
export const SEARCH_INTERNAL_TAGS_SUCCESS = 'SEARCH_INTERNAL_TAGS_SUCCESS'
export const SEARCH_INTERNAL_TAGS_FAILURE = 'SEARCH_INTERNAL_TAGS_FAILURE'

export const CREATE_INTERNAL_TAG_REQUEST = 'CREATE_INTERNAL_TAG_REQUEST'
export const CREATE_INTERNAL_TAG_SUCCESS = 'CREATE_INTERNAL_TAG_SUCCESS'
export const CREATE_INTERNAL_TAG_FAILURE = 'CREATE_INTERNAL_TAG_FAILURE'

export const INTERNET_CONNECTION_SET_STATUS = 'INTERNET_CONNECTION_SET_STATUS'

export const CREATE_ARTICLE_CONVERT_IMAGE_COMPONENT = 'CREATE_ARTICLE_CONVERT_IMAGE_COMPONENT'

export const SAVE_PARTNER_REQUEST = 'SAVE_PARTNER_REQUEST'
export const SAVE_PARTNER_SUCCESS = 'SAVE_PARTNER_SUCCESS'
export const SAVE_PARTNER_FAILURE = 'SAVE_PARTNER_FAILURE'

export const PARTNERS_PAGE_HIDE_FLASH_MESSAGE = 'PARTNERS_PAGE_HIDE_FLASH_MESSAGE'

export const LOAD_REVIEWERS_REQUEST = 'LOAD_REVIEWERS_REQUEST'
export const LOAD_REVIEWERS_SUCCESS = 'LOAD_REVIEWERS_SUCCESS'
export const LOAD_REVIEWERS_FAILURE = 'LOAD_REVIEWERS_FAILURE'

export const GET_REVIEWER_BY_ID_REQUEST = 'GET_REVIEWER_BY_ID_REQUEST'
export const GET_REVIEWER_BY_ID_SUCCESS = 'GET_REVIEWER_BY_ID_SUCCESS'
export const GET_REVIEWER_BY_ID_FAILURE = 'GET_REVIEWER_BY_ID_FAILURE'

export const LOAD_SHOPIFY_PRODUCTS_REQUEST = 'LOAD_SHOPIFY_PRODUCTS_REQUEST'
export const LOAD_SHOPIFY_PRODUCTS_SUCCESS = 'LOAD_SHOPIFY_PRODUCTS_SUCCESS'
export const LOAD_SHOPIFY_PRODUCTS_FAILURE = 'LOAD_SHOPIFY_PRODUCTS_FAILURE'

export const LOAD_RECIPES_REQUEST = 'LOAD_RECIPES_REQUEST'
export const LOAD_RECIPES_SUCCESS = 'LOAD_RECIPES_SUCCESS'
export const LOAD_RECIPES_FAILURE = 'LOAD_RECIPES_FAILURE'

export const APPLY_ARTICLES_FILTERS_REQUEST = 'APPLY_ARTICLES_FILTERS_REQUEST'
export const APPLY_ARTICLES_FILTERS_SUCCESS = 'APPLY_ARTICLES_FILTERS_SUCCESS'
export const APPLY_ARTICLES_FILTERS_FAILURE = 'APPLY_ARTICLES_FILTERS_FAILURE'

export const CREATE_ARTICLE_TOGGLE_DROP_DIALOG = 'CREATE_ARTICLE_TOGGLE_DROP_DIALOG'
export const CREATE_ARTICLE_TOGGLE_UNPUBLISH_DIALOG = 'CREATE_ARTICLE_TOGGLE_UNPUBLISH_DIALOG'
export const LOAD_DEFAULT_EDITORS_QUEUE = 'LOAD_DEFAULT_EDITORS_QUEUE'
export const LOAD_DEFAULT_GENERAL_QUEUE = 'LOAD_DEFAULT_GENERAL_QUEUE'

export const GET_PRODUCT_UNIT_CODE_REQUEST = 'GET_PRODUCT_UNIT_CODE_REQUEST'
export const GET_PRODUCT_UNIT_CODE_SUCCESS = 'GET_PRODUCT_UNIT_CODE_SUCCESS'
export const GET_PRODUCT_UNIT_CODE_FAILURE = 'GET_PRODUCT_UNIT_CODE_FAILURE'

export const CREATE_ARTICLE_CONVERT_TO_COMPONENT_NEW = 'CREATE_ARTICLE_CONVERT_TO_COMPONENT_NEW'
export const CREATE_ARTICLE_SET_LAST_INSERTED_LINK = 'CREATE_ARTICLE_SET_LAST_INSERTED_LINK'
export const CREATE_ARTICLE_SET_LINK_SEARCH_PATTERN = 'CREATE_ARTICLE_SET_LINK_SEARCH_PATTERN'

export const LOAD_CONTENTFUL_PROMOTIONAL_UNITS_REQUEST = 'LOAD_CONTENTFUL_PROMOTIONAL_UNITS_REQUEST'
export const LOAD_CONTENTFUL_PROMOTIONAL_UNITS_SUCCESS = 'LOAD_CONTENTFUL_PROMOTIONAL_UNITS_SUCCESS'
export const LOAD_CONTENTFUL_PROMOTIONAL_UNITS_FAILURE = 'LOAD_CONTENTFUL_PROMOTIONAL_UNITS_FAILURE'

export const LOAD_PRODUCTS_REQUEST = 'LOAD_PRODUCTS_REQUEST'
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS'
export const LOAD_PRODUCTS_FAILURE = 'LOAD_PRODUCTS_FAILURE'

export const LOAD_PRODUCT_REQUEST = 'LOAD_PRODUCT_REQUEST'
export const LOAD_PRODUCT_SUCCESS = 'LOAD_PRODUCT_SUCCESS'
export const LOAD_PRODUCT_FAILURE = 'LOAD_PRODUCT_FAILURE'

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE'

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE'

export const PRODUCT_HIDE_FLASH_MESSAGE = 'PRODUCT_HIDE_FLASH_MESSAGE'
