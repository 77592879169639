import { Map } from 'immutable'
import { TOGGLE_CREATE_ARTICLE_MENU } from 'utils/constants/actions'

export default function articleTemplateReducer(state = Map(), action) {
  switch (action.type) {
    case TOGGLE_CREATE_ARTICLE_MENU:
      return state.setIn(['templateMenuOptions', 'showCreateArticleMenu'], action.show)
    default:
      return state
  }
}
