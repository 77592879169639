const blank = [
  {
    type: 'header',
    priority: 2,
    title: '<h2></h2>',
    subtitle: '<h4></h4>',
    dek: '',
    dummy: {},
  },
  {
    id: Math.random().toString(36).substring(7),
    type: 'text',
    priority: 2,
    text: '',
    delta: null,
  },
]

export default blank
