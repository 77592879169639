import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import Loader from 'components/new-pages/Loader'
import Alert from '@mui/material/Alert'

import { useStore } from 'store/mobx'
import FileInput from 'ui/FileInputNew'

const SelectSource = ({ showLibraryScreen, handleClose, setImage }) => {
  const [imageUploaded, setImageUploaded] = useState(false)
  const { loading, error, uploadImageData, uploadedImage } = useStore('imageLibraryStore')

  useEffect(() => {
    if (uploadedImage && imageUploaded) {
      setImage(uploadedImage.path)
    }
  }, [uploadedImage, setImage, imageUploaded])

  const handleFileInput = (e) => {
    setImageUploaded(false)

    const reader = new FileReader()
    const file = e.target.files[0]

    if (!e.target.files || !e.target.files.length) return

    reader.onload = (upload) => {
      const image = new Image()
      image.src = upload.target.result
      image.onload = () => {
        const imageData = {
          imageFile: upload.target.result,
          fileType: file.type,
        }
        uploadImageData(imageData)
        setImageUploaded(true)
      }
    }

    reader.readAsDataURL(file)
  }

  return (
    <>
      <DialogContent>
        {loading ? (
          <Loader />
        ) : (
          <Grid container direction="row" spacing={4}>
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error.message}</Alert>
              </Grid>
            )}
            <Grid item xs={6}>
              <FileInput
                label="File upload"
                accept="image/*"
                handleFileInput={(e) => handleFileInput(e)}
                btnProps={{
                  size: 'large',
                  fullWidth: true,
                  variant: 'contained',
                  color: 'primary',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PhotoLibraryIcon />}
                size="large"
                fullWidth
                onClick={showLibraryScreen}
              >
                Library
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button type="button" disabled={loading} onClick={() => handleClose(false)}>
          Close
        </Button>
      </DialogActions>
    </>
  )
}

SelectSource.propTypes = {
  showLibraryScreen: PropTypes.func,
  handleClose: PropTypes.func,
  setImage: PropTypes.func,
}

export default observer(SelectSource)
