import { makeAutoObservable, runInAction } from 'mobx'

import { getClasses } from 'api/classes'
import { saveClassSubscription, updateClassSubscription } from 'api/classSubscriptions'
import { stores } from './index'

const { REACT_APP_ENVIRONMENT } = process.env

export default class ClassesStore {
  classes = []
  loading = true
  error = null
  savedNewSub = false

  librarySubs = [{ label: 'All Access', value: REACT_APP_ENVIRONMENT?.toUpperCase() === 'DEV' ? '123' : '128' }]

  constructor() {
    makeAutoObservable(this)
  }

  fetchClasses = async () => {
    try {
      const data = await getClasses()
      runInAction(() => {
        this.classes = data
        this.loading = false
      })
    } catch (error) {
      runInAction(() => {
        this.error = error
      })
    }
  }

  saveClassSubscription = async (data) => {
    const { classSubscriptionsStore } = stores

    this.beforeApiAction()

    try {
      const newSub = await saveClassSubscription(data)
      runInAction(() => {
        classSubscriptionsStore.addClassSubscription(newSub)
        this.successApiAction()
      })
    } catch (error) {
      runInAction(() => {
        this.failureApiAction(error)
      })
    }
  }

  updateClassSubscription = async (data) => {
    const { classSubscriptionsStore } = stores

    this.beforeApiAction()
    try {
      const updatedSub = await updateClassSubscription(data)
      runInAction(() => {
        classSubscriptionsStore.updateClassSubscription({ id: updatedSub.id, data })
        this.successApiAction()
      })
    } catch (error) {
      runInAction(() => {
        this.failureApiAction(error)
      })
    }
  }

  beforeApiAction() {
    this.loading = true
    this.savedNewSub = false
  }

  successApiAction() {
    this.loading = false
    this.error = null
    this.savedNewSub = true
  }

  failureApiAction(error) {
    this.error = error
    this.loading = false
  }
}
