import { initialArticlesState } from './initialArticlesState'
import { initialArticleQueueFilterState } from './initialArticleQueueFilterState'
import { initialArticleQueueRangePickerState } from './initialArticleQueueRangePickerState'
import { initialArticleTemplateState } from './initialArticleTemplateState'
import { initialEditorsState } from './initialEditorsState'
import { initialEditorsQueueState } from './initialEditorsQueueState'
import { initialNavbarState } from './initialNavbarState'
import { initialCreateArticleLayoutState } from './initialCreateArticleLayoutState'
import { initialCreateArticleInteractionsState } from './initialCreateArticleInteractionsState'
import { initialCreateArticleMetadataState } from './initialCreateArticleMetadataState'
import { initialCreateArticleBodyState } from './initialCreateArticleBodyState'
import { initialCreateArticleKeywords } from './initialCreateArticleKeywords'
import { initialEmbedDataState } from './initialEmbedDataState'
import { initialProfileState } from './initialProfileState'
import { initialAuthorsState } from './initialAuthorsState'
import { initialImageUploadState } from './initialImageUploadState'
import { initialArticleSearchState } from './initialArticleSearchState'
import { initialTagsState } from './initialTagsState'
import { initialSeriesState } from './initialSeriesState'
import { initialEventsState } from './initialEventsState'
import { initialVideosState } from './initialVideosState'
import { initialTextAnalysisState } from './initialTextAnalysisState'
import { initialClassesState } from './initialClassesState'
import { initialCreatePodcastMetadataState } from './initialCreatePodcastMetadataState'
import { initialPodcastsState } from './initialPodcastsState'
import { initialProductsState } from './initialProductsState'
import { initialAuthorFieldsState } from './initialAuthorFieldsState'
import { initialConfirmDialogState } from './initialConfirmDialogState'
import { initialImageState } from './initialImageState'
import { initialInternalTagsState } from './initialInternalTagsState'
import { initialPartnersState } from './initialPartnersState'
import { initialInternetConnectionState } from './initialInternetConnectionState'
import { initialReviewerFieldState } from './initialReviewerFieldState'
import { initialShopifyState } from './initialShopifyState'
import { initialRecipesState } from './initialRecipesState'
import { initialContentfulState } from './initialContentfulState'

export const initialState = {
  articles: initialArticlesState,
  articleQueueFilter: initialArticleQueueFilterState,
  articleQueueRangePicker: initialArticleQueueRangePickerState,
  articleTemplate: initialArticleTemplateState,
  editors: initialEditorsState,
  editorsQueue: initialEditorsQueueState,
  navbar: initialNavbarState,
  createArticleLayout: initialCreateArticleLayoutState,
  createArticleInteractions: initialCreateArticleInteractionsState,
  createArticleMetadata: initialCreateArticleMetadataState,
  createArticleBody: initialCreateArticleBodyState,
  createArticleKeywords: initialCreateArticleKeywords,
  createPodcastMetadata: initialCreatePodcastMetadataState,
  embed: initialEmbedDataState,
  partners: initialPartnersState,
  profile: initialProfileState,
  authors: initialAuthorsState,
  imageUpload: {
    header: initialImageUploadState,
    social: initialImageUploadState,
    preview: initialImageUploadState,
    heroVideo: initialImageUploadState,
    gallery: initialImageUploadState,
    heroImage: initialImageUploadState,
    singleImage: initialImageUploadState,
    queuePreview: initialImageUploadState,
    quoteBackground: initialImageUploadState,
    parallax: initialImageUploadState,
    serieHeroImage: initialImageUploadState,
    serieLogo: initialImageUploadState,
    serieOverlay: initialImageUploadState,
    shop: initialImageUploadState,
    textAndImage: initialImageUploadState,
    podcastPreviewImage: initialImageUploadState,
    podcastImages: initialImageUploadState,
    partnerLogo: initialImageUploadState,
    navigationLogo: initialImageUploadState,
    partnerPreviewLogo: initialImageUploadState,
    productPlacementComponent: initialImageUploadState,
    shopLogo: initialImageUploadState,
    recipeIngredient: initialImageUploadState,
    productPlacementUnit: initialImageUploadState,
    reviewComponentItem: initialImageUploadState,
  },
  confirmDialog: {
    unpublish: initialConfirmDialogState,
    draft: initialConfirmDialogState,
    deleteComponent: initialConfirmDialogState,
    deleteHeroImage: initialConfirmDialogState,
    dropComponent: initialConfirmDialogState,
    restoreVersion: initialConfirmDialogState,
    unarchive: initialConfirmDialogState,
    kickUser: initialConfirmDialogState,
  },
  articleSearch: initialArticleSearchState,
  tags: initialTagsState,
  series: initialSeriesState,
  events: initialEventsState,
  videos: initialVideosState,
  classes: initialClassesState,
  textAnalysis: initialTextAnalysisState,
  podcasts: initialPodcastsState,
  products: initialProductsState,
  authorFields: initialAuthorFieldsState,
  images: initialImageState,
  internalTags: initialInternalTagsState,
  internetConnection: initialInternetConnectionState,
  reviewerField: initialReviewerFieldState,
  shopify: initialShopifyState,
  recipes: initialRecipesState,
  contentful: initialContentfulState,
}

export default initialState
