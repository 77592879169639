import { UPDATE_ARTICLES_SUCCESS } from 'utils/constants/actions'
import groupByPublishDate from 'utils/groupByPublishDate'

const articleDateChange = (store) => (next) => (action) => {
  if (action.type === UPDATE_ARTICLES_SUCCESS) {
    const { articles } = store.getState()
    const dateChanged = articles.get('dateChanged')
    if (dateChanged) {
      const groupArticles = articles.get('articles').toJS()
      let allArticles = []
      Object.keys(groupArticles).map((group) => (allArticles = allArticles.concat(groupArticles[group])))
      action.articles = groupByPublishDate(allArticles)
    }
  }

  return next(action)
}

export default articleDateChange
