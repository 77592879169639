import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import isNil from 'lodash/isNil'
import grantedByRole from 'utils/grantedByRole'
import { CREATE_MEDIA } from 'utils/constants/privileges'
import CommonDialogTitle from '../components/Common/DialogComponents/CommonDialogTitle'
import { FormControl, InputLabel, Select } from '@mui/material'

const ConvertDialog = ({ open, onConvert, onCloseDialog, userRoles }) => {
  const [value, setValue] = useState('')

  const location = useLocation()
  const options = location.pathname.includes('recipe')
    ? [
        { value: 'introduction', text: 'Introduction' },
        { value: 'heading', text: 'Heading' },
        { value: 'recipeSteps', text: 'Recipe Steps' },
        { value: 'recipeIngredients', text: 'Recipe Ingredients' },
        { value: 'footer', text: 'Footer' },
      ]
    : [
        { value: 'introduction', text: 'Introduction' },
        { value: 'heading', text: 'Heading' },
        { value: 'list', text: 'List' },
        { value: 'pullquote', text: 'Pull Quote' },
        { value: 'blockquote', text: 'Block Quote' },
        { value: 'footer', text: 'Footer' },
      ]

  const canCreateMedia = !isNil(userRoles) && grantedByRole(userRoles, CREATE_MEDIA)
  if (canCreateMedia) options.push({ value: 'text-and-image', text: 'Text And Image' })

  return (
    <Dialog open={open} fullWidth onClose={() => onCloseDialog()}>
      <CommonDialogTitle onClose={() => onCloseDialog()}>
        <div>Convert</div>
      </CommonDialogTitle>
      <DialogContent>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="converts-label">Convert contents to</InputLabel>
          <Select value={value} labelId="converts-label" onChange={(e) => setValue(e.target.value)}>
            {options.map((option, i) => (
              <MenuItem key={i} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog}>Cancel</Button>
        <Button
          onClick={() => {
            onConvert(value)
            onCloseDialog()
          }}
          disabled={!value}
        >
          Convert
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConvertDialog.propTypes = {
  open: PropTypes.bool,
  onConvert: PropTypes.func,
  onCloseDialog: PropTypes.func,
  userRoles: PropTypes.string,
}

const mapStateToProps = (state) => ({
  userRoles: state.profile.getIn(['profile', 'roles']),
})

export default connect(mapStateToProps)(ConvertDialog)
