import { Map, fromJS } from 'immutable'
import {
  SEARCH_INTERNAL_TAGS_REQUEST,
  SEARCH_INTERNAL_TAGS_SUCCESS,
  SEARCH_INTERNAL_TAGS_FAILURE,
  CREATE_INTERNAL_TAG_REQUEST,
  CREATE_INTERNAL_TAG_SUCCESS,
  CREATE_INTERNAL_TAG_FAILURE,
} from 'utils/constants/actions'

const internalTagsReducer = (state = Map(), action) => {
  switch (action.type) {
    case SEARCH_INTERNAL_TAGS_REQUEST:
      return state.set('loading', true).set('tags', null)
    case SEARCH_INTERNAL_TAGS_FAILURE:
      return state.set('loading', false).set('error', fromJS(action.payload))
    case SEARCH_INTERNAL_TAGS_SUCCESS:
      return state.set('loading', false).set('tags', fromJS(action.payload))
    case CREATE_INTERNAL_TAG_REQUEST:
      return state.set('saving', true).set('createdTag', null)
    case CREATE_INTERNAL_TAG_FAILURE:
      return state.set('saving', false)
    case CREATE_INTERNAL_TAG_SUCCESS:
      return state.set('saving', false).set('createdTag', fromJS(action.payload))
    default:
      return state
  }
}

export default internalTagsReducer
