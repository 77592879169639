import styled from 'styled-components'
import PropTypes, { oneOfType } from 'prop-types'

import Button from '@mui/material/Button'
import UploadIcon from '@mui/icons-material/Upload'

const FileInput = styled.input`
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  z-index: 1000;
`

const FileInputComponent = ({
  handleFileInput,
  label,
  multiple,
  accept,
  maxFileSize,
  fileTooLargeHandler,
  disabled,
  btnProps,
}) => (
  <Button startIcon={<UploadIcon />} {...btnProps}>
    <FileInput
      accept={accept}
      name="fileInput"
      onChange={(e) => {
        if (maxFileSize && fileTooLargeHandler) {
          const files = e.target.files
          for (const f in files) {
            const size = (files[f].size / 1024 / 1024).toFixed(4)
            if (size > maxFileSize) {
              fileTooLargeHandler()
              return
            }
          }
        }

        handleFileInput(e)
      }}
      type="file"
      multiple={multiple}
      disabled={disabled}
      data-testid="imageInputField"
    />
    {label}
  </Button>
)

FileInputComponent.propTypes = {
  handleFileInput: PropTypes.func,
  label: oneOfType([PropTypes.string, PropTypes.object]),
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  maxFileSize: PropTypes.number,
  fileTooLargeHandler: PropTypes.func,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  btnProps: PropTypes.object,
}

export default FileInputComponent
