import { isUrlValid } from 'utils/validators'

const convertToAffiliateLink = (text: string) => {
  const mbgAffiliateParams = 'ref=as_li_qf_sp_asin_il_tl?tag=mind0a3-20'
  let affiliateString = ''
  text.split(' ').forEach((word) => {
    if (isUrlValid(text) && !word.includes(mbgAffiliateParams) && word.includes('https://www.amazon.com/')) {
      if (word.includes('ref=')) {
        const removePart = word.split('/').pop()
        if (removePart !== undefined) {
          word = word.replace(removePart, '').concat(mbgAffiliateParams)
        }
      } else {
        const lastCharacterIsSlash = word.split('').pop() === '/'
        word = word.concat(`${lastCharacterIsSlash ? '' : '/'}${mbgAffiliateParams}`)
      }

      affiliateString = affiliateString.concat(word)
      if (text.split(' ').length > 1) affiliateString = affiliateString.concat(' ')
    }
  })
  return affiliateString
}

export default convertToAffiliateLink
