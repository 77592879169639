import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import StyledFlex from '../../layouts/StyledLayouts'

const TagList = ({ tags, onTagSelected }) => (
  <StyledFlex style={{ flexWrap: 'wrap' }}>
    {tags?.map((tag, idx) => (
      <StyledFlex style={{ flex: '1 0 33%' }} key={idx}>
        <Button onClick={() => onTagSelected(tag)} fullWidth>
          {tag.tag_name}
        </Button>
      </StyledFlex>
    ))}
  </StyledFlex>
)

TagList.propTypes = {
  tags: PropTypes.array,
  onTagSelected: PropTypes.func,
}

export default TagList
