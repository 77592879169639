const arrayMoveMutate = <T>(array: T[], from: number, to: number) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0])
}

// MIT © Sindre Sorhus (https://github.com/sindresorhus/array-move)
const arrayMove = <T>(array: T[], from: number, to: number) => {
  array = array.slice()
  arrayMoveMutate(array, from, to)
  return array
}

export default arrayMove
