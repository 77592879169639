import { ReactNode, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import CircularProgress from '@mui/material/CircularProgress'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { searchInternalTags, createInternalTag } from 'actions/internalTags'
import { useTheme } from '@mui/material/styles'
import { WrappedFieldMetaProps } from 'redux-form'
import Immutable from 'immutable'
import { themeType } from 'utils/theme'
import { RootDispatch, RootState } from '../App'

interface CustomInput {
  name: string
  label: string
  value: string[]
  onChange: (values: string[]) => void
}

interface InternalTagsInputInterface {
  saving: string
  savings: string
  createdTag?: Immutable.Map<string, any>
  input: CustomInput
  searchTags: (value: string) => void
  loading?: boolean
  tags: Immutable.List<string>
  enableCreate?: boolean
  meta: WrappedFieldMetaProps
  createInternalTag: (lastValue: string) => void
}

const InternalTagsInput = (props: InternalTagsInputInterface) => {
  const [pattern, setPattern] = useState('')

  const prevSaving = useRef('')

  const newTagPlaceholder = 'New tag: '

  useEffect(() => {
    if (prevSaving.current !== props.saving && !props.saving && props.createdTag) {
      prevSaving.current = props.saving

      const values = (props.input.value || []).slice()

      values.push(props.createdTag.get('name'))
      props.input.onChange(values)
    }
  }, [props.saving, props.createdTag, props.input, props.savings])

  const handleChange = (e: any, values: any) => {
    const lastValue = values[values.length - 1]
    if (lastValue?.includes(newTagPlaceholder)) {
      createInternalTag(lastValue.replace(newTagPlaceholder, ''))
    } else {
      props?.input.onChange(values)
    }
  }

  const handleInputDebounced = debounce((value) => {
    setPattern(value)
    props.searchTags(value)
  }, 500)
  const handleInput = (e: any) => {
    const { value } = e.target

    if (value.length < 3) {
      return
    }
    handleInputDebounced(value)
  }

  const {
    loading,
    saving,
    tags,
    enableCreate,
    input,
    meta: { touched, error },
    createInternalTag,
    // createdTag, // eslint-disable-line
    // searchTags, // eslint-disable-line
    // ...rest // do we need to pass these?
  } = props
  const theme = useTheme<themeType>()
  const values = input.value || []
  const dataSource: any = tags && pattern.length > 2 ? tags.toJS() : []
  if (enableCreate && !loading && pattern.length > 2 && dataSource.indexOf(pattern) < 0) {
    dataSource.push(`${newTagPlaceholder}${pattern}`)
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Autocomplete
        multiple
        disableClearable
        id="internal-tags"
        options={dataSource}
        value={values}
        freeSolo
        onInputChange={handleInput}
        onChange={handleChange}
        renderTags={(value: ReactNode[], getTagProps) =>
          value.map((option: ReactNode, index: number) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => <TextField {...params} variant="standard" placeholder="Internal Tags" />}
        // {...rest}
      />
      {(loading || saving) && (
        <CircularProgress
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          size={20}
        />
      )}
      {touched && error && <div style={{ color: theme.palette.red.main }}>{error}</div>}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.internalTags.get('loading'),
    saving: state.internalTags.get('saving'),
    tags: state.internalTags.get('tags'),
    createdTag: state.internalTags.get('createdTag'),
  }
}

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  searchTags: (value: string) => dispatch(searchInternalTags(value)),
  createInternalTag: (name: string) => dispatch(createInternalTag(name)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InternalTagsInput)
