import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

interface Props {
  open: boolean
  message?: React.ReactNode
  hide(): void
  severity: 'error' | 'info'
}

const FlashMessage = ({ open, message, hide, severity = 'info' }: Props) => (
  <Snackbar open={open} autoHideDuration={4000} onClose={hide}>
    <MuiAlert elevation={6} severity={severity} variant="filled">
      {message}
    </MuiAlert>
  </Snackbar>
)

export default FlashMessage
