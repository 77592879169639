import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useQueryParams } from '../hooks/useQueryParams'

const ArticleRedirectLayout = () => {
  const history = useHistory()
  const query = useQueryParams()

  const id = query.get('id')

  useEffect(() => {
    if (id) {
      history.push(`/article/edit/${id}`)
    }
  }, [id, history])

  return null
}

export default ArticleRedirectLayout
