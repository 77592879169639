import { isUrlValid } from 'utils/validators'

export const mbgAffiliateParams = 'ref=as_li_qf_sp_asin_il_tl?tag=mind0a3-20'

export const getIsNonAffiliateAmazonLink = (text: string) =>
  isUrlValid(text) && !text.includes(mbgAffiliateParams) && text.includes('amazon.com/')

export const getIsNonAffiliateAmazonText = (text: string) =>
  text.split(' ').reduce((acc, word) => {
    if (acc) {
      return acc
    }
    return getIsNonAffiliateAmazonLink(word)
  }, false)

const formatAffiliateLink = (link: string) => {
  if (link.includes('ref=')) {
    const removePart = link.split('/').pop()
    if (removePart) {
      link = link.replace(removePart, '').concat(mbgAffiliateParams)
    }
  } else {
    const lastCharacterIsSlash = link.split('').pop() === '/'
    link = link.concat(`${lastCharacterIsSlash ? '' : '/'}${mbgAffiliateParams}`)
  }
  return link
}

export default formatAffiliateLink
