import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import SearchIcon from '@mui/icons-material/Search'
import Transition from 'react-inline-transition-group'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import OutsideClick from 'utils/OutsideClick'
import { transitionStyle } from './SearchBarStyles'
import { Field, reduxForm } from 'redux-form'
import AsyncAutoSuggest from 'ui/AsyncAutoSuggest'

const BackdropStyle = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 1;
`

export const SearchBar = ({
  toggleSearchbar,
  showSearchbar,
  fetchSuggestions,
  dataSource,
  dataSourceConfig,
  loading,
  label,
  helperText,
}) => {
  const history = useHistory()

  return (
    <div>
      {showSearchbar ? (
        <div>
          <BackdropStyle />
          <OutsideClick onOutsideClick={() => leaveAnimation(toggleSearchbar)}>
            <Transition
              childrenStyles={{
                base: transitionStyle.base,
                appear: transitionStyle.appear,
              }}
            >
              <div id="searchBar">
                <SearchIcon sx={{ float: 'left', height: '100%', marginRight: '10px', color: '#757575' }} />
                <div style={{ marginTop: '-4px' }}>
                  <Field
                    component={AsyncAutoSuggest}
                    customOnSuggestionSelected={toggleSearchbar}
                    label={label}
                    inputProps={{
                      helperText,
                      fullWidth: true,
                      autoFocus: true,
                    }}
                    width="80%"
                    name="searchField"
                    dataSource={dataSource}
                    dataSourceConfig={dataSourceConfig}
                    loading={loading}
                    fetchSuggestions={fetchSuggestions}
                    handleSuggestionSelected={(suggestion) => history.push(`/article/edit/${suggestion.value}`)}
                  />
                </div>
              </div>
            </Transition>
          </OutsideClick>
        </div>
      ) : (
        <SearchIcon
          sx={{ position: 'absolute', right: '5%', top: '20px', cursor: 'pointer', color: '#fff' }}
          onClick={toggleSearchbar}
        />
      )}
    </div>
  )
}

function leaveAnimation(toggleSearchbar) {
  const searchBar = document.querySelector('#searchBar')
  searchBar.style.width = '172px'
  searchBar.style.opacity = '0'
  setTimeout(() => toggleSearchbar(), 500)
}

SearchBar.propTypes = {
  toggleSearchbar: PropTypes.func,
  showSearchbar: PropTypes.bool,
  fetchSuggestions: PropTypes.func,
  dataSource: PropTypes.array,
  dataSourceConfig: PropTypes.object,
  loading: PropTypes.bool,
  label: PropTypes.string,
}

export default compose(
  connect(),
  reduxForm({
    form: 'searchBar',
    asyncBlurFields: [],
  })
)(SearchBar)
