import PropTypes from 'prop-types'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import DefaultCheckbox from 'ui/DefaultCheckbox'
import AutoSuggestInput from 'ui/AutoSuggestInput'
import AsyncAutoSuggest from 'ui/AsyncAutoSuggest'
import InternalTagsInput from 'components/InternalTagsInput'
import StyledFlex, { StyledColumn } from '../../../layouts/StyledLayouts'
import { pinkRed } from '../../../styles/colors'
import styled from 'styled-components'

const GridStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`

const categories = [
  'Meditation',
  'Spirituality',
  'Personal Growth',
  'Recipes',
  'Functional Food',
  'Food Trends',
  'Outdoors',
  'Motivation',
  'Routines',
  'Recovery',
  'Integrative Health',
  'Mental Health',
  "Women's Health",
  'Healthy Weight',
  'Friendships',
  'Love',
  'Sex',
  'Parenting',
  'Home',
  'Beauty',
  'Travel',
  'Wellness Trends',
  'Social Good',
  'Change-Makers',
  'Off-the-Grid',
  'Climate Change',
  'Nature',
].sort()

const statuses = [
  { label: 'Needs editorial approval', name: 'needEditorial' },
  { label: 'Ready to publish', name: 'published' },
  { label: 'Need creative Approval', name: 'needCreative' },
]

const QueueFilterForm = ({
  editors,
  authors,
  partners,
  authorsLoading,
  loadAuthors,
  loadArticles,
  resetForm,
  closeFilters,
  handleSubmit,
  onSubmit,
  getAuthorById,
  resetDate,
  excludePartnerContent,
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <StyledFlex style={{ justifyContent: 'end' }}>
      <Button
        variant="text"
        sx={{ color: pinkRed }}
        onClick={() => {
          resetForm()
          resetDate()
          loadArticles()
          closeFilters()
        }}
      >
        Reset All
      </Button>
    </StyledFlex>
    <h3>Category</h3>
    <StyledColumn style={{ gap: '12px' }}>
      <GridStyle>
        {categories.map((cat, idx) => (
          <div key={idx}>
            <Field component={DefaultCheckbox} label={cat} name={`categories.${cat}`} />
          </div>
        ))}
      </GridStyle>

      <Field name="internalTags" component={InternalTagsInput} label="Internal tags" fullWidth />

      <h3>People</h3>
      <StyledFlex style={{ flexWrap: 'wrap', gap: '12px' }}>
        <StyledFlex style={{ flex: 1 }}>
          <Field
            component={AsyncAutoSuggest}
            name="authorId"
            inputProps={{
              label: 'Author',
              fullWidth: true,
            }}
            dataSource={authors ? authors.toJS() : []}
            dataSourceConfig={{ label: 'name', value: 'id' }}
            loading={authorsLoading}
            fetchSuggestions={(search) => loadAuthors(search)}
            getSuggestionById={getAuthorById}
          />
        </StyledFlex>
        <StyledFlex style={{ flex: 1 }}>
          <Field
            component={AutoSuggestInput}
            inputProps={{
              label: 'Editor',
              fullWidth: true,
            }}
            dataSource={editors ? editors.toJS() : []}
            dataSourceConfig={{ label: 'name', value: 'id' }}
            name="editorId"
          />
        </StyledFlex>
        <Field
          component={AutoSuggestInput}
          inputProps={{
            label: 'Partner',
            fullWidth: true,
            disabled: excludePartnerContent,
          }}
          dataSource={partners ? partners.toJS() : []}
          dataSourceConfig={{ label: 'name', value: 'id' }}
          name="partnerId"
        />
      </StyledFlex>
      <StyledFlex>
        <Field name="excludePartnerContent" component={DefaultCheckbox} label="Exclude partner content?" />
      </StyledFlex>
      <Divider />
      <h3>Status</h3>
      <StyledFlex style={{ flexWrap: 'wrap' }}>
        {statuses.map((status, idx) => (
          <StyledFlex style={{ flex: 1 }} key={idx}>
            <Field name={status.name} component={DefaultCheckbox} label={status.label} />
          </StyledFlex>
        ))}
      </StyledFlex>
    </StyledColumn>
    <StyledFlex style={{ justifyContent: 'flex-end', gap: '16px' }}>
      <Button variant="text" onClick={closeFilters}>
        Cancel
      </Button>
      <Button type="submit">Apply</Button>
    </StyledFlex>
  </form>
)

QueueFilterForm.propTypes = {
  closeFilters: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  editors: PropTypes.object,
  authors: PropTypes.object,
  partners: PropTypes.object,
  authorsLoading: PropTypes.bool,
  loadAuthors: PropTypes.func,
  loadArticles: PropTypes.func,
  resetForm: PropTypes.func,
  getAuthorById: PropTypes.func,
  resetDate: PropTypes.func,
  excludePartnerContent: PropTypes.bool,
}

export default reduxForm({
  asyncBlurFields: [],
  destroyOnUnmount: false,
  form: 'queueFilterForm',
})(QueueFilterForm)
