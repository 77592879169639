import { Map, fromJS } from 'immutable'
import {
  LOAD_AUTHORS_REQUEST,
  LOAD_AUTHORS_SUCCESS,
  LOAD_AUTHORS_FAILURE,
  GET_AUTHOR_BY_ID_REQUEST,
  GET_AUTHOR_BY_ID_SUCCESS,
  GET_AUTHOR_BY_ID_FAILURE,
} from 'utils/constants/actions'

const authorsReducer = (state = Map(), action) => {
  switch (action.type) {
    case LOAD_AUTHORS_REQUEST:
      return state.set('loading', true)
    case LOAD_AUTHORS_FAILURE:
      return state.set('loading', false).set('error', action.payload)
    case LOAD_AUTHORS_SUCCESS:
      return state.set('loading', false).set('authors', fromJS(action.payload))
    case GET_AUTHOR_BY_ID_REQUEST:
      return state.set('loading', true)
    case GET_AUTHOR_BY_ID_FAILURE:
      return state.set('loading', false).set('error', action.payload)
    case GET_AUTHOR_BY_ID_SUCCESS:
      return state.set('loading', false).set('authors', fromJS([action.payload]))
    default:
      return state
  }
}

export default authorsReducer
