import { Map } from 'immutable'
import { CREATE_ARTICLE_SAVE_DRAFT, CREATE_ARTICLE_FETCH_ARTICLE_SUCCESS } from 'utils/constants/actions'

const createArticleLayoutReducer = (state = Map(), action) => {
  switch (action.type) {
    case CREATE_ARTICLE_SAVE_DRAFT:
      return state.set('showDiscardBtn', action.showDiscardBtn)
    case CREATE_ARTICLE_FETCH_ARTICLE_SUCCESS:
      return state.set('loaded', true)
    default:
      return state
  }
}

export default createArticleLayoutReducer
