import { Map, fromJS } from 'immutable'
import {
  AUTHOR_FIELD_LOAD_REQUEST,
  AUTHOR_FIELD_LOAD_SUCCESS,
  AUTHOR_FIELD_LOAD_FAILURE,
  AUTHOR_FIELD_GET_AUTHOR_BY_ID_REQUEST,
  AUTHOR_FIELD_GET_AUTHOR_BY_ID_SUCCESS,
  AUTHOR_FIELD_GET_AUTHOR_BY_ID_FAILURE,
} from 'utils/constants/actions'

const authorFieldsReducer = (state = Map(), action) => {
  switch (action.type) {
    case AUTHOR_FIELD_LOAD_REQUEST:
      return state.setIn(['fields', action.payload.index, 'loading'], true)

    case AUTHOR_FIELD_LOAD_SUCCESS:
      return state
        .setIn(['fields', action.payload.index, 'authors'], fromJS(action.payload.authors))
        .setIn(['fields', action.payload.index, 'loading'], false)

    case AUTHOR_FIELD_LOAD_FAILURE:
      return state.setIn(['fields', action.payload.index, 'loading'], false)

    case AUTHOR_FIELD_GET_AUTHOR_BY_ID_REQUEST:
      return state.setIn(['fields', action.payload.index, 'loading'], true)

    case AUTHOR_FIELD_GET_AUTHOR_BY_ID_SUCCESS:
      return state
        .setIn(['fields', action.payload.index, 'authors'], fromJS([action.payload.author]))
        .setIn(['fields', action.payload.index, 'loading'], false)

    case AUTHOR_FIELD_GET_AUTHOR_BY_ID_FAILURE:
      return state.setIn(['fields', action.payload.index, 'loading'], false)

    default:
      return state
  }
}

export default authorFieldsReducer
