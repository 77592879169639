import blank from './blank'
import news from './news'
import shop from './shop'

export enum TemplateTypes {
  blank = 'blank',
  news = 'news',
  shop = 'shop',
}

const templates: Record<TemplateTypes, any> = { blank, news, shop }

export default templates
