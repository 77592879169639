import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Navbar } from 'components/NavBar'
import { waveLogo } from 'styles/assets'
import { StyledColumn } from './StyledLayouts'
import { ContentWapper } from './layoutStyles'

const AuthLayout = ({ children, title }) => (
  <StyledColumn style={{ alignItems: 'start' }}>
    <Navbar title={title} />

    <ContentWapper>
      <img src={waveLogo} alt="logo" />
      {children}
    </ContentWapper>
  </StyledColumn>
)

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    title: state.navbar.getIn(['navbarPrefs', 'title']),
  }
}

export default connect(mapStateToProps)(AuthLayout)
