import React from 'react'
import QuillField from './QuillField'
import QuillTabSupport from './QuillTabSupport'

const ArticleHeaderQuill = (props) => (
  <QuillTabSupport>
    {({ tabKeyboardBindings, ...innerProps }) => (
      <QuillField keyboardBindings={tabKeyboardBindings} {...innerProps} {...props} />
    )}
  </QuillTabSupport>
)

export default ArticleHeaderQuill
