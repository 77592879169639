import { fromJS } from 'immutable'
import { actionTypes } from 'redux-form'

import { CREATE_ARTICLE_UPDATE_TIME_SPENT } from 'utils/constants/actions'

const beforeSubmit = (store) => (next) => (action) => {
  if (action.type === actionTypes.SUBMIT && action.meta.form === 'createArticleMetadataForm') {
    const { profile, createArticleMetadata } = store.getState()
    if (!window.location.pathname.includes('article')) {
      return next(action)
    }

    // UPDATE TIME SPENT
    const userId = profile.getIn(['profile', 'userId'])
    const articleTimeSpent = createArticleMetadata.get('timeSpent')
    const timeTrackingStart = createArticleMetadata.get('timeTrackingStart')
    const idleTime = createArticleMetadata.get('timeTrackingIdleTime')

    const userTimeSpent = Math.round((new Date() - timeTrackingStart) / 1000) - idleTime

    const entry = articleTimeSpent.findEntry((item) => item.get('userId') === userId)

    if (entry) {
      const entryKey = entry[0]
      const entryValue = entry[1]
      const currentSpentTime = entryValue.get('time')

      const timeSpent = articleTimeSpent.update(entryKey, (item) => {
        return fromJS({
          userId: userId,
          time: currentSpentTime + userTimeSpent,
        })
      })
      store.dispatch({
        type: CREATE_ARTICLE_UPDATE_TIME_SPENT,
        timeSpent,
      })
    } else {
      const timeSpent = articleTimeSpent.push(
        fromJS({
          userId: userId,
          time: userTimeSpent,
        })
      )
      store.dispatch({
        type: CREATE_ARTICLE_UPDATE_TIME_SPENT,
        timeSpent,
      })
    }
    // UPDATE TIME SPENT END
  }

  return next(action)
}

export default beforeSubmit
