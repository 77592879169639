import {
  CREATE_ARTICLE_INSERT_COMPONENT,
  CREATE_ARTICLE_INSERT_COMPONENT_FROM_PLACEHOLDER,
  CREATE_ARTICLE_REMOVE_COMPONENT,
  CREATE_ARTICLE_UPDATE_METADATA,
  CREATE_ARTICLE_REORDER_COMPONENTS,
  CREATE_ARTICLE_ON_EDITOR_CHANGE,
  CREATE_ARTICLE_UPDATE_HEADER,
  CREATE_ARTICLE_UPDATE_HEADER_TITLE_IMAGE,
  CREATE_ARTICLE_UPDATE_HERO_IMAGE,
  CREATE_ARTICLE_DELETE_HERO_IMAGE,
  CREATE_ARTICLE_SAVE_FOCUSED_DATA,
  CREATE_ARTICLE_INSERT_SPLITTED_COMPONENT,
  CREATE_ARTICLE_ON_COMPONENT_DROP,
  CREATE_ARTICLE_SPLIT_WITH_DRAGGED_COMPONENT,
  CREATE_ARTICLE_TOGGLE_DROP_DIALOG,
  CREATE_ARTICLE_INSERT_DRAGGED_COMPONENT,
  CREATE_ARTICLE_MERGE_COMPONENTS,
  CREATE_ARTICLE_MOVE_TO_COMPONENT,
  CREATE_ARTICLE_TOGGLE_EDIT_DIALOG,
  CREATE_ARTICLE_INSERT_COMPONENT_FROM_MODAL,
  CREATE_ARTICLE_UPDATE_COMPONENT_FROM_MODAL,
  CREATE_ARTICLE_FORM_ERROR,
  CREATE_ARTICLE_SHOW_FLASH_MESSAGE,
  CREATE_ARTICLE_HIDE_ERROR_MESSAGE,
  CREATE_ARTICLE_RESET_STATE,
  CREATE_ARTICLE_SHOULD_SAVE_DRAFT,
  CREATE_ARTICLE_SAVE_DRAFT,
  CREATE_ARTICLE_DISCARD_DRAFT,
  CREATE_ARTICLE_RESET_SUBMIT_STATUS,
  METADATA_TOGGLE_IMAGE_DIALOG,
  CREATE_ARTICLE_ADD_KEY_QUOTE,
  CREATE_ARTICLE_SAVE_TRACKED_TIME_START,
  CREATE_ARTICLE_SAVE_IDLE_TIME,
  CREATE_ARTICLE_SET_ACTIVE,
  CREATE_ARTICLE_PRESERVE_QUILL_STATE,
  METADATA_TOGGLE_URL_DIALOG,
  METADATA_PREVENT_URL_DIALOG,
  CREATE_ARTICLE_TOGGLE_PUBLISH_DIALOG,
  CREATE_ARTICLE_USER_STARTED_TYPING,
  CREATE_ARTICLE_UPDATE_COMPONENT_WORD_COUNT,
  CREATE_ARTICLE_SET_INSERTION_TARGET,
  CREATE_ARTICLE_UNHIGHLIGHT_KEYWORDS,
  CREATE_ARTICLE_REMOVE_DUMMY_FLAG,
  CREATE_ARTICLE_LOAD_VERSION,
  CREATE_ARTICLE_TOGGLE_CONFIRM_DIALOG,
  CREATE_ARTICLE_REMOVE_HEADER_DUMMY_FLAG,
  TOGGLE_INBOUND_LINK_UPDATE_DIALOG,
  CREATE_ARTICLE_HIGHLIGHT_SAME_LINKS,
  CREATE_ARTICLE_MARK_COMPONENT,
  CREATE_ARTICLE_TOGGLE_USER_SUBMIT,
  CREATE_ARTICLE_ENABLE_AUTOSAVE,
  CREATE_ARTICLE_DISABLE_AUTOSAVE,
  CREATE_ARTICLE_TOGGLE_ERROR_DIALOG,
  CREATE_ARTICLE_CONVERT_IMAGE_COMPONENT,
  CREATE_ARTICLE_LINK_SEARCH,
  CREATE_ARTICLE_CHANGE_ACTIVE_SEARCH_LINK,
  CREATE_ARTICLE_DUPLICATE_COMPONENT,
  CREATE_ARTICLE_SET_LAST_INSERTED_LINK,
  CREATE_ARTICLE_SET_LINK_SEARCH_PATTERN,
} from 'utils/constants/actions'
import loadArticleHelper from 'utils/loadArticleHelper'

export function insertComponent(componentType) {
  return {
    type: CREATE_ARTICLE_INSERT_COMPONENT,
    componentType,
  }
}

export function insertComponentFromPlaceholder() {
  return {
    type: CREATE_ARTICLE_INSERT_COMPONENT_FROM_PLACEHOLDER,
  }
}

export function removeComponent(index) {
  return {
    type: CREATE_ARTICLE_REMOVE_COMPONENT,
    index,
  }
}

export function updateMetadata({ field, value }) {
  return {
    type: CREATE_ARTICLE_UPDATE_METADATA,
    field,
    value,
  }
}

export function reorderComponents({ dragIndex, hoverIndex }) {
  return {
    type: CREATE_ARTICLE_REORDER_COMPONENTS,
    dragIndex,
    hoverIndex,
  }
}

export function onEditorChange({ index, html, delta }) {
  return {
    type: CREATE_ARTICLE_ON_EDITOR_CHANGE,
    index,
    html,
    delta,
  }
}

export function updateHeaderComponent({ index, field, html }) {
  return {
    type: CREATE_ARTICLE_UPDATE_HEADER,
    index,
    field,
    html,
  }
}

export function updateHeaderTitleImage({ index, path }) {
  return {
    type: CREATE_ARTICLE_UPDATE_HEADER_TITLE_IMAGE,
    index,
    path,
  }
}

export function updateHeroImage(image) {
  return {
    type: CREATE_ARTICLE_UPDATE_HERO_IMAGE,
    image,
  }
}

export function deleteHeroImage() {
  return {
    type: CREATE_ARTICLE_DELETE_HERO_IMAGE,
  }
}

export function saveFocusedComponentData({ index, cursorPosition, length }) {
  return {
    type: CREATE_ARTICLE_SAVE_FOCUSED_DATA,
    index,
    cursorPosition,
    length,
  }
}

export function insertSplittedComponent({ componentType, toIndex, text }) {
  return {
    type: CREATE_ARTICLE_INSERT_SPLITTED_COMPONENT,
    componentType,
    toIndex,
    text,
  }
}

export function onComponentDrop({ draggedItemIndex, targetItemIndex, showDropDialog }) {
  return {
    type: CREATE_ARTICLE_ON_COMPONENT_DROP,
    draggedItemIndex,
    targetItemIndex,
    showDropDialog,
  }
}

export function splitWithDraggedComponent(editor, selection) {
  return {
    type: CREATE_ARTICLE_SPLIT_WITH_DRAGGED_COMPONENT,
    editor,
    selection,
  }
}

export function toggleDropDialog(show) {
  return {
    type: CREATE_ARTICLE_TOGGLE_DROP_DIALOG,
    show,
  }
}

export function insertDraggedComponent(toIndex) {
  return {
    type: CREATE_ARTICLE_INSERT_DRAGGED_COMPONENT,
    toIndex,
  }
}

export function mergeComponents(index) {
  return {
    type: CREATE_ARTICLE_MERGE_COMPONENTS,
    index,
  }
}

export function focusComponent({ index, goingDown }) {
  return {
    type: CREATE_ARTICLE_MOVE_TO_COMPONENT,
    index,
    goingDown,
  }
}

export function toggleEditDialog({ show, index, componentType, imageType }) {
  return {
    type: CREATE_ARTICLE_TOGGLE_EDIT_DIALOG,
    show,
    index,
    componentType,
    imageType,
  }
}

export function insertComponentFromModal({ componentType, content }) {
  return {
    type: CREATE_ARTICLE_INSERT_COMPONENT_FROM_MODAL,
    componentType,
    content,
  }
}

export function updateComponentFromModal({ index, component }) {
  return {
    type: CREATE_ARTICLE_UPDATE_COMPONENT_FROM_MODAL,
    index,
    component,
  }
}

export function formError(message) {
  return {
    type: CREATE_ARTICLE_FORM_ERROR,
    message,
  }
}

export function showFlashMessage(flashMessage) {
  return {
    type: CREATE_ARTICLE_SHOW_FLASH_MESSAGE,
    flashMessage,
  }
}

export function hideErrorMessage() {
  return {
    type: CREATE_ARTICLE_HIDE_ERROR_MESSAGE,
  }
}

export function resetState() {
  return {
    type: CREATE_ARTICLE_RESET_STATE,
  }
}

export function toggleShouldSaveDraft(flag) {
  return {
    type: CREATE_ARTICLE_SHOULD_SAVE_DRAFT,
    flag,
  }
}

export function saveDraft({ save, dataType, data }) {
  return {
    type: CREATE_ARTICLE_SAVE_DRAFT,
    save,
    dataType,
    data,
  }
}

export function discardDraft() {
  return {
    type: CREATE_ARTICLE_DISCARD_DRAFT,
  }
}

export function resetSubmitStatus() {
  return {
    type: CREATE_ARTICLE_RESET_SUBMIT_STATUS,
  }
}

export function toggleImageDialog({ open, imageType }) {
  return {
    type: METADATA_TOGGLE_IMAGE_DIALOG,
    open,
    imageType,
  }
}

export function saveTrackingTimeStart() {
  return {
    type: CREATE_ARTICLE_SAVE_TRACKED_TIME_START,
  }
}

export function saveIdleTime(time) {
  return {
    type: CREATE_ARTICLE_SAVE_IDLE_TIME,
    time,
  }
}

export function addKeyQuote(quote) {
  return {
    type: CREATE_ARTICLE_ADD_KEY_QUOTE,
    quote,
  }
}

// This was used in BodyComponentWrapper but I think we don't need it anymore
export function setActiveTextComponent(index) {
  return {
    type: CREATE_ARTICLE_SET_ACTIVE,
    index,
  }
}

export function preserveActiveState({ index, preserve }) {
  return {
    type: CREATE_ARTICLE_PRESERVE_QUILL_STATE,
    index,
    preserve,
  }
}

export function toggleUrlDialog() {
  return {
    type: METADATA_TOGGLE_URL_DIALOG,
  }
}

export function preventUrlDialogAction() {
  return {
    type: METADATA_PREVENT_URL_DIALOG,
  }
}

export function togglePublishDialog() {
  return {
    type: CREATE_ARTICLE_TOGGLE_PUBLISH_DIALOG,
  }
}

export function userStartedTyping() {
  return {
    type: CREATE_ARTICLE_USER_STARTED_TYPING,
  }
}

export function updateComponentWordCount(index, count) {
  return {
    type: CREATE_ARTICLE_UPDATE_COMPONENT_WORD_COUNT,
    index,
    count,
  }
}

export function toggleConfirmDialog(namespace, extra) {
  return {
    type: CREATE_ARTICLE_TOGGLE_CONFIRM_DIALOG,
    namespace,
    extra,
  }
}

export function setInsertionTarget(index) {
  return {
    type: CREATE_ARTICLE_SET_INSERTION_TARGET,
    index,
  }
}

export function unhighlightKeywords(keyword) {
  return {
    type: CREATE_ARTICLE_UNHIGHLIGHT_KEYWORDS,
    keyword,
  }
}

export function removeDummyFlag(index) {
  return {
    type: CREATE_ARTICLE_REMOVE_DUMMY_FLAG,
    index,
  }
}

export function markComponent(index) {
  return {
    type: CREATE_ARTICLE_MARK_COMPONENT,
    index,
  }
}

export function loadVersion(version) {
  return {
    type: CREATE_ARTICLE_LOAD_VERSION,
    payload: loadArticleHelper({ articleData: version }),
  }
}

export function removeHeaderDummyFlag({ index, field }) {
  return {
    type: CREATE_ARTICLE_REMOVE_HEADER_DUMMY_FLAG,
    index,
    field,
  }
}

export function toggleInboundLinkUpdateDialog(open) {
  return {
    type: TOGGLE_INBOUND_LINK_UPDATE_DIALOG,
    open,
  }
}

export function highlightSameLinks(link) {
  return {
    type: CREATE_ARTICLE_HIGHLIGHT_SAME_LINKS,
    link,
  }
}

export function toggleUserSubmit() {
  return {
    type: CREATE_ARTICLE_TOGGLE_USER_SUBMIT,
  }
}

export function enableAutoSave() {
  return {
    type: CREATE_ARTICLE_ENABLE_AUTOSAVE,
  }
}

export function disableAutoSave() {
  return {
    type: CREATE_ARTICLE_DISABLE_AUTOSAVE,
  }
}

export function toggleErrorDialog(open) {
  return {
    type: CREATE_ARTICLE_TOGGLE_ERROR_DIALOG,
    open,
  }
}

export function convertImageComponent(index) {
  return {
    type: CREATE_ARTICLE_CONVERT_IMAGE_COMPONENT,
    index,
  }
}

export function searchLinks(pattern) {
  return {
    type: CREATE_ARTICLE_LINK_SEARCH,
    pattern,
  }
}

export function changeActiveSearchLink(next) {
  return {
    type: CREATE_ARTICLE_CHANGE_ACTIVE_SEARCH_LINK,
    next,
  }
}

export function duplicateComponent(index) {
  return {
    type: CREATE_ARTICLE_DUPLICATE_COMPONENT,
    index,
  }
}

export function setLastInsertedLink(link) {
  return {
    type: CREATE_ARTICLE_SET_LAST_INSERTED_LINK,
    link,
  }
}

export function setLinkSearchPattern(pattern) {
  return {
    type: CREATE_ARTICLE_SET_LINK_SEARCH_PATTERN,
    pattern,
  }
}
