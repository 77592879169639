import {
  LOAD_PRODUCTS_REQUEST,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  PRODUCT_HIDE_FLASH_MESSAGE,
  LOAD_PRODUCT_REQUEST,
  LOAD_PRODUCT_SUCCESS,
  LOAD_PRODUCT_FAILURE,
} from 'utils/constants/actions'

import { Map, fromJS } from 'immutable'

const productsReducer = (state = Map(), action) => {
  switch (action.type) {
    case LOAD_PRODUCTS_REQUEST:
      return state.set('loading', true)
    case LOAD_PRODUCT_REQUEST:
      return state.set('loading', true)
    case LOAD_PRODUCTS_SUCCESS:
      return state.set('products', fromJS(action.payload)).set('loading', false)
    case LOAD_PRODUCT_SUCCESS:
      return state.set('product', fromJS(action.payload)).set('loading', false)
    case LOAD_PRODUCTS_FAILURE:
      return state.set('error', action.payload).set('loading', false)
    case LOAD_PRODUCT_FAILURE:
      return state.set('error', action.payload).set('loading', false)
    case CREATE_PRODUCT_REQUEST:
      return state.set('creating', true).set('createError', null)
    case CREATE_PRODUCT_SUCCESS:
      return state.set('creating', false)
    case CREATE_PRODUCT_FAILURE:
      return state
        .set('snackMessage', `Failed to create a product: ${action.payload.response.message}`)
        .set('createError', action.payload)
        .set('creating', false)
    case UPDATE_PRODUCT_REQUEST:
      return state.set('updating', true).set('updateError', null)
    case UPDATE_PRODUCT_SUCCESS:
      return state.set('updating', false)
    case UPDATE_PRODUCT_FAILURE:
      return state
        .set('updateError', action.payload)
        .set('snackMessage', `Error updating product: ${action.payload.response.message}`)
        .set('updating', false)
    case PRODUCT_HIDE_FLASH_MESSAGE:
      return state.set('snackMessage', null)
    default:
      return state
  }
}

export default productsReducer
