import PropTypes from 'prop-types'
import { useState } from 'react'
import Tooltip from '@mui/material/Tooltip'

import imageUrl from '../../../../../utils/imageUrl'
import ImageUpload from '../../../ImageUpload'
import { ImagePlaceholder, ImagePlaceholderIcon, LessonImageStyle } from '../../styles'

const LessonImageComponent = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  return (
    <>
      {props.image ? (
        <Tooltip title="Double-Click to Edit" placement="bottom">
          <LessonImageStyle
            src={imageUrl(props.image.replace('https://res.mindbodygreen.com/', ''))}
            onDoubleClick={() => setIsDialogOpen(true)}
          />
        </Tooltip>
      ) : (
        <ImagePlaceholder data-testid="lessonImgPlaceholder" onClick={() => setIsDialogOpen(true)}>
          <ImagePlaceholderIcon />
          <h3>Insert Image</h3>
        </ImagePlaceholder>
      )}

      {isDialogOpen && (
        <ImageUpload
          open={isDialogOpen}
          handleClose={() => setIsDialogOpen(false)}
          setImage={(image) => props.setImage(image)}
        />
      )}
    </>
  )
}

LessonImageComponent.propTypes = {
  image: PropTypes.string,
  setImage: PropTypes.func,
}

export default LessonImageComponent
