import { Map, fromJS } from 'immutable'
import {
  SET_NAVBAR_TITLE,
  TOGGLE_SEARCHBAR,
  TOGGLE_SHOW_VERSION_NOTICE,
  LOAD_VERSION_FILE_SUCCESS,
} from 'utils/constants/actions'

const navbarReducer = (state = Map(), action) => {
  switch (action.type) {
    case SET_NAVBAR_TITLE:
      return state.setIn(['navbarPrefs', 'title'], fromJS(action.title))
    case TOGGLE_SEARCHBAR:
      return state.setIn(['navbarPrefs', 'showSearchbar'], action.show)
    case TOGGLE_SHOW_VERSION_NOTICE:
      return state.setIn(['navbarPrefs', 'showVersionNotice'], action.show)
    case LOAD_VERSION_FILE_SUCCESS:
      if (!state.getIn(['navbarPrefs', 'currentVersion'])) {
        return state.setIn(['navbarPrefs', 'currentVersion'], action.payload.version)
      }
      return state
    default:
      return state
  }
}

export default navbarReducer
