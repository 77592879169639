import { useRef } from 'react'

const ClearOnClick = ({ enabled, children }) => {
  const quill = useRef()
  const cleared = useRef(false)

  const handleClick = () => {
    if (enabled && !cleared.current) {
      quill.current.setText('')
      cleared.current = true
    }
  }

  return children({
    onEditorRef: (el) => (quill.current = el),
    onClick: handleClick,
  })
}

export default ClearOnClick
