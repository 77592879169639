import React from 'react'
import PropTypes from 'prop-types'
import { Adopt } from 'react-adopt'
import { over } from 'lodash'

import QuillField from './QuillField'
import BaseToolbar from './BaseToolbar'
import QuillClearFormatting from './QuillClearFormatting'
import QuillLinkFormat from './QuillLinkFormat'
import QuillLinkConversion from './QuillLinkConversion'
import QuillInsertKeyword from './QuillInsertKeyword'
import PreventNewlines from './PreventNewlines'

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import StrikeThroughIcon from '@mui/icons-material/FormatStrikethrough'

const strikeToolbarButton = (
  <Tooltip title="Strikethrough">
    <IconButton className="ql-strike">
      <StrikeThroughIcon />
    </IconButton>
  </Tooltip>
)

const mapper = {
  clear: <QuillClearFormatting />,
  link: <QuillLinkFormat />,
  convert: <QuillLinkConversion />,
  keyword: <QuillInsertKeyword />,
  noNewline: <PreventNewlines />,
}

const ModalQuill = (props) => (
  <Adopt mapper={mapper}>
    {(plugins) => {
      const { clear, link, convert, keyword, noNewline } = plugins
      const { clearFormat, clearToolbarButton } = clear
      const { linkFormat, linkToolbarButton } = link
      const { keywordFormat, keywordToolbarButton } = keyword

      return (
        <QuillField
          {...props}
          onEditorRef={over([
            props.onEditorRef,
            clear.onEditorRef,
            link.onEditorRef,
            convert.onEditorRef,
            keyword.onEditorRef,
          ])}
          onChange={over([props.onChange, ...(props.outsideArticle ? [] : [convert.onChange])])}
          onChangeSelection={over([props.onChangeSelection, link.onChangeSelection, keyword.onChangeSelection])}
          formats={['bold', 'italic', 'underline', 'strike', linkFormat, keywordFormat, clearFormat]}
          toolbar={
            <BaseToolbar>
              {strikeToolbarButton}
              {linkToolbarButton}
              {keywordToolbarButton}
              {clearToolbarButton}
            </BaseToolbar>
          }
          handlers={{
            ...clear.clearHandler,
            ...link.linkHandler,
            ...keyword.keywordHandler,
          }}
          keyboardBindings={props.noNewLine ? noNewline.keyboardBindings : {}}
        />
      )
    }}
  </Adopt>
)

export default ModalQuill
