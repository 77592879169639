import { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'

import { useStore } from 'store/mobx'
import SelectSource from './SelectSource'
import SearchLibrary from './SearchLibrary'

const screens = {
  SCREEN_SELECT_SOURCE: 1,
  SCREEN_SEARCH_LIBRARY: 2,
  SCREEN_UPLOAD_IMAGE: 2,
}

const ImageUpload = ({ open, handleClose, setImage }) => {
  const { resetList } = useStore('imageLibraryStore')

  const [dialogSize, setDialogSize] = useState('sm')
  const [screen, setScreen] = useState(screens.SCREEN_SELECT_SOURCE)

  const showSelectSourceScreen = () => {
    setScreen(screens.SCREEN_SELECT_SOURCE)
    setDialogSize('sm')
  }

  const showLibraryScreen = () => {
    setScreen(screens.SCREEN_SEARCH_LIBRARY)
    setDialogSize('md')
  }

  // const showImageDetailsScreen = (image) => {
  //   setScreen(SCREEN_IMAGE_DETAILS)
  //   setDialogSize('md')
  //   setActiveImage(image)
  // }

  const insertImage = (imgPath) => {
    setImage(imgPath)
    handleClose(false)
    resetList()
  }

  return (
    <Dialog open={open} maxWidth={dialogSize} fullWidth onClose={handleClose}>
      <DialogTitle>Upload Image</DialogTitle>

      {screen === screens.SCREEN_SELECT_SOURCE && (
        <SelectSource showLibraryScreen={showLibraryScreen} handleClose={handleClose} setImage={insertImage} />
      )}
      {screen === screens.SCREEN_SEARCH_LIBRARY && (
        <SearchLibrary goBack={showSelectSourceScreen} setImage={insertImage} />
      )}
      {/* {screen === SCREEN_IMAGE_DETAILS ? <ImageData image={activeImage} goBack={showLibraryScreen} /> : null} */}
    </Dialog>
  )
}

ImageUpload.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  setImage: PropTypes.func,
}

export default observer(ImageUpload)
