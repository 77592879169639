export const CREATE_ARTICLE = 'Create Article'
export const EDIT_ARTICLE = 'Edit Article'
export const CREATE_MEDIA = 'Create Media'
export const EDIT_MEDIA = 'Edit Media'
export const VIEW_QUEUE = 'View Queue'
export const EDIT_FOREIGN_ARTICLE = 'Edit Foreign Article'
export const PUBLISH_ARTICLE = 'Publish Article'
export const REPUBLISH_ARTICLE = 'Republish Article'
export const UPDATE_LIVE_ARTICLE = 'Update Live Article'
export const CREATE_CONTRIBUTOR = 'Create Contributor'
export const MANAGE_CONTRIBUTOR = 'Manage Contributor'
export const MANAGE_FEATURES = 'Manage Features'
export const MANAGE_SERIES = 'Create Series'
export const EDIT_SERIES = 'Edit Series'
export const CREATE_TAGS = 'Create Tags'
export const EDIT_TAGS = 'Edit Tags'
export const MANAGE_PODCASTS = 'Manage Podcasts'
export const MANAGE_PRODUCTS = 'Manage Products'
export const MANAGE_PARTNERS = 'Manage Partners'
export const SUPPORT_MANAGEMENT = 'Support MANAGEMENT'
export const KICK_USERS = 'Kick Users'

export type Privileges =
  | typeof CREATE_ARTICLE
  | typeof EDIT_ARTICLE
  | typeof CREATE_MEDIA
  | typeof EDIT_MEDIA
  | typeof VIEW_QUEUE
  | typeof EDIT_FOREIGN_ARTICLE
  | typeof PUBLISH_ARTICLE
  | typeof REPUBLISH_ARTICLE
  | typeof UPDATE_LIVE_ARTICLE
  | typeof CREATE_CONTRIBUTOR
  | typeof MANAGE_CONTRIBUTOR
  | typeof CREATE_TAGS
  | typeof EDIT_TAGS
  | typeof MANAGE_PODCASTS
  | typeof MANAGE_PRODUCTS
  | typeof MANAGE_PARTNERS
  | typeof SUPPORT_MANAGEMENT
  | typeof KICK_USERS
  | typeof MANAGE_FEATURES
