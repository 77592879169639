import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Drawer from '@mui/material/Drawer'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice'
import InventoryIcon from '@mui/icons-material/Inventory'
import ArtTrack from '@mui/icons-material/ArtTrack'
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import PeopleIcon from '@mui/icons-material/People'
import ListIcon from '@mui/icons-material/List'
import BusinessIcon from '@mui/icons-material/Business'
import ShopIcon from '@mui/icons-material/Shop'
import LabelIcon from '@mui/icons-material/Label'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import SchoolIcon from '@mui/icons-material/School'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'

import {
  MANAGE_SERIES,
  CREATE_TAGS,
  MANAGE_PODCASTS,
  MANAGE_PRODUCTS,
  MANAGE_PARTNERS,
  SUPPORT_MANAGEMENT,
} from 'utils/constants/privileges'
import { signOutCognitoQA } from 'auth/cognitoQA'
import initCognitoSdk from 'auth/cognito'
import DuckImage from 'styles/assets/Duck.jpg'
import { oceanBlue } from '../../styles/colors'
import grantedByRole from '../../utils/grantedByRole'
import { useStore } from '../../store/mobx'
import { observer } from 'mobx-react-lite'
import { StyledColumn } from '../../layouts/StyledLayouts'

const { REACT_APP_ENVIRONMENT } = process.env

const UserInfoStyle = styled(StyledColumn)`
  background-color: ${(p) => p.backgroundColor};
  padding: 18px 84px 16px 12px;
  gap: 24px;
`

const Email = styled.p`
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  color: #fff;
`

const AnimatedThemeSelector = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  transition: 0.25s;
  transform: rotate(${(props) => props.rotation}deg);
`

const SideMenu = ({ open = false, toggleSidemenu, image, email, userRoles, cognitoUser }) => {
  const [arePartnersExpanded, setArePartnersExpanded] = useState(false)
  const [areClassesExpanded, setAreClassesExpanded] = useState(false)
  const [isSupportExpanded, setIsSupportExpanded] = useState(false)
  const [isAdvancedExpanded, setIsAdvancedExpanded] = useState(false)

  const { isDarkMode, toggleDarkMode } = useStore('appLayoutStore')
  const [rotation, setRotation] = useState(isDarkMode ? 0 : 180)

  useEffect(() => {
    setRotation((p) => p + 180)
  }, [isDarkMode])

  const history = useHistory()

  const navigate = (route) => {
    history.push(route)
    toggleSidemenu()
  }

  const signOut = () => {
    if (REACT_APP_ENVIRONMENT?.toUpperCase() === 'QA') {
      sessionStorage.removeItem('id_token')
      sessionStorage.removeItem('refresh_token')
      sessionStorage.removeItem('tester_id')
      sessionStorage.removeItem('session_id')
      localStorage.clear()
      signOutCognitoQA()
      history.push('/login')
    } else {
      const auth = initCognitoSdk({ history })
      sessionStorage.removeItem('id_token')
      sessionStorage.removeItem('refresh_token')
      auth.signOut()
    }
  }

  return (
    <Drawer anchor={'left'} open={open} onClose={toggleSidemenu}>
      <UserInfoStyle backgroundColor={oceanBlue}>
        <Avatar src={image || DuckImage} size={60} />
        <StyledColumn style={{ gap: '8px', alignItems: 'flex-start' }}>
          <Email>{email}</Email>
          <IconButton
            onClick={() => toggleDarkMode()}
            sx={{ width: '40px', height: '40px', overflow: 'hidden', position: 'relative' }}
          >
            <AnimatedThemeSelector rotation={rotation}>
              <LightModeIcon color="squash" />
              <DarkModeIcon sx={{ transform: 'rotate(180deg)' }} />
            </AnimatedThemeSelector>
          </IconButton>
        </StyledColumn>
      </UserInfoStyle>

      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/')}>
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="Articles" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/recipe-queue')}>
            <ListItemIcon>
              <RestaurantMenuIcon />
            </ListItemIcon>
            <ListItemText primary="Recipes" />
          </ListItemButton>
        </ListItem>
        {grantedByRole(userRoles, MANAGE_PODCASTS) && (
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/podcasts')}>
              <ListItemIcon>
                <SettingsVoiceIcon />
              </ListItemIcon>
              <ListItemText primary="Podcasts" />
            </ListItemButton>
          </ListItem>
        )}
        {grantedByRole(userRoles, MANAGE_PRODUCTS) && (
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/products')}>
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary="Products" />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/sponsored')}>
            <ListItemIcon>
              <ArtTrack />
            </ListItemIcon>
            <ListItemText primary="Sponsored Flights" />
          </ListItemButton>
        </ListItem>
        {grantedByRole(userRoles, MANAGE_PARTNERS) && (
          <>
            <ListItemButton onClick={() => setArePartnersExpanded((p) => !p)}>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary="Partners" />
              {arePartnersExpanded ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={arePartnersExpanded} timeout="auto" /* unmountOnExit */>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/partners')}>
                  <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary="List" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/partners/product-unit')}>
                  <ListItemIcon>
                    <FeaturedVideoIcon />
                  </ListItemIcon>
                  <ListItemText primary="Product Placement" />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}
        {grantedByRole(userRoles, SUPPORT_MANAGEMENT) && (
          <>
            <ListItemButton onClick={() => setAreClassesExpanded((p) => !p)}>
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="Classes" />
              {areClassesExpanded ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={areClassesExpanded} timeout="auto" /* unmountOnExit */>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/classes')}>
                  <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary="List" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/class-promotions')}>
                  <ListItemIcon>
                    <SubscriptionsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Promotions" />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={() => setIsSupportExpanded((p) => !p)}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Support" />
              {isSupportExpanded ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isSupportExpanded} timeout="auto" /* unmountOnExit */>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/class-subscriptions')}>
                  <ListItemIcon>
                    <SubscriptionsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Class Subscriptions" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/orders')}>
                  <ListItemIcon>
                    <ShopIcon />
                  </ListItemIcon>
                  <ListItemText primary="Orders" />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}
        {grantedByRole(userRoles, CREATE_TAGS) && grantedByRole(userRoles, MANAGE_SERIES) && (
          <>
            <ListItemButton onClick={() => setIsAdvancedExpanded((p) => !p)}>
              <ListItemIcon>
                <AllInclusiveIcon />
              </ListItemIcon>
              <ListItemText primary="Advanced" />
              {isAdvancedExpanded ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isAdvancedExpanded} timeout="auto" /* unmountOnExit */>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/series')}>
                  <ListItemIcon>
                    <FormatListNumberedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Series" />
                </ListItemButton>
                {grantedByRole(userRoles, CREATE_TAGS) && (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/tags')}>
                    <ListItemIcon>
                      <LabelIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tags" />
                  </ListItemButton>
                )}
              </List>
            </Collapse>
          </>
        )}
        <Divider />
        <br />
        <ListItem disablePadding>
          <ListItemButton onClick={() => signOut()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  )
}

SideMenu.propTypes = {
  email: PropTypes.string,
  image: PropTypes.string,
  userRoles: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    email: state.profile.getIn(['profile', 'emailAddress']),
    image: state.profile.getIn(['profile', 'image']),
    userRoles: state.profile.getIn(['profile', 'roles']),
    cognitoUser: state.cognitoUser.getIn(['cognitoUser']),
  }
}

export default connect(mapStateToProps)(observer(SideMenu))
