import { LOAD_PODCASTS_REQUEST, LOAD_PODCASTS_SUCCESS, LOAD_PODCASTS_FAILURE } from 'utils/constants/actions'

import { Map, fromJS } from 'immutable'

const podcastsReducer = (state = Map(), action) => {
  switch (action.type) {
    case LOAD_PODCASTS_REQUEST:
      return state.set('loading', true)
    case LOAD_PODCASTS_SUCCESS:
      return state.set('podcasts', fromJS(action.payload)).set('loading', false)
    case LOAD_PODCASTS_FAILURE:
      return state.set('loading', false).set('error', action.payload)

    default:
      return state
  }
}

export default podcastsReducer
