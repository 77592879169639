import { CREATE_ARTICLE_CONVERT_TO_COMPONENT_NEW } from 'utils/constants/actions'
import { showFlashMessage } from 'actions/createArticleActions'
import deltaToHTML from 'utils/deltaToHTML'
import { isUrlValid } from 'utils/validators'
import { parse } from 'recipe-ingredient-parser'

const convertToComponent = (store) => (next) => (action) => {
  if (action.type !== CREATE_ARTICLE_CONVERT_TO_COMPONENT_NEW) return next(action)

  const { createArticleBody } = store.getState()
  const bodyComponents = createArticleBody.getIn(['present', 'components'])

  // prevent multiple introduction components
  if (action.componentType === 'introduction') {
    const introductionExists = bodyComponents.some((c) => c.get('type') === 'introduction')
    if (introductionExists) {
      return next(showFlashMessage("Article can't have more than one Introduction component."))
    }
  }

  // prevent multiple footer components
  if (action.componentType === 'footer') {
    const footerExists = bodyComponents.some((c) => c.get('type') === 'footer')
    if (footerExists) {
      return next(showFlashMessage("Article can't have more than one Footer component."))
    }
  }

  // split the current component at selection
  const { index, length } = action.selection
  const before = action.editor.getContents(0, index)
  const after = action.editor.getContents(index + length, action.editor.getLength())

  if (index > 0) {
    // selection not reaching start of text
    action.beforeComponent = {
      id: Math.random().toString(36).substring(7),
      priority: bodyComponents.getIn([action.index, 'priority']),
      type: 'text',
      text: deltaToHTML(before),
      delta: before,
    }
  }

  if (index + length < action.editor.getLength() - 1) {
    // selection not reaching end of text
    action.afterComponent = {
      id: Math.random().toString(36).substring(7),
      priority: bodyComponents.getIn([action.index, 'priority']),
      type: 'text',
      text: deltaToHTML(after),
      delta: after,
    }
  }

  // prepare the new component
  const text = action.editor.getText(index, length)
  const content = action.editor.getContents(index, length)

  action.newComponent = {
    id: Math.random().toString(36).substring(7),
    type: action.componentType,
    priority: 2,
  }

  switch (action.componentType) {
    case 'heading':
      action.newComponent.level = 2
      action.newComponent.text = text
      action.newComponent.anchor = ''
      action.newComponent.number = 0
      break

    case 'list': {
      action.newComponent.items = []
      content.eachLine((line, attributes, i) => {
        if (line.insert.length > 0) {
          action.newComponent.items.push({
            text: deltaToHTML(line),
          })
        }
      })

      action.newComponent.title = ''
      action.newComponent.listType = 'unordered'
      action.newComponent.includeBullet = false
      break
    }

    case 'text-and-image':
      action.newComponent.heading = ''
      action.newComponent.text = text
      action.newComponent.image = null
      action.newComponent.isMirrored = false
      break

    case 'pullquote':
    case 'blockquote': {
      const delta = content.ops.map((op) => {
        if (op.attributes) {
          Object.keys(op.attributes).forEach((key) => {
            if (key !== 'bold') delete op.attributes[key]
          })
        }
        return op
      })

      action.newComponent.text = deltaToHTML(delta)
      break
    }

    case 'introduction':
    case 'footer':
      action.newComponent.text = text
      break

    case 'recipeSteps': {
      action.newComponent.items = []
      content.eachLine((line, attributes, i) => {
        if (line.insert.length > 0) {
          action.newComponent.items.push({
            text: deltaToHTML(line),
            image: null,
          })
        }
      })

      break
    }

    case 'recipeIngredients': {
      action.newComponent.title = 'Ingredients'

      const lines = text.split('\n')
      action.newComponent.items = lines.map((line) => {
        const item = parse(line)
        line.split(' ').forEach((word, i) => {
          if (isUrlValid(word)) item.url = word
        })
        return item
      })

      break
    }

    default:
  }

  return next(action)
}

export default convertToComponent
