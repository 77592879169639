import { useLocation, useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

const TabStyle = { margin: '4px 0' }

const tabs = {
  articles: [
    { value: '/', label: 'Article Queue' },
    { value: '/my-articles', label: 'My Articles' },
  ],
  recipes: [
    { value: '/recipe-queue', label: 'Recipe Queue' },
    { value: '/my-recipes', label: 'My Recipes' },
  ],
}

const TabsComponent = () => {
  const history = useHistory()
  const { pathname } = useLocation()

  const tabsContent = useMemo(
    () => Object.values(tabs).find((type) => type.find((t) => t.value === pathname)),
    [pathname]
  )

  const handleChange = (_event: React.SyntheticEvent<Element, Event>, value: string) => {
    history.push(value)
  }

  if (!tabsContent) {
    return null
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        height: '100%',
        color: '#fff',
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      <Tabs
        value={pathname}
        onChange={handleChange}
        textColor="inherit"
        TabIndicatorProps={{ style: { backgroundColor: '#fff' } }}
      >
        {tabsContent.map((t) => (
          <Tab sx={TabStyle} key={t.value} value={t.value} label={t.label} />
        ))}
      </Tabs>
    </Box>
  )
}

export default TabsComponent
