import { Map } from 'immutable'
import { TOGGLE_QUEUE_FILTER_POPOVER, ARTICLES_RESET_STATE } from 'utils/constants/actions'

const articleQueueFilterReducer = (state = Map(), action) => {
  switch (action.type) {
    case TOGGLE_QUEUE_FILTER_POPOVER:
      return state.set('open', action.open)
    case ARTICLES_RESET_STATE:
      return state.set('open', false)
    default:
      return state
  }
}

export default articleQueueFilterReducer
