import { Map, fromJS } from 'immutable'
import {
  ARTICLES_UPDATE_TITLE,
  ARTICLES_UPDATE_URL,
  ARTICLES_UPDATE_EDITORS_NOTES,
  ARTICLES_SET_PUBLISH_DT,
  ARTICLES_UPDATE_PREVIEW_IMAGE,
  ARTICLES_SORT,
  ARTICLES_SHOW_FLASH_MESSAGE,
  ARTICLES_HIDE_ERROR_MESSAGE,
  LOAD_ARTICLES_REQUEST,
  LOAD_ARTICLES_FAILURE,
  LOAD_ARTICLES_SUCCESS,
  UPDATE_ARTICLES_REQUEST,
  UPDATE_ARTICLES_SUCCESS,
  UPDATE_ARTICLES_FAILURE,
  ARTICLES_TOGGLE_ARCHIVE_DIALOG,
  APPLY_ARTICLES_FILTERS_REQUEST,
  APPLY_ARTICLES_FILTERS_SUCCESS,
  APPLY_ARTICLES_FILTERS_FAILURE,
  ARTICLES_RELOAD,
  ARTICLES_TOGGLE_COMPRESSED_VIEW,
  ARTICLES_TOGGLE_COMPRESSED_VIEW_ITEM,
  ARTICLES_RESET_STATE,
  ARCHIVE_ARTICLE_REQUEST,
  ARCHIVE_ARTICLE_SUCCESS,
  ARCHIVE_ARTICLE_FAILURE,
  CREATE_ARTICLE_FETCH_ARTICLE_FAILURE,
  CREATE_ARTICLE_SEND_ARTICLE_SUCCESS,
} from 'utils/constants/actions'

import { initialArticlesState } from 'store/initialState/initialArticlesState'

const articlesReducer = (state = Map(), action) => {
  switch (action.type) {
    case ARTICLES_UPDATE_TITLE:
      return state.setIn(['articles', action.dateKey, action.articleIdx, 'titles', 'site'], action.title)

    case ARTICLES_UPDATE_URL:
      return state.setIn(['articles', action.dateKey, action.articleIdx, 'url'], action.url)

    case ARTICLES_UPDATE_EDITORS_NOTES:
      return state.setIn(
        ['articles', action.dateKey, action.articleIdx, 'editorsNotes'],
        fromJS([{ comment: action.editorsNotes }])
      )

    case ARTICLES_SET_PUBLISH_DT:
      return state
        .setIn(['articles', action.dateKey, action.articleIdx, 'publishDate'], action.dateTime)
        .set('dateChanged', true)

    case ARTICLES_UPDATE_PREVIEW_IMAGE:
      const updatedState = state.setIn(
        ['articles', action.dateKey, action.articleIdx, 'previewImage'],
        fromJS(action.previewImage)
      )
      if (action.updateHero) {
        return updatedState.setIn(
          ['articles', action.dateKey, action.articleIdx, 'body', 0, 'image'],
          fromJS(action.previewImage)
        )
      }
      return updatedState

    case ARTICLES_SORT:
      return state.set('articles', action.sortedList).set('sortBy', action.sortBy).set('sortDir', action.sortDir)

    case LOAD_ARTICLES_REQUEST:
      return state.set('loading', true)

    case LOAD_ARTICLES_SUCCESS:
      return state
        .set('articles', action.articles)
        .set('compressedView', action.compressedView)
        .set('loading', false)
        .set('needsReload', false)

    case LOAD_ARTICLES_FAILURE:
      return state
        .set('loading', false)
        .set('errorType', 'loadingArticles')
        .set('flashMessage', action.payload.response.message || 'Loading failed')

    case UPDATE_ARTICLES_REQUEST:
      return state.setIn(['updatingArticles', action.payload.dateKey, action.payload.index], true)

    case UPDATE_ARTICLES_SUCCESS:
      let newState = state
        .setIn(['updatingArticles', action.payload.dateKey, action.payload.index], false)
        .set('dateChanged', false)
        .set(
          'flashMessage',
          `${action.payload.route === 'articles' ? 'Article' : 'Recipe'} has been updated successfully`
        )
      if (action.articles) newState = newState.set('articles', fromJS(action.articles))
      return newState

    case UPDATE_ARTICLES_FAILURE:
      return state
        .set('errorType', 'updatingArticle')
        .set('flashMessage', action.payload.response.message)
        .set('updatingArticles', fromJS({}))

    case ARTICLES_SHOW_FLASH_MESSAGE:
      return state.set('flashMessage', action.flashMessage)

    case ARTICLES_HIDE_ERROR_MESSAGE:
      return state.set('errorType', '').set('flashMessage', '')

    case ARTICLES_TOGGLE_ARCHIVE_DIALOG:
      return state
        .setIn(['archive', 'showModal'], action.show)
        .setIn(['archive', 'articleId'], action.id)
        .setIn(['archive', 'isPublished'], action.isPublished)
        .setIn(['archive', 'articlePath'], fromJS(action.articlePath))

    case APPLY_ARTICLES_FILTERS_REQUEST:
      return state.set('loading', true)

    case APPLY_ARTICLES_FILTERS_SUCCESS:
      return state.set('articles', action.articles).set('compressedView', action.compressedView).set('loading', false)

    case APPLY_ARTICLES_FILTERS_FAILURE:
      return state
        .set('loading', false)
        .set('errorType', 'loadingArticles')
        .set('flashMessage', action.payload.response.message || 'Loading failed')

    case ARTICLES_RELOAD:
      return state.set('articles', null)

    case ARTICLES_TOGGLE_COMPRESSED_VIEW:
      return state.set('compressedView', !state.get('compressedView')).set('articles', fromJS(action.updatedArticles))

    case ARTICLES_TOGGLE_COMPRESSED_VIEW_ITEM:
      const compressed = state.getIn(['articles', action.dateKey, action.index, 'compressed'])
      return state.setIn(['articles', action.dateKey, action.index, 'compressed'], !compressed)

    case ARCHIVE_ARTICLE_REQUEST:
      return state.setIn(['archive', 'loading'], true)

    case ARCHIVE_ARTICLE_SUCCESS:
      return state
        .setIn(['archive', 'loading'], false)
        .set('flashMessage', 'Article has been archived successfully')
        .setIn(['archive', 'showModal'], false)
        .deleteIn([...action.payload.articlePath])

    case ARCHIVE_ARTICLE_FAILURE:
      return state
        .setIn(['archive', 'loading'], false)
        .set('errorType', 'archivingArticle')
        .set('flashMessage', action.payload.response.message)
        .setIn(['archive', 'showModal'], false)

    case CREATE_ARTICLE_FETCH_ARTICLE_FAILURE:
      if (action.payload.status === 404) {
        return state.set('flashMessage', action.payload.response.message)
      }

      return state

    case ARTICLES_RESET_STATE:
      return (state = initialArticlesState)

    case CREATE_ARTICLE_SEND_ARTICLE_SUCCESS:
      return state.set('needsReload', true)

    default:
      return state
  }
}

export default articlesReducer
