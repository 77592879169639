import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth'

import { v4 as uuidv4 } from 'uuid'

const {
  REACT_APP_CMS_URL,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_COGNITO_APP_WEB_DOMAIN,
} = process.env

const initCognitoSdk = ({ loadProfile, history } = {}) => {
  const authData = {
    ClientId: REACT_APP_COGNITO_CLIENT_ID,
    AppWebDomain: REACT_APP_COGNITO_APP_WEB_DOMAIN,
    TokenScopesArray: ['profile', 'email', 'openid'],
    RedirectUriSignIn: REACT_APP_CMS_URL,
    RedirectUriSignOut: `${REACT_APP_CMS_URL}/login`,
    IdentityProvider: 'Google',
    UserPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    AdvancedSecurityDataCollectionFlag: 'false',
  }

  const auth = new CognitoAuth(authData)
  auth.useCodeGrantFlow()

  auth.userhandler = {
    onSuccess: (res) => {
      const idToken = res.getIdToken().getJwtToken()
      const refreshToken = res.refreshToken.refreshToken
      sessionStorage.setItem('id_token', idToken)
      sessionStorage.setItem('refresh_token', refreshToken)
      sessionStorage.setItem('session_id', uuidv4())
      if (loadProfile) loadProfile()

      const redirect = sessionStorage.getItem('redirect')
      if (history) {
        history.replace(redirect || '/')
      }
    },
    onFailure: (res) => {
      sessionStorage.removeItem('id_token')
      sessionStorage.removeItem('session_id')
      localStorage.clear()
      history.replace('/login')
    },
  }

  return auth
}

export default initCognitoSdk
