import {
  CREATE_ARTICLE_SAVE_HISTORY,
  CREATE_ARTICLE_UNDO,
  CREATE_ARTICLE_REDO,
  CREATE_ARTICLE_INSERT_COMPONENT,
  CREATE_ARTICLE_INSERT_COMPONENT_FROM_PLACEHOLDER,
  CREATE_ARTICLE_INSERT_COMPONENT_FROM_MODAL,
  CREATE_ARTICLE_UPDATE_COMPONENT_FROM_MODAL,
  CREATE_ARTICLE_REMOVE_COMPONENT,
  CREATE_ARTICLE_REORDER_COMPONENTS,
  CREATE_ARTICLE_SPLIT_WITH_DRAGGED_COMPONENT,
  CREATE_ARTICLE_CONVERT_TO_COMPONENT,
  CREATE_ARTICLE_DUPLICATE_COMPONENT,
  CREATE_ARTICLE_CONVERT_TO_COMPONENT_NEW,
} from 'utils/constants/actions'

const undoableActions = [
  CREATE_ARTICLE_INSERT_COMPONENT,
  CREATE_ARTICLE_INSERT_COMPONENT_FROM_PLACEHOLDER,
  CREATE_ARTICLE_INSERT_COMPONENT_FROM_MODAL,
  CREATE_ARTICLE_UPDATE_COMPONENT_FROM_MODAL,
  CREATE_ARTICLE_REMOVE_COMPONENT,
  CREATE_ARTICLE_REORDER_COMPONENTS,
  CREATE_ARTICLE_SPLIT_WITH_DRAGGED_COMPONENT,
  CREATE_ARTICLE_CONVERT_TO_COMPONENT,
  CREATE_ARTICLE_DUPLICATE_COMPONENT,
  CREATE_ARTICLE_CONVERT_TO_COMPONENT_NEW,
]

const historyLimit = 5

const historyMiddleware = (store) => (next) => (action) => {
  if (undoableActions.includes(action.type)) {
    const { createArticleBody } = store.getState()
    const present = createArticleBody.get('present')
    const past = createArticleBody.get('past')

    store.dispatch({
      type: CREATE_ARTICLE_SAVE_HISTORY,
      present,
      limitReached: past.count() === historyLimit,
    })
  }

  if (action.type === CREATE_ARTICLE_UNDO) {
    const { createArticleBody } = store.getState()
    const past = createArticleBody.get('past')
    const present = createArticleBody.get('present')
    const future = createArticleBody.get('future')

    let futureArray
    const limitReached = future.count() === historyLimit
    if (limitReached) futureArray = future.splice(-1, 1)

    action.state = {
      past: past.shift(),
      present: past.get(0),
      future: limitReached ? futureArray.unshift(present) : future.unshift(present),
    }
  }

  if (action.type === CREATE_ARTICLE_REDO) {
    const { createArticleBody } = store.getState()
    const past = createArticleBody.get('past')
    const present = createArticleBody.get('present')
    const future = createArticleBody.get('future')

    let pastArray
    const limitReached = future.count() === historyLimit
    if (limitReached) pastArray = past.splice(-1, 1)

    action.state = {
      past: limitReached ? pastArray.unshift(present) : past.unshift(present),
      present: future.get(0),
      future: future.shift(),
    }
  }

  return next(action)
}

export default historyMiddleware
