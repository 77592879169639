import React, { useEffect, useState } from 'react'
import Transition from 'react-inline-transition-group'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setConnectionStatus } from 'actions/internetConnectionActions'
import { useTheme, Chip } from '@mui/material'

const { REACT_APP_OFFLINE_TIME_LIMIT } = process.env

const OfflineNotifier = ({ setConnectionStatus }) => {
  const [online, setOnline] = useState(true)
  const [connectionBack, setConnectionBack] = useState(false)
  const [redirectTimeout, setRedirectTimeout] = useState(null)
  const theme = useTheme()
  const history = useHistory()

  const styles = {
    negativeChip: {
      backgroundColor: theme.palette.starDustOrange.main,
      padding: '1.1rem 0.5rem',
    },
    positiveChip: {
      backgroundColor: theme.palette.green.main,
      padding: '1.1rem 0.5rem',
    },
    chipLabel: {
      fontSize: '1.5rem',
      color: '#fff',
    },
    base: {
      opacity: 0,
      position: 'fixed',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 1200,
      top: '15%',
    },
    appear: {
      opacity: 1,
      transition: 'all 1000ms',
    },
  }

  const connectionIsBack = () => {
    setConnectionBack(true)
    setTimeout(() => {
      setConnectionBack(false)
    }, 3000)
  }

  useEffect(() => {
    const handleOnline = () => {
      clearTimeout(redirectTimeout)
      setOnline(true)
      setConnectionStatus(true)
      connectionIsBack()
    }

    const handleOffline = () => {
      setOnline(false)
      setConnectionStatus(false)
      setConnectionBack(false)

      setRedirectTimeout(setTimeout(() => history.push('/'), REACT_APP_OFFLINE_TIME_LIMIT))
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [setConnectionStatus, redirectTimeout, history])

  return (
    <div>
      {!online && (
        <Transition
          childrenStyles={{
            base: styles.base,
            appear: styles.appear,
          }}
        >
          <Chip
            label={<div style={styles.chipLabel}>Your device lost its internet connection.</div>}
            sx={styles.negativeChip}
          />
        </Transition>
      )}
      {connectionBack && (
        <Transition
          childrenStyles={{
            base: styles.base,
            appear: styles.appear,
          }}
        >
          <Chip label={<div style={styles.chipLabel}>Internet connection is back</div>} sx={styles.positiveChip} />
        </Transition>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setConnectionStatus: (online) => dispatch(setConnectionStatus(online)),
  }
}

export default connect(undefined, mapDispatchToProps)(OfflineNotifier)
