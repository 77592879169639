import { makeAutoObservable, runInAction } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import { isUrlValid } from 'utils/validators'
import { createCRUDObject } from '../../utils/apiUtils'

export class ClassDomainObject {
  id = null
  urlKey = ''
  title = ''
  emailTitle = ''
  shortTitle = ''
  price = null
  includeInUnlock = false
  active = false
  availableForPurchase = false
  training = false
  unlock = false
  curriculumType = ''
  type = ''
  instructorDisplay = ''
  instructors = []
  assistantUserIds = []
  modules = []
  prereqClassIds = []
  image = {}
  totalDuration = null
  totalActiveVideoCount = null
  bundleIds = []
  categories = []
  tags = []
  relatedClasses = []
  previewVideos = []
  description = ''
  shortDescription = ''
  publishDate = null
  articleCallout = ''
  terms = []
  tools = []

  loading = false
  error = null
  toolError = null
  termError = null
  store = null
  detailsLoaded = false //we use a separate http request to load the details of the Class.

  constructor(store, id = uuidv4()) {
    makeAutoObservable(this)

    this.store = store
    this.id = id
  }

  manualSaveToServer = async (newJson, useLoading = true) => {
    if (newJson) {
      this.updateFromJson(newJson)
    }
    if (useLoading) {
      this.loading = true
    }
    try {
      const response = await this.store.transportLayer.editClass(this.asJson)
      runInAction(() => {
        this.updateFromJson(response)
        if (useLoading) {
          this.loading = false
        }
      })
    } catch (error) {
      runInAction(() => {
        if (useLoading) {
          this.loading = false
        }
        this.error = error
      })

      setTimeout(() => {
        runInAction(() => {
          this.error = null
        })
      }, 5000)
    }
  }

  noResponseSaveToServer = async (newJson) => {
    if (!newJson) {
      return
    }
    this.loading = true
    try {
      await this.store.transportLayer.editClass(this.asJson)
      runInAction(() => {
        this.loading = false
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false
        this.error = error
      })

      setTimeout(() => {
        runInAction(() => {
          this.error = null
        })
      }, 5000)
    }
  }

  fetchClassDetails = async (id, useLoading = true) => {
    const moduleDetailsLoaded = this.modules.every((item) => Boolean(item.id))

    if (this.detailsLoaded && moduleDetailsLoaded) {
      return
    }

    if (useLoading) {
      this.loading = true
    }
    this.error = null
    try {
      const data = await this.store.transportLayer.getClass(id)
      runInAction(() => {
        if (!data.id) {
          this.error = { message: 'Class not found.' }
          this.loading = false
        } else {
          this.store.updateClassFromServer(data)
          this.detailsLoaded = true
          this.loading = false
          this.error = null
        }
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false
        this.error = error
      })

      setTimeout(() => {
        runInAction(() => {
          this.error = null
        })
      }, 5000)
    }
  }

  // Remove this Class from the client and the server.
  delete() {
    this.store.transportLayer.deleteClass(this.id)
    this.store.removeClass(this)
  }

  get asJson() {
    return {
      id: this.id,
      urlKey: this.urlKey,
      title: this.title,
      emailTitle: this.emailTitle,
      shortTitle: this.shortTitle,
      price: this.price,
      includeInUnlock: this.includeInUnlock,
      active: this.active,
      availableForPurchase: this.availableForPurchase,
      training: this.training,
      unlock: this.unlock,
      curriculumType: this.curriculumType,
      type: this.type,
      instructorDisplay: this.instructorDisplay,
      instructors: this.instructors,
      assistantUserIds: this.assistantUserIds,
      modules: this.modules,
      prereqClassIds: this.prereqClassIds,
      image: this.image,
      totalDuration: this.totalDuration,
      totalActiveVideoCount: this.totalActiveVideoCount,
      bundleIds: this.bundleIds,
      categories: this.categories,
      tags: this.tags,
      relatedClasses: this.relatedClasses,
      previewVideos: this.previewVideos,
      description: this.description,
      shortDescription: this.shortDescription,
      publishDate: this.publishDate,
      articleCallout: this.articleCallout,
      terms: this.terms,
      tools: this.tools,
    }
  }

  updateFromJson(json) {
    this.id = json.id
    this.urlKey = json.urlKey
    this.title = json.title
    this.emailTitle = json.emailTitle
    this.shortTitle = json.shortTitle
    this.price = json.price
    this.includeInUnlock = json.includeInUnlock
    this.active = json.active
    this.availableForPurchase = json.availableForPurchase
    this.training = json.training
    this.unlock = json.unlock
    this.curriculumType = json.curriculumType
    this.type = json.type
    this.instructorDisplay = json.instructorDisplay
    this.instructors = json.instructors
    this.assistantUserIds = json.assistantUserIds
    this.modules = json.modules
    this.prereqClassIds = json.prereqClassIds
    this.image = json.image
    this.totalDuration = json.totalDuration
    this.totalActiveVideoCount = json.totalActiveVideoCount
    this.bundleIds = json.bundleIds
    this.categories = json.categories
    this.tags = json.tags
    this.relatedClasses = json.relatedClasses
    this.previewVideos = json.previewVideos
    this.description = json.description
    this.shortDescription = json.shortDescription
    this.publishDate = json.publishDate
    this.articleCallout = json.articleCallout
    this.terms = json.terms
    this.tools = json.tools
  }

  createModule = async (classId, module) => {
    this.loading = true
    try {
      const data = await this.store.transportLayer.createModule(classId, module)
      runInAction(() => {
        this.modules.unshift(data)
        this.loading = false
        this.toolError = null
      })
      return data
    } catch (error) {
      runInAction(() => {
        this.loading = false
        this.error = error
      })

      setTimeout(() => {
        runInAction(() => {
          this.error = null
        })
      }, 5000)
    }
  }

  mutateModule = async (newModule, property) => {
    try {
      const module = await this.store.transportLayer.updateModule(this.id, newModule.id, newModule)
      const moduleIndex = this.modules.findIndex((m) => m.id === module.id)

      if (moduleIndex === -1) {
        throw new Error('Module not found')
      }

      runInAction(() => {
        this.modules[moduleIndex] = module
      })
    } catch (error) {
      runInAction(() => {
        this.error = error
      })

      setTimeout(() => {
        runInAction(() => {
          this.error = null
        })
      }, 5000)
    }
  }

  createLesson = async (classId, moduleId, formData) => {
    let { versions, ...rest } = formData //versions are not part of lesson schema

    this.loading = true
    try {
      const session = await this.store.transportLayer.createLesson(classId, rest)
      runInAction(() => {
        const module = this.modules.find((item) => item.id === moduleId)
        session.versions = []
        module.sessions?.unshift(session)

        this.loading = false
        this.error = null
      })
      return session
    } catch (error) {
      runInAction(() => {
        this.loading = false
        this.error = error
      })

      setTimeout(() => {
        runInAction(() => {
          this.error = null
        })
      }, 5000)
    }
  }

  updateLesson = async (classId, moduleId, lessonId, formData) => {
    let { versions, ...rest } = formData //versions are not part of lesson schema

    this.loading = true
    try {
      const updatedLesson = await this.store.transportLayer.updateLesson(classId, lessonId, rest)
      runInAction(() => {
        const module = this.modules.find((item) => {
          return item.id === moduleId
        })
        const sessionIndex = module.sessions.findIndex((item) => item.id === lessonId)
        module.sessions[sessionIndex] = { ...updatedLesson, versions }
        this.loading = false
        this.error = null
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false
        this.error = error
      })

      setTimeout(() => {
        runInAction(() => {
          this.error = null
        })
      }, 5000)
    }
  }

  getVersions = async (classId, moduleId, lessonId) => {
    this.loading = true
    try {
      const versions = await this.store.transportLayer.getVersions(classId, lessonId)
      runInAction(() => {
        const module = this.modules.find((item) => item.id === moduleId)
        const session = module.sessions?.find((item) => item.id === lessonId)
        session.versions = versions
        this.loading = false
        this.error = null
      })
      return versions
    } catch (error) {
      runInAction(() => {
        this.loading = false
        this.error = error
      })

      setTimeout(() => {
        runInAction(() => {
          this.error = null
        })
      }, 5000)
    }
  }

  createVersion = async (classId, moduleId, lessonId, formData) => {
    this.loading = true
    try {
      const version = await this.store.transportLayer.createVersion(classId, lessonId, formData)
      runInAction(() => {
        const module = this.modules.find((item) => item.id === moduleId)
        const session = module.sessions?.find((item) => item.id === lessonId)
        if (session.versions) {
          session.versions.unshift(version)
        } else {
          session.versions = [version]
        }
        this.loading = false
        this.error = null
      })
      return version
    } catch (error) {
      runInAction(() => {
        this.loading = false
        this.error = error.message
      })

      setTimeout(() => {
        runInAction(() => {
          this.error = null
        })
      }, 5000)
    }
  }

  updateVersion = async (classId, moduleId, lessonId, versionId, formData) => {
    this.loading = true
    try {
      const version = await this.store.transportLayer.updateVersion(classId, lessonId, versionId, formData)
      runInAction(() => {
        const module = this.modules.find((item) => item.id === moduleId)
        const session = module.sessions?.find((item) => item.id === lessonId)
        const versionIndex = session.versions.findIndex((item) => item.id === version.id)
        session.versions[versionIndex] = version

        this.loading = false
        this.error = null
      })
      return version
    } catch (error) {
      runInAction(() => {
        this.loading = false
        this.error = error.message
      })

      setTimeout(() => {
        runInAction(() => {
          this.error = null
        })
      }, 5000)
    }
  }

  deleteVersion = async (classId, moduleId, lessonId, versionId) => {
    this.loading = true
    try {
      await this.store.transportLayer.deleteVersion(classId, lessonId, versionId)
      runInAction(() => {
        const module = this.modules.find((item) => item.id === moduleId)
        const session = module.sessions?.find((item) => item.id === lessonId)
        const versionIndex = session.versions.findIndex((item) => item.id === versionId)
        session.versions.splice(versionIndex, 1)

        this.loading = false
        this.error = null
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false
        this.error = error.message
      })

      setTimeout(() => {
        runInAction(() => {
          this.error = null
        })
      }, 5000)
    }
  }

  updateAttendance = async (data) => {
    this.updateAttendanceLoading = true
    this.updateAttendanceError = null
    this.updateAttendanceResponse = null
    try {
      // pass params and body
      const updated = await this.store.transportLayer.updateAttendanceRequest(data)
      runInAction(() => {
        this.updateAttendanceLoading = false
        this.updateAttendanceError = null
        this.updateAttendanceResponse = updated
      })
    } catch (error) {
      runInAction(() => {
        this.updateAttendanceLoading = false
        this.updateAttendanceError = error
      })
    }
  }

  validateToolFormData = (formData) => {
    let errorMessage = null
    if (!formData) {
      errorMessage = 'The form is empty.'
    }

    const { title, url, type } = formData

    if (type === 'url' && !isUrlValid(url)) {
      errorMessage = 'Url is not valid.'
    } else if ((type === 'ebook' || type === 'document') && !url) {
      errorMessage = 'Please upload a file.'
    }

    if (!title) errorMessage = 'Title is required'

    this.toolError = { message: errorMessage }

    setTimeout(() => {
      runInAction(() => {
        this.toolError = null
      })
    }, 5000)

    return errorMessage ? false : true
  }

  validateTermFormData = (formData) => {
    let errorMessage = null
    if (!formData) errorMessage = 'The form is empty.'

    const { title, price } = formData

    if (!title) errorMessage = 'Title is required'
    if (!price) errorMessage = 'Price is required'

    this.termError = { message: errorMessage }

    setTimeout(() => {
      runInAction(() => {
        this.termError = null
      })
    }, 5000)

    return errorMessage ? false : true
  }

  saveTool = async (formData) => {
    if (!this.validateToolFormData(formData)) {
      return
    }

    const payload = createCRUDObject(formData)

    const data = await this.store.transportLayer.updateTool(formData.classId, formData.id, payload)

    runInAction(() => {
      this.loading = false
      this.toolError = null

      const index = this.tools.findIndex((item) => item.id === data.id)
      this.tools.splice(index, 1, data)
    })
    return data
  }

  createTool = async (formData) => {
    if (!this.validateToolFormData(formData)) return

    if (!formData.termId) {
      delete formData.termId
    }

    const data = await this.store.transportLayer.createTool(formData.classId, formData)
    runInAction(() => {
      this.loading = false
      this.toolError = null

      if (this.tools) {
        this.tools.unshift(data)
      } else {
        this.tools = [data]
      }
    })
    return data
  }

  updateTerm = async (formData) => {
    if (!this.validateTermFormData(formData)) return

    if (!formData.termId) {
      delete formData.termId
    }

    const data = await this.store.transportLayer.updateTerm(formData.classId, formData.id, formData)

    runInAction(() => {
      this.loading = false
      this.toolError = null

      const index = this.terms.findIndex((item) => item.id === data.id)
      this.terms.splice(index, 1, data)
    })
    return data
  }

  createTerm = async (formData) => {
    if (!this.validateTermFormData(formData)) return

    const data = await this.store.transportLayer.createTerm(formData.classId, formData)
    runInAction(() => {
      this.loading = false
      this.toolError = null

      if (this.terms) {
        this.terms.unshift(data)
      } else {
        this.terms = [data]
      }
    })
    return data
  }

  listVideoAssignments = async () => {
    return this.store.transportLayer.listVideoAssignments()
  }

  createVideoAssignment = async (formData) => {
    return this.store.transportLayer.createVideoAssignment(formData)
  }

  updateVideoAssignment = async (formData) => {
    return this.store.transportLayer.updateVideoAssignment(formData)
  }

  updateVideoAssignmentSubmission = async (formData) => {
    return this.store.transportLayer.updateVideoAssignmentSubmission(formData)
  }
}
