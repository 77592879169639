import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import CircularProgress from '@mui/material/CircularProgress'

import TagList from 'components/Tags/TagList'
import CommonDialogTitle from '../../Common/DialogComponents/CommonDialogTitle'
import useTags from '../../../hooks/useTags'

const TagsDialog = ({ open, toggleTagsDialog, addedTags, onTagSelected }) => {
  const tagsQuery = useTags()

  return (
    <Dialog open={open} onClose={() => toggleTagsDialog(false)} fullWidth>
      <CommonDialogTitle onClose={() => toggleTagsDialog(false)}>
        <div>Click on a tag to add to the list</div>
      </CommonDialogTitle>
      <DialogContent>
        {tagsQuery.isLoading ? (
          <CircularProgress />
        ) : (
          <TagList
            tags={tagsQuery.data?.filter((tag) => tag.is_favorite && !addedTags.includes(tag.tag_name))}
            onTagSelected={onTagSelected}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

TagsDialog.propTypes = {
  open: PropTypes.bool,
  toggleTagsDialog: PropTypes.func,
  addedTags: PropTypes.array,
  onTagSelected: PropTypes.func,
}

export default TagsDialog
