import PropTypes from 'prop-types'

import { CTAWrapper } from '../../../NavBar/CTAWrapper'
import SaveClassCTAs from '../ClassDetails/SaveClassCTAs'
import { useStore } from 'store/mobx'
import { useHistory } from 'react-router-dom'
import { lessonComponents } from '../../../../containers/new-pages/ClassLessons'

const SaveLessonChanges = (props) => {
  const classesStore = useStore('classesStore')
  const classItem = classesStore.classes?.find((item) => item.id === props.classId)

  const history = useHistory()

  const handleSave = async () => {
    const isEdit = !!props.lessonId

    const result = await (isEdit
      ? classItem.updateLesson(props.classId, props.moduleId, props.lessonId, props.formData)
      : classItem.createLesson(props.classId, props.moduleId, props.formData))

    if (!isEdit && result?.id) {
      const lessonType = Object.values(lessonComponents).find((l) => l.title === props.formData.type)
      if (lessonType) {
        history.push(`/classes/${props.classId}/modules/${props.moduleId}/${lessonType.id}/${result.id}`)
      }
    }
  }

  return (
    <CTAWrapper>
      <SaveClassCTAs handleSave={handleSave} backUrl={`/classes/${props.classId}/modules/${props.moduleId}`} />
    </CTAWrapper>
  )
}

SaveLessonChanges.propTypes = {
  classId: PropTypes.number,
  moduleId: PropTypes.string,
  lessonId: PropTypes.string,
  formData: PropTypes.object,
}

export default SaveLessonChanges
