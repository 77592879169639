import React, { useState } from 'react'
import Button from '@mui/material/Button'
import GalleryIcon from '@mui/icons-material/Collections'
import FileInput from 'ui/FileInput'
import StyledFlex from '../../../../../layouts/StyledLayouts'

const buttonStyle = {
  flex: 1,
  height: 52,
}

export interface ImageInputData {
  dataUri: string | ArrayBuffer
  fileName: string
  fileType: string
  width: number
  height: number
}

interface Props {
  handleImageInput(data: ImageInputData): void
  maxFileSize?: number
  minWidth?: number
  searchLibrarySelect(): void
}

const SelectSource = (props: Props) => {
  const [fileLarge, setFileLarge] = useState(false)
  const [imageTooSmall, setImageTooSmall] = useState(false)

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()
    if (!e.target.files || !e.target.files.length) {
      return
    }
    const file = e.target.files[0]

    reader.onload = (e) => {
      if (!e.target) {
        return
      }

      const image = new Image()
      image.src = e.target.result as string
      image.onload = () => {
        const { width, height } = image
        if (width < (props.minWidth || 0)) {
          setImageTooSmall(true)
          return
        }

        if (e.target?.result) {
          props.handleImageInput({
            dataUri: e.target.result,
            fileName: file.name,
            fileType: file.type,
            width,
            height,
          })
        }
      }
    }

    reader.readAsDataURL(file)
  }

  return (
    <div>
      <StyledFlex $gap style={{ alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <FileInput
          label="File upload"
          accept="image/*"
          handleFileInput={handleFileInput}
          maxFileSize={props.maxFileSize}
          style={buttonStyle}
          fileTooLargeHandler={() => setFileLarge(true)}
        />
        <Button
          variant="contained"
          startIcon={<GalleryIcon color={'invertedLight' as any} />}
          style={buttonStyle}
          onClick={props.searchLibrarySelect}
        >
          Library
        </Button>
      </StyledFlex>
      {fileLarge && <span>File size should not exceed {props.maxFileSize} MB.</span>}
      {imageTooSmall && <span>Image width should be greater than {`${props.minWidth}px`}.</span>}
    </div>
  )
}

export default SelectSource
