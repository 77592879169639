import axios from './axios'

export const fetchVideos = async (searchTerm) => {
  const { data } = await axios.get(`videos/search?search=${searchTerm}`)
  return data
}

export const loadVideoPlayers = async () => {
  const { data } = await axios.get('videos/players')
  return data
}
