import { RSAA, getJSON } from 'redux-api-middleware'

import loadArticleHelper from 'utils/loadArticleHelper'

import {
  CREATE_ARTICLE_SUBMIT_ARTICLE,
  CREATE_ARTICLE_SEND_ARTICLE_REQUEST,
  CREATE_ARTICLE_SEND_ARTICLE_SUCCESS,
  CREATE_ARTICLE_SEND_ARTICLE_FAILURE,
  CREATE_ARTICLE_FETCH_ARTICLE_REQUEST,
  CREATE_ARTICLE_FETCH_ARTICLE_SUCCESS,
  CREATE_ARTICLE_FETCH_ARTICLE_FAILURE,
  LOAD_EDITORS_REQUEST,
  LOAD_EDITORS_SUCCESS,
  LOAD_EDITORS_FAILURE,
  LOAD_AUTHORS_REQUEST,
  LOAD_AUTHORS_SUCCESS,
  LOAD_AUTHORS_FAILURE,
  GET_AUTHOR_BY_ID_REQUEST,
  GET_AUTHOR_BY_ID_SUCCESS,
  GET_AUTHOR_BY_ID_FAILURE,
  LOAD_PARTNERS_REQUEST,
  LOAD_PARTNERS_SUCCESS,
  LOAD_PARTNERS_FAILURE,
  LOAD_EVENTS_REQUEST,
  LOAD_EVENTS_SUCCESS,
  LOAD_EVENTS_FAILURE,
  CREATE_ARTICLE_PUBLISH_ARTICLE_REQUEST,
  CREATE_ARTICLE_PUBLISH_ARTICLE_SUCCESS,
  CREATE_ARTICLE_PUBLISH_ARTICLE_FAILURE,
  CREATE_ARTICLE_UNPUBLISH_ARTICLE_REQUEST,
  CREATE_ARTICLE_UNPUBLISH_ARTICLE_SUCCESS,
  CREATE_ARTICLE_UNPUBLISH_ARTICLE_FAILURE,
  CREATE_ARTICLE_INBOUND_ARTICLES_REQUEST,
  CREATE_ARTICLE_INBOUND_ARTICLES_SUCCESS,
  CREATE_ARTICLE_INBOUND_ARTICLES_FAILURE,
  CREATE_ARTICLE_UPDATE_INBOUND_LINKS_REQUEST,
  CREATE_ARTICLE_UPDATE_INBOUND_LINKS_SUCCESS,
  CREATE_ARTICLE_UPDATE_INBOUND_LINKS_FAILURE,
  CREATE_ARTICLE_PUBLISH_MULTIPLE_REQUEST,
  CREATE_ARTICLE_PUBLISH_MULTIPLE_SUCCESS,
  CREATE_ARTICLE_PUBLISH_MULTIPLE_FAILURE,
  UNARCHIVE_ARTICLE_REQUEST,
  UNARCHIVE_ARTICLE_SUCCESS,
  UNARCHIVE_ARTICLE_FAILURE,
} from 'utils/constants/actions'
import firebase from '../utils/firebase'
import { ref, set } from 'firebase/database'

export function submitArticle({ results, livePreview }) {
  return {
    type: CREATE_ARTICLE_SUBMIT_ARTICLE,
    results,
    livePreview,
  }
}

export function sendArticle({ route, id, data }) {
  return {
    [RSAA]: {
      endpoint: id ? `${route}s/${id}/` : `${route}s`,
      method: id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [
        CREATE_ARTICLE_SEND_ARTICLE_REQUEST,
        {
          type: CREATE_ARTICLE_SEND_ARTICLE_SUCCESS,
          // returns the latest article version
          payload: async (action, state, res) => {
            const { id: articleId } = await (id ? Promise.resolve({ id }) : getJSON(res))

            set(ref(firebase.database, `/lastUpdates/${route}s/${articleId}`), new Date().toISOString())

            const { createArticleMetadata } = state
            const article = { ...data.article, id: articleId }

            article.version = createArticleMetadata.get('currentVersion') + 1
            article.lastUpdate = new Date().toISOString()

            return article
          },
          meta: { article: data.article || data },
        },
        CREATE_ARTICLE_SEND_ARTICLE_FAILURE,
      ],
      body: JSON.stringify(data),
    },
  }
}

export function fetchArticle({ route, id }) {
  return {
    [RSAA]: {
      endpoint: `${route}s/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [
        CREATE_ARTICLE_FETCH_ARTICLE_REQUEST,
        {
          type: CREATE_ARTICLE_FETCH_ARTICLE_SUCCESS,
          payload: (action, state, res) =>
            getJSON(res).then((json) => loadArticleHelper({ articleData: json, storeCurrentVersion: true })),
        },
        CREATE_ARTICLE_FETCH_ARTICLE_FAILURE,
      ],
    },
  }
}

export function searchInboundLinkedArticles(articleId) {
  return {
    [RSAA]: {
      endpoint: `articles/${articleId}/linked`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [
        CREATE_ARTICLE_INBOUND_ARTICLES_REQUEST,
        CREATE_ARTICLE_INBOUND_ARTICLES_SUCCESS,
        CREATE_ARTICLE_INBOUND_ARTICLES_FAILURE,
      ],
    },
  }
}

export function updateInboundLinks(data) {
  return {
    [RSAA]: {
      endpoint: 'articles/replace-urls',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [
        CREATE_ARTICLE_UPDATE_INBOUND_LINKS_REQUEST,
        CREATE_ARTICLE_UPDATE_INBOUND_LINKS_SUCCESS,
        CREATE_ARTICLE_UPDATE_INBOUND_LINKS_FAILURE,
      ],
      body: JSON.stringify(data),
    },
  }
}

export function publishArticle(id) {
  return {
    [RSAA]: {
      endpoint: `articles/${id}/publication-request`,
      method: 'POST',
      types: [
        CREATE_ARTICLE_PUBLISH_ARTICLE_REQUEST,
        CREATE_ARTICLE_PUBLISH_ARTICLE_SUCCESS,
        CREATE_ARTICLE_PUBLISH_ARTICLE_FAILURE,
      ],
    },
  }
}

export function publishMultiple(ids) {
  return {
    [RSAA]: {
      endpoint: `articles/publication-request?ids=${ids.join(',')}`,
      method: 'POST',
      types: [
        CREATE_ARTICLE_PUBLISH_MULTIPLE_REQUEST,
        CREATE_ARTICLE_PUBLISH_MULTIPLE_SUCCESS,
        CREATE_ARTICLE_PUBLISH_MULTIPLE_FAILURE,
      ],
    },
  }
}

export function unpublishArticle(id) {
  return {
    [RSAA]: {
      endpoint: `articles/${id}/unpublication-request`,
      method: 'POST',
      types: [
        CREATE_ARTICLE_UNPUBLISH_ARTICLE_REQUEST,
        CREATE_ARTICLE_UNPUBLISH_ARTICLE_SUCCESS,
        CREATE_ARTICLE_UNPUBLISH_ARTICLE_FAILURE,
      ],
    },
  }
}

// deprecate, use useEditors instead
export function getEditorsList() {
  return {
    [RSAA]: {
      endpoint: 'editors',
      method: 'GET',
      types: [LOAD_EDITORS_REQUEST, LOAD_EDITORS_SUCCESS, LOAD_EDITORS_FAILURE],
    },
  }
}

export function loadAuthors(searchStr) {
  return {
    [RSAA]: {
      endpoint: `authors?name=${searchStr}`,
      method: 'GET',
      types: [LOAD_AUTHORS_REQUEST, LOAD_AUTHORS_SUCCESS, LOAD_AUTHORS_FAILURE],
    },
  }
}

export function getAuthorById(id) {
  return {
    [RSAA]: {
      endpoint: `authors?id=${id}`,
      method: 'GET',
      types: [GET_AUTHOR_BY_ID_REQUEST, GET_AUTHOR_BY_ID_SUCCESS, GET_AUTHOR_BY_ID_FAILURE],
    },
  }
}

export function getPartnersList() {
  return {
    [RSAA]: {
      endpoint: 'partners',
      method: 'GET',
      types: [LOAD_PARTNERS_REQUEST, LOAD_PARTNERS_SUCCESS, LOAD_PARTNERS_FAILURE],
    },
  }
}

export function loadEvents() {
  return {
    [RSAA]: {
      endpoint: 'events',
      method: 'GET',
      types: [LOAD_EVENTS_REQUEST, LOAD_EVENTS_SUCCESS, LOAD_EVENTS_FAILURE],
    },
  }
}

export function unarchiveArticle(id) {
  return {
    [RSAA]: {
      endpoint: `articles/${id}/unarchive`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [UNARCHIVE_ARTICLE_REQUEST, UNARCHIVE_ARTICLE_SUCCESS, UNARCHIVE_ARTICLE_FAILURE],
    },
  }
}
