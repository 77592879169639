import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import ItalicIcon from '@mui/icons-material/FormatItalic'
import DefaultSelectField from 'ui/DefaultSelectField'
import { QuillField } from 'ui/QuillTransition'
import { grey30 } from 'styles/colors'
import styled from 'styled-components'
import StyledFlex from '../../../../../layouts/StyledLayouts'

const SectionHeader = styled.h4`
  margin: 0;
  color: ${grey30};
`

const QuillWrapper = styled(StyledFlex)`
  div {
    width: 100%;
  }
`

const Toolbar = ({ id }) => (
  <Paper elevation={2} id={id}>
    <IconButton className="ql-italic" style={{ margin: '10px 10px 10px 5px' }}>
      <ItalicIcon />
    </IconButton>
  </Paper>
)

Toolbar.propTypes = {
  id: PropTypes.string,
}

const GalleryTitleInput = ({ input: { value, onChange } }) => (
  <QuillField
    bounds="#gallery-form"
    toolbar={<Toolbar />}
    formats={['italic']}
    onChange={onChange}
    value={value || ''}
    placeholder="Gallery title"
    activeFormat={{ name: 'header', value: 4 }}
  />
)

GalleryTitleInput.propTypes = {
  input: PropTypes.object,
}

const validateForm = (data) => {
  const errors = {}
  if (!data.galleryType) {
    errors.galleryType = 'Please select the type of gallery'
  }

  if (!data.layout) {
    errors.layout = 'Please select gallery layout'
  }

  return errors
}

class GalleryData extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    initialize: PropTypes.func,
  }

  constructor(props) {
    super(props)

    if (!props.initialValues) {
      props.initialize({ priority: 2 })
    }
  }

  render() {
    const { handleSubmit, initialValues } = this.props
    setTimeout(() => window.dispatchEvent(new Event('resize'))) // making the repositionOnUpdate wait

    const editMode = initialValues !== null

    return (
      <form onSubmit={handleSubmit} id="gallery-form">
        <SectionHeader>Basic data</SectionHeader>
        <QuillWrapper>
          <Field component={GalleryTitleInput} name="title" />
        </QuillWrapper>
        <StyledFlex style={{ gap: '16px' }}>
          <Field component={DefaultSelectField} label="Gallery layout" name="layout" fullWidth required>
            <MenuItem value="Standard">Standard</MenuItem>
            <MenuItem value="Tile">Tile</MenuItem>
          </Field>
          <Field
            component={DefaultSelectField}
            label="Gallery type"
            name="galleryType"
            fullWidth
            required
            disabled={editMode}
          >
            <MenuItem value="Image">Image</MenuItem>
            <MenuItem value="Location">Location</MenuItem>
          </Field>
          <Field component={DefaultSelectField} label="Context" name="priority" fullWidth>
            <MenuItem value={1}>Enhanced</MenuItem>
            <MenuItem value={2}>Standard</MenuItem>
            <MenuItem value={3}> Supplemental </MenuItem>
          </Field>
        </StyledFlex>
        <StyledFlex></StyledFlex>

        <StyledFlex style={{ justifyContent: 'flex-end' }}>
          <Button type="submit">{editMode ? 'Continue' : 'Add slide'}</Button>
        </StyledFlex>
      </form>
    )
  }
}

export default compose(
  connect(),
  reduxForm({
    form: 'galleryData',
    asyncBlurFields: [],
    validate: validateForm,
  })
)(GalleryData)
