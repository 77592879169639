import { Suspense, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Autocomplete from '@mui/material/Autocomplete'
import { observer } from 'mobx-react-lite'

import { Form } from '../styles'
import SaveLessonChanges from '../SaveLessonChanges'
import StyledFlex, { StyledColumn } from '../../../../../layouts/StyledLayouts'
import Loader from 'components/new-pages/Loader'

import ToolsForm from '../FormComponents/ToolsForm'
import PhaseDropdown from '../FormComponents/PhaseDropdown'
import InstructorsDropdown from '../FormComponents/InstructorsDropdown'
import LessonImageComponent from '../FormComponents/LessonImageComponent'

const generateDefaultFormData = () => ({
  title: '',
  optional: false,
  active: true,
  image: '',

  phase: '',
  tools: [],
})

const EditDownloadLesson = ({ cls, moduleId, activeLesson, activeLessonIdx }) => {
  const [formData, setFormData] = useState(generateDefaultFormData())

  const [termsInputVal, setTermsInputVal] = useState('')

  useEffect(() => {
    if (activeLesson) {
      setFormData({ ...activeLesson })
      setTermsInputVal(activeLesson.termId ? cls.terms.find((t) => t.id === activeLesson.termId)?.title : '')
    }
  }, [activeLesson, setTermsInputVal, cls, activeLessonIdx])

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value })
  }

  const handleChangeNegatedBooolean = (event) => {
    const { name, value, type, checked } = event.target
    setFormData({ ...formData, [name]: type === 'checkbox' ? !checked : value })
  }

  const handleAutocompleteFieldChange = (event, value, fieldName) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <SaveLessonChanges
        classId={cls.id}
        moduleId={moduleId}
        lessonId={activeLesson?.id}
        formData={{ ...formData, type: 'Download', moduleId, classId: cls.id }}
      />
      <Form component="form" id="download-form" onSubmit={handleSubmit}>
        <StyledColumn style={{ gap: '12px' }}>
          <StyledFlex style={{ flex: 1 }}>
            <LessonImageComponent image={formData.image} setImage={(image) => setFormData((p) => ({ ...p, image }))} />
          </StyledFlex>

          <StyledFlex style={{ gap: '16px' }}>
            <TextField
              value={formData.title}
              name="title"
              type="text"
              label="Title"
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Title' }}
              variant="standard"
              fullWidth
              sx={{ flex: 1 / 3 }}
            />
            {cls.terms?.length > 0 && (
              <Autocomplete
                data-testid="videoLessontermsDropdown"
                name="termId"
                value={cls.terms.find((t) => t.id === formData.termId) || null}
                options={cls.terms}
                getOptionLabel={(option) => option?.title || ''}
                renderInput={(params) => <TextField {...params} label="Select Term" variant="standard" />}
                inputValue={termsInputVal}
                onChange={(e, v) => setFormData({ ...formData, termId: v?.id })}
                onInputChange={(e, v) => setTermsInputVal(v)}
                fullWidth
                sx={{ flex: 1 / 3 }}
              />
            )}
          </StyledFlex>

          <StyledFlex style={{ gap: '16px' }}>
            <FormControlLabel
              control={<Switch checked={formData.active} onChange={handleChange} name="active" color="primary" />}
              label="Active"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={!formData.optional}
                  onChange={handleChangeNegatedBooolean}
                  name="optional"
                  color="primary"
                />
              }
              label="Required for completion"
            />
          </StyledFlex>
          <StyledFlex style={{ gap: '16px' }}>
            <Suspense fallback={<Loader />}>
              <PhaseDropdown
                cls={cls}
                moduleId={moduleId}
                formData={formData}
                handleAutocompleteFieldChange={handleAutocompleteFieldChange}
              />
              <InstructorsDropdown
                selectedInstructorId={formData.instructorId}
                classInstructorIds={cls.instructors}
                handleChange={handleAutocompleteFieldChange}
              />
              <ToolsForm
                selectedValues={formData.tools}
                options={cls.tools}
                handleChange={handleAutocompleteFieldChange}
              />
            </Suspense>
          </StyledFlex>
        </StyledColumn>
      </Form>
    </>
  )
}

EditDownloadLesson.propTypes = {
  cls: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
  activeLesson: PropTypes.object,
  activeLessonIdx: PropTypes.number,
  lessons: PropTypes.array,
}

export default observer(EditDownloadLesson)
