import React from 'react'
import PropTypes from 'prop-types'
import Select from '@mui/material/Select'
import { FormControl } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'

const DefaultSelectField = ({ input, label, defaultValue, meta: { touched, error }, children, ...custom }) => (
  <FormControl variant="standard" fullWidth error={!!error}>
    <InputLabel id={`${label.replace(/\s/g, '')}-label`}>{label}</InputLabel>
    <Select
      labelId={`${label.replace(/\s/g, '')}-label`}
      value={input.value || defaultValue || ''}
      onChange={(event) => input.onChange(event.target.value)}
      children={children}
      {...custom}
    />
    {error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
)

DefaultSelectField.propTypes = {
  children: PropTypes.node,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default DefaultSelectField
