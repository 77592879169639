import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { v4 as uuidv4 } from 'uuid'

export const signOutCognitoQA = () => {
  const { REACT_APP_COGNITO_USER_POOL_ID = '', REACT_APP_COGNITO_CLIENT_ID = '' } = process.env

  const poolData = {
    UserPoolId: REACT_APP_COGNITO_USER_POOL_ID, // Your user pool id here
    ClientId: REACT_APP_COGNITO_CLIENT_ID, // Your client id here
  }
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
  const cognitoUser = userPool.getCurrentUser()
  cognitoUser?.signOut()
}

const initCognitoSdkQA = (email: string, password: string, loadProfile: () => void, history: any) => {
  const {
    REACT_APP_COGNITO_USER_POOL_ID = '',
    REACT_APP_COGNITO_CLIENT_ID = '',
    REACT_APP_COGNITO_IDENTITY_POOL_ID = '',
  } = process.env

  const authenticationData = {
    Username: email,
    Password: password,
  }

  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)

  const poolData = {
    UserPoolId: REACT_APP_COGNITO_USER_POOL_ID, // Your user pool id here
    ClientId: REACT_APP_COGNITO_CLIENT_ID, // Your client id here
  }

  if (!(poolData.UserPoolId && poolData.ClientId)) {
    throw new Error('Missing pool data!')
  }

  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

  const userData = {
    Username: email,
    Pool: userPool,
  }

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: function (result) {
      const idToken = result.getIdToken().getJwtToken()
      const refreshToken = result.getRefreshToken().getToken()

      sessionStorage.removeItem('justSignedOut')
      sessionStorage.setItem('id_token', idToken)
      sessionStorage.setItem('tester_id', email)
      sessionStorage.setItem('refresh_token', refreshToken)

      sessionStorage.setItem('session_id', uuidv4())

      if (loadProfile) {
        loadProfile()
      }

      if (history) {
        history.replace('/')
      }
    },
    onFailure: function (err) {
      sessionStorage.removeItem('id_token')
      sessionStorage.removeItem('session_id')
      localStorage.clear()
      // history.replace('/login')
      alert(err.message || JSON.stringify(err))
    },
  })

  return cognitoUser
}

export default initCognitoSdkQA
