import { RSAA } from 'redux-api-middleware'
import cloneDeep from 'lodash/cloneDeep'

const { REACT_APP_MBG_SITE } = process.env

const authMiddleware = (store) => (next) => (action) => {
  const values = action[RSAA]
  const token = sessionStorage.getItem('id_token')
  if (values && !values.endpoint.includes(`${REACT_APP_MBG_SITE}`)) {
    if (values.headers) {
      values.headers.Authorization = token
    } else {
      values.headers = {
        Authorization: token,
      }
    }

    // assign original action in case of unauthorized error
    const origAction = cloneDeep(values)

    // update meta field of failure type
    const failureType = values.types[2]
    if (failureType === Object(failureType)) {
      if (failureType.meta) failureType.meta.originalAction = origAction
      else failureType.meta = { originalAction: origAction }
    } else {
      values.types[2] = {
        type: values.types[2],
        meta: {
          originalAction: origAction,
        },
      }
    }

    return next({
      [RSAA]: {
        ...values,
      },
    })
  } else {
    return next(action)
  }
}

export default authMiddleware
