import { useEffect, useRef } from 'react'
import { onValue, ref } from 'firebase/database'

import firebase from 'utils/firebase'
import { isAfter } from 'date-fns'

interface Args {
  articleId: string
  refreshArticle(route: string, id: string): void
  refreshKey: string
}

const useRefreshArticle = ({ articleId, refreshKey, refreshArticle }: Args) => {
  const lastUpdatedAt = useRef(new Date())

  const getUpdateRef = (key: string) => ref(firebase.database, key)

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return
    }

    const ref = getUpdateRef(`/lastUpdates/${refreshKey}/${articleId}`)

    const unsub = onValue(ref, (snapshot) => {
      const updatedAt = new Date(snapshot.val())

      if (isAfter(updatedAt, lastUpdatedAt.current)) {
        lastUpdatedAt.current = updatedAt

        refreshArticle(refreshKey, articleId)
      }
    })

    return () => {
      unsub()
    }
  }, [articleId, refreshKey, refreshArticle])
}

export default useRefreshArticle
