import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { connect } from 'react-redux'
import { change } from 'redux-form'

import { toggleSeriesDialog, addSerie } from 'actions/seriesActions'
import { LoaderContainer } from '../../../ui/LoaderContainer'

const validateSeries = (seriesName, series) => {
  const alreadyExist = series.find((s) => s.get('name') === seriesName)
  return alreadyExist ? alreadyExist.get('id') : null
}

const SeriesDialog = ({ open, toggleSeriesDialog, seriesName, series, addSerie, dialogLoading, updateSeriesId }) => {
  return (
    <Dialog open={open} onClose={toggleSeriesDialog}>
      <DialogContent onClose={toggleSeriesDialog}>
        <LoaderContainer isLoading={dialogLoading}>Do you want to create a new "{seriesName}" series?</LoaderContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleSeriesDialog} disabled={dialogLoading}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            const alreadyExist = validateSeries(seriesName, series)
            if (!alreadyExist) addSerie(seriesName)
            else {
              updateSeriesId(alreadyExist)
              toggleSeriesDialog()
            }
          }}
          disabled={dialogLoading}
        >
          Create a new series
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SeriesDialog.propTypes = {
  open: PropTypes.bool,
  toggleSeriesDialog: PropTypes.func,
  seriesName: PropTypes.string,
  series: PropTypes.object,
  addSerie: PropTypes.func,
  dialogLoading: PropTypes.bool,
  updateSeriesId: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    open: state.series.get('showDialog'),
    seriesName: state.series.get('seriesName'),
    series: state.series.get('series'),
    dialogLoading: state.series.get('dialogLoading'),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSeriesDialog: () => dispatch(toggleSeriesDialog(false)),
    addSerie: (name) => dispatch(addSerie({ name })),
    updateSeriesId: (id) => dispatch(change('createArticleMetadataForm', 'seriesId', id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeriesDialog)
