import { useQuery } from 'react-query'
import { fetchVideos } from '../api/videos'
import { QueryKeys } from '../utils/QueryKeys'

const useFetchVideos = (args) => {
  const query = useQuery([QueryKeys.video, args], () => fetchVideos(args.searchTerm), {
    enabled: !!args.searchTerm.length,
  })

  return query
}

export default useFetchVideos
