import { Map } from 'immutable'
import { TOGGLE_TAGS_DIALOG } from 'utils/constants/actions'

// is there an initial state? clean it up and clean these actions
const tagsReducer = (state = Map(), action) => {
  switch (action.type) {
    case TOGGLE_TAGS_DIALOG:
      return state.set('showDialog', action.open)
    default:
      return state
  }
}

export default tagsReducer
