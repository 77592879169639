import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Paper from '@mui/material/Paper'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import { v4 as uuidv4 } from 'uuid'
import { observer } from 'mobx-react-lite'
import { useStore } from 'store/mobx'

import Loader from 'components/new-pages/Loader'
import RemoveInstanceDialog from './RemoveInstanceDialog'
import { Form, RemoveInstanceBtn } from '../styles'
import SaveLessonChanges from '../SaveLessonChanges'
import StyledFlex, { StyledColumn } from '../../../../../layouts/StyledLayouts'
import LessonImageComponent from '../FormComponents/LessonImageComponent'
import { getNyTime, getUTCTime } from '../../../../../utils/formatDate'
import AlertDialog from 'components/new-pages/AlertDialog'

const generateInstance = () => ({
  id: uuidv4(),
  host: '',
  link: '',
  videoId: '',
  startDate: new Date(),
})

const defaultFormData = {
  title: '',
  position: 0,
  optional: false,
  active: true,
  image: '',
  termId: null,
  instances: [generateInstance()],
}

const EditLiveLesson = ({ cls, moduleId, activeLesson, activeLessonIdx }) => {
  const showDialogOnceRef = useRef(null)
  const { loading, error, saveLiveLesson } = useStore('editLiveLessonStore')
  const [formData, setFormData] = useState({ ...defaultFormData, ...activeLesson, position: activeLessonIdx || 0 })
  const [showDialog, setShowDialog] = useState(false)

  const [removeInstanceConfirmationVisible, toggleRemoveInstanceConfirmation] = useState(false)
  const [activeInstanceIdx, setActiveInstanceIdx] = useState(-1)
  const [termsInputVal, setTermsInputVal] = useState(
    activeLesson?.termId ? cls.terms.find((t) => t.id === activeLesson.termId)?.title : ''
  )

  const addInstance = () => {
    const newInstances = [...formData.instances]
    newInstances.push(generateInstance())
    setFormData({
      ...formData,
      instances: newInstances,
    })
  }

  const removeInstance = (idx) => {
    const newInstances = [...formData.instances]
    newInstances.splice(idx, 1)
    setFormData({
      ...formData,
      instances: newInstances,
    })
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value })
  }

  const handleChangeNegatedBooolean = (event) => {
    const { name, value, type, checked } = event.target
    setFormData({ ...formData, [name]: type === 'checkbox' ? !checked : value })
  }

  const handleChangeInstance = (event, index) => {
    const { value } = event.target
    const newInstances = [...formData.instances]
    let updatedInstance = newInstances[index]
    updatedInstance = { ...updatedInstance, [event.target.getAttribute('valuename')]: value }

    newInstances.splice(index, 1, updatedInstance)

    setFormData({ ...formData, instances: newInstances })
  }

  const handleChangeDate = (date, name, index) => {
    const newInstances = [...formData.instances]
    let updatedInstance = newInstances[index]
    updatedInstance = { ...updatedInstance, [name]: date }

    newInstances.splice(index, 1, updatedInstance)

    setFormData({ ...formData, instances: newInstances })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = { ...formData, moduleId, classId: cls.id }

    saveLiveLesson(data, !!data.id)
  }

  const videoIdPresent = formData.instances.some((instance) => instance.videoId)

  return (
    <>
      <SaveLessonChanges
        classId={cls.id}
        moduleId={moduleId}
        lessonId={activeLesson?.id}
        formData={{ ...formData, type: 'Live Session', moduleId, classId: cls.id }}
      />
      <Form component="form" id="live-lesson-form" onSubmit={handleSubmit}>
        {loading && <Loader />}
        <StyledColumn style={{ gap: '12px' }}>
          {error?.message && (
            <Alert severity="error" variant="filled">
              {error.message}
            </Alert>
          )}
          <StyledFlex style={{ justifyContent: 'flex-start' }}>
            <LessonImageComponent image={formData.image} setImage={(image) => setFormData((p) => ({ ...p, image }))} />
          </StyledFlex>

          <StyledFlex style={{ gap: '16px' }}>
            <TextField
              variant="standard"
              value={formData.title}
              fullWidth
              name="title"
              type="text"
              label="Title"
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Title' }}
              sx={{ flex: 1 / 3 }}
            />
            {cls.terms?.length > 0 && (
              <Autocomplete
                data-testid="liveLessontermsDropdown"
                name="termId"
                disabled={videoIdPresent}
                value={cls.terms.find((t) => t.id === formData.termId) || null}
                options={cls.terms}
                getOptionLabel={(option) => option?.title || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Select Term"
                    onClick={() => {
                      if (!showDialogOnceRef.current) {
                        setShowDialog(true)
                        return
                      }
                    }}
                  />
                )}
                inputValue={termsInputVal}
                onChange={(e, v) => setFormData({ ...formData, termId: v?.id })}
                onInputChange={(e, v) => setTermsInputVal(v)}
                sx={{ flex: 1 / 3 }}
              />
            )}
          </StyledFlex>
          <StyledFlex style={{ gap: '16px' }}>
            <FormControlLabel
              control={<Switch checked={formData.active} onChange={handleChange} name="active" color="primary" />}
              label="Active"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={!formData.optional}
                  onChange={handleChangeNegatedBooolean}
                  name="optional"
                  color="primary"
                />
              }
              label="Required for completion"
            />
          </StyledFlex>
          <div data-testid="liveLessonInstances" style={{ width: '67%' }}>
            <StyledColumn style={{ gap: '12px' }}>
              {formData.instances.map((instance, idx) => (
                <Paper key={instance.id} sx={{ position: 'relative', padding: '24px 16px' }}>
                  <StyledColumn style={{ gap: '12px' }}>
                    {formData.instances.length > 1 && (
                      <RemoveInstanceBtn
                        onClick={() => {
                          setActiveInstanceIdx(idx)
                          toggleRemoveInstanceConfirmation(true)
                        }}
                        aria-label="Close"
                      >
                        <CloseIcon />
                      </RemoveInstanceBtn>
                    )}
                    <StyledFlex style={{ gap: '16px' }}>
                      <TextField
                        value={instance.host}
                        fullWidth
                        type="text"
                        label="Host"
                        name="host"
                        onChange={(e) => handleChangeInstance(e, idx)}
                        // using only names blocks the UI in dev
                        inputProps={{ 'aria-label': 'Host', valuename: 'host' }}
                        variant="standard"
                      />
                      <TextField
                        value={instance.link}
                        fullWidth
                        type="text"
                        label="Link"
                        name="link"
                        // using only names blocks the UI in dev
                        inputProps={{ 'aria-label': 'Link', valuename: 'link' }}
                        onChange={(e) => handleChangeInstance(e, idx)}
                        variant="standard"
                      />
                    </StyledFlex>
                    <StyledFlex style={{ gap: '16px' }}>
                      <TextField
                        value={instance.videoId}
                        fullWidth
                        type="text"
                        label="Video ID"
                        name="videoId"
                        inputProps={{ 'aria-label': 'Video ID', valuename: 'videoId' }}
                        onChange={(e) => handleChangeInstance(e, idx)}
                        variant="standard"
                      />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          name="startDate"
                          variant="inline"
                          ampm
                          label="Start Date"
                          value={getNyTime(instance.startDate)}
                          onChange={(date) => handleChangeDate(getUTCTime(date), 'startDate', idx)}
                          renderInput={(params) => (
                            <TextField name="startDate" {...params} fullWidth variant="standard" />
                          )}
                          fullWidth
                        />
                      </LocalizationProvider>
                    </StyledFlex>
                  </StyledColumn>
                </Paper>
              ))}
            </StyledColumn>
          </div>

          <div>
            <Button onClick={addInstance}>Add Instance</Button>
          </div>
        </StyledColumn>

        {removeInstanceConfirmationVisible && activeInstanceIdx !== -1 && (
          <RemoveInstanceDialog
            open={removeInstanceConfirmationVisible}
            instanceIdx={activeInstanceIdx}
            toggleDialog={toggleRemoveInstanceConfirmation}
            removeInstance={removeInstance}
          />
        )}
        <AlertDialog
          open={!showDialogOnceRef.current && showDialog}
          title="Confirm action"
          // eslint-disable-next-line max-len
          text="Changing the term is not recommended because it will affect progress tracking for the users."
          handleOk={() => {
            setShowDialog(false)
            showDialogOnceRef.current = true
          }}
        />
      </Form>
    </>
  )
}

EditLiveLesson.propTypes = {
  cls: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
  activeLesson: PropTypes.object,
  activeLessonIdx: PropTypes.number,
  lessons: PropTypes.array,
}

export default observer(EditLiveLesson)
