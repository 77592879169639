import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { PropTypes } from 'prop-types'
import { useState } from 'react'
import { useStore } from 'store/mobx'
import { logError } from '../../../../utils/errorHandling'

import { ModulePropType } from './ModulePropType'
import WithProgress from '../../../../ui/WithProgress'
import styled from 'styled-components'
import CommonDialogTitle from '../../../Common/DialogComponents/CommonDialogTitle'

const DialogActionsComponent = (props) => (
  <DialogActions>
    <Button disabled={props.isLoading} color="secondary" onClick={props.handleCancel}>
      Cancel
    </Button>
    <WithProgress isLoading={props.isLoading}>
      <Button disabled={props.isLoading} onClick={props.handleConfirm}>
        Confirm
      </Button>
    </WithProgress>
  </DialogActions>
)

DialogActions.propTypes = {
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
}

const InnerContentWrapper = styled.div`
  padding: 8px 0 0;
`

const DialogContentComponent = (props) => {
  return (
    <DialogContent>
      <InnerContentWrapper>
        <TextField
          value={props.title}
          onChange={(e) => props.setTitle(e.currentTarget.value)}
          fullWidth
          name="title"
          type="text"
          label="Title"
          inputProps={{ 'aria-label': 'Title' }}
        />
      </InnerContentWrapper>
    </DialogContent>
  )
}

DialogContentComponent.propTypes = {
  title: PropTypes.string,
  setTitle: PropTypes.func,
}

export const ModuleEditDialog = (props) => {
  const [title, setTitle] = useState(props.module.title)
  const [isLoading, setIsLoading] = useState(false)

  const { classes } = useStore('classesStore')
  const currentClass = classes.find((item) => item.id === props.classId)

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      await currentClass.mutateModule({ ...props.module, title })
      props.handleDialogClose()
    } catch (error) {
      logError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog fullWidth open={props.isOpen} onClose={props.handleDialogClose}>
      <CommonDialogTitle onClose={props.handleDialogClose}>Edit Module {props.module.id}</CommonDialogTitle>
      <DialogContentComponent title={title} setTitle={setTitle} />
      <DialogActionsComponent
        isLoading={isLoading}
        handleCancel={props.handleDialogClose}
        handleConfirm={handleConfirm}
      />
    </Dialog>
  )
}

ModuleEditDialog.propTypes = {
  module: ModulePropType,
  isOpen: PropTypes.bool,
  handleDialogClose: PropTypes.func,
  classId: PropTypes.number,
}

export const CreateModuleDialog = (props) => {
  const [title, setTitle] = useState('')
  const [objectType, setObjectType] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const classesStore = useStore('classesStore')
  const classItem = classesStore.classes?.find((item) => item.id === props.classId)

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      await classItem.createModule(props.classId, { title, objectType })
      props.handleDialogClose()
    } catch (error) {
      logError(error)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Dialog fullWidth open={props.isOpen} onClose={props.handleDialogClose}>
      <CommonDialogTitle onClose={props.handleDialogClose}>Create a New Module</CommonDialogTitle>
      <DialogContentComponent title={title} setTitle={setTitle} objectType={objectType} setObjectType={setObjectType} />
      <DialogActionsComponent
        isLoading={isLoading}
        handleCancel={props.handleDialogClose}
        handleConfirm={handleConfirm}
      />
    </Dialog>
  )
}

CreateModuleDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleDialogClose: PropTypes.func,
  classId: PropTypes.number,
}
