export default function createPreliminaryCrops(key, crops) {
  key = key.split('/').splice(1).join('/') // temporary
  return crops.map((crop) => {
    const { width, aspect } = crop
    const height = Math.round(width / aspect)
    return {
      width: width,
      height: height,
      cropStart: {
        x: 0,
        y: 0,
      },
      cropEnd: {
        x: width,
        y: height,
      },
      path: `https://mindbodygreen-res.cloudinary.com/image/upload/g_auto,c_fill,w_${width},h_${height}/${key}`,
    }
  })
}
