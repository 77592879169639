import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import InsertKeywordDialog from 'ui/InsertKeyword'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import KeyIcon from '@mui/icons-material/VpnKey'

const keywordToolbarButton = (
  <Tooltip title="Keyword">
    <IconButton className="ql-key">
      <KeyIcon />
    </IconButton>
  </Tooltip>
)

const QuillInsertKeyword = ({ children }) => {
  const quill = useRef()
  const searchTerm = useRef()
  const [selection, setSelection] = useState(null)
  const [showDialog, setShowDialog] = useState(false)

  const insertKeywordLink = (url) => {
    const editor = quill.current
    editor.formatText(selection.index, selection.length, {
      link: { url },
    })

    searchTerm.current = null
    setSelection(null)
    setShowDialog(false)
  }

  const handleSelectionChange = (range, source, editor) => {
    if (range && range.length) {
      setSelection(range)
      searchTerm.current = editor.getText(range.index, range.length)
    } else searchTerm.current = null
  }

  return (
    <div>
      {showDialog && (
        <InsertKeywordDialog
          searchTerm={searchTerm.current}
          onCancel={() => setShowDialog(false)}
          onUrlSelected={insertKeywordLink}
        />
      )}
      {children({
        keywordHandler: { key: () => searchTerm && setShowDialog(true) },
        keywordFormat: 'key',
        keywordToolbarButton,
        onEditorRef: (el) => (quill.current = el),
        onChangeSelection: handleSelectionChange,
      })}
    </div>
  )
}

QuillInsertKeyword.propTypes = {
  children: PropTypes.func,
}

export default QuillInsertKeyword
