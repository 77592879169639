import { CREATE_ARTICLE_MOVE_TO_COMPONENT } from 'utils/constants/actions'

const moveWithArrows = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_MOVE_TO_COMPONENT) {
    if (action.index) {
      const { createArticleBody } = store.getState()
      const components = createArticleBody.getIn(['present', 'components'])

      if (action.goingDown) {
        const sliced = components.slice(action.index)
        const nextIndex = sliced.findIndex((c) => c.get('type') === 'text')
        if (nextIndex) action.index += nextIndex
        else action.index--
      } else {
        const sliced = components.slice(0, action.index + 1)
        const slicedReversed = components.slice(0, action.index + 1).reverse()
        const nextComp = slicedReversed.find((c) => c.get('type') === 'text')
        if (nextComp) {
          const nextIndex = sliced.findIndex((c) => c.get('id') === nextComp.get('id'))
          action.index = nextIndex
        } else action.index++
      }
    }
  }

  return next(action)
}

export default moveWithArrows
