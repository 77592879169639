import { LOAD_VERSION_FILE_SUCCESS } from 'utils/constants/actions'

import { toggleShowVersionNotice } from 'actions/navbarActions'

const checkCMSVersion = (store) => (next) => (action) => {
  if (action.type === LOAD_VERSION_FILE_SUCCESS) {
    const { navbar } = store.getState()
    const oldVersion = navbar.getIn(['navbarPrefs', 'currentVersion'])
    const newVersion = action.payload.version

    if (oldVersion && oldVersion < newVersion) {
      store.dispatch(toggleShowVersionNotice(true))
    }
  }

  return next(action)
}

export default checkCMSVersion
