import { Map, fromJS } from 'immutable'
import {
  CREATE_ARTICLE_INSERT_COMPONENT,
  CREATE_ARTICLE_UPDATE_METADATA,
  CREATE_ARTICLE_SAVE_FOCUSED_DATA,
  CREATE_ARTICLE_INSERT_SPLITTED_COMPONENT,
  CREATE_ARTICLE_ON_COMPONENT_DROP,
  CREATE_ARTICLE_INSERT_DRAGGED_COMPONENT,
  CREATE_ARTICLE_MOVE_TO_COMPONENT,
  CREATE_ARTICLE_TOGGLE_EDIT_DIALOG,
  CREATE_ARTICLE_FETCH_ARTICLE_REQUEST,
  CREATE_ARTICLE_FETCH_ARTICLE_SUCCESS,
  CREATE_ARTICLE_FETCH_ARTICLE_FAILURE,
  CREATE_PODCAST_FETCH_PODCAST_REQUEST,
  CREATE_PODCAST_FETCH_PODCAST_SUCCESS,
  CREATE_PODCAST_FETCH_PODCAST_FAILURE,
  CREATE_ARTICLE_SEND_ARTICLE_REQUEST,
  CREATE_ARTICLE_SEND_ARTICLE_SUCCESS,
  CREATE_ARTICLE_SEND_ARTICLE_FAILURE,
  CREATE_ARTICLE_SUBMIT_ARTICLE,
  CREATE_ARTICLE_HIDE_ERROR_MESSAGE,
  CREATE_ARTICLE_FORM_ERROR,
  CREATE_ARTICLE_SHOW_FLASH_MESSAGE,
  CREATE_ARTICLE_SHOULD_SAVE_DRAFT,
  CREATE_ARTICLE_RESET_SUBMIT_STATUS,
  CREATE_ARTICLE_RESET_STATE,
  CREATE_ARTICLE_ADD_KEY_QUOTE,
  CREATE_ARTICLE_TOGGLE_PUBLISH_DIALOG,
  CREATE_ARTICLE_PUBLISH_ARTICLE_REQUEST,
  CREATE_ARTICLE_PUBLISH_ARTICLE_SUCCESS,
  CREATE_ARTICLE_PUBLISH_ARTICLE_FAILURE,
  CREATE_ARTICLE_UNPUBLISH_ARTICLE_REQUEST,
  CREATE_ARTICLE_UNPUBLISH_ARTICLE_SUCCESS,
  CREATE_ARTICLE_UNPUBLISH_ARTICLE_FAILURE,
  CREATE_ARTICLE_USER_STARTED_TYPING,
  CREATE_ARTICLE_TOGGLE_UNPUBLISH_DIALOG,
  CREATE_ARTICLE_SET_INSERTION_TARGET,
  CREATE_ARTICLE_CONVERT_TO_COMPONENT,
  CREATE_ARTICLE_LOAD_VERSION,
  CREATE_ARTICLE_TOGGLE_CONFIRM_DIALOG,
  CREATE_ARTICLE_INBOUND_ARTICLES_REQUEST,
  CREATE_ARTICLE_INBOUND_ARTICLES_SUCCESS,
  CREATE_ARTICLE_INBOUND_ARTICLES_FAILURE,
  TOGGLE_INBOUND_LINK_UPDATE_DIALOG,
  CREATE_ARTICLE_UPDATE_INBOUND_LINKS_REQUEST,
  CREATE_ARTICLE_UPDATE_INBOUND_LINKS_SUCCESS,
  CREATE_ARTICLE_UPDATE_INBOUND_LINKS_FAILURE,
  CREATE_ARTICLE_PUBLISH_MULTIPLE_REQUEST,
  CREATE_ARTICLE_PUBLISH_MULTIPLE_SUCCESS,
  CREATE_ARTICLE_PUBLISH_MULTIPLE_FAILURE,
  CREATE_ARTICLE_HIGHLIGHT_SAME_LINKS,
  CREATE_ARTICLE_TOGGLE_USER_SUBMIT,
  CREATE_ARTICLE_ENABLE_AUTOSAVE,
  CREATE_ARTICLE_DISABLE_AUTOSAVE,
  UNARCHIVE_ARTICLE_REQUEST,
  UNARCHIVE_ARTICLE_SUCCESS,
  UNARCHIVE_ARTICLE_FAILURE,
  CREATE_PODCAST_SEND_PODCAST_REQUEST,
  CREATE_PODCAST_SEND_PODCAST_SUCCESS,
  CREATE_PODCAST_SEND_PODCAST_FAILURE,
  CREATE_ARTICLE_TOGGLE_ERROR_DIALOG,
  PUBLISH_PODCAST_REQUEST,
  PUBLISH_PODCAST_SUCCESS,
  PUBLISH_PODCAST_FAILURE,
  UNPUBLISH_PODCAST_REQUEST,
  UNPUBLISH_PODCAST_SUCCESS,
  UNPUBLISH_PODCAST_FAILURE,
  CREATE_ARTICLE_LINK_SEARCH,
  CREATE_ARTICLE_CHANGE_ACTIVE_SEARCH_LINK,
  CREATE_ARTICLE_INSERT_COMPONENT_FROM_MODAL,
  CREATE_ARTICLE_SET_LINK_SEARCH_PATTERN,
} from 'utils/constants/actions'

import { initialCreateArticleInteractionsState } from 'store/initialState/initialCreateArticleInteractionsState'

const createArticleInteractionsReducer = (state = Map(), action) => {
  switch (action.type) {
    case CREATE_ARTICLE_INSERT_COMPONENT:
      return state.set('split', action.split)

    case CREATE_ARTICLE_INSERT_SPLITTED_COMPONENT:
      return state.set('split', false)

    case CREATE_ARTICLE_UPDATE_METADATA:
      return state.setIn(['metadata', action.field], action.value)

    case CREATE_ARTICLE_SAVE_FOCUSED_DATA:
      return state
        .setIn(['focusedComponent', 'index'], action.index)
        .setIn(['focusedComponent', 'cursorPosition'], action.cursorPosition)
        .setIn(['focusedComponent', 'length'], action.length)

    case CREATE_ARTICLE_ON_COMPONENT_DROP:
      return state
        .setIn(['drop', 'draggedItemIndex'], action.draggedItemIndex)
        .setIn(['drop', 'targetItemIndex'], action.targetItemIndex)

    case CREATE_ARTICLE_INSERT_DRAGGED_COMPONENT:
      return state.setIn(['drop', 'draggedItemIndex'], null).setIn(['drop', 'targetItemIndex'], null)

    case CREATE_ARTICLE_MOVE_TO_COMPONENT:
      return state.set('focusTo', action.index).set('focusGoingDown', action.goingDown)

    case CREATE_ARTICLE_TOGGLE_CONFIRM_DIALOG:
      if (action.namespace === 'deleteComponent') return state.set('deleteComponent', action.extra)
      return state

    case CREATE_ARTICLE_TOGGLE_EDIT_DIALOG:
      return state
        .setIn(['edit', 'componentIndex'], action.index)
        .setIn(['edit', 'show'], action.show)
        .setIn(['edit', 'type'], action.componentType)
        .setIn(['edit', 'imageType'], action.imageType)

    case CREATE_ARTICLE_FETCH_ARTICLE_REQUEST:
    case CREATE_PODCAST_FETCH_PODCAST_REQUEST:
      return state.set('loading', true)

    case CREATE_ARTICLE_FETCH_ARTICLE_FAILURE:
    case CREATE_PODCAST_FETCH_PODCAST_FAILURE:
      // if (action.payload.status === 404) browserHistory.goBack()
      return state.set('loading', false)

    case CREATE_ARTICLE_FETCH_ARTICLE_SUCCESS:
    case CREATE_PODCAST_FETCH_PODCAST_SUCCESS:
      return state
        .set('loading', false)
        .set('lastSavedState', fromJS(action.payload.json))
        .set('showUnpublishBtn', action.payload.showUnpublishBtn)

    case CREATE_ARTICLE_SEND_ARTICLE_REQUEST:
    case CREATE_PODCAST_SEND_PODCAST_REQUEST:
      return state.set('saving', true)

    case CREATE_ARTICLE_SEND_ARTICLE_SUCCESS:
    case CREATE_PODCAST_SEND_PODCAST_SUCCESS:
      let updatedState = state.set('saving', false).set('saved', true).set('versionLoaded', false)

      if (action.meta) {
        updatedState = updatedState.set('lastSavedState', fromJS(action.meta.article))
      }

      return updatedState
    case CREATE_ARTICLE_SEND_ARTICLE_FAILURE:
    case CREATE_PODCAST_SEND_PODCAST_FAILURE:
      return state
        .set('saving', false)
        .set('saved', false)
        .set('showErrorDialog', true)
        .set('submitError', action.payload.response)
        .set('flashMessage', 'Submission failure: ' + action.payload.response.message)

    case CREATE_ARTICLE_PUBLISH_ARTICLE_REQUEST:
    case PUBLISH_PODCAST_REQUEST:
      return state.set('publishing', true)

    case CREATE_ARTICLE_PUBLISH_ARTICLE_SUCCESS:
    case PUBLISH_PODCAST_SUCCESS:
      return state.set('publishing', false).set('published', true).set('showUnpublishBtn', true)
    // .set('flashMessage', 'Article has been saved successfully')

    case CREATE_ARTICLE_PUBLISH_MULTIPLE_REQUEST:
      return state.set('publishingMultiple', true)

    case CREATE_ARTICLE_PUBLISH_MULTIPLE_SUCCESS:
    case CREATE_ARTICLE_PUBLISH_MULTIPLE_FAILURE:
      return state.set('publishingMultiple', false)

    case CREATE_ARTICLE_PUBLISH_ARTICLE_FAILURE:
    case PUBLISH_PODCAST_FAILURE:
      return state
        .set('publishing', false)
        .set('published', false)
        .set('flashMessage', 'Submission failure: ' + action.payload.response.message)

    case CREATE_ARTICLE_UNPUBLISH_ARTICLE_REQUEST:
    case UNPUBLISH_PODCAST_REQUEST:
      return state.set('unpublishing', true)

    case CREATE_ARTICLE_UNPUBLISH_ARTICLE_SUCCESS:
    case UNPUBLISH_PODCAST_SUCCESS:
      return state.set('unpublishing', false).set('unpublished', true).set('showUnpublishBtn', false)

    case CREATE_ARTICLE_UNPUBLISH_ARTICLE_FAILURE:
    case UNPUBLISH_PODCAST_FAILURE:
      return state
        .set('unpublishing', false)
        .set('unpublished', false)
        .set('flashMessage', 'Submission failure: ' + action.payload.response.message)

    case CREATE_ARTICLE_SUBMIT_ARTICLE:
      return state.set('error', action.error).set('flashMessage', action.flashMessage)

    case CREATE_ARTICLE_SHOW_FLASH_MESSAGE:
      return state.set('flashMessage', action.flashMessage)

    case CREATE_ARTICLE_HIDE_ERROR_MESSAGE:
      return state.set('error', false).set('flashMessage', '')

    case CREATE_ARTICLE_FORM_ERROR:
      return state.set('error', true).set('flashMessage', action.message)

    case CREATE_ARTICLE_SHOULD_SAVE_DRAFT:
      return state.set('shouldSaveDraft', action.flag).set('loading', !action.flag)

    case CREATE_ARTICLE_RESET_SUBMIT_STATUS:
      return state.set('saved', false).set('published', false).set('unpublished', false)

    case CREATE_ARTICLE_RESET_STATE:
      return (state = initialCreateArticleInteractionsState)

    case CREATE_ARTICLE_ADD_KEY_QUOTE:
      return state.set('flashMessage', 'Key quote added to collection. Please see Basic Details section.')

    case CREATE_ARTICLE_TOGGLE_PUBLISH_DIALOG:
      return state.set('showPublishDialog', !state.get('showPublishDialog'))

    case CREATE_ARTICLE_USER_STARTED_TYPING:
      return state.set('startedTyping', true)

    case CREATE_ARTICLE_TOGGLE_UNPUBLISH_DIALOG:
      return state.set('showUnpublishDialog', !state.get('showUnpublishDialog'))

    case CREATE_ARTICLE_SET_INSERTION_TARGET:
      return state.set('insertionTarget', action.index)

    case CREATE_ARTICLE_CONVERT_TO_COMPONENT:
    case CREATE_ARTICLE_INSERT_COMPONENT_FROM_MODAL:
      if (action.flashMessage) {
        return state.set('flashMessage', action.flashMessage)
      } else return state

    case CREATE_ARTICLE_LOAD_VERSION:
      return state
        .set('flashMessage', `Version ${action.payload.metaData.version} has been loaded successfully.`)
        .set('versionLoaded', action.versionLoaded)

    case CREATE_ARTICLE_INBOUND_ARTICLES_REQUEST:
      return state.set('inboundLinksLoading', true).set('inboundLinks', fromJS([]))

    case CREATE_ARTICLE_INBOUND_ARTICLES_SUCCESS:
      return state.set('inboundLinksLoading', false).set('inboundLinks', fromJS(action.payload))

    case CREATE_ARTICLE_INBOUND_ARTICLES_FAILURE:
      return state.set('inboundLinksLoading', false)

    case TOGGLE_INBOUND_LINK_UPDATE_DIALOG:
      return state.set('showInboundLinkUpdateDialog', action.open)

    case CREATE_ARTICLE_UPDATE_INBOUND_LINKS_REQUEST:
      return state.set('inboundLinksUpdated', false)

    case CREATE_ARTICLE_UPDATE_INBOUND_LINKS_SUCCESS:
      return state.set('inboundLinksUpdated', true)

    case CREATE_ARTICLE_UPDATE_INBOUND_LINKS_FAILURE:
      return state.set('inboundLinksUpdated', false)

    case CREATE_ARTICLE_HIGHLIGHT_SAME_LINKS:
      return state.set('highlightedSameLinks', true)

    case CREATE_ARTICLE_TOGGLE_USER_SUBMIT:
      return state.set('userSubmit', !state.get('userSubmit'))

    case CREATE_ARTICLE_ENABLE_AUTOSAVE:
      return state.set('autoSave', true)

    case CREATE_ARTICLE_DISABLE_AUTOSAVE:
      return state.set('autoSave', false)

    case UNARCHIVE_ARTICLE_REQUEST:
      return state.set('unarchiving', true)

    case UNARCHIVE_ARTICLE_SUCCESS:
      return state.set('unarchiving', false)

    case UNARCHIVE_ARTICLE_FAILURE:
      return state.set('unarchiving', false)

    case CREATE_ARTICLE_TOGGLE_ERROR_DIALOG:
      return state.set('showErrorDialog', false)

    case CREATE_ARTICLE_LINK_SEARCH:
      return state.set('activeSearchLink', 0).set('linksFound', action.linksFound)

    case CREATE_ARTICLE_CHANGE_ACTIVE_SEARCH_LINK:
      return state.update('activeSearchLink', (current) => {
        const linksFound = state.get('linksFound')
        if (!linksFound) {
          return current
        }
        if (action.next) {
          return (current + 1) % (linksFound + 1)
        }
        const previous = current - 1
        return previous >= 0 ? previous : linksFound
      })

    case CREATE_ARTICLE_SET_LINK_SEARCH_PATTERN:
      return state
        .set('linkSearchPattern', action.pattern)
        .set('activeSearchLink', 0)
        .set('linksFound', action.linksFound || 0)

    default:
      return state
  }
}

export default createArticleInteractionsReducer
