import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { connect } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { useHistory } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { useStore } from 'store/mobx'

import SideMenu from 'components/SideMenu'
import { waveLogoSmall } from 'styles/assets'
import grantedByRole from 'utils/grantedByRole'
import { SUPPORT_MANAGEMENT } from 'utils/constants/privileges'
import LiveLessonButtons from 'components/new-pages/Classes/Lessons/LiveLessonsButtons'
import ProgressTrackingButtons from 'components/new-pages/ClassSubscriptions/Progress/AppBarButtons'
import { ContentWapper } from './layoutStyles'

const Logo = styled.img`
  margin: 0 15px;
`

const Title = styled(Typography)`
  text-transform: capitalize;
  flex-grow: 1;
`

const NewLayout = ({ location, children, userRoles, params }) => {
  const { infoMessage, setInfoMessage, severity } = useStore('appLayoutStore')
  const { fetchClasses, classesLoaded } = useStore('classesStore')

  const [drawerOpen, setIsDrawerOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [showInfoBox, toggleInfoBox] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (!classesLoaded) {
      fetchClasses({ moreDetails: true, fetchAll: true })
    }
  }, [fetchClasses, classesLoaded])

  useEffect(() => {
    if (userRoles && !grantedByRole(userRoles, SUPPORT_MANAGEMENT)) {
      history.push('/')
    }
  }, [userRoles, history])

  useEffect(() => {
    let newTitle = location.pathname.substring(1).replace(/-/g, ' ').split('/')[0]
    // progress tracking page
    if (location.pathname.includes('class-subscriptions/progress')) {
      newTitle = 'Progress Tracking'
    }
    setTitle(newTitle)
  }, [location.pathname])

  // shows the info message
  useEffect(() => {
    if (infoMessage) {
      toggleInfoBox(true)
    }
  }, [infoMessage])

  const handleInfoBoxClose = (event, reason) => {
    if (reason === 'clickaway') return
    setInfoMessage(null)
    toggleInfoBox(false)
  }

  return (
    <>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton sx={{ color: '#fff' }} aria-label="menu" onClick={() => setIsDrawerOpen((p) => !p)}>
            <MenuIcon />
          </IconButton>
          <Logo src={waveLogoSmall} alt="Wave logo" />
          <Title variant="h6">{title}</Title>
          {!!(location.pathname.includes('live-lesson') && params.id && params.moduleId) && (
            <LiveLessonButtons classId={params.id} moduleId={params.moduleId} />
          )}
          {location.pathname.includes('class-subscriptions/progress') && <ProgressTrackingButtons />}
        </Toolbar>
      </AppBar>
      <SideMenu open={drawerOpen} toggleSidemenu={() => setIsDrawerOpen((p) => !p)} />
      <ContentWapper maxWidth="xl">{children}</ContentWapper>
      <Snackbar
        open={!!infoMessage && showInfoBox}
        autoHideDuration={2000}
        onClose={handleInfoBoxClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={!!severity ? severity : 'info'} variant="filled" onClose={handleInfoBoxClose}>
          {infoMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

NewLayout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.object,
  userRoles: PropTypes.string,
  params: PropTypes.object,
}

const mapStateToProps = (state) => ({
  userRoles: state.profile.getIn(['profile', 'roles']),
})

export default connect(mapStateToProps)(observer(NewLayout))
