import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

const PhaseDropdown = (props) => {
  const [input, setInput] = useState(props.formData.phase)

  const allModulePhases = useMemo(() => {
    const phases = []
    if (input?.length && input !== props.formData.phase) {
      phases.push(input)
    }
    if (props.formData.phase?.length) {
      phases.push(props.formData.phase)
    }

    const activeModule = props.cls.modules.find((m) => m.id === props.moduleId)
    if (!activeModule?.sessions) {
      return phases
    }
    const modulePhases = activeModule.sessions
      ?.filter((m) => typeof m.phase === 'string' && !!m.phase.length)
      .map((m) => m.phase)

    return Array.from(new Set([...phases, ...modulePhases]))
  }, [props.cls, props.moduleId, props.formData, input])

  return (
    <Autocomplete
      name="phase"
      value={allModulePhases?.find((phase) => phase === props.formData.phase) || null}
      onChange={(e, v) => props.handleAutocompleteFieldChange(e, v || '', 'phase')}
      onInputChange={(e, v) => setInput(v)}
      options={allModulePhases}
      noOptionsText="No phases exist yet, enter a phase name"
      fullWidth
      renderInput={(params) => <TextField {...params} label="Phase" placeholder="Phase name" variant="standard" />}
    />
  )
}

PhaseDropdown.propTypes = {
  cls: PropTypes.object,
  moduleId: PropTypes.string,
  formData: PropTypes.object,
  handleAutocompleteFieldChange: PropTypes.func,
}

export default PhaseDropdown
