import { useHistory, useLocation, useParams } from 'react-router-dom'
import Link from '@mui/material/Link'
import { StyledBreadcrumbs } from './styles'
import { FC } from 'react'

type ClassBreadcrumbsProps = {
  classTitle?: string
  classId?: string
  moduleId?: string
  moduleTitle?: string
  lessonId?: string
  lessonType?: string
  lessonTitle?: string
  termTitle?: string
  toolTitle?: string
  versionTitle?: string
  videoAssignmentTitle?: string
  videoAssignmentId?: string
}

const ClassBreadcrumbs: FC<ClassBreadcrumbsProps> = ({
  classId,
  classTitle,
  moduleId,
  moduleTitle,
  lessonId,
  lessonType,
  lessonTitle,
  termTitle,
  toolTitle,
  versionTitle,
  videoAssignmentTitle,
  videoAssignmentId,
}) => {
  const history = useHistory()
  const params = useParams<{ classId: string }>()
  const { pathname } = useLocation()

  const getUrls = () => {
    const urls = [{ to: '/classes', title: 'Classes' }]

    if (termTitle && classId) {
      return [...urls, { to: `/classes/${classId}/terms`, title: `${classTitle} - ${termTitle}` }]
    }

    if (versionTitle && classId) {
      return [...urls, { to: `/classes/${classId}/versions`, title: `${classTitle} - ${versionTitle}` }]
    }

    if (toolTitle && classId) {
      return [...urls, { to: `/classes/${classId}/tools`, title: `${classTitle} - ${toolTitle}` }]
    }

    if (pathname?.includes('video-assignments')) {
      let newUrls = [...urls, { to: `/classes/${params.classId}/video-assignments`, title: 'Video Assignments' }]
      if (pathname.endsWith('submissions')) {
        newUrls = [
          ...newUrls,
          {
            to: `/classes/${params.classId}/video-assignments/${videoAssignmentId}/submissions`,
            title: `${videoAssignmentTitle} - Submissions`,
          },
        ]
      }
      return newUrls
    }

    //MODULES
    if (classId) {
      urls.push({ to: `/classes/${classId}/modules`, title: classTitle as string })

      if (moduleId) {
        urls.push({ to: `/classes/${classId}/modules/${moduleId}`, title: moduleTitle as string })

        if (lessonType) {
          if (lessonId) {
            urls.push({
              to: `/classes/${classId}/modules/${moduleId}/${lessonType}/${lessonId}`,
              title: lessonTitle || 'unknown title',
            })
          } else {
            urls.push({
              to: `/classes/${classId}/modules/${moduleId}/${lessonType}`,
              title: `Create Lesson: ${lessonType}`,
            })
          }
        }
      }
    }

    return urls
  }

  return (
    <StyledBreadcrumbs>
      {getUrls().map((urlData, i, s) => {
        const isActive = i === s.length - 1
        return (
          <Link key={urlData.to} color={isActive ? 'textPrimary' : 'inherit'} onClick={() => history.push(urlData.to)}>
            {urlData.title}
          </Link>
        )
      })}
    </StyledBreadcrumbs>
  )
}

export default ClassBreadcrumbs
