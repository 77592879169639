import {
  SET_NAVBAR_TITLE,
  TOGGLE_SEARCHBAR,
  TOGGLE_SHOW_VERSION_NOTICE,
  LOAD_VERSION_FILE_REQUEST,
  LOAD_VERSION_FILE_SUCCESS,
  LOAD_VERSION_FILE_FAILURE,
} from 'utils/constants/actions'
import { RSAA } from 'redux-api-middleware'

const { REACT_APP_CMS_URL } = process.env

export function setNavbarTitle(title = '') {
  return {
    type: SET_NAVBAR_TITLE,
    title: title,
  }
}

export function toggleSearchbar(show) {
  return {
    type: TOGGLE_SEARCHBAR,
    show,
  }
}

export function toggleShowVersionNotice(show) {
  return {
    type: TOGGLE_SHOW_VERSION_NOTICE,
    show,
  }
}

export function loadVersionFile() {
  return {
    [RSAA]: {
      endpoint: `${REACT_APP_CMS_URL}/version.json`,
      method: 'GET',
      types: [LOAD_VERSION_FILE_REQUEST, LOAD_VERSION_FILE_SUCCESS, LOAD_VERSION_FILE_FAILURE],
    },
  }
}
