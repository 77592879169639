import { useState, useEffect } from 'react'

const useBeforeFirstRender = <T extends Function>(fn: T) => {
  const [hasRendered, setHasRendered] = useState(false)
  useEffect(() => setHasRendered(true), [hasRendered])
  if (!hasRendered) {
    fn()
  }
}

export default useBeforeFirstRender
