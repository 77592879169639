import * as H from 'history'

export const isArticlePublished = (article: any) => {
  const publicationFlag = (article.get('statusFlags') || []).find((flag: any) => {
    return flag && flag.get('type') === 'publication' && flag.get('status') === 'published'
  })

  return publicationFlag && article.get('active') && article.get('publishDate')
}

export const getIsArticle = (location: H.Location<unknown>) => location.pathname.includes('article')
