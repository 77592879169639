import { Suspense, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import Paper from '@mui/material/Paper'
import CloseIcon from '@mui/icons-material/Close'
import { v4 as uuidv4 } from 'uuid'
import { Form, RemoveInstanceBtn } from '../styles'
import SaveLessonChanges from '../SaveLessonChanges'
import IconButton from '@mui/material/IconButton'
import StyledFlex, { StyledColumn } from '../../../../../layouts/StyledLayouts'
import Loader from 'components/new-pages/Loader'

import ToolsForm from '../FormComponents/ToolsForm'
import PhaseDropdown from '../FormComponents/PhaseDropdown'
import InstructorsDropdown from '../FormComponents/InstructorsDropdown'
import LessonImageComponent from '../FormComponents/LessonImageComponent'

const generateDefaultChoice = () => ({
  id: uuidv4(),
  answer: false,
  choice: '',
})

const generateDefaultQuestion = () => ({
  id: uuidv4(),
  question: '',
  active: true,
  randomize: true,
  choices: [generateDefaultChoice()],
})

const generateDefaultFormData = () => ({
  active: true,
  image: '',
  optional: false,
  phase: '',
  title: '',
  tools: [],
  questions: [generateDefaultQuestion()],
})

const EditAssesmentLesson = ({ cls, moduleId, activeLesson, activeLessonIdx }) => {
  const [formData, setFormData] = useState(generateDefaultFormData())

  const [termsInputVal, setTermsInputVal] = useState('')

  useEffect(() => {
    if (activeLesson) {
      setFormData({ ...activeLesson })
      setTermsInputVal(activeLesson.termId ? cls.terms.find((t) => t.id === activeLesson.termId)?.title : '')
    }
  }, [activeLesson, setTermsInputVal, cls, activeLessonIdx])

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value })
  }

  const handleChangeNegatedBooolean = (event) => {
    const { name, value, type, checked } = event.target
    setFormData({ ...formData, [name]: type === 'checkbox' ? !checked : value })
  }

  const handleAutocompleteFieldChange = (event, value, fieldName) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  const handleDeleteQuestion = (index) => {
    setFormData((p) => ({ ...p, questions: p.questions.filter((q, i) => i !== index) }))
  }

  const handleEditQuestion = (e, index) => {
    const { name, value, type, checked } = e.target

    setFormData((prev) => {
      const newQuestions = [...prev.questions]
      const editedQuestion = { ...newQuestions[index], [name]: type === 'checkbox' ? checked : value }

      newQuestions.splice(index, 1, editedQuestion)

      return { ...prev, questions: newQuestions }
    })
  }

  const handleAddQuestion = () => {
    setFormData((prev) => ({
      ...prev,
      questions: [...prev.questions, generateDefaultQuestion()],
    }))
  }

  const handleEditChoice = (e, questionIndex, choiceIndex) => {
    const { name, value, type, checked } = e.target

    setFormData((prev) => {
      const editedQuestions = [
        ...prev.questions.slice(0, questionIndex),
        {
          ...prev.questions[questionIndex],
          choices: [
            ...prev.questions[questionIndex].choices.slice(0, choiceIndex),
            { ...prev.questions[questionIndex].choices[choiceIndex], [name]: type === 'checkbox' ? checked : value },
            ...prev.questions[questionIndex].choices.slice(choiceIndex + 1),
          ],
        },
        ...prev.questions.slice(questionIndex + 1),
      ]

      return { ...prev, questions: editedQuestions }
    })
  }

  const handleEditCorrectChoice = (e, questionIndex, choiceIndex) => {
    const { name, checked } = e.target

    setFormData((prev) => {
      const mutatedChoices = prev.questions[questionIndex].choices.map((c, i) => {
        if (i === choiceIndex) {
          c[name] = checked
        } else {
          c[name] = false
        }
        return c
      })

      const editedQuestions = [
        ...prev.questions.slice(0, questionIndex),
        {
          ...prev.questions[questionIndex],
          choices: mutatedChoices,
        },
        ...prev.questions.slice(questionIndex + 1),
      ]

      return { ...prev, questions: editedQuestions }
    })
  }

  const handleAddChoice = (questionIndex) => {
    setFormData((prev) => ({
      ...prev,
      questions: [
        ...prev.questions.slice(0, questionIndex),
        {
          ...prev.questions[questionIndex],
          choices: [...prev.questions[questionIndex].choices, generateDefaultChoice()],
        },
        ...prev.questions.slice(questionIndex + 1),
      ],
    }))
  }

  const handleRemoveChoice = (questionIndex, choiceIndex) => {
    setFormData((prev) => ({
      ...prev,
      questions: [
        ...prev.questions.slice(0, questionIndex),
        {
          ...prev.questions[questionIndex],
          choices: prev.questions[questionIndex].choices.filter((q, i) => i !== choiceIndex),
        },
        ...prev.questions.slice(questionIndex + 1),
      ],
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <SaveLessonChanges
        classId={cls.id}
        moduleId={moduleId}
        lessonId={activeLesson?.id}
        formData={{ ...formData, type: 'Assessment', moduleId, classId: cls.id }}
      />
      <Form component="form" id="assessment-form" onSubmit={handleSubmit}>
        <StyledColumn style={{ gap: '12px' }}>
          <StyledFlex style={{ justifyContent: 'flex-start' }}>
            <LessonImageComponent image={formData.image} setImage={(image) => setFormData((p) => ({ ...p, image }))} />
          </StyledFlex>

          <StyledFlex style={{ gap: '16px' }}>
            <TextField
              value={formData.title}
              fullWidth
              name="title"
              type="text"
              label="Title"
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Title' }}
              variant="standard"
              sx={{ flex: 1 / 3 }}
            />
            {cls.terms?.length > 0 && (
              <Autocomplete
                data-testid="videoLessontermsDropdown"
                name="termId"
                value={cls.terms.find((t) => t.id === formData.termId) || null}
                options={cls.terms}
                getOptionLabel={(option) => option?.title || ''}
                renderInput={(params) => <TextField {...params} label="Select Term" variant="standard" />}
                inputValue={termsInputVal}
                onChange={(e, v) => setFormData({ ...formData, termId: v?.id })}
                onInputChange={(e, v) => setTermsInputVal(v)}
                sx={{ flex: 1 / 3 }}
              />
            )}
          </StyledFlex>

          <StyledFlex style={{ gap: '16px' }}>
            <FormControlLabel
              control={<Switch checked={formData.active} onChange={handleChange} name="active" color="primary" />}
              label="Active"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={!formData.optional}
                  onChange={handleChangeNegatedBooolean}
                  name="optional"
                  color="primary"
                />
              }
              label="Required for completion"
            />
          </StyledFlex>

          <StyledFlex style={{ gap: '16px' }}>
            <Suspense fallback={<Loader />}>
              <PhaseDropdown
                cls={cls}
                moduleId={moduleId}
                formData={formData}
                handleAutocompleteFieldChange={handleAutocompleteFieldChange}
              />
              <InstructorsDropdown
                selectedInstructorId={formData.instructorId}
                classInstructorIds={cls.instructors}
                handleChange={handleAutocompleteFieldChange}
              />
              <ToolsForm
                selectedValues={formData.tools}
                options={cls.tools}
                handleChange={handleAutocompleteFieldChange}
              />
            </Suspense>
          </StyledFlex>

          {formData.questions.map((question, questionIndex) => (
            <Paper
              sx={{
                padding: '12px',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
                alignSelf: 'stretch',
              }}
            >
              {formData.questions.length > 1 && (
                <RemoveInstanceBtn
                  onClick={() => {
                    handleDeleteQuestion(questionIndex)
                  }}
                  aria-label="Close"
                >
                  <CloseIcon />
                </RemoveInstanceBtn>
              )}

              <TextField
                value={question.question}
                fullWidth
                name="question"
                type="text"
                label="Question"
                onChange={(e) => handleEditQuestion(e, questionIndex)}
                inputProps={{ 'aria-label': 'Host' }}
                variant="standard"
              />

              <StyledFlex style={{ gap: '16px' }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={question.active}
                      onChange={(e) => handleEditQuestion(e, questionIndex)}
                      name="active"
                      color="primary"
                    />
                  }
                  label="Active"
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={!!question.randomize}
                      onChange={(e) => handleEditQuestion(e, questionIndex)}
                      name="randomize"
                      color="primary"
                    />
                  }
                  label="Randomize Choices"
                />
              </StyledFlex>

              {question.choices.map((choice, choiceIndex) => (
                <StyledFlex key={choice.id} style={{ gap: '16px' }}>
                  <TextField
                    value={choice.choice}
                    fullWidth
                    name="choice"
                    type="text"
                    label="Choice"
                    onChange={(e) => handleEditChoice(e, questionIndex, choiceIndex)}
                    inputProps={{ 'aria-label': 'Host' }}
                    variant="standard"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={choice.answer}
                        onChange={(e) => handleEditCorrectChoice(e, questionIndex, choiceIndex)}
                        name="answer"
                        color="primary"
                      />
                    }
                    label="Correct Answer"
                    sx={{ whiteSpace: 'nowrap' }}
                  />
                  <StyledFlex style={{ alignItems: 'center' }}>
                    <IconButton onClick={() => handleRemoveChoice(questionIndex, choiceIndex)}>
                      <CloseIcon />
                    </IconButton>
                  </StyledFlex>
                </StyledFlex>
              ))}

              <Button style={{ marginTop: '1em' }} onClick={() => handleAddChoice(questionIndex)}>
                Add Choice
              </Button>
            </Paper>
          ))}

          <Button onClick={handleAddQuestion}>Add Question</Button>
        </StyledColumn>
      </Form>
    </>
  )
}

EditAssesmentLesson.propTypes = {
  cls: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
  activeLesson: PropTypes.object,
  activeLessonIdx: PropTypes.number,
  lessons: PropTypes.array,
}

export default EditAssesmentLesson
