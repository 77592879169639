import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { configureScope } from '@sentry/browser'

import initCognitoSdk from 'auth/cognito'
import { loadProfile } from 'actions/profileActions'
import { useHistory, useLocation } from 'react-router-dom'
import { RootDispatch, RootState } from '../App'

const { REACT_APP_ENVIRONMENT } = process.env

const mapStateToProps = (state: RootState) => {
  return {
    profile: state.profile.get('profile'),
    error: state.profile.get('error'),
  }
}

const mapDispatchToProps = (dispatch: RootDispatch) => {
  return {
    loadProfile: () => dispatch(loadProfile()),
  }
}

interface Props extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {
  children: React.ReactNode
}

export const ProtectedRoute = connect(
  mapStateToProps,
  mapDispatchToProps
)((props: Props) => {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (REACT_APP_ENVIRONMENT?.toUpperCase() === 'QA') {
      checkIfUserIsLoggedInQA()
    } else {
      checkIfUserIsLoggedIn()
    }

    window.onbeforeunload = () => {
      if (sessionStorage.getItem('draftArticleComponents') || sessionStorage.getItem('draftArticleMetadata')) {
        return 'You have unsaved changes'
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.profile && REACT_APP_ENVIRONMENT?.toUpperCase() !== 'DEV') {
      configureScope((scope) => {
        scope.setUser({
          email: props.profile.get('emailAddress'),
          username: props.profile.get('userName'),
          id: props.profile.get('userId'),
        })
      })
    }
  }, [history, location, props.profile])

  const checkIfUserIsLoggedIn = () => {
    const { loadProfile } = props

    const auth = initCognitoSdk({ loadProfile, history })
    if (window.location.href.includes('code')) {
      auth.parseCognitoWebResponse(window.location.href)
    } else if (!auth.isUserSignedIn()) {
      sessionStorage.setItem('redirect', window.location.pathname + window.location.search)
      history.push('/login')
    } else {
      loadProfile()
    }
  }

  const checkIfUserIsLoggedInQA = () => {
    const { loadProfile } = props
    if (sessionStorage.getItem('id_token')) {
      loadProfile()
    }
  }

  return <div>{props.children}</div>
})
