export const HERO_IMAGE = 'hero-image'
export const QUILL = 'text'
export const HEADING_TERMINATOR = 'heading-terminator'
export const HEADING = 'heading'
export const BLOCK_QUOTE = 'blockquote'
export const PULL_QUOTE = 'pullquote'
export const EMBED = 'embed'
export const SUBNAV = 'subnavigation'
export const LIST = 'list'
export const AD = 'ad'
export const VIDEO = 'video'
export const GALLERY = 'gallery'
export const IMAGE = 'image'
export const SHOP = 'shop'
export const PARALLAX_IMAGE = 'parallax-image'
export const TEXT_AND_IMAGE = 'text-and-image'
export const RECIPE_STEPS = 'recipeSteps'
export const RECIPE_INGREDIENTS = 'recipeIngredients'
export const RECIPE_NOTES = 'recipeNotes'
export const CODE = 'code'
export const PERSPECTIVE = 'perspective'
export const GLOSSARY = 'glossary'
export const RELATED_READS = 'related-reads'
export const INLINE_RECIRCULATION = 'inline-recirculation'
export const ALTERNATE_HERO = 'alternate-hero-position'
export const EQUIPMENT = 'equipment'
export const INTRODUCTION = 'introduction'
export const FOOTER = 'footer'
export const PRODUCT = 'product'
export const SUMMARY = 'summary'
export const INLINE_CLASS_LESSON = 'inline-class-lesson'
export const RECIPE = 'recipe'
export const SUBHEADING = 'subheading'
export const PROMOTIONAL_PLACEMENT = 'promotional-placement'
export const ENHANCED_LIST = 'enhanced-list'
export const REVIEW = 'review'
export const PRODUCT_REVIEW = 'product-review'
export const PRODUCT_REVIEW_QUICK_LIST = 'product-review-quick-list'
export const TABLE = 'table'

export const MEDIA_COMPONENTS = [
  IMAGE,
  TEXT_AND_IMAGE,
  GALLERY,
  PARALLAX_IMAGE,
  VIDEO,
  EMBED,
  AD,
  SHOP,
  CODE,
  RELATED_READS,
  ALTERNATE_HERO,
  PRODUCT,
  SUMMARY,
  INLINE_CLASS_LESSON,
  PROMOTIONAL_PLACEMENT,
  PRODUCT_REVIEW,
  PRODUCT_REVIEW_QUICK_LIST,
]
