import { Map, fromJS } from 'immutable'
import {
  CREATE_ARTICLE_TOGGLE_CONFIRM_DIALOG,
  CREATE_ARTICLE_SHOULD_SAVE_DRAFT,
  CREATE_ARTICLE_ON_COMPONENT_DROP,
} from 'utils/constants/actions'

const confirmDialogReducer =
  (namespace) =>
  (state = Map(), action) => {
    switch (action.type) {
      case CREATE_ARTICLE_TOGGLE_CONFIRM_DIALOG:
        if (namespace !== action.namespace) return state
        return state.set('open', !state.get('open')).set('extra', fromJS(action.extra))

      case CREATE_ARTICLE_SHOULD_SAVE_DRAFT:
        if (namespace === 'draft') return state.set('open', action.showDraftDialog)
        return state

      case CREATE_ARTICLE_ON_COMPONENT_DROP:
        if (namespace === 'dropComponent') return state.set('open', action.showDropDialog)
        return state

      default:
        return state
    }
  }

export default confirmDialogReducer
