import {
  createSydnicationsFormValues,
  createInternalNotesFlagsValues,
  createAnalyticsFormValues,
} from './createArticleMetadataHelpers'
import joinComponentsByType from './joinComponentsByType'
import { initialCreateArticleBodyState } from 'store/initialState/initialCreateArticleBodyState'

import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import shortid from 'shortid'

const loadArticle = ({ articleData, storeCurrentVersion, isPodcast }) => {
  const { body, ...metaData } = cloneDeep(articleData)
  let bodyComponents
  if (body) {
    bodyComponents = body
      .filter((c) => !!c.type)
      .map((c) => {
        c.id = Math.random().toString(36).substring(7)
        if (['heading', 'image', 'list', 'gallery', 'shop', 'text-and-image'].includes(c.type)) {
          if (!c.anchor || c.anchor.length < 1) c.anchor = shortid.generate()
        }
        return c
      })
    bodyComponents = joinComponentsByType(bodyComponents, 'text', 'text')

    const headerExist = body.some((c) => c.type === 'header')
    if (!headerExist) {
      const headerComponent = initialCreateArticleBodyState.getIn(['present', 'components', 0])
      bodyComponents.unshift(headerComponent)
    }
  }

  metaData.statusFlags = createInternalNotesFlagsValues(metaData.statusFlags || [])
  metaData.syndications = createSydnicationsFormValues(metaData.syndications || [])
  metaData.metaData = createAnalyticsFormValues(metaData.metaData || [])

  if (!metaData.nsfw && !isPodcast) metaData.nsfw = false
  if (!metaData.publishNotificationEmails) metaData.publishNotificationEmails = ['']

  if (metaData.publishDate) {
    // this will convert publishDate to EST/EDT
    metaData.publishDate = moment(metaData.publishDate).format()
  }

  if (!metaData.userCreatedUrl && !isPodcast) {
    delete metaData.url
  }

  const timeSpent = metaData.timeSpent || []
  delete metaData.timeSpent

  let showUnpublishBtn = false
  if (
    metaData.active &&
    metaData.publishDate &&
    metaData.statusFlags &&
    metaData.statusFlags.find((flag) => flag && flag.type === 'publication' && flag.status === 'published')
  ) {
    showUnpublishBtn = true
  }

  let payload = { bodyComponents, metaData, timeSpent, showUnpublishBtn, json: articleData }

  if (storeCurrentVersion) payload = { ...payload, currentVersion: metaData.version }

  return payload
}

export default loadArticle
