import Alert from '@mui/material/Alert'

import { ClassAttendanceMutation } from '../../../../../hooks/useClass'
import { StyledColumn } from '../../../../../layouts/StyledLayouts'

import { makePlural } from '../../../../../utils/stringHelpers'
import IncorrectTermIdView from './IncorrectTermIdView'

interface DataErrorProps {
  message: string
}

export const DataErrorDisplay = (props: DataErrorProps) => (
  <Alert severity="error" variant="outlined">
    Error: {props.message}
  </Alert>
)

export const MutationErrorDisplay = (props: ClassAttendanceMutation.Error) => {
  if (!props.emails && !props.message) {
    return null
  }

  return (
    <Alert severity="error" variant="outlined">
      {props.message}
      {props.emails && (
        <StyledColumn $gap>
          {props.emails.map((e) => (
            <div>{e}</div>
          ))}
        </StyledColumn>
      )}
    </Alert>
  )
}

/* also shows unprocessed emails and errors, if any */
export const MutationSuccessDisplay = ({
  classId,
  success: { failed, succeeded },
}: {
  classId: number
  success: ClassAttendanceMutation.Success
}) => {
  return (
    <>
      {failed && (
        <Alert severity="error" variant="outlined">
          <StyledColumn $gap>
            {failed.notFound.length > 0 && (
              <>
                <strong>
                  {failed.notFound.length} user{makePlural(failed.notFound)} email
                  {failed.notFound.length > 1 && 's'} not found:
                </strong>
                {failed.notFound.map((e) => (
                  <div key={e}>{e}</div>
                ))}
              </>
            )}

            {failed.noSubscription.length > 0 && (
              <>
                <strong>
                  {failed.noSubscription.length} user{makePlural(failed.noSubscription)} with no active subscription to
                  this class found:
                </strong>
                {failed.noSubscription.map((e) => (
                  <div key={e}>{e}</div>
                ))}
              </>
            )}
            {failed.notificationErrors.length > 0 && (
              <>
                <strong>
                  {failed.notificationErrors.length} error{makePlural(failed.notificationErrors)} creating a module
                  completion notification:
                </strong>
                {failed.notificationErrors.map((e) => (
                  <div key={e.email}>
                    {e.email}: {e.error}
                  </div>
                ))}
              </>
            )}
            {failed.incorrectTermId.length > 0 && (
              <IncorrectTermIdView classId={classId} incorrectTermId={failed.incorrectTermId} />
            )}
            {failed.multipleTrackingRecords.length > 0 && (
              <>
                <strong>
                  {failed.multipleTrackingRecords.length} user{makePlural(failed.multipleTrackingRecords)} with multiple
                  tracking records found:
                </strong>
                {failed.multipleTrackingRecords.map((e) => (
                  <div key={e.userId}>
                    {e.email} with user-id: {e.userId} has multiple tracking records for class: {e.classId}
                  </div>
                ))}
              </>
            )}
          </StyledColumn>
        </Alert>
      )}
      {succeeded && (
        <Alert severity="success" variant="outlined">
          <div>Successfully updated {succeeded.length} user</div>
          {succeeded.length > 1 && 's'}
          <StyledColumn style={{ marginTop: '8px', gap: '4px' }}>
            {succeeded.map((e) => (
              <div key={e}>{e}</div>
            ))}
          </StyledColumn>
        </Alert>
      )}
    </>
  )
}
