import styled from 'styled-components'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

export const Form = styled(Box)`
  width: 100%;
`

export const RemoveInstanceBtn = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;
`
