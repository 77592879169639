const convertTextToSlug = (text: string) => {
  const url = text
    .replace(/\s+/g, '-')
    .replace(/&/g, '-and-')
    .replace(/[^\w-]+/g, '')
    .replace(/-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
    .toLowerCase()
  return `${url}`
}

export default convertTextToSlug
