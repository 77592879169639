import React from 'react'

import AppLayoutStore from './AppLayoutStore'
import ClassSubscriptionsStore from './ClassSubscriptionsStore'
import OrdersStore from './OrdersStore'
import EditClassSubscriptionStore from './EditClassSubscriptionStore'
import ProgressTrackingStore from './ProgressTrackingStore'
import ClassPromotionsStore from './ClassPromotionsStore'
import CampaignStore from './CampaignStore'
import ClassesStore from './ClassesStore'

import ImageLibraryStore from './ImageLibraryStore'
import EditLiveLessonStore from './EditLiveLessonStore'
import VideoLibraryStore from './VideoLibraryStore'
import InstructorsStore from './InstructorsStore'
import FileStore from './fileStore'
import { classesTransportLayer } from 'api/classes'

export const stores = Object.freeze({
  appLayoutStore: new AppLayoutStore(),
  classSubscriptionsStore: new ClassSubscriptionsStore(),
  ordersStore: new OrdersStore(),
  editClassSubscriptionStore: new EditClassSubscriptionStore(),
  progressTrackingStore: new ProgressTrackingStore(),
  classPromotionsStore: new ClassPromotionsStore(),
  classesStore: new ClassesStore(classesTransportLayer),
  campaignStore: new CampaignStore(),
  imageLibraryStore: new ImageLibraryStore(),
  fileStore: new FileStore(),
  editLiveLessonStore: EditLiveLessonStore,
  videoLibraryStore: VideoLibraryStore,
  instructorsStore: InstructorsStore,
})

export const storeContext = React.createContext(stores)
export const StoreProvider = storeContext.Provider
export const useStore = (store) => React.useContext(storeContext)[store]
