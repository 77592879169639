import { LOAD_ARTICLES_SUCCESS, APPLY_ARTICLES_FILTERS_SUCCESS } from 'utils/constants/actions'

import groupByPublishDate from 'utils/groupByPublishDate'

const loadArticles = (store) => (next) => (action) => {
  if (action.type === LOAD_ARTICLES_SUCCESS || action.type === APPLY_ARTICLES_FILTERS_SUCCESS) {
    const { articles } = store.getState()

    const compressedViewLocalStorage = JSON.parse(localStorage.getItem('wave_cview'))

    action.compressedView =
      compressedViewLocalStorage === null ? articles.get('compressedView') : compressedViewLocalStorage

    if (action.compressedView) {
      action.payload = action.payload.map((article) => {
        article.compressed = action.compressedView
        return article
      })
    }

    action.articles = groupByPublishDate(action.payload)
  }

  return next(action)
}

export default loadArticles
