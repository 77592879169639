import { useStore } from 'store/mobx'

import { ClassAttendanceMutation } from '../../../../../hooks/useClass'
import { ClassModule } from '../../../../../types/ClassModule'
import { makePlural } from '../../../../../utils/stringHelpers'

interface Props {
  classId: number
  incorrectTermId: ClassAttendanceMutation.IncorrectTermId[]
}

const IncorrectTermIdView = (props: Props) => {
  const { classes } = useStore('classesStore')
  const classItem: ClassModule.Data | undefined = classes.find((item: ClassModule.Data) => item.id === props.classId)

  const getTermName = (termId: number) => {
    const term = classItem?.terms?.find((t) => t.id === termId)

    return term ? `"${term.title}"` : `term ID ${termId}`
  }

  return (
    <>
      <strong>
        Incorrect term ID for {props.incorrectTermId.length} user
        {makePlural(props.incorrectTermId)}:
      </strong>
      {props.incorrectTermId.map((data) => (
        <div key={data.email}>
          {data.email} has subscription{makePlural(data.termIds)} for {data.termIds.map(getTermName).join(', ')}
        </div>
      ))}
    </>
  )
}

export default IncorrectTermIdView
