import { fromJS } from 'immutable'
import moment from 'moment'

export const initialCreateArticleMetadataStateObject = {
  metaData: {
    template: 'standard',
    slug: '',
    createDate: moment().format(),
    active: false,
    titles: {
      site: '',
      email: '',
      emailSubject: '',
      social: '',
      short: '',
      pageHeader: '',
    },
    publishDate: '',
    expirationDate: '',
    url: '',
    authorIds: [],
    publishNotificationEmails: [''],
    partnerIds: [],
    noMBGMarketing: false,
    category: '',
    tags: [],
    syndications: ['mindbodygreen.com', 'Daily Newsletter', 'Facebook', 'Apple News', 'Google AMP', 'SmartNews'],
    indexForSearch: true,
    metaData: [],
    previewText: '',
    editorsNotes: [],
    editorRating: 0,
    statusFlags: [
      { type: 'creative', status: 'required' },
      { type: 'copy', status: 'required' },
    ],
    keyQuotes: [],
    userCreatedUrl: false,
    nsfw: false,
    version: 0,
    expertReview: {
      reviewerId: null,
      date: null,
      type: '',
    },
    assignedEditorId: null,
  },
  imageDialogOpen: false,
  imageDialogType: null,
  socialImageDialogOpen: false,
  timeTrackingStart: new Date(),
  timeTrackingIdleTime: 0,
  timeSpent: [],
  currentVersion: null,
  loadedVersionNumber: null,
  showUrlDialog: false,
  preventUrlDialog: false,
  keywordsInArticle: [],
  personalStory: false,
  expertOpinion: false,
}

export const initialCreateArticleMetadataState = fromJS(initialCreateArticleMetadataStateObject)

export const getNewInitialCreateArticleMetadataState = () => fromJS(initialCreateArticleMetadataStateObject)
