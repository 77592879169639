import { fromJS } from 'immutable'

export const initialPartnersState = fromJS({
  loading: false,
  partners: [],
  error: null,
  saving: false,
  flashMessage: '',
  productUnit: '',
  productUnitData: {
    title: '',
    description: '',
    partner: null,
    image: null,
    url: '',
    category: '',
    cta: 'Buy Now',
  },
})
