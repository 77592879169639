import { CREATE_ARTICLE_SEND_ARTICLE_SUCCESS, CREATE_PODCAST_SEND_PODCAST_SUCCESS } from 'utils/constants/actions'
import customHistory from '../../utils/customHistory'

// import { browserHistory } from '../..'

const redirectAndClearDraft = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_SEND_ARTICLE_SUCCESS || action.type === CREATE_PODCAST_SEND_PODCAST_SUCCESS) {
    const { createArticleInteractions } = store.getState()
    const shouldSaveDraft = createArticleInteractions.get('shouldSaveDraft')

    const { pathname } = window.location

    let key = ''
    if (pathname.includes('article')) {
      key = 'article'
    } else if (pathname.includes('recipe')) {
      key = 'recipe'
    } else if (pathname.includes('podcast')) {
      key = 'podcast'
    }
    const articleId = action.payload.id

    if (articleId) {
      customHistory.replace(`/${key}/edit/${articleId}`)
    }

    if (shouldSaveDraft || articleId) {
      sessionStorage.removeItem(`${key}.draftArticleComponents`)
      sessionStorage.removeItem(`${key}.draftArticleMetadata`)
    }
  }

  return next(action)
}

export default redirectAndClearDraft
