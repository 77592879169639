import PropTypes from 'prop-types'

export const ModulePropType = PropTypes.shape({
  gsi_pk: PropTypes.string,
  id: PropTypes.string,
  objectType: PropTypes.string,
  pk: PropTypes.string,
  sessions: PropTypes.array,
  sk: PropTypes.string,
  title: PropTypes.string,
})
