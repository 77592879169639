import PropTypes from 'prop-types'
import React from 'react'
import { SetPriority } from '.'
import { Box, Button } from '@mui/material'

const SetPriorityForm = ({ priority, updatePriority, handleConfirm }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <SetPriority priority={priority} updatePriority={updatePriority} />
    <Button sx={{ marginLeft: 'auto', marginTop: '10px' }} onClick={handleConfirm}>
      Next
    </Button>
  </Box>
)

SetPriorityForm.propTypes = {
  priority: PropTypes.number,
  updatePriority: PropTypes.func,
}

export default SetPriorityForm
