import { makeAutoObservable, runInAction } from 'mobx'
import { fetchImages, uploadImage } from 'api/images'

export default class ImageLibraryStore {
  images = []
  loading = false
  error = null
  hasMore = false
  uploadedImage = null

  constructor() {
    makeAutoObservable(this)
  }

  resetList = () => {
    this.images = []
    this.hasMore = false
  }

  fetchImages = async (params) => {
    this.loading = true
    this.hasMore = false
    try {
      const data = await fetchImages(params)
      runInAction(() => {
        this.images = params.from > 0 ? this.images.concat(data) : data
        this.loading = false
        this.error = null
        if (data?.length >= 10) {
          this.hasMore = true
        }
      })
    } catch (error) {
      runInAction(() => {
        this.error = error
        this.loading = false
        this.hasMore = false

        setTimeout(() => {
          this.error = null
        }, 3000)
      })
    }
  }

  uploadImageData = async (data) => {
    this.loading = true
    try {
      const { key } = await uploadImage(data)
      runInAction(() => {
        this.loading = false
        this.error = null
        this.uploadedImage = {
          path: key,
        }
      })
    } catch (error) {
      runInAction(() => {
        this.error = error
        this.loading = false

        setTimeout(() => {
          this.error = null
        }, 3000)
      })
    }
  }
}
