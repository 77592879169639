import PropTypes from 'prop-types'
import { Article, DateRange, ArticleQueueFilter, CompressToggle } from '.'
import formatDate from 'utils/formatDate'
import moment from 'moment'
import styled from 'styled-components'
import StyledFlex, { StyledColumn } from '../../layouts/StyledLayouts'

const QueueControlsWrapper = styled(StyledFlex)`
  align-items: center;
  justify-content: ${(props) => (props.isArticle ? 'space-between' : 'end')};
`

const RenderArticles = ({
  setPublishDT,
  articles,
  updateEditorsNotes,
  onEditPreviewImage,
  updatingArticles,
  toggleArchiveDialog,
  updateArticle,
  pathname,
  showFilter,
  compressedView,
  toggleCompressedView,
  toggleCompressedViewItem,
  showCompressedViewBtn,
  isArticle,
  isOnline,
}) => (
  <div>
    <QueueControlsWrapper isArticle={isArticle}>
      {isArticle && <DateRange />}
      <div>
        {showCompressedViewBtn && (
          <CompressToggle compressedView={compressedView} toggleCompressedView={toggleCompressedView} />
        )}
        {showFilter && isArticle && <ArticleQueueFilter />}
      </div>
    </QueueControlsWrapper>
    {articles?.entrySeq().map(([date, articleList]) => (
      <StyledColumn key={date}>
        <h2>{`${formatDate(moment(date, 'LL'), 'dddd, MMMM Do', 'LL')} (${articleList.size})`}</h2>
        {articleList.map((a, i) => (
          <Article
            key={a.get('id')}
            presenceKey={`/${isArticle ? 'articles' : 'recipes'}/${a.get('id')}`}
            refreshKey={isArticle ? 'articles' : 'recipes'}
            articleIdx={i}
            dateKey={date}
            article={a}
            updating={updatingArticles.hasIn([date, i]) && updatingArticles.getIn([date, i])}
            updateEditorsNotes={(editorsNotes) => updateEditorsNotes(i, date, editorsNotes)}
            setPublishDT={(dateTime) => setPublishDT(dateTime, date, i)}
            toggleArchiveDialog={toggleArchiveDialog}
            onEditPreviewImage={(image) => onEditPreviewImage(image, date, i)}
            updateArticle={(params) => updateArticle(Object.assign(a.toJS(), params), i, date)}
            pathname={pathname}
            toggleCompressedViewItem={() => {
              if (compressedView) toggleCompressedViewItem(date, i)
            }}
            isOnline={isOnline}
            showFilter={showFilter}
            isArticle={isArticle}
          />
        ))}
      </StyledColumn>
    ))}
  </div>
)

RenderArticles.propTypes = {
  updateEditorsNotes: PropTypes.func,
  setPublishDT: PropTypes.func,
  articles: PropTypes.object,
  onEditPreviewImage: PropTypes.func,
  updatingArticles: PropTypes.object,
  toggleArchiveDialog: PropTypes.func,
  updateArticle: PropTypes.func,
  pathname: PropTypes.string,
  showFilter: PropTypes.bool,
  compressedView: PropTypes.bool,
  toggleCompressedView: PropTypes.func,
  toggleCompressedViewItem: PropTypes.func,
  showCompressedViewBtn: PropTypes.bool,
  isArticle: PropTypes.bool,
  isOnline: PropTypes.bool,
}

export default RenderArticles
