import { Map, fromJS } from 'immutable'
import { LOAD_PROFILE_REQUEST, LOAD_PROFILE_SUCCESS, LOAD_PROFILE_FAILURE, LOAD_QA } from 'utils/constants/actions'

const profileReducer = (state = Map(), action) => {
  switch (action.type) {
    case LOAD_QA:
      return state.set('loading', false).set('profile', action.payload)
    case LOAD_PROFILE_REQUEST:
      return state.set('loading', true)
    case LOAD_PROFILE_FAILURE:
      return state.set('loading', false).set('error', action.payload)
    case LOAD_PROFILE_SUCCESS:
      return state.set('loading', false).set('profile', fromJS(action.payload))
    default:
      return state
  }
}

export default profileReducer
