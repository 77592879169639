import React, { Component } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { ListSubheader } from '@mui/material'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { PieChart, Pie, Tooltip, Cell } from 'recharts'

import { toggleDialog, analyzeText } from 'actions/textAnalysisActions'
import SentimentBar from 'components/CreateArticle/SentimentBar'
import { cerulean, greenApple, pinkRed, blueGrey40, squash, oceanBlue } from 'styles/colors'
import StyledFlex from '../../../layouts/StyledLayouts'
import CommonDialogTitle from '../../Common/DialogComponents/CommonDialogTitle'
import { LoaderContainer } from '../../../ui/LoaderContainer'

const customStyles = {
  largeSubheader: {
    fontSize: '20px',
    paddingLeft: 0,
  },
}

const emotionColor = (emotion) => {
  switch (emotion) {
    case 'Anger':
      return pinkRed
    case 'Disgust':
      return squash
    case 'Fear':
      return blueGrey40
    case 'Joy':
      return greenApple
    case 'Sadness':
      return oceanBlue
    default:
      return ''
  }
}

class TextAnalysisDialog extends Component {
  static propTypes = {
    open: PropTypes.bool,
    toggleDialog: PropTypes.func,
    keywords: PropTypes.object,
    sentiment: PropTypes.object,
    emotion: PropTypes.object,
    analyzeText: PropTypes.func,
    loading: PropTypes.bool,
  }

  state = {
    hoveredKeyword: -1,
  }

  onKeywordHover = (index) => {
    this.setState({ hoveredKeyword: index })
  }

  render() {
    const { open, toggleDialog, keywords, sentiment, emotion, analyzeText, loading } = this.props
    const { hoveredKeyword } = this.state

    const sentimentLabel = sentiment.get('label').charAt(0).toUpperCase() + sentiment.get('label').slice(1)

    const emotionData = Object.keys(emotion.toJS()).map((k) => {
      return {
        name: k.charAt(0).toUpperCase() + k.slice(1),
        value: Math.round(emotion.toJS()[k] * 1000) / 1000,
      }
    })

    return (
      <Dialog open={open} fullWidth onClose={() => toggleDialog(false)}>
        <CommonDialogTitle onClose={() => toggleDialog(false)}>
          <div>Keywords (sorted by relevancy)</div>
        </CommonDialogTitle>
        <DialogContent>
          <LoaderContainer isLoading={loading} />
          <StyledFlex style={{ gap: '16px' }}>
            <StyledFlex style={{ flex: '1 0 48%' }}>
              <List>
                {keywords.map((k, i) => (
                  <ListItem
                    key={i}
                    onMouseEnter={() => this.onKeywordHover(i)}
                    onMouseLeave={() => this.onKeywordHover(-1)}
                    sx={{
                      color: '#fff',
                      backgroundColor: `rgba(0, 155, 210, ${k.get('relevance')})`,
                    }}
                  >
                    {hoveredKeyword === i ? `Relevance: ${Math.round(k.get('relevance') * 100)}%` : k.get('text')}
                  </ListItem>
                ))}
              </List>
            </StyledFlex>
            <StyledFlex style={{ flex: '1 0 48%' }}>
              <ListSubheader style={customStyles.largeSubheader}>Sentiment</ListSubheader>
              <SentimentBar label={sentimentLabel} score={sentiment.get('score')} />
              <br />
              <ListSubheader style={customStyles.largeSubheader}>Emotion</ListSubheader>
              <PieChart width={350} height={220}>
                <Pie
                  dataKey="value"
                  nameKey="name"
                  data={emotionData}
                  cx={155}
                  cy={110}
                  outerRadius={80}
                  fill={cerulean}
                  label={(emotion) => emotion.name}
                >
                  {emotionData.map((e, i) => (
                    <Cell key={i} fill={emotionColor(e.name)} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </StyledFlex>
          </StyledFlex>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => analyzeText()}>
            Re-run
          </Button>
          <Button onClick={() => toggleDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    keywords: state.textAnalysis.getIn(['NLUAnalysis', 'keywords']).take(10),
    sentiment: state.textAnalysis.getIn(['NLUAnalysis', 'sentiment', 'document']),
    emotion: state.textAnalysis.getIn(['NLUAnalysis', 'emotion', 'document', 'emotion']),
    loading: state.textAnalysis.get('loading'),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDialog: (open) => dispatch(toggleDialog(open)),
    analyzeText: () => dispatch(analyzeText()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextAnalysisDialog)
