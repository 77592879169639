import { CREATE_ARTICLE_SHOULD_SAVE_DRAFT } from '../../utils/constants/actions'

const loadDraft = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_SHOULD_SAVE_DRAFT) {
    if (action.flag) {
      const key = window.location.pathname.split('/')[1]
      const body = sessionStorage.getItem(`${key}.draftArticleComponents`)
      const metadata = sessionStorage.getItem(`${key}.draftArticleMetadata`)
      action.draftComponents = JSON.parse(body)
      action.draftMetadata = JSON.parse(metadata)
      action.contentType = key

      if (body || metadata) {
        action.showDraftDialog = true
      } else {
        action.showDraftDialog = false
      }
    }
  }

  return next(action)
}

export default loadDraft
