import React, { useRef } from 'react'

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import FormatClearIcon from '@mui/icons-material/FormatClear'

const clearToolbarButton = (
  <Tooltip title="Clear formatting">
    <IconButton className="ql-clear">
      <FormatClearIcon />
    </IconButton>
  </Tooltip>
)

const QuillClearFormatting = ({ children }) => {
  const quill = useRef()

  const clearFormattingHandler = () => {
    const editor = quill.current
    const { index, length } = editor.getSelection()

    const formats = editor.options.formats
    const data = formats.reduce((agg, fmt) => {
      if (fmt !== 'clear') agg[fmt] = false
      return agg
    }, {})

    editor.formatText(index, length, data)
  }

  return children({
    clearHandler: { clear: clearFormattingHandler },
    clearFormat: 'clear',
    clearToolbarButton,
    onEditorRef: (el) => (quill.current = el),
  })
}

export default QuillClearFormatting
