import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formValueSelector, change } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { togglePublishDialog, toggleInboundLinkUpdateDialog } from 'actions/createArticleActions'
import { publishArticle, searchInboundLinkedArticles } from 'actions/createArticleApiActions'
import removeHtmlTags from 'utils/removeHtmlTags'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { isArticlePublished } from 'utils/articleUtils'
import { isUrlValid } from 'utils/validators'
import convertTextToSlug from 'utils/convertTextToSlug'
import StyledFlex from '../../../layouts/StyledLayouts'
import CommonDialogTitle from '../../Common/DialogComponents/CommonDialogTitle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
  DialogActions,
  DialogContent,
  Dialog,
  Button,
  List,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  FormGroup,
  Box,
} from '@mui/material'

class PublishDialog extends Component {
  static propTypes = {
    open: PropTypes.bool,
    match: PropTypes.object,
    toggleDialog: PropTypes.func,
    publishArticle: PropTypes.func,
    pageTitle: PropTypes.string,
    previewTitle: PropTypes.string,
    headerTitle: PropTypes.string,
    previewImage: PropTypes.object,
    category: PropTypes.string,
    tags: PropTypes.array,
    publishDate: PropTypes.string,
    url: PropTypes.string,
    editorId: PropTypes.number,
    metaDescription: PropTypes.string,
    userCreatedUrl: PropTypes.bool,
    articleData: PropTypes.object,
    searchInboundLinkedArticles: PropTypes.func,
    inboundLinksLoading: PropTypes.bool,
    inboundLinks: PropTypes.object,
    openInboundLinkUpdateDialog: PropTypes.func,
    active: PropTypes.bool,
    legacyUrl: PropTypes.string,
    dek: PropTypes.string,
    expirationDate: PropTypes.string,
    enablePublishing: PropTypes.bool,
    changeFieldValue: PropTypes.func,
  }

  constructor(props) {
    super(props)
    const { articleData, url, searchInboundLinkedArticles, legacyUrl, changeFieldValue } = props

    if (isArticlePublished(articleData) && url !== articleData.get('url')) {
      searchInboundLinkedArticles(articleData.get('id'))
    }

    // check if the URL is formatted correctly
    const isUrlFormattedCorrectly = url && !legacyUrl ? isUrlValid(url, true) : true

    if (!isUrlFormattedCorrectly) {
      const formattedURL = convertTextToSlug(url)
      changeFieldValue('url', formattedURL)
    }

    this.state = {
      agreeLinks: false,
      noUrlFormattingErrors: isUrlFormattedCorrectly,
    }
  }

  render() {
    const {
      open,
      toggleDialog,
      publishArticle,
      pageTitle,
      previewTitle,
      headerTitle,
      previewImage,
      category,
      tags,
      publishDate,
      url,
      editorId,
      metaDescription,
      userCreatedUrl,
      inboundLinksLoading,
      inboundLinks,
      articleData,
      openInboundLinkUpdateDialog,
      active,
      legacyUrl,
      dek,
      expirationDate,
      enablePublishing,
      match: {
        params: { id },
      },
    } = this.props

    const { agreeLinks, noUrlFormattingErrors } = this.state

    const isValid =
      pageTitle &&
      previewTitle &&
      headerTitle &&
      dek &&
      previewImage &&
      category &&
      tags &&
      tags.length &&
      publishDate &&
      expirationDate &&
      ((active && legacyUrl && !userCreatedUrl) || url) &&
      editorId &&
      metaDescription

    const urlMismatch = isArticlePublished(articleData) && url !== articleData.get('url')

    return (
      <Dialog
        open={open}
        onClose={toggleDialog}
        maxWidth="md"
        title={
          noUrlFormattingErrors
            ? `Please confirm that all of the following fields have been filled out 
              in order for the article to be published:`
            : 'URL is not formatted correctly'
        }
        fullWidth
      >
        <CommonDialogTitle onClose={toggleDialog}>
          <div>
            {noUrlFormattingErrors
              ? `Please confirm that all of the following fields have been filled out 
              in order for the article to be published:`
              : 'URL is not formatted correctly'}
          </div>
        </CommonDialogTitle>
        <DialogContent>
          {noUrlFormattingErrors ? (
            <>
              <Box>
                <FormGroup row>
                  <List
                    sx={{ flexGrow: 2, minWidth: '50%' }}
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Body
                      </ListSubheader>
                    }
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <CheckCircleIcon color={removeHtmlTags(headerTitle) ? 'green' : 'pinkRed'} />
                      </ListItemIcon>
                      <ListItemText primary="Header Title" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon>
                        <CheckCircleIcon color={removeHtmlTags(dek) ? 'green' : 'pinkRed'} />
                      </ListItemIcon>
                      <ListItemText primary="Dek" />
                    </ListItemButton>
                  </List>

                  <List
                    sx={{ flexGrow: 2, minWidth: '50%' }}
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Tags
                      </ListSubheader>
                    }
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <CheckCircleIcon color={category ? 'green' : 'pinkRed'} />
                      </ListItemIcon>
                      <ListItemText primary="Category" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon>
                        <CheckCircleIcon color={tags && tags.length ? 'green' : 'pinkRed'} />
                      </ListItemIcon>
                      <ListItemText primary="Tags" />
                    </ListItemButton>
                  </List>
                </FormGroup>
                <FormGroup row>
                  <List
                    sx={{ flexGrow: 2, minWidth: '50%' }}
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Basic Details
                      </ListSubheader>
                    }
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <CheckCircleIcon color={editorId ? 'green' : 'pinkRed'} />
                      </ListItemIcon>
                      <ListItemText primary="Editor" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon>
                        <CheckCircleIcon color={previewTitle ? 'green' : 'pinkRed'} />
                      </ListItemIcon>
                      <ListItemText primary="Preview Title" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon>
                        <CheckCircleIcon color={previewImage ? 'green' : 'pinkRed'} />
                      </ListItemIcon>
                      <ListItemText primary="Preview Image" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon>
                        <CheckCircleIcon color={pageTitle ? 'green' : 'pinkRed'} />
                      </ListItemIcon>
                      <ListItemText primary="Page Title" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon>
                        <CheckCircleIcon color={publishDate ? 'green' : 'pinkRed'} />
                      </ListItemIcon>
                      <ListItemText primary="Publish Date" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon>
                        <CheckCircleIcon color={expirationDate ? 'green' : 'pinkRed'} />
                      </ListItemIcon>
                      <ListItemText primary="Expiration Date" />
                    </ListItemButton>
                  </List>
                  <List
                    sx={{ flexGrow: 2, minWidth: '50%' }}
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Seo & syndications
                      </ListSubheader>
                    }
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <CheckCircleIcon
                          color={url || (active && legacyUrl && !userCreatedUrl) ? 'green' : 'pinkRed'}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Article URL" />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemIcon>
                        <CheckCircleIcon color={metaDescription ? 'green' : 'pinkRed'} />
                      </ListItemIcon>
                      <ListItemText primary="Meta Description" />
                    </ListItemButton>
                  </List>
                </FormGroup>
              </Box>
              {urlMismatch && inboundLinks && inboundLinks.count() > 0 && (
                <div>
                  <StyledFlex>
                    You have changed the article URL. There are articles that link to this article. Would you like to
                    update the URL in those articles after this article is published?
                  </StyledFlex>
                  <RadioGroup
                    row
                    name="agreeLinks"
                    defaultValue={agreeLinks ? 'yes' : 'no'}
                    onChange={(e, v) => this.setState({ agreeLinks: v === 'yes' })}
                  >
                    <FormControlLabel value="no" control={<Radio />} label="No (Note: this is not good for SEO)" />
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  </RadioGroup>
                </div>
              )}
            </>
          ) : (
            <div>
              URL has been autocorrected as it was not formatted correctly. Please check the new URL, and note that you
              can modify it anytime after closing this modal.
              <p>
                <strong>New URL:</strong> <span style={{ fontSize: '1.4rem' }}>aaa{url}</span>
              </p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {noUrlFormattingErrors ? (
            <>
              <Button onClick={toggleDialog}>Cancel</Button>
              <Button
                color="secondary"
                onClick={() => {
                  if (!enablePublishing || !isValid || inboundLinksLoading) return
                  publishArticle(id)
                  agreeLinks && openInboundLinkUpdateDialog()
                  toggleDialog()
                }}
                disabled={!isValid || inboundLinksLoading || !enablePublishing}
              >
                Publish
              </Button>
            </>
          ) : (
            <Button color="primary" onClick={() => this.setState({ noUrlFormattingErrors: true })}>
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

const selector = formValueSelector('createArticleMetadataForm')
const mapStateToProps = (state) => {
  return {
    open: state.createArticleInteractions.get('showPublishDialog'),
    pageTitle: selector(state, 'titles.pageHeader'),
    previewTitle: selector(state, 'titles.site'),
    headerTitle: state.createArticleBody
      .getIn(['present', 'components'])
      .find((c) => c.get('type') === 'header')
      .get('title'),
    dek: state.createArticleBody
      .getIn(['present', 'components'])
      .find((c) => c.get('type') === 'header')
      .get('dek'),
    previewImage: selector(state, 'previewImage'),
    category: selector(state, 'category'),
    tags: selector(state, 'tags'),
    publishDate: selector(state, 'publishDate'),
    expirationDate: selector(state, 'expirationDate'),
    url: selector(state, 'url'),
    editorId: selector(state, 'assignedEditorId'),
    editorRating: selector(state, 'editorRating'),
    socialTitle: selector(state, 'titles.social'),
    socialCaption: selector(state, 'socialPromotion.caption'),
    metaDescription: selector(state, 'seo.metaDescription'),
    userCreatedUrl: state.createArticleMetadata.getIn(['metaData', 'userCreatedUrl']),
    articleData: state.createArticleMetadata.get('metaData'),
    inboundLinksLoading: state.createArticleInteractions.get('inboundLinksLoading'),
    inboundLinks: state.createArticleInteractions.getIn(['inboundLinks', 'articles']),
    active: state.createArticleMetadata.getIn(['metaData', 'active']),
    legacyUrl: state.createArticleMetadata.getIn(['metaData', 'legacyUrl']),
  }
}

const dispatchStateToProps = (dispatch) => {
  return {
    toggleDialog: () => dispatch(togglePublishDialog()),
    publishArticle: (articleId) => dispatch(publishArticle(articleId)),
    searchInboundLinkedArticles: (articleId) => dispatch(searchInboundLinkedArticles(articleId)),
    openInboundLinkUpdateDialog: () => dispatch(toggleInboundLinkUpdateDialog(true)),
    changeFieldValue: (field, value) => dispatch(change('createArticleMetadataForm', field, value)),
  }
}

export default connect(mapStateToProps, dispatchStateToProps)(withRouter(PublishDialog))
