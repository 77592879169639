import { CREATE_ARTICLE_CONVERT_IMAGE_COMPONENT } from 'utils/constants/actions'

const convertImageComponent = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_CONVERT_IMAGE_COMPONENT) {
    const { createArticleBody } = store.getState()
    const component = createArticleBody.getIn(['present', 'components', action.index])

    const bodyComponents = createArticleBody.getIn(['present', 'components']).toJS()
    if (component.get('type') === 'text-and-image') {
      const imageComponent = {
        id: Math.random().toString(36).substring(7),
        type: 'image',
        priority: 2,
        image: component.get('image').toJS(),
      }
      const textComponent = {
        id: Math.random().toString(36).substring(7),
        type: 'text',
        text: `<p><strong>${component.get('heading')}</strong></p>` + component.get('text'),
      }
      bodyComponents.splice(action.index, 1, textComponent, imageComponent)
    } else {
      const textAndImageComponent = {
        id: Math.random().toString(36).substring(7),
        type: 'text-and-image',
        heading: '',
        image: component.get('image').toJS(),
        text: '<p>Enter text here</p>',
        isMirrored: false,
        priority: 2,
      }
      bodyComponents.splice(action.index, 1, textAndImageComponent)
    }

    action.updatedBodyComponents = bodyComponents
  }

  return next(action)
}

export default convertImageComponent
