import PropTypes from 'prop-types'
import { useCallback, useMemo, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import ReactCrop from 'react-image-crop'
import Alert from '@mui/material/Alert'

import 'react-image-crop/src/ReactCrop.scss'
import StyledFlex, { StyledColumn } from '../../../../../layouts/StyledLayouts'

const AdjustCrop = (props) => {
  const [crop, setCrop] = useState()

  const imageRef = useRef()

  const isImageTooSmall = useCallback(() => {
    if (imageRef.current) {
      return props.crop.width > imageRef.current.naturalWidth
    }
    return false
  }, [props.crop.width])

  const getDisplayRelation = (containerWidth, containerHeight, naturalWidth, naturalHeight) => ({
    widthRel: containerWidth / naturalWidth,
    heightRel: containerHeight / naturalHeight,
  })

  const actualCropSize = useMemo(() => {
    if (!imageRef.current) {
      return undefined
    }
    const { widthRel, heightRel } = getDisplayRelation(
      imageRef.current.width,
      imageRef.current.height,
      imageRef.current.naturalWidth,
      imageRef.current.naturalHeight
    )

    return {
      width: Math.round(crop.width / widthRel),
      height: Math.round(crop.height / heightRel),
    }
  }, [crop])

  const getMinWidthHeight = () => {
    if (!imageRef.current) {
      return null
    }
    const { naturalWidth, naturalHeight, width, height } = imageRef.current

    const { widthRel, heightRel } = getDisplayRelation(width, height, naturalWidth, naturalHeight)

    const aspectBasedHeight = props.crop.width / props.crop.aspect
    return {
      minWidth: props.crop.width * widthRel,
      minHeight: aspectBasedHeight * heightRel,
    }
  }

  const getAbsoluteCrop = (image) => {
    const { naturalWidth, naturalHeight, width, height } = image

    const widthNaturalRel = naturalWidth / width
    const heightNaturalRel = naturalHeight / height

    return {
      x: Math.round(crop.x * widthNaturalRel),
      y: Math.round(crop.y * heightNaturalRel),
      width: Math.round(crop.width * widthNaturalRel),
      height: Math.round(crop.height * heightNaturalRel),
    }
  }

  const onImageLoad = (e) => {
    const { minWidth, minHeight } = getMinWidthHeight(e.currentTarget)

    let crop

    if (props.imageCrop) {
      // we have the current crop info, match initial crop state to that
      const { naturalWidth, naturalHeight, width, height } = e.currentTarget

      const { widthRel, heightRel } = getDisplayRelation(width, height, naturalWidth, naturalHeight)

      const x = props.imageCrop.cropStart.x * widthRel
      const y = props.imageCrop.cropStart.y * heightRel

      crop = {
        unit: 'px',
        x,
        y,
        width: props.imageCrop.cropEnd.x * widthRel - x,
        height: props.imageCrop.cropEnd.y * heightRel - y,
      }
    } else {
      // no initial crop info, initial crop state is the default
      crop = {
        unit: 'px',
        x: 0,
        y: 0,
        width: minWidth,
        height: minHeight,
      }
    }

    setCrop(crop)
  }

  const { uploadedImageUrl, onCropCancel, saveCropOverride } = props
  return (
    <StyledColumn style={{ gap: '12px' }}>
      {isImageTooSmall() && <Alert severity="warning">Selected image is smaller than minimum width.</Alert>}
      {actualCropSize && `Current crop (w: ${actualCropSize.width}, h: ${actualCropSize.height})`}
      <div>
        <ReactCrop
          {...getMinWidthHeight()}
          crop={crop}
          keepSelection
          aspect={props.crop.aspect}
          onChange={(c) => setCrop(c)}
        >
          <img ref={imageRef} src={uploadedImageUrl} alt="resizable" onLoad={onImageLoad} />
        </ReactCrop>
      </div>
      <StyledFlex style={{ justifyContent: 'flex-end' }}>
        <Button onClick={onCropCancel}>Cancel</Button>
        <Button onClick={() => saveCropOverride(getAbsoluteCrop(imageRef.current))}>Save</Button>
      </StyledFlex>
    </StyledColumn>
  )
}

AdjustCrop.propTypes = {
  onCropCancel: PropTypes.func,
  uploadedImageUrl: PropTypes.string,
  crop: PropTypes.object,
  imageCrop: PropTypes.object,
  saveCropOverride: PropTypes.func,
}

export default AdjustCrop
