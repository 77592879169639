import moment from 'moment'

import { CREATE_ARTICLE_SUBMIT_ARTICLE } from 'utils/constants/actions'
import { sendArticle } from 'actions/createArticleApiActions'

import { mergeComponents, toggleUserSubmit } from 'actions/createArticleActions'

import removeHtmlTags from 'utils/removeHtmlTags'
import removeEmptyFromObject from 'utils/removeEmptyFromObject'
import removeBodyIfEmpty from 'utils/removeBodyIfEmpty'
import removeHighlighting from 'utils/removeHighlighting'
import joinComponentsByType from 'utils/joinComponentsByType'
import removeEmptyLinesInQuill from 'utils/removeEmptyLinesInQuill'

import deltaToHTML from 'utils/deltaToHTML'
import omit from 'lodash/omit'
import isEqual from 'lodash/isEqual'
import customHistory from '../../utils/customHistory'

const submitArticle = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_SUBMIT_ARTICLE) {
    const { createArticleBody, profile, textAnalysis, createArticleMetadata, createArticleInteractions, recipes } =
      store.getState()
    const route = customHistory.location.pathname.split('/')[1]
    const results = { ...action.results }
    if (!results.titles) results.titles = {}

    const userId = profile.getIn(['profile', 'userId'])

    const textAnalysisLoaded = textAnalysis.get('loaded')
    const highlightedSameLinks = createArticleInteractions.get('highlightedSameLinks')

    results.timeSpent = createArticleMetadata.get('timeSpent').toJS()
    results.active = createArticleMetadata.getIn(['metaData', 'active'])
    results.statusFlags = createArticleMetadata.getIn(['metaData', 'statusFlags']).toJS()
    results.archiveStatus = createArticleMetadata.getIn(['metaData', 'archiveStatus'])
    if (results.archiveStatus) results.archiveStatus = results.archiveStatus.toJS()
    if (results.publishDate) results.publishDate = moment(results.publishDate).toISOString()

    delete results.synd
    delete results.editorName
    delete results.editor
    delete results.author

    if (!results.id) {
      results.createDate = moment().format()
      // TODO: create this behavior for other domains as well (recipes, podcasts)
      // results.assignedEditorId = userId
    }

    // set the latest version
    const currentVersion = createArticleMetadata.get('currentVersion')
    if (currentVersion) results.version = currentVersion
    else results.version = 0

    // uncheck daily newsletter
    // partnerIds can be [null] or [`number`]
    if (results.partnerIds && results.partnerIds[0]) results.syndications[1] = null

    let wordCount = 0

    let bodyComponents = createArticleBody.getIn(['present', 'components']).toJS()

    // --- require title in header for premium templates
    const template = createArticleMetadata.getIn(['metaData', 'template'])
    const header = bodyComponents.find((c) => c.type === 'header')
    const headerTitle = removeHtmlTags(header.title)
    if (template === 'premium' && !headerTitle) {
      action.error = false
      action.flashMessage = 'Title (header) is required in the premium template.'
      return next(action)
    }
    // end --- require title in header for premium templates

    // check if text analysis is ran
    if (textAnalysisLoaded || highlightedSameLinks) {
      results.NLUAnalysis = textAnalysis.get('NLUAnalysis').toJS()
      // if text analysis is ran there might be some highlighted words left
      bodyComponents = removeHighlighting(bodyComponents)
    }

    // default socialCaption to dek
    if (!results.socialPromotion && bodyComponents && bodyComponents.length) {
      results.socialPromotion = {
        caption: removeHtmlTags(bodyComponents[0].dek),
      }
    } else {
      results.socialPromotion = {
        caption: removeHtmlTags(bodyComponents[0].dek),
        ...results.socialPromotion,
      }
    }

    // move header and footer to right positions
    bodyComponents.forEach((c, i) => {
      if (c.type === 'introduction') bodyComponents.splice(1, 0, bodyComponents.splice(i, 1)[0])
      if (c.type === 'footer') bodyComponents.splice(bodyComponents.length, 0, bodyComponents.splice(i, 1)[0])
    })

    results.body = bodyComponents
      .filter((c) => {
        return c.type !== 'text' || !(removeHtmlTags(c.text) === '' || !c.text)
      })
      .map((c, index) => {
        if (c.type !== 'table') {
          delete c.id
        }
        delete c.fieldEmpty
        delete c.preserve
        delete c.active
        delete c.dummy
        // for now
        wordCount += c.wordCount || 0
        delete c.wordCount

        // this will be removed, output can be handled in Quill component
        if (c.type === 'header') {
          c.title = removeHtmlTags(c.title)
          results.titles.body = c.title
          c.subtitle = removeHtmlTags(c.subtitle)
          c.dek = removeHtmlTags(c.dek)
        } else if (c.type === 'list') {
          c.items.forEach((i) => {
            if (i) {
              i.text = removeHtmlTags(removeEmptyLinesInQuill(i.text))
            }
          })
        } else if (c.type === 'perspective') {
          c.perspectives.forEach((p) => {
            delete p.username
          })
        } else if (c.type === 'text') {
          // remove frontend-only formatting
          if (c.delta) {
            c.delta
              .filter((op) => op.attributes && op.attributes.link)
              .forEach((op) => (op.attributes.link.sameLink = false))
            c.delta
              .filter((op) => op.attributes && op.attributes.searchHighlight)
              .forEach((op) => (op.attributes.searchHighlight = false))
            c.text = deltaToHTML(c.delta)
            delete c.delta
          }

          // remove empty lines in text components
          c.text = removeEmptyLinesInQuill(c.text)
        }

        if (c.type !== 'text' && c.type !== 'text-and-image') {
          if (c.text) {
            c.text = removeHtmlTags(c.text)
          }
        }

        return c
      })

    // remove user name from notes
    if (results.editorsNotes) {
      results.editorsNotes = results.editorsNotes.map(({ userName, ...rest }) => rest)
    }

    results.body = joinComponentsByType(results.body, 'text', 'text')
    store.dispatch(mergeComponents())

    let finalResults = removeBodyIfEmpty(results)
    finalResults = removeEmptyFromObject(finalResults)

    // remove an old, unused field that would break data validation on server-side
    delete finalResults.editorsRating

    if (!finalResults.metaData) finalResults.metaData = {}
    finalResults.metaData['word count'] = wordCount
    finalResults.metaData = Object.keys(finalResults.metaData).map((key) => {
      return {
        property: key,
        value: finalResults.metaData[key],
      }
    })

    action.error = false
    action.flashMessage = ''

    const lastSavedState = createArticleInteractions.get('lastSavedState')
    const userSubmit = createArticleInteractions.get('userSubmit')

    if (!userSubmit && finalResults.id && lastSavedState) {
      store.dispatch(toggleUserSubmit())
      const redundantProps = ['timeSpent', 'version', 'author', 'editor']
      const a = omit(lastSavedState.toJS(), redundantProps)
      const b = omit(finalResults, redundantProps)
      if (isEqual(a, b)) return
    }

    if (!action.livePreview) {
      store.dispatch(sendArticle({ route, id: finalResults.id, data: { article: finalResults } }))
    } else {
      const previewArticle = { ...finalResults }

      // add recipe components for the preview
      if (previewArticle.body && previewArticle.body.length) {
        previewArticle.body.map((comp) => {
          if (comp.type === 'recipe') {
            const allRecipes = recipes.get('data').toJS()
            const recipe = allRecipes.find((r) => r.id === comp.recipeId)
            if (recipe) {
              const { body } = recipe
              if (body && body.length) {
                body.forEach((rBodyComp) => {
                  if (rBodyComp.type === 'recipeSteps') {
                    comp.recipeSteps = rBodyComp
                  }
                  if (rBodyComp.type === 'recipeIngredients') {
                    comp.recipeIngredients = rBodyComp
                  }
                  if (rBodyComp.type === 'recipeNotes') {
                    comp.recipeNotes = rBodyComp
                  }
                })
              }
            }
          }

          return comp
        })

        document.querySelector('#previewInput').value = JSON.stringify(previewArticle)
        document.querySelector('#previewForm').submit()
      }
    }

    action.id = finalResults.id
    delete action.results
  }

  return next(action)
}

export default submitArticle
