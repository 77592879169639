import { LOAD_ARTICLES_REQUEST } from 'utils/constants/actions'

import { RSAA } from 'redux-api-middleware'
import { getFormValues } from 'redux-form'

import generateQueryUrl from 'utils/generateQueryUrl'
import removeEmptyFromObject from 'utils/removeEmptyFromObject'
import { getNyStartOfDay } from '../utils/formatDate'
import { addMonths, endOfDay } from 'date-fns'

const applyArticlesFilters = (store) => (next) => (action) => {
  const values = action[RSAA]
  if (values && values.types.includes(LOAD_ARTICLES_REQUEST)) {
    let formValues = getFormValues('queueFilterForm')(store.getState()) || {}
    if (formValues.categories) {
      formValues = Object.assign({}, formValues, {
        categories: Object.keys(formValues.categories).filter((k) => formValues.categories[k]),
      })
    }

    const { articleQueueRangePicker, profile } = store.getState()
    const filters = { ...formValues }

    const from = articleQueueRangePicker.get('from')
    const to = articleQueueRangePicker.get('to')

    // apply date if at least "from" is valid
    if (from) {
      filters.start = from.toISOString()

      if (to) {
        filters.end = to.toISOString()
      } else {
        filters.end = endOfDay(from).toISOString()
      }
    } else {
      filters.start = getNyStartOfDay(new Date()).toISOString()
      filters.end = addMonths(getNyStartOfDay(new Date()), 1).toISOString()
    }
    filters.noPublishDate = articleQueueRangePicker.get('withNoPublishDate')

    // default params for own articles
    if (window.location.pathname.includes('my-')) {
      filters.editorId = profile.getIn(['profile', 'userId'])
    }

    const params = removeEmptyFromObject(filters)
    const endpoint = generateQueryUrl(values.endpoint, params)

    return next({
      [RSAA]: {
        ...values,
        endpoint,
      },
    })
  } else return next(action)
}

export default applyArticlesFilters
