import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import ItalicIcon from '@mui/icons-material/FormatItalic'
import BoldIcon from '@mui/icons-material/FormatBold'
import UnderlinedIcon from '@mui/icons-material/FormatUnderlined'
import DefaultTextField from 'ui/DefaultTextField'
import { QuillField } from 'ui/QuillTransition'
import imageUrl from 'utils/imageUrl'
import { grey30 } from 'styles/colors'
import styled from 'styled-components'
import StyledFlex, { StyledColumn } from '../../../../../layouts/StyledLayouts'

const SectionHeader = styled.h4`
  margin-bottom: 0;
  color: ${grey30};
`

const PreviewImage = styled.img`
  max-width: auto;
  max-height: 250px;
`

const SlideTitleToolbar = ({ id }) => (
  <Paper elevation={2} id={id}>
    <IconButton className="ql-italic" style={{ margin: '10px 10px 10px 5px' }}>
      <ItalicIcon />
    </IconButton>
  </Paper>
)

SlideTitleToolbar.propTypes = {
  id: PropTypes.string,
}

const SlideTitleInput = ({ input: { value, onChange } }) => (
  <QuillField
    toolbar={<SlideTitleToolbar />}
    bounds="#gallery-slide-form"
    formats={['italic']}
    onChange={onChange}
    value={value || ''}
    placeholder="Slide title"
    activeFormat={{ name: 'header', value: 4 }}
  />
)

SlideTitleInput.propTypes = {
  input: PropTypes.object,
}

const SlideTextToolbar = ({ id }) => (
  <Paper elevation={2} id={id}>
    <IconButton className="ql-bold">
      <BoldIcon />
    </IconButton>
    <IconButton className="ql-italic">
      <ItalicIcon />
    </IconButton>
    <IconButton className="ql-underline">
      <UnderlinedIcon />
    </IconButton>
  </Paper>
)

SlideTextToolbar.propTypes = {
  id: PropTypes.string,
}

const SlideTextInput = ({ input: { value, onChange }, placeholder }) => (
  <QuillField
    toolbar={<SlideTextToolbar />}
    bounds="#gallery-slide-form"
    customFormats={['italic', 'bold', 'underline']}
    onChange={onChange}
    value={value || ''}
    placeholder={placeholder}
    activeFormat={{ name: 'header', value: 4 }}
  />
)

SlideTextInput.propTypes = {
  input: PropTypes.object,
  placeholder: PropTypes.string,
}

class SlideData extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    handleEditImageData: PropTypes.func,
    hasLocation: PropTypes.bool,
    initialValues: PropTypes.object,
    image: PropTypes.object,
  }

  render() {
    const { handleSubmit, handleCancel, hasLocation, initialValues, handleEditImageData, image } = this.props

    const editMode = initialValues !== null

    return (
      <form id="gallery-slide-form" onSubmit={handleSubmit}>
        {image && <PreviewImage src={imageUrl(image.path)} alt="preview" />}
        <SectionHeader>Slide data</SectionHeader>
        <StyledColumn style={{ gap: '12px' }}>
          <Field component={SlideTitleInput} name="title" />
          <Field component={SlideTextInput} name="text" placeholder="Slide text" />
        </StyledColumn>

        {hasLocation && <SectionHeader>Location data</SectionHeader>}
        {hasLocation && (
          <StyledColumn style={{ gap: '12px' }}>
            <Field component={DefaultTextField} fullWidth label="Location name" name="locationName" />
            <Field component={SlideTextInput} name="address" placeholder="Address" />
          </StyledColumn>
        )}
        <StyledFlex style={{ justifyContent: 'space-between' }}>
          <Button onClick={handleCancel}>Go back</Button>
          <StyledColumn>
            {editMode && <Button onClick={handleEditImageData}>Edit image</Button>}
            <Button type="submit">{editMode ? 'Save' : 'Upload image'}</Button>
          </StyledColumn>
        </StyledFlex>
      </form>
    )
  }
}

export default compose(
  connect(),
  reduxForm({
    form: 'slideData',
    asyncBlurFields: [],
  })
)(SlideData)
