import { fromJS } from 'immutable'
import blueprints from 'store/blueprints'

export const initialCreateArticleBodyState = fromJS({
  past: [],
  present: {
    components: blueprints.blank,
    doMerge: false,
  },
  future: [],
  activeComponentIndex: 0,
  wordCount: 0,
  internalLinks: 0,
  externalLinks: 0,
  lastInsertedLink: null,
})
