// blues
export const skyBlue = '#5fccff'
export const darkSkyBlue = '#2db4e5'
export const cerulean = '#1976d2' // primary1Color, we might want to unify these with main mui theme
export const mediumBlue = '#3054c1'
export const oceanBlue = '#006db2' // primary2Color

// purples
export const purple = '#7d5bae'
export const purple30 = '#9575CD'

// blue greys
export const blueGrey40 = '#78909C'

// greens
export const greenApple = '#76c950'
export const green = '#00E676'

// reds
export const pinkRed = '#ff0066' // accent1Color
export const red = '#F44336'

// yellows
export const squash = '#edba19'
export const orange = '#fe8d06'
export const starDustOrange = '#ff8400'
export const dustyOrange = '#e88440'

// grey
export const grey = '#d8d8d8'
export const grey05 = '#424242'
export const grey10 = '#4a4a4a'
export const grey15 = '#dcdcdc'
export const grey20 = '#727272'
export const grey30 = '#a8a8a8'
export const grey40 = '#fafafa'
export const grey50 = '#f2f2f2'
export const grey60 = '#eeeeee'

// black
export const black = '#4a4a4a'

export const podcastTeal = '#36a58a'
