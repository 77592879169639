import { ListSubheader } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { grey15, cerulean, pinkRed } from 'styles/colors'

const breaks = [0, 12.5, 25, 37.5, 50, 62.5, 75, 87.5, 99.5]
const labels = [
  { value: -1, position: 0 },
  { value: -0.75, position: '12.5%' },
  { value: -0.5, position: '25%' },
  { value: -0.25, position: '37.5%' },
  { value: 0, position: '50%' },
  { value: 0.25, position: '62.5%' },
  { value: 0.5, position: '75%' },
  { value: 0.75, position: '87.5%' },
  { value: 1, position: '99%' },
]

const Sentiment = styled.div`
  width: 100%;
  height: 20px;
  background-color: ${grey15};
`

const customStyles = {
  labelPositive: {
    fontSize: '16px',
    color: cerulean,
    paddingLeft: 0,
  },
  labelNegative: {
    fontSize: '16px',
    color: pinkRed,
    paddingLeft: 0,
  },
}

const SentimentBar = ({ label, score }) => {
  const width = `${Math.round(score * 100) / 2}%`
  let x = '50%'
  if (label !== 'Positive') x = `${50 - Math.round(score * 100) / 2}%`

  return (
    <div>
      <ListSubheader style={label === 'Positive' ? customStyles.labelPositive : customStyles.labelNegative}>
        {label}: {Math.round(score * 1000) / 1000}
      </ListSubheader>
      <Sentiment>
        <svg width="100%" height="20">
          {label === 'Positive' ? (
            <rect width={width} height="20" x={x} style={{ fill: cerulean }} />
          ) : (
            <rect width={width} height="20" x={x} style={{ fill: pinkRed }} />
          )}
        </svg>
      </Sentiment>
      <svg width="100%" height="20">
        {breaks.map((b, i) => (
          <text key={i} x={`${b}%`} y="10" fontSize={10}>
            |
          </text>
        ))}
        {labels.map((l, i) => (
          <text key={i} x={l.position} y="20" fontSize={9}>
            {l.value}
          </text>
        ))}
      </svg>
    </div>
  )
}

SentimentBar.propTypes = {
  label: PropTypes.string,
  score: PropTypes.number,
}

export default SentimentBar
