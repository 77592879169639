import { CREATE_ARTICLE_LINK_SEARCH, CREATE_ARTICLE_SET_LINK_SEARCH_PATTERN } from 'utils/constants/actions'

import deltaToHTML from 'utils/deltaToHTML'

const searchLinks = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_LINK_SEARCH) {
    const { createArticleBody } = store.getState()
    const bodyComponents = createArticleBody.getIn(['present', 'components']).toJS()

    let linksFound = 0
    bodyComponents.forEach((c) => {
      if (c.type !== 'text' || !c.delta) return

      c.delta.forEach((op) => {
        if (op.attributes && op.attributes.link) {
          op.attributes.searchHighlight = action.pattern.length > 3 && op.attributes.link.url.includes(action.pattern)
          op.attributes.searchHighlight && linksFound++
        }
      })

      c.text = deltaToHTML(c.delta)
    })

    action.updatedBodyComponents = bodyComponents
    action.linksFound = linksFound
  }

  if (action.type === CREATE_ARTICLE_SET_LINK_SEARCH_PATTERN && action.pattern.length > 3) {
    const { createArticleBody } = store.getState()
    const bodyComponents = createArticleBody.getIn(['present', 'components']).toJS()

    const linksFound = bodyComponents.reduce((count, next) => {
      if (next.type !== 'text' || !next.delta) return count

      return (
        count +
        next.delta.reduce((total, op) => {
          const matches = op.attributes && op.attributes.link && op.attributes.link.url.includes(action.pattern)
          return total + (matches ? 1 : 0)
        }, 0)
      )
    }, 0)

    action.linksFound = linksFound
  }

  return next(action)
}

export default searchLinks
