import { Map } from 'immutable'
import { INTERNET_CONNECTION_SET_STATUS } from 'utils/constants/actions'

const internetConnectionReducer = (state = Map(), action) => {
  switch (action.type) {
    case INTERNET_CONNECTION_SET_STATUS:
      return state.set('online', action.online)
    default:
      return state
  }
}

export default internetConnectionReducer
