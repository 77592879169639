import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import Chip from '@mui/material/Chip'

import { updateModule } from 'api/classes'
import SaveClassCTAs from '../SaveClassCTAs'
import { useStore } from '../../../../../store/mobx'
import { logError } from '../../../../../utils/errorHandling'
import { CenterWrapper, CTAWrapper } from '../../../../NavBar/CTAWrapper'

const SaveLessonDragChanges = (props) => {
  const { classes } = useStore('classesStore')
  const data = classes.find((item) => item.id === props.classId)

  const handleSave = async () => {
    const moduleIndex = data.modules.findIndex((m) => m.id === props.activeModuleId)

    if (moduleIndex === -1) {
      logError(`No module found on save: ${props.activeModule.id}, ${props.classId}`)
      return
    }

    if (!data.modules[moduleIndex].sessions) {
      return null
    }

    await updateModule(props.classId, props.activeModuleId, {
      ...data[moduleIndex],
      sessions: data.modules[moduleIndex].sessions.map((m) => m.id),
    })

    if (props.handleDismissWarning) {
      props.handleDismissWarning()
    }
  }

  return (
    <>
      {typeof props.warningTitle === 'string' && (
        <CenterWrapper>
          <Chip color="warning" label={props.warningTitle} />
        </CenterWrapper>
      )}
      <CTAWrapper>
        <SaveClassCTAs handleSave={handleSave} backUrl={props.backUrl} />
      </CTAWrapper>
    </>
  )
}

SaveLessonDragChanges.propTypes = {
  classId: PropTypes.number,
  activeModuleId: PropTypes.string,
  warningTitle: PropTypes.string,
  handleDismissWarning: PropTypes.func,
  backUrl: PropTypes.string,
}

export default observer(SaveLessonDragChanges)
