import { Map, fromJS } from 'immutable'
import {
  GET_EMBED_DATA_REQUEST,
  GET_EMBED_DATA_SUCCESS,
  GET_EMBED_DATA_FAILURE,
  EMBED_RESET,
} from 'utils/constants/actions'

const embedDataReducer = (state = Map(), action) => {
  switch (action.type) {
    case GET_EMBED_DATA_REQUEST:
      return state.set('loading', true)
    case GET_EMBED_DATA_SUCCESS:
      return state.set('data', fromJS(action.payload)).set('loading', false).set('error', false)
    case GET_EMBED_DATA_FAILURE:
      return state.set('error', true).set('loading', false)
    case EMBED_RESET:
      return state.set('data', null).set('loading', false).set('error', false)
    default:
      return state
  }
}

export default embedDataReducer
