import styled from 'styled-components'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const CloseIconStyled = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

const AppBarButtons = () => {
  return (
    <>
      <Link to={'/class-subscriptions'}>
        <IconButton>
          <CloseIconStyled />
        </IconButton>
      </Link>
    </>
  )
}

export default AppBarButtons
