import { ApiError, RSAA } from 'redux-api-middleware'
import initCognitoSdk from 'auth/cognito'
import customHistory from '../utils/customHistory'

const { REACT_APP_ENVIRONMENT } = process.env

const authErrorMiddleware = (store) => (next) => (action) => {
  if (action.error && action.payload instanceof ApiError) {
    if (action.payload.status === 401) {
      if (REACT_APP_ENVIRONMENT?.toUpperCase() === 'QA') {
        const testerId = sessionStorage.getItem('tester_id')
        if (!testerId) {
          customHistory.push('/login')
        }
      } else {
        const auth = initCognitoSdk()
        const session = auth.getCachedSession()

        // refresh the session?
        if (session.getRefreshToken() && session.getRefreshToken().getToken()) {
          auth.getSession()

          // retry original action
          if (action.meta && action.meta.originalAction) {
            return store.dispatch({
              [RSAA]: {
                ...action.meta.originalAction,
              },
            })
          }
        }
      }
    }
  }
  return next(action)
}

export default authErrorMiddleware
