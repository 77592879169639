import { Quill } from 'react-quill'

const deleteSelectedTextInQuill = (component, selection) => {
  const delta = component.delta
  const editorDiv = document.createElement('div')
  const editor = new Quill(editorDiv)
  editor.setContents(delta)
  editor.deleteText(selection)
  component.text = editorDiv.getElementsByClassName('ql-editor')[0].innerHTML
  editorDiv.remove()

  return component
}

export default deleteSelectedTextInQuill
