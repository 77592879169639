import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import WarningIcon from '@mui/icons-material/Warning'
import WithProgress from 'ui/WithProgress'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { logError } from '../../../../utils/errorHandling'

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  ul {
    overflow-wrap: break-word;
  }
`

const CloseIconStyled = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

const SaveButton = styled(Button)`
  padding-left: 2rem;
  background-color: ${({ theme }) => theme.palette.invertedLight.main};
  padding-right: 2rem;
  color: ${({ theme }) => theme.palette.primary.dark};

  :hover {
    background-color: ${({ theme }) => theme.palette.invertedDark.main};
  }
`

const SaveClassCTAs = ({ handleSave, backUrl, router }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState()

  const history = useHistory()

  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  })

  useEffect(() => {
    const timer = isSuccess && setTimeout(() => setIsSuccess(false), 2000)
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [isSuccess])

  const handleSaveClick = async (e) => {
    try {
      setIsLoading(true)
      await handleSave(e)
      if (isMounted.current) {
        setIsSuccess(true)
      }
    } catch (error) {
      logError(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleBack = () => {
    if (backUrl) {
      history.push(backUrl)
    } else {
      history.goBack()
    }
  }

  const clearError = () => {
    setError(undefined)
  }

  return (
    <>
      <WithProgress isLoading={isLoading} style={{ marginRight: '2rem' }}>
        <SaveButton
          disabled={isLoading}
          variant="contained"
          type="submit"
          form="class-edit-form"
          onClick={handleSaveClick}
        >
          Save{isSuccess && 'd '}
          {isSuccess && <CheckIcon color="primary" sx={{ marginLeft: '0.5rem' }} />}
        </SaveButton>
      </WithProgress>

      <IconButton onClick={handleBack}>
        <CloseIconStyled />
      </IconButton>

      {error && (
        <Dialog open onClose={clearError}>
          <DialogTitle>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <WarningIcon color="secondary" /> Error saving
            </div>
          </DialogTitle>
          <DialogContent style={{ padding: '20px' }}>
            <ErrorsContainer>
              <h4>{error.message}</h4>
              {error.errors && (
                <ul>
                  {error.errors.map((e) => (
                    <li>{JSON.stringify(e)}</li>
                  ))}
                </ul>
              )}
            </ErrorsContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={clearError}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

SaveClassCTAs.propTypes = {
  handleSave: PropTypes.func,
  backUrl: PropTypes.string,
}

export default SaveClassCTAs
