import { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { greenApple } from 'styles/colors'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Archive'
import Tooltip from '@mui/material/Tooltip'
import Chip from '@mui/material/Chip'

import { PreviewImage } from '../PreviewImage'
import TitleColumn from './TitleColumn'
import ArticleViewing from '../ArticleViewing'
import PublishTimePicker from 'ui/PublishTimePicker'
import formatDate from 'utils/formatDate'
import { isArticlePublished } from '../../../utils/articleUtils'
import StyledFlex, { StyledColumn } from '../../../layouts/StyledLayouts'
import { useUserSessions } from '../../Presence/useUserSessions'
import useRefreshArticle from '../useRefreshArticle'
import { RootDispatch } from '../../../App'
import { refreshArticle, resetState } from 'actions/articlesActions'
import ConfirmMultipleKickDialog from '../../CreateArticle/Dialogs/ConfirmMultipleKickDialog'
import { ArticleQueueHover, BaseColumn, InnerWrapper, Wrapper } from './ArticleStyles'
import DetailsColumn from './DetailsColumn'

const { REACT_APP_MBG_SITE } = process.env

const StyledTimeCircle = styled.div`
  background-color: ${greenApple};
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
`

const StyledPublishTime = styled.div`
  float: left;
`

const commonIconStyles = `
margin-left: 0.4rem;
cursor: pointer;
`

const StyledEditIcon = styled(EditIcon)`
  ${commonIconStyles}
`

const StyledRedEyeIcon = styled(RemoveRedEyeIcon)`
  ${commonIconStyles}
`

const StyledArchiveIcon = styled(ArchiveIcon)`
  ${commonIconStyles}
`

const tooltipReady = 'Ready to publish'

interface Props extends ReturnType<typeof mapDispatchToProps> {
  articleIdx: number
  dateKey: string
  presenceKey: string
  article: Record<string, any>
  updateEditorsNotes: () => void
  setPublishDT: (a: string) => void
  toggleArchiveDialog: (a: Record<string, any>) => void
  updateArticle: (a: Record<string, any>) => void
  pathname: string
  toggleCompressedViewItem: () => void
  showFilter: boolean
  isArticle: boolean
  refreshKey: string
}

export const Article = ({
  articleIdx,
  dateKey,
  article,
  updateEditorsNotes,
  setPublishDT,
  toggleArchiveDialog,
  updateArticle,
  pathname,
  toggleCompressedViewItem,
  presenceKey,
  refreshKey,
  showFilter,
  isArticle,
  refreshArticle,
  changeTagFilter,
  resetState,
}: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { userSessions, kickUser } = useUserSessions({ presenceKey })

  useRefreshArticle({
    articleId: article.get('id'),
    refreshKey,
    refreshArticle: useCallback(refreshArticle, [refreshArticle]),
  })

  const onTagClick = (tag: string) => {
    changeTagFilter(tag)
    resetState()
  }

  const isPublished = isArticlePublished(article)
  const compressed = article.get('compressed')

  const previewLink =
    !article.get('userCreatedUrl') && article.get('legacyUrl')
      ? `${REACT_APP_MBG_SITE}0-${article.get('id')}/preview.html?preview=true`
      : `${REACT_APP_MBG_SITE}articles/preview?id=${article.get('id')}`

  return (
    <>
      <Wrapper $gap onDoubleClick={toggleCompressedViewItem} compressed={compressed}>
        <InnerWrapper compressed={compressed}>
          <StyledFlex style={{ width: compressed ? '10%' : '15%', alignItems: 'center' }}>
            <PreviewImage image={article.get('previewImage')} articleId={article.get('id')} pathname={pathname} />
          </StyledFlex>
          <StyledColumn
            style={{
              width: compressed ? '23%' : '30%',
              justifyContent: 'center',
            }}
          >
            <TitleColumn
              articleId={article.get('id')}
              category={article.get('category')}
              template={article.get('template')}
              roles={article.getIn(['author', 'roles'])}
              influencer={article.getIn(['author', 'tier']) === 1}
              partnerIds={article.get('partnerIds')}
              title={article.getIn(['titles', 'site']) || article.get('slug')}
              url={article.get('url')}
              compressed={compressed}
              userCreatedUrl={article.get('userCreatedUrl')}
              legacyUrl={article.get('legacyUrl')}
              pathname={pathname}
            />
          </StyledColumn>
          <BaseColumn>{article.getIn(['author', 'name'])}</BaseColumn>
          <DetailsColumn
            compressed={compressed}
            article={article}
            articleIdx={articleIdx}
            updateEditorsNotes={updateEditorsNotes}
            updateArticle={updateArticle}
          />
          {compressed ? (
            <StyledColumn style={{ width: '12%', justifyContent: 'center', alignItems: 'flex-end' }}>
              {userSessions.length > 0 && (
                <div>{article.get('publishDate') ? formatDate(article.get('publishDate'), 'h:mm a') : 'no date'}</div>
              )}
              {userSessions.length === 0 && (
                <PublishTimePicker
                  value={article.get('publishDate')}
                  onDateChange={(dateTime: string) => {
                    setPublishDT(dateTime)
                    updateArticle({ publishDate: dateTime })
                  }}
                />
              )}
            </StyledColumn>
          ) : (
            <StyledColumn
              style={{
                width: '15%',
                alignItems: 'flex-end',
              }}
            >
              <StyledFlex style={{ marginTop: '2rem', gap: '4px', alignItems: 'center' }}>
                <ArticleViewing userSessions={userSessions} handleDialogOpen={() => setIsDialogOpen(true)} />

                {isPublished && (
                  <Tooltip title={tooltipReady}>
                    <StyledTimeCircle />
                  </Tooltip>
                )}
                <StyledPublishTime>
                  {userSessions.length > 0 && (
                    <div>
                      {article.get('publishDate') ? formatDate(article.get('publishDate'), 'h:mm a') : 'no date'}
                    </div>
                  )}
                  {userSessions.length === 0 && (
                    <PublishTimePicker
                      value={article.get('publishDate')}
                      onDateChange={(dateTime: string) => {
                        setPublishDT(dateTime)
                        updateArticle({ publishDate: dateTime })
                      }}
                    />
                  )}
                </StyledPublishTime>
              </StyledFlex>
              <ArticleQueueHover>
                <StyledFlex>
                  <Link to={`${pathname}/${encodeURIComponent(article.get('id'))}`}>
                    <StyledEditIcon color="primary" />
                  </Link>
                  <a href={previewLink} target="_blank" rel="noreferrer">
                    <StyledRedEyeIcon color="primary" />
                  </a>
                  {isArticle && (
                    <StyledArchiveIcon
                      color="primary"
                      onClick={() =>
                        toggleArchiveDialog({
                          show: true,
                          id: article.get('id'),
                          isPublished,
                          articlePath: ['articles', dateKey, articleIdx],
                        })
                      }
                    />
                  )}
                </StyledFlex>
              </ArticleQueueHover>
            </StyledColumn>
          )}
          {compressed && (
            <StyledColumn style={{ width: '13%', justifyContent: 'center', alignItems: 'flex-end' }}>
              <StyledFlex style={{ marginTop: '1rem' }}>
                {isPublished && (
                  <Tooltip title={tooltipReady}>
                    <StyledTimeCircle />
                  </Tooltip>
                )}
                <Link to={`${pathname}/${encodeURIComponent(article.get('id'))}`}>
                  <StyledEditIcon color="primary" />
                </Link>
                <a href={previewLink} target="_blank" rel="noreferrer">
                  <StyledRedEyeIcon color="primary" />
                </a>
                {isArticle && (
                  <StyledArchiveIcon
                    color="primary"
                    onClick={() =>
                      toggleArchiveDialog({
                        show: true,
                        id: article.get('id'),
                        isPublished,
                        articlePath: ['articles', dateKey, articleIdx],
                      })
                    }
                  />
                )}
              </StyledFlex>
            </StyledColumn>
          )}
        </InnerWrapper>
        {!compressed && showFilter && article.get('internalTags') && (
          <StyledFlex>
            {article.get('internalTags').map((tag: string, idx: number) => (
              <Chip key={idx} style={{ marginRight: 5 }} label={tag} onClick={() => onTagClick(tag)} />
            ))}
          </StyledFlex>
        )}
      </Wrapper>

      <ConfirmMultipleKickDialog
        users={userSessions}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        kickUser={kickUser}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  changeTagFilter: (tag: string) => dispatch(change('queueFilterForm', 'internalTags', [tag])),
  resetState: () => dispatch(resetState()),
  refreshArticle: (route: string, id: string) => dispatch(refreshArticle(route, id)),
})

export default connect(undefined, mapDispatchToProps)(Article)
