import React from 'react'
import PropTypes from 'prop-types'
import { adopt } from 'react-adopt'
import { over } from 'lodash'

import QuillField from './QuillField'
import BaseToolbar from './BaseToolbar'
import QuillClearFormatting from './QuillClearFormatting'
import QuillLinkFormat from './QuillLinkFormat'
import QuillLinkConversion from './QuillLinkConversion'
import QuillInsertKeyword from './QuillInsertKeyword'
import QuillConvertToComponent from './QuillConvertToComponent'
import ClearOnClick from './ClearOnClick'
import QuillMiscFormats from './QuillMiscFormats'
import QuillKeyQuote from './QuillKeyQuote'
import HighlightLinks from './HighlightLinks'
import MoveWithArrows from './MoveWithArrows'

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import StrikeThroughIcon from '@mui/icons-material/FormatStrikethrough'

const strikeToolbarButton = (
  <Tooltip title="Strikethrough">
    <IconButton className="ql-strike">
      <StrikeThroughIcon />
    </IconButton>
  </Tooltip>
)

const Composed = adopt({
  clear: <QuillClearFormatting />,
  link: ({ selectionOnly, showFlashMessage, render }) => (
    <QuillLinkFormat selectionOnly={selectionOnly} showFlashMessage={showFlashMessage} children={render} />
  ),
  convertLink: <QuillLinkConversion />,
  keyword: <QuillInsertKeyword />,
  convertComponent: ({ convertToComponent, render }) => (
    <QuillConvertToComponent convertToComponent={convertToComponent} children={render} />
  ),
  miscFormats: <QuillMiscFormats />,
  keyQuote: ({ handleKeyQuote, render }) => <QuillKeyQuote handleKeyQuote={handleKeyQuote} children={render} />,
  highlightLinks: <HighlightLinks />,
  moveWithArrows: <MoveWithArrows />,
  clickClear: ({ clearOnClick, render }) => <ClearOnClick enabled={clearOnClick} children={render} />,
})

const ArticleBodyQuill = ({
  isArticle,
  convertToComponent,
  handleKeyQuote,
  clearOnClick,
  selectionOnly,
  showFlashMessage,
  ...props
}) => (
  <Composed
    convertToComponent={convertToComponent}
    handleKeyQuote={handleKeyQuote}
    clearOnClick={clearOnClick}
    selectionOnly={selectionOnly}
    showFlashMessage={showFlashMessage}
  >
    {(plugins) => {
      const {
        clear,
        link,
        convertLink,
        keyword,
        convertComponent,
        moveWithArrows,
        highlightLinks,
        keyQuote,
        miscFormats,
        clickClear,
      } = plugins
      const { clearFormat, clearToolbarButton } = clear
      const { linkFormat, linkToolbarButton } = link
      const { keywordFormat, keywordToolbarButton } = keyword
      const { convertFormat, convertToolbarButton } = convertComponent
      const { kquoteFormat, keyQuoteToolbarButton } = keyQuote

      return (
        <QuillField
          {...props}
          className="quill-line-margin-bottom"
          onEditorRef={over([
            props.onEditorRef,
            clear.onEditorRef,
            link.onEditorRef,
            convertLink.onEditorRef,
            keyword.onEditorRef,
            convertComponent.onEditorRef,
            miscFormats.onEditorRef,
            keyQuote.onEditorRef,
            highlightLinks.onEditorRef,
            moveWithArrows.onEditorRef,
            clickClear.onEditorRef,
          ])}
          onChange={over([props.onChange, convertLink.onChange, highlightLinks.onChange])}
          onChangeSelection={over([
            props.onChangeSelection,
            link.onChangeSelection,
            keyword.onChangeSelection,
            convertComponent.onChangeSelection,
          ])}
          onClick={over([props.onClick, clickClear.onClick])}
          formats={[
            'bold',
            'italic',
            'underline',
            'strike',
            linkFormat,
            keywordFormat,
            convertFormat,
            ...miscFormats.formats,
            kquoteFormat,
            clearFormat,
          ]}
          toolbar={
            !selectionOnly && (
              <BaseToolbar>
                {strikeToolbarButton}
                {linkToolbarButton}
                {keywordToolbarButton}
                {isArticle && keyQuoteToolbarButton}
                {convertToolbarButton}
                {miscFormats.scriptToolbarButton}
                {miscFormats.fractionToolbarButton}
                {clearToolbarButton}
              </BaseToolbar>
            )
          }
          handlers={{
            ...clear.clearHandler,
            ...link.linkHandler,
            ...keyword.keywordHandler,
            ...keyQuote.kquoteHandler,
            ...convertComponent.convertHandler,
            ...miscFormats.handlers,
          }}
          onKeyDown={(e) => {
            if (selectionOnly && ![37, 38, 39, 40].includes(e.keyCode)) {
              // allow arrow keys
              e.stopPropagation()
              e.preventDefault()
            }
          }}
          keyboardBindings={{ ...moveWithArrows.keyboardBindings }}
          showFlashMessage={showFlashMessage}
        />
      )
    }}
  </Composed>
)

ArticleBodyQuill.propTypes = {
  isArticle: PropTypes.bool,
  convertToComponent: PropTypes.func,
  handleKeyQuote: PropTypes.func,
  clearOnClick: PropTypes.bool,
  selectionOnly: PropTypes.bool,
  showFlashMessage: PropTypes.func,
  onEditorRef: PropTypes.func,
  onChangeSelection: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
}

export default ArticleBodyQuill
