import styled from 'styled-components'

import { grey30 } from 'styles/colors'

const Wrapper = styled.h2`
  text-align: center;
  margin-top: 150px;
  font-style: italic;
  color: ${grey30};
`

const NoResults = () => <Wrapper>There are no results</Wrapper>

export default NoResults
