import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import { useStore } from 'store/mobx'
import Alert from '@mui/material/Alert'
import Loader from 'components/new-pages/Loader'
import useGetLessonDetails from './useGetLessonDetails'
import ClassBreadcrumbs from '../../../../components/new-pages/Classes/Breadcrumbs'
import { lessonComponents } from '..'
import { Suspense } from 'react'
import Form from 'components/new-pages/Classes/Lessons/EditDownloadLesson'

const DownloadLesson = ({ match }) => {
  const params = match.params
  const classId = params.id ? Number(params.id) : null

  const { classes } = useStore('classesStore')
  const classItem = classes.find((item) => item.id === classId)

  const { notFoundMsg, activeLesson, activeLessonIdx, moduleId, module } = useGetLessonDetails(
    params,
    classItem?.fetchClassDetails,
    classItem?.modules
  )

  if (!classItem?.detailsLoaded) {
    return <Loader />
  }

  return (
    <>
      <ClassBreadcrumbs
        classTitle={classItem?.title}
        classId={classItem?.id}
        lessonId={activeLesson?.id}
        moduleId={module?.id}
        moduleTitle={module?.title}
        lessonTitle={activeLesson?.title}
        lessonType={lessonComponents.download.id}
      />
      {classItem?.error?.message && <Alert severity="error">{classItem?.error.message}</Alert>}
      {notFoundMsg && <Alert severity="error">{notFoundMsg}</Alert>}
      {classItem && (
        <Form
          cls={classItem}
          activeLesson={activeLesson}
          activeLessonIdx={activeLessonIdx}
          moduleId={moduleId}
          lessons={module?.lessons}
        />
      )}
    </>
  )
}

DownloadLesson.propTypes = {
  params: PropTypes.object,
}

export default observer(DownloadLesson)
