import VersionList from './List'

const ClassVersions = ({
  classData,
  classId,
  setEditDialogData,
  setEditDialogOpen,
  versions,
  terms,
  setDeleteID,
}) => {
  return (
    <div>
      <h2>Versions</h2>
      <VersionList
        versions={versions}
        terms={terms}
        classId={classId}
        classData={classData}
        setEditDialogData={setEditDialogData}
        setEditDialogOpen={setEditDialogOpen}
        setDeleteID={setDeleteID}
      />
    </div>
  )
}

export default ClassVersions
