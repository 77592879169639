import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'

import { Navbar } from 'components/NavBar'
import SideMenu from 'components/SideMenu'

import { StyledColumn } from './StyledLayouts'
import { CreateButton } from '../components/Common/CreateButton'
import { ContentWapper } from './layoutStyles'

const ProductsLayout = ({ children, title }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <StyledColumn>
      <Navbar title={title} showLogo toggleSidemenu={() => setIsDrawerOpen((p) => !p)} />
      <SideMenu open={isDrawerOpen} toggleSidemenu={() => setIsDrawerOpen((p) => !p)} />
      <Link to="/products/edit">
        <CreateButton color="primary" size="medium">
          <AddIcon />
        </CreateButton>
      </Link>
      <ContentWapper>{children}</ContentWapper>
    </StyledColumn>
  )
}

ProductsLayout.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    title: state.navbar.getIn(['navbarPrefs', 'title']),
  }
}

export default connect(mapStateToProps)(ProductsLayout)
