import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Navbar } from 'components/NavBar'
import SideMenu from 'components/SideMenu'
import AddIcon from '@mui/icons-material/Add'

import grantedByRole from 'utils/grantedByRole'
import { CREATE_ARTICLE } from 'utils/constants/privileges'
import { ContentWapper } from './layoutStyles'
import { StyledColumn } from './StyledLayouts'
import { CreateButton } from '../components/Common/CreateButton'

const RecipesLayout = ({ children, title, userRoles }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  return (
    <StyledColumn>
      <Navbar title={title} showLogo toggleSidemenu={() => setIsDrawerOpen((p) => !p)} />
      <SideMenu open={isDrawerOpen} toggleSidemenu={() => setIsDrawerOpen((p) => !p)} />
      {grantedByRole(userRoles, CREATE_ARTICLE) && (
        <Link to="/recipe/edit">
          <CreateButton color="primary" size="medium">
            <AddIcon />
          </CreateButton>
        </Link>
      )}
      <ContentWapper>{children}</ContentWapper>
    </StyledColumn>
  )
}

RecipesLayout.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  userRoles: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    title: state.navbar.getIn(['navbarPrefs', 'title']),
    userRoles: state.profile.getIn(['profile', 'roles']),
  }
}

export default connect(mapStateToProps)(RecipesLayout)
