import { fromJS } from 'immutable'

export const initialProductsState = fromJS({
  loading: false,
  products: [],
  error: null,
  creating: false,
  createError: null,
  updating: false,
  updateError: null,
  snackMessage: null,
})
