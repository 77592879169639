import { CREATE_ARTICLE_MERGE_COMPONENTS } from 'utils/constants/actions'
import joinComponentsByType from 'utils/joinComponentsByType'

const mergeComponents = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_MERGE_COMPONENTS) {
    const { createArticleBody } = store.getState()
    const components = createArticleBody.getIn(['present', 'components'])
    action.mergedComponents = joinComponentsByType(components.toJS(), 'text', 'text')
  }

  return next(action)
}

export default mergeComponents
