// base: string, params: object
const generateQueryUrl = (base, params) => {
  let url = base

  Object.entries(params).forEach(([key, val], index) => {
    const isFirst = index === 0
    const newParam = `${isFirst ? '?' : '&'}${key}=${val}`
    url = url.concat(newParam)
  })

  return url
}

export default generateQueryUrl
