import React, { Children, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import isNil from 'lodash/isNil'
import grantedByRole from 'utils/grantedByRole'
import { useHistory } from 'react-router-dom'
import { Privileges } from '../utils/constants/privileges'
import { RootState } from '../App'

const withRequiredPrivilege = (requiredPrivilege: Privileges, redirect = '/') => {
  interface Props extends ReturnType<typeof mapStateToProps> {
    children: React.ReactElement
  }

  const EnsureRoleContainer = (props: Props) => {
    const history = useHistory()
    // prevents loading the child component until the user roles are checked
    const [checked, setChecked] = useState(false)

    useEffect(() => {
      // userRoles loaded
      if (!isNil(props.userRoles)) {
        // if user does not have the required privilege, redirect to the specified route
        if (!grantedByRole(props.userRoles, requiredPrivilege)) {
          history.replace(redirect)
        } else {
          setChecked(true)
        }
      }
    }, [history, props.userRoles])

    return <>{checked && Children.only(props.children)}</>
  }

  const mapStateToProps = (state: RootState) => ({
    userRoles: state.profile.getIn(['profile', 'roles']),
  })

  return connect(mapStateToProps)(EnsureRoleContainer)
}

export default withRequiredPrivilege
