import { makeAutoObservable } from 'mobx'
import { addMonths, startOfDay } from 'date-fns'

export default class CampaignStore {
  dateRange = { from: startOfDay(new Date()), to: addMonths(startOfDay(new Date()), 1) }

  constructor() {
    makeAutoObservable(this)
  }

  setDateRange = (newRange) => {
    this.dateRange = newRange
  }
}
