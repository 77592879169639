import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { reset, formValueSelector } from 'redux-form'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import QueueFilterForm from './QueueFilterForm'

import { loadArticles, resetState } from 'actions/articlesActions'

import { loadAuthors, getAuthorById } from 'actions/createArticleApiActions'
import { onDateChange } from 'actions/articleQueueRangePickerActions'

import moment from 'moment'

import { toggleQueueFilterPopover } from 'actions/articleQueueFilterActions'

const ArticleQueueFilter = ({
  resetState,
  editors,
  isOpen,
  toggleQueueFilterPopover,
  authors,
  authorsLoading,
  partners,
  loadAuthors,
  loadArticles,
  resetForm,
  getAuthorById,
  resetDate,
  excludePartnerContent,
}) => {
  const buttonRef = useRef()
  return (
    <div style={{ display: 'inline-flex' }}>
      <Button ref={buttonRef} variant="text" onClick={(e) => toggleQueueFilterPopover(!isOpen)}>
        Filter <ExpandMoreIcon />
      </Button>
      <Popover
        anchorEl={buttonRef.current}
        open={isOpen}
        PaperProps={{ style: { padding: 30, width: 830, height: '70%' } }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toggleQueueFilterPopover(false)}
      >
        <QueueFilterForm
          closeFilters={() => toggleQueueFilterPopover(false)}
          editors={editors}
          authors={authors}
          authorsLoading={authorsLoading}
          partners={partners}
          loadArticles={loadArticles}
          resetForm={resetForm}
          loadAuthors={loadAuthors}
          onSubmit={resetState}
          getAuthorById={getAuthorById}
          resetDate={resetDate}
          excludePartnerContent={excludePartnerContent}
        />
      </Popover>
    </div>
  )
}

ArticleQueueFilter.propTypes = {
  resetState: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleQueueFilterPopover: PropTypes.func.isRequired,
  editors: PropTypes.object,
  authors: PropTypes.object,
  partners: PropTypes.object,
  authorsLoading: PropTypes.bool,
  loadAuthors: PropTypes.func,
  loadArticles: PropTypes.func,
  resetForm: PropTypes.func,
  getAuthorById: PropTypes.func,
  resetDate: PropTypes.func,
  excludePartnerContent: PropTypes.bool,
}

const selector = formValueSelector('queueFilterForm')
function mapStateToProps(state = {}) {
  return {
    isOpen: state.articleQueueFilter.get('open'),
    editors: state.editors.get('editors'),
    authors: state.authors.get('authors'),
    authorsLoading: state.authors.get('loading'),
    partners: state.partners.get('partners'),
    excludePartnerContent: selector(state, 'excludePartnerContent'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resetState: () => dispatch(resetState()),
    resetForm: () => dispatch(reset('queueFilterForm')),
    toggleQueueFilterPopover: (isOpen) => dispatch(toggleQueueFilterPopover(isOpen)),
    loadAuthors: (value) => dispatch(loadAuthors(value)),
    loadArticles: (userId) => dispatch(loadArticles({ userId, route: 'articles' })),
    getAuthorById: (id) => dispatch(getAuthorById(id)),
    resetDate: () => {
      dispatch(onDateChange({ from: moment().format('MMM D, YYYY'), to: null }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleQueueFilter)
