import deltaToHTML from 'utils/deltaToHTML'

import { CREATE_ARTICLE_HIGHLIGHT_SAME_LINKS, CREATE_ARTICLE_SET_LAST_INSERTED_LINK } from 'utils/constants/actions'

const highlightSameLinks = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_HIGHLIGHT_SAME_LINKS) {
    const newLink = action.link
    const { createArticleBody } = store.getState()
    const bodyComponents = createArticleBody.getIn(['present', 'components']).toJS()

    const quillFields = bodyComponents.filter((c) => c.type === 'text' && c.delta)

    const linkCount = quillFields.reduce((agg, next) => {
      const linksDeltas = next.delta.filter((op) => op.attributes && op.attributes.link)
      return agg + linksDeltas.filter((op) => op.attributes.link.url === newLink).length
    }, 0)

    if (linkCount < 2) return

    quillFields.forEach((c) => {
      const linkDeltas = c.delta.filter((op) => op.attributes && op.attributes.link)
      linkDeltas.forEach((op) => {
        op.attributes.link.sameLink = newLink === op.attributes.link.url
      })

      c.text = deltaToHTML(c.delta)
    })

    action.updatedBodyComponents = bodyComponents
  }

  if (action.type === CREATE_ARTICLE_SET_LAST_INSERTED_LINK) {
    const { createArticleBody } = store.getState()
    const bodyComponents = createArticleBody.getIn(['present', 'components']).toJS()
    const quillFields = bodyComponents.filter((c) => c.type === 'text' && c.delta)

    const linkCount = quillFields.reduce((agg, next) => {
      const linksDeltas = next.delta.filter((op) => op.attributes && op.attributes.link)
      return agg + linksDeltas.filter((op) => op.attributes.link.url === action.link).length
    }, 0)

    if (linkCount < 2) return
  }

  next(action)
}

export default highlightSameLinks
