export const isDateValid = (date) => date instanceof Date && !isNaN(date)

export const isUrlValid = (str, isSlug) => {
  let regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/

  // check just url key, not the whole url
  if (isSlug) {
    regex = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/
  }

  return regex.test(str)
}
