import countLinks from './countLinks'

/**
 * Recalculates word count, internal and external link count and returns the updated redux state.
 */
const updateArticleStats = (state) => {
  const body = state.getIn(['present', 'components'])
  const wordCount = body.reduce((total, value) => total + value.get('wordCount', 0), 0)
  const linkCount = (internal) =>
    body.reduce((total, c) => {
      if (c.get('type') === 'text') return total + countLinks(c.get('text'), internal)
      else if (c.get('type') === 'header') return total + countLinks(c.get('dek'), internal)
      return total
    }, 0)

  return state.set('wordCount', wordCount).set('internalLinks', linkCount(true)).set('externalLinks', linkCount(false))
}

export default updateArticleStats
