import { CREATE_PODCAST_SUBMIT_PODCAST } from 'utils/constants/actions'
import { sendPodcast } from 'actions/createPodcastApiActions'
import { toggleUserSubmit } from 'actions/createArticleActions'
import removeHtmlTags from 'utils/removeHtmlTags'
import removeBodyIfEmpty from 'utils/removeBodyIfEmpty'
import removeEmptyFromObject from 'utils/removeEmptyFromObject'
import deltaToHTML from 'utils/deltaToHTML'
import moment from 'moment'
import omit from 'lodash/omit'
import isEqual from 'lodash/isEqual'

const submitPodcast = (store) => (next) => (action) => {
  if (action.type === CREATE_PODCAST_SUBMIT_PODCAST) {
    const { createArticleBody, profile, createArticleInteractions } = store.getState()
    const userId = profile.getIn(['profile', 'userId'])
    const route = window.location.pathname.split('/')[1]
    let results = { ...action.results }
    if (results.subjects) {
      results.subjects = results.subjects.map((sub) => sub.id)
    }

    if (!results.id) {
      results.createDate = moment().format()
      results.assignedEditorId = userId
    }

    const bodyComponents = createArticleBody.getIn(['present', 'components']).toJS()
    results.body = bodyComponents
      .filter((c) => {
        return c.type !== 'text' || !(removeHtmlTags(c.text) === '' || !c.text)
      })
      .map((c) => {
        delete c.id
        delete c.fieldEmpty
        delete c.preserve
        delete c.active
        // for now
        delete c.wordCount

        if (c.type === 'header') {
          c.title = removeHtmlTags(c.title)
          c.subtitle = removeHtmlTags(c.subtitle)
          c.dek = removeHtmlTags(c.dek)
        } else if (c.type === 'text' && c.delta) {
          c.delta
            .filter((op) => op.attributes && op.attributes.searchHighlight)
            .forEach((op) => (op.attributes.searchHighlight = false))

          c.text = deltaToHTML(c.delta)
          delete c.delta
        }

        if (c.type !== 'text') {
          if (c.text) {
            c.text = removeHtmlTags(c.text)
          }
        }

        return c
      })

    let finalResults = removeBodyIfEmpty(results)
    finalResults = removeEmptyFromObject(finalResults)

    const id = finalResults.id
    if (id) finalResults.lastUpdate = moment().format()
    action.error = false
    action.flashMessage = ''

    const lastSavedState = createArticleInteractions.get('lastSavedState')
    const userSubmit = createArticleInteractions.get('userSubmit')

    if (!userSubmit && id && lastSavedState) {
      store.dispatch(toggleUserSubmit())
      const redundantProps = ['lastUpdate', 'version']
      const a = omit(lastSavedState.toJS(), redundantProps)
      const b = omit(finalResults, redundantProps)
      if (isEqual(a, b)) return
    }

    if (!action.livePreview) {
      store.dispatch(sendPodcast({ route, id, podcast: finalResults }))
    } else {
      document.querySelector('#previewInput').value = JSON.stringify(finalResults)
      document.querySelector('#typeInput').value = 'podcast'
      document.querySelector('#previewForm').submit()
    }

    action.id = finalResults.id
  }

  return next(action)
}

export default submitPodcast
