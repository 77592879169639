import Immutable from 'immutable'
import { RSAA } from 'redux-api-middleware'
import {
  LOAD_PROFILE_REQUEST,
  LOAD_PROFILE_SUCCESS,
  LOAD_PROFILE_FAILURE,
  LOAD_QA,
  SET_COGNITO_USER,
  REMOVE_COGNITO_USER,
} from 'utils/constants/actions'
const { Map } = require('immutable')

export function loadProfile() {
  return {
    [RSAA]: {
      endpoint: 'identity',
      method: 'GET',
      types: [LOAD_PROFILE_REQUEST, LOAD_PROFILE_SUCCESS, LOAD_PROFILE_FAILURE],
    },
  }
}
