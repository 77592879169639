import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CommonDialogTitle from '../Common/DialogComponents/CommonDialogTitle'
import { LoaderContainer } from '../../ui/LoaderContainer'

const ArchiveDialog = ({ open, articleId, articlePath, loading, isPublished, toggleArchiveDialog, archiveArticle }) => {
  const history = useHistory()
  return (
    <Dialog open={open} fullWidth onClose={() => toggleArchiveDialog(false)}>
      <CommonDialogTitle onClose={() => toggleArchiveDialog(false)}>
        <div>Archive dialog</div>
      </CommonDialogTitle>
      <DialogContent>
        <LoaderContainer isLoading={loading} />
        <h3>
          {!isPublished
            ? 'Are you sure you want to archive this article?'
            : 'You must unpublish the article before archiving it.'}
        </h3>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => toggleArchiveDialog(false)} disabled={loading}>
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={() =>
            isPublished
              ? history.push(`/article/edit?id=${articleId}`)
              : archiveArticle({ id: articleId, articlePath: articlePath.toJS() })
          }
          disabled={loading}
        >
          {isPublished ? 'Go to article' : 'Archive'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ArchiveDialog.propTypes = {
  open: PropTypes.bool,
  articleId: PropTypes.string,
  articlePath: PropTypes.object,
  loading: PropTypes.bool,
  isPublished: PropTypes.bool,
  toggleArchiveDialog: PropTypes.func,
  archiveArticle: PropTypes.func,
}

export default ArchiveDialog
