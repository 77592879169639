import { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'
import styled from 'styled-components'

import DefaultTextField from 'ui/DefaultTextField'
import DefaultSelectField from 'ui/DefaultSelectField'
import DefaultCheckbox from 'ui/DefaultCheckbox'
import ChipInputField from 'ui/ChipInputField'

import { grey30 } from 'styles/colors'
import StyledFlex, { StyledColumn } from '../../../../../layouts/StyledLayouts'
import { RootState } from '../../../../../App'

const PreviewImage = styled.img`
  max-width: auto;
  max-height: 250px;
`

const SectionHeader = styled.h4`
  margin-bottom: 0;
  color: ${grey30};
`

const SuggestedKeywords = styled.div`
  font-size: 12px;
`

const validateForm = (data: any) => {
  const errors: { tags?: string } = {}
  if (!data.tags || !data.tags.length) {
    errors.tags = 'Please add some tags'
  }

  return errors
}

interface Props extends ReturnType<typeof mapStateToProps> {
  uploadedImageUrl: string
  uploadedImageLabels: any[]
  isPreviewImage: boolean
  hasCrops: boolean
  handleSubmit(): void
  onChooseDifferentImage(): void
  change(type: string, newTags: any[]): void
}

const ImageData = (props: Props) => {
  const [keywords, setKeywords] = useState<string[]>(
    props.uploadedImageLabels
      ? // @ts-expect-error
        props.uploadedImageLabels.map((label: any) => label.get('Name')).toJS()
      : []
  )

  const addSuggestedTag = (index: number) => {
    setKeywords((p) => {
      const keywords = p.slice()
      const keyword = keywords[index]
      keywords.splice(index, 1)

      const newTags = props.tags ? props.tags.slice() : []
      newTags.push(keyword)
      props.change('tags', newTags)

      return keywords
    })
  }

  const addAllKeywords = () => {
    setKeywords((p) => {
      const keywords = p.slice()

      const newTags = props.tags ? props.tags.slice() : []
      keywords.forEach((word) => newTags.push(word))
      props.change('tags', newTags)

      return []
    })
  }

  const readKeyword = (value: string) => {
    setKeywords((p) => [...p, value])
  }
  return (
    <form onSubmit={props.handleSubmit}>
      <PreviewImage src={props.uploadedImageUrl} alt="uploaded preview" />
      {props.isPreviewImage && (
        <StyledFlex>
          <Field component={DefaultCheckbox} name="heroImage" label="Use preview image as hero?" />
        </StyledFlex>
      )}
      <SectionHeader>Display Text</SectionHeader>

      <StyledFlex style={{ gap: '16px' }}>
        <StyledColumn style={{ flex: 1, gap: '12px' }}>
          <Field component={DefaultTextField} fullWidth label="Image credit (e.g. Miachel Breton)" name="credit" />
          <Field component={DefaultTextField} fullWidth label="Image caption (optional)" name="caption" />
          <Field component={DefaultTextField} fullWidth label="Alt text" name="altText" />
        </StyledColumn>
        <StyledColumn style={{ flex: 1, gap: '12px' }}>
          <Field component={DefaultTextField} fullWidth label="Image source (e.g. Getty Images)" name="source" />
          <Field component={DefaultTextField} fullWidth label="Image click-thru URL" name="linkUrl" />
        </StyledColumn>
      </StyledFlex>
      <StyledFlex style={{ gap: '16px' }}>
        <Field component={DefaultCheckbox} name="nofollowUrl" label="Nofollow URL?" />
        <Field component={DefaultCheckbox} name="sponsoredUrl" label="Sponsored URL?" />
      </StyledFlex>
      <SectionHeader>Image Metadata</SectionHeader>
      <StyledFlex style={{ gap: '16px' }}>
        <StyledColumn style={{ flex: 1, gap: '12px' }}>
          <Field component={DefaultTextField} fullWidth label="Image name" name="seoImageName" required />
          <Field
            component={DefaultSelectField}
            fullWidth
            label="Image type"
            name="imageType"
            type="select"
            defaultValue="Photo"
            required
          >
            <MenuItem value="Photo">Photo</MenuItem>
            <MenuItem value="Illustration">Illustration</MenuItem>
            <MenuItem value="Graphic">Graphic</MenuItem>
          </Field>
        </StyledColumn>
        <StyledColumn style={{ flex: 1 }}>
          <Field
            component={ChipInputField}
            fullWidth
            label="Keywords"
            name="tags"
            deleteHandler={props.uploadedImageLabels ? readKeyword : null}
          />
          {keywords.length > 0 && (
            <SuggestedKeywords>
              <strong>Suggested Keywords: </strong>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {keywords.map((word, idx) => (
                  <Chip
                    key={idx}
                    label={word}
                    sx={{ margin: 1, cursor: 'pointer' }}
                    onClick={() => addSuggestedTag(idx)}
                  />
                ))}
              </div>
              <Button onClick={addAllKeywords}>Add all</Button>
            </SuggestedKeywords>
          )}
          <Field component={DefaultTextField} fullWidth label="Notes" name="notes" multiline />
        </StyledColumn>
      </StyledFlex>
      <StyledFlex style={{ marginTop: '20px', justifyContent: 'flex-end' }}>
        <Button onClick={props.onChooseDifferentImage}>Choose a different image</Button>
        <Button type="submit">{`Save ${props.hasCrops ? '& Do Crops' : ''}`}</Button>
      </StyledFlex>
    </form>
  )
}

const selector = formValueSelector('imageData')
const mapStateToProps = (state: RootState) => {
  return {
    tags: selector(state, 'tags'),
  }
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'imageData',
    asyncBlurFields: [],
    validate: validateForm,
  })
)(ImageData)
