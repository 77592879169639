import Rating from '@mui/material/Rating'
import AttachFileIcon from '@mui/icons-material/AttachFile'

import StyledFlex, { StyledColumn } from '../../../../layouts/StyledLayouts'
import { BaseColumn } from '../ArticleStyles'
import { pinkRed } from '../../../../styles/colors'
import EditorsNotes from './EditorsNotes'
import StatusIcons from '../../StatusIcons'
import { useMemo } from 'react'
import { EditorsNotesWrapper } from './styles'

interface RankingProps {
  editorRanking?: number
}

const EditorRanking = (props: RankingProps) => {
  if (typeof props.editorRanking !== 'number' || !props.editorRanking) {
    return null
  }

  return <Rating max={props.editorRanking} name="rating-field" value={props.editorRanking} readOnly />
}

interface Props {
  compressed?: boolean
  article: any
  articleIdx: number
  updateEditorsNotes: () => void
  updateArticle: (a: Record<string, any>) => void
}

const DetailsColumn = (props: Props) => {
  const { creativeFlag, copyFlag } = useMemo(() => {
    let creativeFlag = false
    let copyFlag = false

    const statusFlags = props.article.get('statusFlags')
    if (statusFlags) {
      statusFlags.forEach((flag: 'creative' | 'copy' | Record<string, any>) => {
        const type = typeof flag !== 'string' ? flag.get('type') : flag
        if (type === 'creative') {
          creativeFlag = true
        }
        if (type === 'copy') {
          copyFlag = true
        }
      })
    }

    return { creativeFlag, copyFlag }
  }, [props.article])
  return (
    <BaseColumn>
      <p>{props.article.getIn(['editor', 'name'])}</p>
      {props.compressed ? (
        <StyledColumn style={{ width: '12%', alignItems: 'center', justifyContent: 'center' }}>
          <EditorRanking editorRanking={props.article.get('editorRating')} />
        </StyledColumn>
      ) : (
        <div>
          <StyledFlex style={{ justifyContent: 'center' }}>
            <EditorRanking editorRanking={props.article.get('editorRating')} />
          </StyledFlex>
          <StyledFlex style={{ gap: '16px' }}>
            {props.article.get('editorsNotes') && (
              <EditorsNotesWrapper>
                <AttachFileIcon sx={{ color: pinkRed }} />

                <EditorsNotes
                  articleIdx={props.articleIdx}
                  editorsNotes={props.article.get('editorsNotes')}
                  updateEditorsNotes={props.updateEditorsNotes}
                  updateArticle={props.updateArticle}
                />
              </EditorsNotesWrapper>
            )}
            <StatusIcons creativeFlag={creativeFlag} copyFlag={copyFlag} />
          </StyledFlex>
        </div>
      )}
    </BaseColumn>
  )
}

export default DetailsColumn
