import { fromJS } from 'immutable'

export const initialAuthorFieldsState = fromJS({
  fields: [
    {
      loading: false,
      authors: [],
    },
    {
      loading: false,
      authors: [],
    },
    {
      loading: false,
      authors: [],
    },
  ],
})
