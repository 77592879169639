import { RSAA } from 'redux-api-middleware'

import {
  CREATE_ARTICLE_KEYWORDS_REQUEST,
  CREATE_ARTICLE_KEYWORDS_SUCCESS,
  CREATE_ARTICLE_KEYWORDS_FAILURE,
} from 'utils/constants/actions'

export function getKeywords(keyword) {
  return {
    [RSAA]: {
      endpoint: `keywords/urls?keyword=${keyword}`,
      method: 'GET',
      types: [CREATE_ARTICLE_KEYWORDS_REQUEST, CREATE_ARTICLE_KEYWORDS_SUCCESS, CREATE_ARTICLE_KEYWORDS_FAILURE],
    },
  }
}
