import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import { useStore } from 'store/mobx'
import Alert from '@mui/material/Alert'
import { useHistory } from 'react-router-dom'
import Loader from 'components/new-pages/Loader'
import Lessons from 'components/new-pages/Classes/Lessons/List'
import ClassBreadcrumbs from 'components/new-pages/Classes/Breadcrumbs'
import CreateLessonButton from './CreateLessonButton'
import LiveLesson from './LessonDetails/LiveLesson'
import VideoLesson from './LessonDetails/VideoLesson'
import AssessmentLesson from './LessonDetails/AssessmentLesson'
import AssignmentLesson from './LessonDetails/AssignmentLesson'
import DownloadLesson from './LessonDetails/DownloadLesson'
import ClassRequirement from './LessonDetails/ClassRequirement'

export const lessonComponents = {
  video: { id: 'video', title: 'Video', component: VideoLesson },
  liveSession: { id: 'live-session', title: 'Live Session', component: LiveLesson },
  assessment: { id: 'assessment', title: 'Assessment', component: AssessmentLesson },
  assignment: { id: 'assignment', title: 'Assignment', component: AssignmentLesson },
  download: {
    id: 'download',
    title: 'Download',
    component: DownloadLesson,
  },
  classRequirement: { id: 'class-requirement', title: 'Class Requirement', component: ClassRequirement },
}

export const lessonTypes = Object.values(lessonComponents).filter((c) => c.id !== lessonComponents.classRequirement.id)

const ModuleContainer = ({ match }) => {
  const params = match.params
  const history = useHistory()

  const classId = params.id ? Number(params.id) : null
  const moduleId = params.moduleId
  const { classes } = useStore('classesStore')
  const classItem = classes.find((item) => item.id === classId)
  const activeModule = classItem?.modules.find((m) => m.id === moduleId)

  useEffect(() => {
    if (!Number.isNaN(parseInt(classId))) {
      classItem?.fetchClassDetails(classId)
    } else history.push('/classes')
  }, [classItem, classId, history])

  if (!classItem?.detailsLoaded) {
    return <Loader />
  }

  return (
    <>
      {classItem?.error?.message ? <Alert severity="error">{classItem.error.message}</Alert> : null}
      {activeModule && (
        <>
          <ClassBreadcrumbs
            classTitle={classItem.title}
            classId={classId}
            moduleTitle={activeModule?.title}
            moduleId={moduleId}
          />
          <Lessons activeModule={activeModule} classId={classId} terms={classItem.terms} />

          <CreateLessonButton
            disableLiveLesson={!classItem.terms || !classItem.terms.length}
            lessonTypes={lessonTypes}
            classId={classId}
            moduleId={activeModule.id}
          />
        </>
      )}
    </>
  )
}
ModuleContainer.propTypes = {
  params: PropTypes.object,
}

export default observer(ModuleContainer)
