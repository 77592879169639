import { useQuery } from 'react-query'
import { getUsers } from '../api/users'
import { QueryKeys } from '../utils/QueryKeys'

const useUsers = ({ userIds }) => {
  const query = useQuery([QueryKeys.users], () => getUsers({ ids: userIds }))

  return query
}

export default useUsers
