import React from 'react'
import PropsTypes from 'prop-types'
import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'

const StyledLoader = styled(CircularProgress)`
  position: fixed;
  top: 50%;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 2;
`

const Loader = ({ withBackdrop }) => (
  <>
    {withBackdrop ? (
      <Backdrop open invisible>
        <StyledLoader data-testid="loader" />
      </Backdrop>
    ) : (
      <StyledLoader data-testid="loader" />
    )}
  </>
)

Loader.propTypes = {
  withBackdrop: PropsTypes.bool,
}

export default Loader
