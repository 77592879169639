import { SELECT_ARTICLE_TEMPLATE, TOGGLE_CREATE_ARTICLE_MENU } from 'utils/constants/actions'

export function selectArticleTemplate(templateType = '') {
  return {
    type: SELECT_ARTICLE_TEMPLATE,
    templateType,
  }
}

export function toggleCreateArticleMenu(show) {
  return {
    type: TOGGLE_CREATE_ARTICLE_MENU,
    show,
  }
}
