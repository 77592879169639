import { useRef } from 'react'
import { TAB } from 'utils/keyCodes'

const QuillTabSupport = ({ children }) => {
  const quill = useRef()

  const handleTab = (tabForward) => {
    const quills = Array.from(document.querySelectorAll('.ql-editor'))
    const index = quills.findIndex((el) => el === quill.current.root)
    const nextIndex = tabForward
      ? index === quills.length - 1
        ? 0
        : index + 1
      : index === 0
      ? quills.length - 1
      : index - 1

    quills[nextIndex].focus()
    return false
  }

  return children({
    onEditorRef: (el) => (quill.current = el),
    tabKeyboardBindings: {
      tab: {
        key: TAB,
        handler: () => handleTab(true),
      },
      shiftTab: {
        key: TAB,
        shiftKey: true,
        handler: () => handleTab(false),
      },
    },
  })
}

export default QuillTabSupport
