import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Navbar } from 'components/NavBar'
import SideMenu from 'components/SideMenu'

const iconStyle = {
  width: 50,
  height: 50,
  padding: 0,
  position: 'fixed',
  bottom: 35,
  right: 35,
  zIndex: 1000,
}

const SponsoredFlightsLayout = ({ title, children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  return (
    <div>
      <Navbar title={title} showLogo toggleSidemenu={() => setIsDrawerOpen((p) => !p)} />
      <SideMenu open={isDrawerOpen} toggleSidemenu={() => setIsDrawerOpen((p) => !p)} />
      {children}
    </div>
  )
}

SponsoredFlightsLayout.propTypes = {
  children: PropTypes.element.isRequired,
  currentPath: PropTypes.string,
  title: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    title: state.navbar.getIn(['navbarPrefs', 'title']),
  }
}

export default connect(mapStateToProps)(SponsoredFlightsLayout)
