import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useState } from 'react'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { addMonths, isSameDay } from 'date-fns'
import styled from 'styled-components'

import { toggleWithNoPublishDate, onDateChange } from 'actions/articleQueueRangePickerActions'
import { resetState } from 'actions/articlesActions'
import { getNyEndOfDay, getNyStartOfDay } from '../../../utils/formatDate'
import StyledFlex from '../../../layouts/StyledLayouts'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  column-gap: 16px;
  row-gap: 4px;
`

const DateRange = (props) => {
  const [from, setFrom] = useState(props.from || getNyStartOfDay(new Date()))
  const [to, setTo] = useState(props.to || addMonths(getNyStartOfDay(new Date()), 1))
  const [withNoPublishDate, setWithNoPublishDate] = useState(props.withNoPublishDate)
  const [errors, setErrors] = useState({ from: null, to: null })

  const handleError = (input) => (error) => {
    setErrors((p) => ({ ...p, [input]: error }))
  }

  const handleConfirm = () => {
    props.onDateChange({ from, to })
    props.toggleWithNoPublishDate(withNoPublishDate)
    props.resetState()
  }

  const isSearchDisabled =
    props.loading ||
    !Object.values(errors).every((e) => e === null) ||
    (isSameDay(from, props.from) && isSameDay(to, props.to) && withNoPublishDate === props.withNoPublishDate)

  return (
    <Grid>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="From"
          value={getNyStartOfDay(from)}
          onChange={(date) => setFrom(date)}
          renderInput={(params) => <TextField variant="standard" {...params} />}
          maxDate={to}
          onError={handleError('from')}
        />

        <DatePicker
          label="To"
          value={getNyEndOfDay(to)}
          onChange={(date) => setTo(date)}
          renderInput={(params) => <TextField variant="standard" {...params} />}
          minDate={from}
          onError={handleError('to')}
        />
      </LocalizationProvider>
      <StyledFlex style={{ alignItems: 'flex-end' }}>
        <Button variant="outlined" disabled={isSearchDisabled} onClick={handleConfirm}>
          Search
        </Button>
      </StyledFlex>
      <FormControlLabel
        sx={{ gridColumn: '1 / 3' }}
        control={<Checkbox checked={withNoPublishDate} onChange={(e) => setWithNoPublishDate(e.target.checked)} />}
        label="Include articles without publish date"
      />
    </Grid>
  )
}

DateRange.propTypes = {
  loading: PropTypes.bool,
  resetState: PropTypes.func,
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
  withNoPublishDate: PropTypes.bool,
  toggleWithNoPublishDate: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    loading: state.articles.get('loading'),
    withNoPublishDate: state.articleQueueRangePicker.get('withNoPublishDate'),
    from: state.articleQueueRangePicker.get('from'),
    to: state.articleQueueRangePicker.get('to'),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetState: () => dispatch(resetState()),
    toggleWithNoPublishDate: (value) => dispatch(toggleWithNoPublishDate(value)),
    onDateChange: ({ from, to }) => {
      dispatch(onDateChange({ from, to }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateRange)
