import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import { cerulean } from 'styles/colors'
import AnchorIcon from 'styles/assets/AnchorIcon'
import CommonDialogTitle from '../../components/Common/DialogComponents/CommonDialogTitle'
import styled from 'styled-components'
import { LoaderContainer } from '../LoaderContainer'

const LabelStyles = styled.p`
  color: ${cerulean};
  margin-top: 0;
  display: inline-block;
  margin-right: 10px;
`

const NoKeywordsStyles = styled.p`
  text-align: center;
`

const RadioLabel = ({ title, showAnchorIcon }) => (
  <div>
    <LabelStyles>{title}</LabelStyles>
    {showAnchorIcon ? <AnchorIcon width={16} height={17} /> : null}
  </div>
)

RadioLabel.propTypes = {
  title: PropTypes.string,
  showAnchorIcon: PropTypes.bool,
}

class KeywordListDialog extends Component {
  state = {
    selectedUrl: null,
  }

  render() {
    const { dialogOpen, searchTerm, onSubmit, onClose, onChangeSearchTerm, keywords, searching } = this.props

    return (
      <Dialog open={dialogOpen} modal={false} fullWidth onClose={onClose}>
        <CommonDialogTitle onClose={onClose}>
          <div>"{searchTerm}"</div>
        </CommonDialogTitle>
        <DialogContent>
          <LoaderContainer isLoading={searching}>
            <div className="loadedContent">
              <RadioGroup name="keywordsResults" onChange={(e, selectedUrl) => this.setState({ selectedUrl })}>
                {keywords.map((k, i) => {
                  // Disabled for now
                  // let showAnchorIcon = false
                  // if (k.get('anchorTerms')) {
                  //   showAnchorIcon = k.get('anchorTerms').some((a) => a === searchTerm)
                  // }

                  return (
                    <FormControlLabel
                      key={i}
                      value={k.get('url')}
                      control={
                        <Radio
                        // onClick={() => window.open(`${process.env.REACT_APP_MBG_SITE}${k.get('url')}`, '_blank')}
                        />
                      }
                      label={k.getIn(['titles', 'site'])}
                    />
                  )
                })}
              </RadioGroup>
              {keywords.count() === 0 && <NoKeywordsStyles>No keywords were found for "{searchTerm}"</NoKeywordsStyles>}
            </div>
          </LoaderContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onChangeSearchTerm} style={{ float: 'left' }}>
            Search different word
          </Button>
          <Button onClick={onClose}>Close</Button>
          <Button
            disabled={!this.state.selectedUrl}
            onClick={() => this.state.selectedUrl && onSubmit(this.state.selectedUrl)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

KeywordListDialog.propTypes = {
  dialogOpen: PropTypes.bool,
  searchTerm: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onChangeSearchTerm: PropTypes.func,
  keywords: PropTypes.object,
  searching: PropTypes.bool,
}

export default KeywordListDialog
