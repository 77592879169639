import {
  CREATE_PODCAST_SUBMIT_PODCAST,
  CREATE_PODCAST_FETCH_PODCAST_REQUEST,
  CREATE_PODCAST_FETCH_PODCAST_SUCCESS,
  CREATE_PODCAST_FETCH_PODCAST_FAILURE,
  CREATE_PODCAST_SEND_PODCAST_REQUEST,
  CREATE_PODCAST_SEND_PODCAST_SUCCESS,
  CREATE_PODCAST_SEND_PODCAST_FAILURE,
  GET_SUBJECT_BY_ID_REQUEST,
  GET_SUBJECT_BY_ID_SUCCESS,
  GET_SUBJECT_BY_ID_FAILURE,
} from 'utils/constants/actions'

import { RSAA, getJSON } from 'redux-api-middleware'
import loadArticleHelper from 'utils/loadArticleHelper'

const { REACT_APP_API_URL } = process.env

export function submitPodcast({ results, livePreview }) {
  return {
    type: CREATE_PODCAST_SUBMIT_PODCAST,
    results,
    livePreview,
  }
}

export function sendPodcast({ id, podcast }) {
  return {
    [RSAA]: {
      endpoint: id ? `${REACT_APP_API_URL}podcasts/${id}` : `${REACT_APP_API_URL}podcasts`,
      method: id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [
        CREATE_PODCAST_SEND_PODCAST_REQUEST,
        {
          type: CREATE_PODCAST_SEND_PODCAST_SUCCESS,
          payload: (action, state, res) => getJSON(res),
          meta: { article: podcast },
        },
        CREATE_PODCAST_SEND_PODCAST_FAILURE,
      ],
      body: JSON.stringify(podcast),
    },
  }
}

export function fetchPodcast(id) {
  return {
    [RSAA]: {
      endpoint: `${REACT_APP_API_URL}podcasts/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [
        CREATE_PODCAST_FETCH_PODCAST_REQUEST,
        {
          type: CREATE_PODCAST_FETCH_PODCAST_SUCCESS,
          payload: (action, state, res) =>
            getJSON(res).then((json) =>
              loadArticleHelper({ articleData: json, storeCurrentVersion: true, isPodcast: true })
            ),
        },
        CREATE_PODCAST_FETCH_PODCAST_FAILURE,
      ],
    },
  }
}

export function loadSubject(id) {
  return {
    [RSAA]: {
      endpoint: `${REACT_APP_API_URL}authors?id=${id}`,
      method: 'GET',
      types: [GET_SUBJECT_BY_ID_REQUEST, GET_SUBJECT_BY_ID_SUCCESS, GET_SUBJECT_BY_ID_FAILURE],
    },
  }
}
