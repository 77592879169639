export default function createCloudinaryCropPath({ cropData: { x, y, width, height }, key }) {
  key = key.split('/').splice(1).join('/')
  return {
    path: `https://mindbodygreen-res.cloudinary.com/image/upload/x_${x},y_${y},w_${width},h_${height},c_crop/${key}`,
    width: width,
    height: height,
    cropStart: {
      x: x,
      y: y,
    },
    cropEnd: {
      x: x + width,
      y: y + height,
    },
  }
}
