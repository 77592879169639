import styled from 'styled-components'

import StyledFlex, { StyledColumn } from '../../../layouts/StyledLayouts'

export const ArticleQueueHover = styled.div`
  display: none;
`

export const BaseColumn = styled(StyledColumn)`
  width: 20%;
  align-items: center;
  justify-content: center;
`

export const Wrapper = styled(StyledColumn)<{ compressed?: boolean }>`
  border-radius: 8px;

  padding: ${({ compressed }) => (compressed ? '12px 4px' : '16px 8px')};
  ${({ compressed }) =>
    compressed &&
    `
    ${ArticleQueueHover} {
      display: block;
    }
    ${BaseColumn}: {
      width: 15%;
    }
  `}

  &:hover {
    background-color: rgba(0, 155, 210, 0.08);

    ${ArticleQueueHover} {
      display: block;
    }
  }
`

export const InnerWrapper = styled(StyledFlex)<{ compressed?: boolean }>`
  gap: ${({ compressed }) => (compressed ? 12 : 16)}px;
`
