import { CircularProgress } from '@mui/material'
import styled from 'styled-components'
import CSS from 'csstype'
import React from 'react'

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface Props {
  isLoading?: boolean
  style?: CSS.Properties
  children?: React.ReactNode
}

export const LoaderContainer = ({ isLoading, style, children }: Props) => {
  if (isLoading) {
    return (
      <Wrapper>
        <CircularProgress
          className="loader"
          data-testid="loader"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            ...style,
          }}
        />
      </Wrapper>
    )
  }

  return <>{children || null}</>
}
