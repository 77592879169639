import React, { useRef } from 'react'

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import QuoteIcon from '@mui/icons-material/FormatQuote'

const keyQuoteToolbarButton = (
  <Tooltip title="Key Quote">
    <IconButton className="ql-kquote">
      <QuoteIcon />
    </IconButton>
  </Tooltip>
)

const QuillKeyQuote = ({ children, handleKeyQuote }) => {
  const quill = useRef()

  const handleAddKeyQuote = () => {
    const { index, length } = quill.current.getSelection()
    const selectedText = quill.current.getText(index, length)
    handleKeyQuote(selectedText)

    // collapse selection
    quill.current.setSelection(index + length, 0)
  }

  return children({
    kquoteHandler: { kquote: handleAddKeyQuote },
    kquoteFormat: 'kquote',
    keyQuoteToolbarButton,
    onEditorRef: (el) => (quill.current = el),
  })
}

export default QuillKeyQuote
