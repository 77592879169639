import { makeAutoObservable, runInAction } from 'mobx'
import { getInstructors } from 'api/instructors'

const store = makeAutoObservable({
  instructors: [],
  loading: false,
  error: null,

  getInstructors: async () => {
    store.loading = true
    store.error = null
    try {
      const instructors = await getInstructors()
      runInAction(() => {
        store.loading = false
        store.instructors = instructors.map((instr) => ({ userId: instr.userId, name: instr.name }))
      })
    } catch (error) {
      runInAction(() => {
        store.error = error
        store.loading = false
      })
    }
  },
})

export default store
