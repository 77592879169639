import { StyledDialogTitle } from './styles'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import React, { ReactNode } from 'react'

interface Props {
  onClose?(e: React.MouseEvent<HTMLButtonElement>): void
  children: ReactNode
}

const CommonDialogTitle = (props: Props) => (
  <StyledDialogTitle>
    {props.children}

    {props.onClose && (
      <IconButton sx={{ gridArea: 'closeButton' }} onClick={props.onClose}>
        <CloseIcon />
      </IconButton>
    )}
  </StyledDialogTitle>
)

export default CommonDialogTitle
