import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

const ToolsForm = (props) => {
  if (props.isSingleSelect) {
    return (
      <Autocomplete
        name="tools"
        value={
          props.selectedValues && props.options
            ? props.options?.find((tool) => props.selectedValues?.includes(tool.id))
            : null
        }
        onChange={(e, v) => props.handleChange(e, v?.id ? [v.id] : [], 'tools')}
        options={props.options ? props.options : []}
        getOptionLabel={(option) => option?.title || ''}
        fullWidth
        renderInput={(params) => <TextField {...params} label="Tools" placeholder="Tool" variant="standard" />}
      />
    )
  }

  return (
    <Autocomplete
      name="tools"
      value={
        props.selectedValues && props.options
          ? props.options?.filter((tool) => props.selectedValues?.includes(tool.id))
          : []
      }
      onChange={(e, v) => {
        props.handleChange(
          e,
          v?.map((v) => v.id),
          'tools'
        )
      }}
      disableCloseOnSelect
      multiple
      options={props.options ? props.options : []}
      getOptionLabel={(option) => option?.title}
      limitTags={7}
      fullWidth
      renderInput={(params) => <TextField {...params} label="Tools" placeholder="Tool" variant="standard" />}
    />
  )
}

const ToolType = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  active: PropTypes.bool,
})

ToolsForm.propTypes = {
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(ToolType),
  handleChange: PropTypes.func,
  isSingleSelect: PropTypes.bool,
}

export default ToolsForm
