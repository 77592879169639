import axios from './axios'

export const getClassSubscriptions = async ({ requestData, lastEvaluatedKey }) => {
  const params = { ...requestData, ...lastEvaluatedKey }

  const { data } = await axios.get('class-subscriptions', { params })
  return data
}

export const saveClassSubscription = async (body) => {
  const { data } = await axios.post('class-subscriptions', body)
  return data
}

export const updateClassSubscription = async (body) => {
  const { data } = await axios.put(`class-subscriptions/${body.id}`, body)
  return data
}
