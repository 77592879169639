import { fromJS, Map } from 'immutable'
import { SEARCH_IMAGES_REQUEST, SEARCH_IMAGES_SUCCESS, SEARCH_IMAGES_FAILURE } from 'utils/constants/actions'

const imageReducer = (state = Map(), action) => {
  switch (action.type) {
    case SEARCH_IMAGES_REQUEST:
      return state.set('searching', true)

    case SEARCH_IMAGES_FAILURE:
      return state.set('searching', false).set('error', action.error)

    case SEARCH_IMAGES_SUCCESS:
      return state
        .set('searching', false)
        .set(
          'images',
          !action.payload.from
            ? fromJS(action.payload.images)
            : fromJS(state.get('images').toJS().concat(action.payload.images))
        )

    default:
      return state
  }
}

export default imageReducer
