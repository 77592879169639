import { Map, fromJS } from 'immutable'
import {
  LOAD_SHOPIFY_PRODUCTS_REQUEST,
  LOAD_SHOPIFY_PRODUCTS_SUCCESS,
  LOAD_SHOPIFY_PRODUCTS_FAILURE,
} from 'utils/constants/actions'

const tagsReducer = (state = Map(), action) => {
  switch (action.type) {
    case LOAD_SHOPIFY_PRODUCTS_REQUEST:
      return state.set('loading', true)

    case LOAD_SHOPIFY_PRODUCTS_SUCCESS:
      return state.set('loading', false).set('products', fromJS(action.payload))

    case LOAD_SHOPIFY_PRODUCTS_FAILURE:
      return state.set('loading', false)

    default:
      return state
  }
}

export default tagsReducer
