import { Component } from 'react'
import { connect } from 'react-redux'
import imageUrl from 'utils/imageUrl'
import cloneDeep from 'lodash/cloneDeep'
import DialogContent from '@mui/material/DialogContent'
import moment from 'moment'

import { SelectSource, SearchLibrary, ImageData, UploadConfirmation, Crops, AdjustCrop } from '.'
import SetPriorityForm from './SetPriorityForm'

import { uploadImage, saveImageData } from 'actions/imageActions'
import createCloudinaryCropPath from 'utils/createCloudinaryCropPath'
import createPreliminaryCrops from 'utils/createPreliminaryCrops'
import StyledFlex, { StyledColumn } from '../../../../../layouts/StyledLayouts'
import CommonDialogTitle from '../../../../Common/DialogComponents/CommonDialogTitle'
import { LoaderContainer } from '../../../../../ui/LoaderContainer'
import { RootDispatch, RootState } from '../../../../../App'

enum TitlesEnum {
  SCREEN_SET_PRIORITY = 'set-priority',
  SCREEN_SELECT_SOURCE = 'select-source',
  SCREEN_MEDIA_LIBRARY = 'search-library',
  SCREEN_UPLOAD_CONFIRMATION = 'upload-confirmation',
  SCREEN_IMAGE_MANAGEMENT = 'image-management',
  SCREEN_CROPS_MANAGEMENT = 'crops-management',
  SCREEN_CROP_ADJUST = 'crop-adjust',
}

interface CropType {
  width: number
  height: number
  aspect: number // do we need aspect or height?
}

interface Props extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {
  show: boolean
  initialState?: TitlesEnum
  namespace: string
  imageTitle: string
  crops: CropType[]
  priority: number
  imageDialogType: string
  minWidth?: number
  onCancel(): void
  onEditImage(data: string, priority: number): void
  onInsertImage(data: string): void
}

interface State {
  image: any | null
  selectedImage: any | null
  selectedCropIndex: number
  screen: TitlesEnum
  skipImageMetadata: boolean
  priority: number
}

class SingleImageInsert extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      image: props.initialState ? this.initializeImageCrops(props.initialState, props.crops) : null,
      selectedImage: null,
      selectedCropIndex: -1,
      screen: this.getInitialScreenState(props),
      skipImageMetadata: props.imageDialogType === 'partnerLogo' || props.imageDialogType === 'navigationLogo',
      priority: props.priority || 2,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      uploadingImage,
      uploadedImage,
      savingImageData,
      onInsertImage,
      onEditImage,
      initialState,
      imageId,
      imageError,
      show,
      crops,
    } = nextProps
    const { priority, skipImageMetadata, selectedImage } = this.state

    // uploading image file complete
    if (show && this.props.uploadingImage && !uploadingImage && uploadedImage) {
      if (skipImageMetadata) {
        onInsertImage(uploadedImage.get('Location'))
      } else {
        const { width, height } = selectedImage
        const newImage: any = {
          crops: createPreliminaryCrops(uploadedImage.get('Key'), crops).map((crop: CropType) => ({
            ...crop,
            pristine: true,
          })),
          path: uploadedImage.get('Key'),
          width,
          height,
        }

        const createDate = uploadedImage.getIn(['metadata', 'exif', 'CreateDate'])
        if (createDate) {
          newImage.createDate = moment(createDate, 'YYYY:MM:DD HH:mm:ss').toISOString()
        }

        const caption = uploadedImage.getIn(['iptc', 'caption'])
        if (caption) newImage.caption = caption

        const credit = uploadedImage.getIn(['iptc', 'credit'])
        if (credit) newImage.credit = credit

        const keywords = uploadedImage.getIn(['iptc', 'keywords'])
        if (keywords) newImage.tags = keywords

        this.setState({
          image: newImage,
          screen: TitlesEnum.SCREEN_IMAGE_MANAGEMENT,
        })
      }
    }

    // upload image file failed
    if (this.props.uploadingImage && !uploadingImage && imageError) {
      this.setState({
        selectedImage: null,
        screen: TitlesEnum.SCREEN_SELECT_SOURCE,
      })
    }

    // saving image data complete
    if (this.props.savingImageData && !savingImageData) {
      const data = Object.assign({}, this.state.image, { id: imageId })
      data.crops = data.crops.filter((crop: any) => !crop.pristine)
      data.crops.forEach((crop: any) => delete crop.pristine)

      if (initialState) {
        onEditImage(data, priority)
      } else {
        onInsertImage(data)
      }
    }

    // saving image data failed
    if (this.props.savingImageData && !savingImageData && imageError) {
      this.setState({
        screen: TitlesEnum.SCREEN_CROPS_MANAGEMENT,
      })
    }

    // dialog reopened
    if (!this.props.show && show && initialState) {
      this.setState({
        image: initialState,
        screen: TitlesEnum.SCREEN_IMAGE_MANAGEMENT,
      })
    }
  }

  getInitialScreenState = (props: Props) => {
    if (props.initialState) {
      return props.imageType === 'inline' ? TitlesEnum.SCREEN_SET_PRIORITY : TitlesEnum.SCREEN_IMAGE_MANAGEMENT
    }
    return props.imageType === 'inline' ? TitlesEnum.SCREEN_SET_PRIORITY : TitlesEnum.SCREEN_SELECT_SOURCE
  }

  initializeImageCrops(initialImage: any, crops: CropType[]) {
    const image = cloneDeep(initialImage)
    const initialCrops = createPreliminaryCrops(image.path, crops).map((crop: any) => ({ ...crop, pristine: true }))

    if (!image.crops) image.crops = []
    image.crops.forEach((crop: any) => {
      const idx = initialCrops.findIndex((c: CropType) => {
        const ratioA = crop.width / crop.height
        const ratioB = c.width / c.height
        return Math.abs(ratioA - ratioB) < 1e-2
      })

      if (idx > -1) initialCrops[idx] = { ...crop, pristine: false }
    })

    image.crops = initialCrops
    return image
  }

  searchLibrarySelect() {
    this.setState({
      screen: TitlesEnum.SCREEN_MEDIA_LIBRARY,
    })
  }

  handleImageInput(data: any) {
    this.setState({
      selectedImage: data,
      screen: TitlesEnum.SCREEN_UPLOAD_CONFIRMATION,
    })
  }

  cancelUploadConfirmation() {
    this.setState({
      selectedImage: null,
      screen: TitlesEnum.SCREEN_SELECT_SOURCE,
    })
  }

  onUploadImage() {
    const { selectedImage } = this.state
    this.props.uploadImage({
      imageFile: selectedImage.dataUri,
      fileType: selectedImage.fileType,
    })
  }

  handleImageSourceChange() {
    this.setState({
      selectedImage: null,
      screen: TitlesEnum.SCREEN_SELECT_SOURCE,
      image: null,
    })
  }

  handleLibraryImage = (image: any) => {
    const { crops } = this.props
    this.setState(
      {
        image: this.initializeImageCrops(image, crops),
      },
      () => this.switchScreen(TitlesEnum.SCREEN_IMAGE_MANAGEMENT)
    )
  }

  handleImageSave(data: any) {
    const { crops } = this.props

    const image = Object.assign({}, this.state.image, data)

    this.setState({ image }, () => {
      if (crops.length) {
        this.setState({
          screen: TitlesEnum.SCREEN_CROPS_MANAGEMENT,
        })
      } else {
        this.handleCropsComplete()
      }
    })
  }

  handleCropsComplete() {
    const { saveImageData } = this.props
    const { image } = this.state
    const data = Object.assign({}, image)
    data.crops = data.crops.filter((crop: any) => !crop.pristine)
    data.crops.forEach((crop: any) => delete crop.pristine)
    delete data.heroImage
    saveImageData(data)
  }

  adjustCrop(crop: any, index: number) {
    this.setState({
      screen: TitlesEnum.SCREEN_CROP_ADJUST,
      selectedCropIndex: index,
    })
  }

  handleCropCancel() {
    this.setState({
      screen: TitlesEnum.SCREEN_CROPS_MANAGEMENT,
      selectedCropIndex: -1,
    })
  }

  handleCrop(cropData: any) {
    const { selectedCropIndex } = this.state
    const newImage = Object.assign({}, this.state.image)
    newImage.crops[selectedCropIndex] = createCloudinaryCropPath({
      key: newImage.path,
      cropData,
      // pristine: false, // createCloudinaryCropPath is not using this
    })

    this.setState({
      image: newImage,
    })
    this.handleCropCancel()
  }

  switchScreen = (screen: TitlesEnum) => {
    this.setState({
      screen,
    })
  }

  get title() {
    switch (this.state.screen) {
      case TitlesEnum.SCREEN_SET_PRIORITY:
        return 'Context'
      case TitlesEnum.SCREEN_SELECT_SOURCE:
        return `Insert ${this.props.imageTitle}`
      case TitlesEnum.SCREEN_IMAGE_MANAGEMENT:
        return `${this.props.imageTitle}`
      case TitlesEnum.SCREEN_MEDIA_LIBRARY:
        return 'MBG Image Library'
      case TitlesEnum.SCREEN_UPLOAD_CONFIRMATION:
        return 'Confirm upload'
      case TitlesEnum.SCREEN_CROPS_MANAGEMENT:
        return `${this.props.imageTitle} crops`
      case TitlesEnum.SCREEN_CROP_ADJUST:
        return 'Adjust crop'
      default:
        return 'Image Insert'
    }
  }

  render() {
    const { onCancel, uploadingImage, savingImageData, uploadedImage, imageError, imageDialogType, minWidth, crops } =
      this.props
    const { screen, selectedImage, image, selectedCropIndex, priority } = this.state

    const imgUrl = image ? imageUrl(image.path, null) : null
    const labels = uploadedImage ? uploadedImage.get('Labels') : null

    return (
      <>
        <CommonDialogTitle onClose={() => onCancel()}>
          <div>{this.title}</div>
        </CommonDialogTitle>
        <DialogContent>
          <LoaderContainer style={{ top: '40%' }} isLoading={uploadingImage || savingImageData}>
            {/* dialog content component should be moved into every possible inner component
             * due to integrated dialog actions */}
            {(() => {
              switch (screen) {
                case TitlesEnum.SCREEN_SET_PRIORITY:
                  return (
                    <SetPriorityForm
                      priority={priority}
                      updatePriority={(v) => this.setState({ priority: v })}
                      handleConfirm={() =>
                        this.switchScreen(image ? TitlesEnum.SCREEN_IMAGE_MANAGEMENT : TitlesEnum.SCREEN_SELECT_SOURCE)
                      }
                    />
                  )
                case TitlesEnum.SCREEN_SELECT_SOURCE:
                  return (
                    <StyledColumn $gap>
                      <SelectSource
                        handleImageInput={(e) => this.handleImageInput(e)}
                        maxFileSize={5}
                        minWidth={minWidth || 0}
                        searchLibrarySelect={() => this.switchScreen(TitlesEnum.SCREEN_MEDIA_LIBRARY)}
                      />
                      {imageError && <StyledFlex>Upload failed</StyledFlex>}
                    </StyledColumn>
                  )

                case TitlesEnum.SCREEN_UPLOAD_CONFIRMATION:
                  return (
                    <UploadConfirmation
                      onCancel={() => this.cancelUploadConfirmation()}
                      onUpload={() => this.onUploadImage()}
                      imageFile={selectedImage.dataUri}
                    />
                  )

                case TitlesEnum.SCREEN_MEDIA_LIBRARY:
                  return (
                    <SearchLibrary
                      onCancel={() => this.switchScreen(TitlesEnum.SCREEN_SELECT_SOURCE)}
                      onImageSelected={this.handleLibraryImage}
                    />
                  )

                case TitlesEnum.SCREEN_IMAGE_MANAGEMENT:
                  return (
                    // @ts-expect-error
                    <ImageData
                      onChooseDifferentImage={() => this.handleImageSourceChange()}
                      uploadedImageUrl={imgUrl}
                      uploadedImageLabels={labels}
                      initialValues={image}
                      onSubmit={(data: any) => this.handleImageSave(data)}
                      isPreviewImage={imageDialogType === 'previewImage'}
                      hasCrops={crops.length > 0}
                    />
                  )

                case TitlesEnum.SCREEN_CROPS_MANAGEMENT:
                  return (
                    <Crops
                      uploadedImageCrops={image.crops}
                      adjustCrop={(crop, idx) => this.adjustCrop(crop, idx)}
                      onChooseDifferentImage={() => this.handleImageSourceChange()}
                      onCropsDone={() => this.handleCropsComplete()}
                      crops={crops}
                    />
                  )

                case TitlesEnum.SCREEN_CROP_ADJUST:
                  return (
                    <AdjustCrop
                      uploadedImageUrl={imgUrl}
                      crop={crops[selectedCropIndex]}
                      imageCrop={image.crops[selectedCropIndex]}
                      onCropCancel={() => this.handleCropCancel()}
                      saveCropOverride={(cropData) => this.handleCrop(cropData)}
                    />
                  )
                default:
                  return null
              }
            })()}
          </LoaderContainer>
        </DialogContent>
      </>
    )
  }
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  const context = ownProps.namespace
  return {
    uploadedImage: state.imageUpload[context].get('uploadedImage'),
    uploadingImage: state.imageUpload[context].get('uploading'),
    savingImageData: state.imageUpload[context].get('savingData'),
    imageId: state.imageUpload[context].get('imageId'),
    imageType: state.createArticleInteractions.getIn(['edit', 'imageType']),
    imageError: state.imageUpload[context].get('error'),
  }
}

const mapDispatchToProps = (dispatch: RootDispatch, ownProps: any) => {
  const context = ownProps.namespace
  return {
    uploadImage: (data: any) => dispatch(uploadImage(context, data)),
    saveImageData: (data: any) => dispatch(saveImageData(context, data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleImageInsert)
