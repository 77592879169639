import axios from './axios'

export const getOrders = async ({ requestData, lastEvaluatedKey }) => {
  const params = { ...requestData, ...lastEvaluatedKey }
  const { data } = await axios.get(`orders?${new URLSearchParams(params).toString()}`)
  return data
}

export const createAdjustment = async ({ orderId, sortKey, adjustment }) => {
  const { data } = await axios.post(`orders/adjustment/${orderId}`, { sortKey, adjustment })
  return data
}

export const deleteAdjustment = async ({ orderId, sortKey, createDate }) => {
  const { data } = await axios.post(`orders/adjustment/delete/${orderId}`, { sortKey, createDate })
  return data
}

export const getClassSubDetails = async ({ id, ...params }) => {
  const { data } = await axios.get(`class-subscriptions/${id}?${new URLSearchParams(params).toString()}`)
  return data
}
