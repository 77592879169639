import { RSAA } from 'redux-api-middleware'

import {
  ANALYSE_TEXT_REQUEST,
  ANALYSE_TEXT_FAILURE,
  ANALYSE_TEXT_SUCCESS,
  TEXT_ANALYSIS_DIALOG_TOGGLE,
} from 'utils/constants/actions'

export function analyzeText() {
  return {
    [RSAA]: {
      endpoint: 'analyze/text',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      types: [ANALYSE_TEXT_REQUEST, ANALYSE_TEXT_SUCCESS, ANALYSE_TEXT_FAILURE],
    },
  }
}

export function toggleDialog(open) {
  return {
    type: TEXT_ANALYSIS_DIALOG_TOGGLE,
    open,
  }
}
