import { ARTICLES_SORT } from 'utils/constants/actions'
import moment from 'moment'

const articleQueueSort = (store) => (next) => (action) => {
  if (action.type === ARTICLES_SORT) {
    const { articles } = store.getState()
    const { sortBy } = action

    const list = articles.get('articles')
    const previousSortBy = articles.get('sortBy')
    const sortDir = articles.get('sortDir')

    if (previousSortBy === sortBy) {
      action.sortedList = list.reverse()
      action.sortDir = sortDir === 'DESC' ? 'ASC' : 'DESC'
    } else {
      action.sortedList = list.sort((a, b) => {
        const aItem = a.get(sortBy)
        const bItem = b.get(sortBy)
        if (sortBy === 'publish_time') {
          return moment(aItem) - moment(bItem)
        } else {
          return aItem.localeCompare(bItem)
        }
      })
      action.sortDir = 'DESC'
    }
  }

  return next(action)
}

export default articleQueueSort
