import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { observer } from 'mobx-react-lite'

import isValidEmail from 'utils/isValidEmail'
import FileInput from 'ui/FileInputNew'
import Loader from 'components/new-pages/Loader'
import { logError } from '../../../../../utils/errorHandling'
import { useUpdateClassAttendance } from '../../../../../hooks/useClass'
import { ClassModule } from '../../../../../types/ClassModule'
import { DataErrorDisplay, MutationErrorDisplay, MutationSuccessDisplay } from './Wiews'
import { StyledColumn } from '../../../../../layouts/StyledLayouts'

interface Props {
  classId: number
  open: boolean
  handleClose(isClosed: boolean): void
  activeLiveSession?: ClassModule.Session
}

const UpdateAttendance = ({ open, handleClose, activeLiveSession, classId }: Props) => {
  const [dataError, setDataError] = useState<string>()
  const [emailAddresses, setEmailAddresses] = useState<string[]>([])

  const classAttendanceMutation = useUpdateClassAttendance()

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddresses([])
    const reader = new FileReader()

    if (!event.target?.files?.length) {
      return
    }

    const file = event.target.files[0]

    reader.onload = (e) => {
      if (typeof e.target?.result !== 'string') {
        return
      }
      // split the content by lines
      const results = e.target.result?.split(/\r?\n/) || []

      // check if valid emails and remove duplicates
      setEmailAddresses([...new Set(results.filter((r) => isValidEmail(r)))])

      setDataError(undefined)
      classAttendanceMutation.reset()
    }

    reader.readAsText(file)
  }

  const handleUpdateClick = async () => {
    setDataError(undefined)
    try {
      const instances = activeLiveSession?.instances

      if (!activeLiveSession || !Array.isArray(instances)) {
        throw new Error('Session instances not found')
      }

      const params = {
        lessonId: activeLiveSession.id,
        classId,
        emailAddresses,
        termId: activeLiveSession.termId,
        activityDate: instances[0].startDate,
        validateOnly: false,
      }

      await classAttendanceMutation.mutate(params)
    } catch (error) {
      logError(error)
      if (error instanceof Error) {
        setDataError(error.message)
      }
    } finally {
      setEmailAddresses([])
    }
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Update Attendance</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {classAttendanceMutation.isLoading ? (
          <Loader />
        ) : (
          <FileInput
            label="Upload CSV"
            accept=".csv"
            handleFileInput={handleFileUpload}
            btnProps={{
              variant: 'contained',
              color: 'primary',
            }}
          />
        )}

        {dataError && <DataErrorDisplay message={dataError} />}

        {classAttendanceMutation.isError && <MutationErrorDisplay {...classAttendanceMutation.error} />}

        {classAttendanceMutation.isSuccess && (
          <MutationSuccessDisplay classId={classId} success={classAttendanceMutation.data} />
        )}

        {emailAddresses.length > 0 && (
          <>
            <strong style={{ marginBottom: '12px' }}>
              {emailAddresses.length} email{emailAddresses.length > 1 && 's'} found:
            </strong>
            <StyledColumn $gap>
              {emailAddresses.map((email) => (
                <div key={email}>{email}</div>
              ))}
            </StyledColumn>
            <h4>To update attendance, press CONFIRM.</h4>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose(false)
          }}
          color="secondary"
        >
          Close
        </Button>
        <Button
          color="primary"
          disabled={!emailAddresses.length || classAttendanceMutation.isLoading}
          onClick={handleUpdateClick}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(UpdateAttendance)
