import axios from './axios'

export const getTags = async () => {
  const { data } = await axios.get('v2/tags')
  return data
}

export const updateTag = async ({ id, tag }) => {
  const { data } = await axios.put(`v2/tags/${id}`, tag)
  return data
}

export const createTag = async ({ tag }) => {
  const { data } = await axios.post('v2/tags', tag)
  return data
}
