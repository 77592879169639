export const QueryKeys = {
  articleSearch: 'articleSearch',
  articleById: 'articleById',
  articleVersions: 'articleVersions',
  campaignId: 'campaignId',
  campaigns: 'campaigns',
  class: 'class',
  editors: 'editors',
  instructors: 'instructors',
  liveArticleVersion: 'liveArticleVersion',
  partners: 'partners',
  partnersById: 'partnersById',
  tags: 'tags',
  users: 'users',
  video: 'video',
  videoAssignments: 'videoAssignments',
  videoAssignmentSubmissions: 'videoAssignmentsSubmissions',
}
