import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import ItalicIcon from '@mui/icons-material/FormatItalic'
import MenuItem from '@mui/material/MenuItem'
import { QuillField } from 'ui/QuillTransition'
import DefaultTextField from 'ui/DefaultTextField'
import AffiliateUrlField from 'ui/AffiliateUrlField'
import DefaultCheckbox from 'ui/DefaultCheckbox'
import DefaultSelectField from 'ui/DefaultSelectField'
import ImageInput from 'ui/ImageInput'
import imageUrl from 'utils/imageUrl'
import deltaToHTML from 'utils/deltaToHTML'
import SingleImageInsert from './SingleImageInsert'
import { Quill } from 'react-quill'
import styled from 'styled-components'
import StyledFlex, { StyledColumn } from '../../../../../layouts/StyledLayouts'

const StyledTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
`

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`

const ProductTitleToolbar = ({ id }) => (
  <Paper elevation={2} id={id}>
    <IconButton className="ql-italic">
      <ItalicIcon />
    </IconButton>
  </Paper>
)

ProductTitleToolbar.propTypes = {
  id: PropTypes.string,
}

const ProductTitleInput = ({ input: { value, onChange } }) => (
  <QuillField
    toolbar={<ProductTitleToolbar />}
    bounds="#shop-product-form"
    customFormats={['italic']}
    onChange={onChange}
    value={value || ''}
    placeholder="Product name"
    activeFormat={{ name: 'header', value: 4 }}
  />
)

ProductTitleInput.propTypes = {
  input: PropTypes.object,
}

const validateForm = (data) => {
  const errors = {}
  if (!data.productName && !data.productId) {
    errors.productName = 'Required'
  }

  if (!data.url && !data.productId) {
    errors.url = 'Required'
  }

  return errors
}

const ProductData = ({
  products,
  initialValues,
  handleCancel,
  handleSubmit,
  closeDialog,
  productDataSubmit,
  formProductId,
}) => {
  const [imageMode, setImageMode] = useState(false)
  const [imageData, setImageData] = useState(null)
  const [productImage, setProductImage] = useState(null)
  const [imageSrc, setImageSrc] = useState(null)
  const [defaultData, setDefaultData] = useState({
    description: null,
    title: null,
    url: null,
    price: null,
  })

  const editor = useRef(new Quill(document.createElement('div')))

  useEffect(() => {
    if (initialValues?.productId && !initialValues?.image) {
      setProductImage(findProductImage(initialValues.productId))
    }
    if (initialValues?.image) {
      setImageData(initialValues.image)
    }
    if (initialValues?.productId) {
      setDefaultData(getProductData(initialValues.productId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  const findProductImage = (productId) => {
    const product = products && products.find((p) => p.get('id') === productId)
    const metafields = product && product.get('metafields')
    const productImage = metafields && metafields.find((f) => f.get('key') === 'Product_Image')
    return productImage ? productImage.get('value') : null
  }

  const getProductData = (productId) => {
    const product = products.find((p) => p.get('id') === productId)
    const title = product.get('title')

    const metafields = product.get('metafields')
    const descField = metafields && metafields.find((f) => f.get('key') === 'Subtitle')
    const urlField = metafields && metafields.find((f) => f.get('key') === 'pdp_url')

    const variant = product.get('variants') ? product.get('variants').toJS().pop() : null
    const price = variant ? variant.price : null

    return {
      title,
      description: descField && deltaToHTML(editor.current.clipboard.convert(descField.get('value'))),
      url: urlField && urlField.get('value'),
      price,
    }
  }

  const onProductChange = (value) => {
    const product = products.find((p) => p.get('id') === value)

    const title = product.get('title')

    const metafields = product.get('metafields')

    const descField = metafields && metafields.find((f) => f.get('key') === 'Subtitle')
    const urlField = metafields && metafields.find((f) => f.get('key') === 'pdp_url')
    const productImage = metafields && metafields.find((f) => f.get('key') === 'Product_Image')

    const variant = product.get('variants') ? product.get('variants').toJS().pop() : null

    setImageData(null)
    setProductImage(productImage ? productImage.get('value') : null)

    setDefaultData({
      title,
      description: descField && deltaToHTML(editor.current.clipboard.convert(descField.get('value'))),
      url: urlField && urlField.get('value'),
      price: variant ? variant.price : null,
    })

    return value
  }

  // sets imageSrc
  useEffect(() => {
    setImageSrc(imageData ? imageUrl(imageData.path) : productImage)
    setTimeout(() => window.dispatchEvent(new Event('resize')))
  }, [imageData, productImage])

  const onImageInsert = (image) => {
    setImageData(image)
    setImageMode(false)
  }

  const submitForm = (values) => {
    productDataSubmit({ ...values, image: imageData })
  }

  return (
    <>
      {imageMode ? (
        <SingleImageInsert
          imageTitle="Product image"
          initialState={imageData}
          namespace="shop"
          onInsertImage={onImageInsert}
          onEditImage={onImageInsert}
          onCancel={() => setImageMode(false)}
          crops={[
            {
              width: 943,
              aspect: 1.78,
            },
            {
              width: 943,
              aspect: 1.5,
            },
          ]}
          show
        />
      ) : (
        <>
          <StyledTitle>
            <div>Manage product data</div>
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </StyledTitle>
          <DialogContent>
            <form id="shop-product-form" onSubmit={handleSubmit(submitForm)}>
              <StyledFlex style={{ justifyContent: 'space-between', gap: '16px' }}>
                <StyledColumn style={{ flex: 1, gap: '12px' }}>
                  <Field
                    component={DefaultSelectField}
                    fullWidth
                    label="Select Product"
                    name="productId"
                    type="select"
                    defaultValue={null}
                    normalize={onProductChange}
                  >
                    {products &&
                      products.map((p) => (
                        <MenuItem key={p.get('id')} value={p.get('id')}>
                          {p.get('title')}{' '}
                        </MenuItem>
                      ))}
                  </Field>
                  <Field component={ProductTitleInput} name="productName" />
                  {defaultData.title && (
                    <>
                      <div style={{ fontSize: 12 }}>Default: {defaultData.title}</div>
                      <br />
                    </>
                  )}
                  <Field
                    component={DefaultTextField}
                    label="Description"
                    name="description"
                    minRows={3}
                    multiline
                    fullWidth
                  />
                  {defaultData.description && (
                    <>
                      <div
                        style={{ fontSize: 12 }}
                        dangerouslySetInnerHTML={{
                          __html: defaultData.description.replace(/<p>(.*?)<\/p>/i, (match, p1) => `Default: ${p1}`),
                        }}
                      />
                      <br />
                    </>
                  )}
                  <Field component={DefaultTextField} fullWidth label="Source" name="source" />
                  <Field component={DefaultTextField} fullWidth label="Brand" name="brand" />
                  <Field component={DefaultTextField} fullWidth label="Price" name="price" />
                  {defaultData.price && (
                    <>
                      <div style={{ fontSize: 12 }}>Default: $ {defaultData.price}</div>
                      <br />
                    </>
                  )}
                  <Field component={AffiliateUrlField} fullWidth label="URL" name="url" />
                  {defaultData.url && (
                    <>
                      <div style={{ fontSize: 12 }}>Default: {defaultData.url}</div>
                      <br />
                    </>
                  )}
                  <Field component={DefaultTextField} fullWidth label="Button Text" name="buttonText" />
                  <StyledFlex style={{ gap: '16px' }}>
                    <Field component={DefaultCheckbox} name="nofollowUrl" label="Nofollow?" />
                    <Field component={DefaultCheckbox} name="sponsoredUrl" label="Sponsored?" />
                  </StyledFlex>
                </StyledColumn>
                <StyledColumn style={{ flex: 1 }}>
                  <Field
                    component={ImageInput}
                    label="Logo"
                    name="logo"
                    namespace="shopLogo"
                    crops={[]}
                    dialogTitle="Product Logo"
                    imageStyles={{ margin: 'auto', width: '60%', objectFit: 'contain' }}
                  />
                  <br />

                  {imageSrc ? (
                    <>
                      <StyledImage src={imageSrc} alt="preview" />
                      <br />
                      <StyledFlex style={{ justifyContent: 'center' }}>
                        <Button onClick={() => setImageMode(true)}>Change image</Button>
                      </StyledFlex>
                    </>
                  ) : (
                    <StyledFlex style={{ justifyContent: 'center' }}>
                      <br />
                      <Button onClick={() => setImageMode(true)}>Upload image</Button>
                    </StyledFlex>
                  )}
                </StyledColumn>
              </StyledFlex>

              <StyledFlex style={{ justifyContent: 'space-between' }}>
                <Button onClick={handleCancel}>Go back</Button>
                <StyledFlex>
                  <Button disabled={!formProductId && !imageData} type="submit">
                    Save
                  </Button>
                </StyledFlex>
              </StyledFlex>
            </form>
          </DialogContent>
        </>
      )}
    </>
  )
}

ProductData.propTypes = {
  initialValues: PropTypes.object,
  products: PropTypes.object,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  closeDialog: PropTypes.func,
  productDataSubmit: PropTypes.func,
  formProductId: PropTypes.number,
}

const selector = formValueSelector('productData')
const mapStateToProps = (state) => ({
  productLoading: state.shopify.get('loading'),
  products: state.shopify.get('products'),
  formProductId: selector(state, 'productId'),
})

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'productData',
    asyncBlurFields: [],
    validate: validateForm,
  })
)(ProductData)
