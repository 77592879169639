import React, { useState } from 'react'
import { connect } from 'react-redux'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Dialog, Button, TextField } from '@mui/material'

import { toggleConfirmDialog } from 'actions/createArticleActions'
import CommonDialogTitle from '../../Common/DialogComponents/CommonDialogTitle'
import { RootDispatch, RootState } from '../../../App'

interface Props extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {
  onUnpublish(): void
  currentPath: string
}

const UnpublishDialog = ({ open, toggleDialog, onUnpublish, currentPath }: Props) => {
  const [confirm, setConfirm] = useState('')

  const updateConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirm(e.target.value.toLowerCase().trim())
  }

  let contentType = 'article'
  if (currentPath.includes('recipe')) contentType = 'recipe'
  if (currentPath.includes('podcast')) contentType = 'podcast'

  return (
    <Dialog open={open} onClose={toggleDialog}>
      <CommonDialogTitle onClose={toggleDialog}>
        <div>Are you sure you want to unpublish this {contentType}?</div>
      </CommonDialogTitle>
      <DialogContent>
        <TextField
          helperText='Type "unpublish" to confirm'
          value={confirm}
          onChange={updateConfirm}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleDialog}>Cancel</Button>
        <Button
          color="secondary"
          disabled={confirm !== 'unpublish'}
          onClick={() => {
            if (confirm === 'unpublish') {
              onUnpublish()
              toggleDialog()
            }
          }}
        >
          Unpublish
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: RootState) => ({
  open: state.confirmDialog.unpublish.get('open'),
})

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  toggleDialog: () => dispatch(toggleConfirmDialog('unpublish')),
})

export default connect(mapStateToProps, mapDispatchToProps)(UnpublishDialog)
