import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TemplateIconMenu from 'components/ArticleTemplateMenu'
import { Navbar } from 'components/NavBar'
import SideMenu from 'components/SideMenu'
import { toggleSearchbar } from 'actions/navbarActions'
import { searchArticleById, searchArticleByTitle, searchArticleByUrl } from 'actions/articleSearchActions'

import Searchbar from 'components/NavBar/SearchBar'

import grantedByRole from 'utils/grantedByRole'
import { CREATE_ARTICLE, VIEW_QUEUE } from 'utils/constants/privileges'
import { ContentWapper } from './layoutStyles'
import { StyledColumn } from './StyledLayouts'

const DashboardLayout = ({
  children,
  title,
  toggleSearchbar,
  showSearchbar,
  showCreateArticleMenu,
  searchArticles,
  articlesLoading,
  articles,
  userRoles,
  currentUserId,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  let foundArticles = articles ? articles.toJS() : []

  // filter out other people's articles if we don't have permissions to view them
  if (!grantedByRole(userRoles, VIEW_QUEUE)) {
    foundArticles = foundArticles.filter((a) => a.editorId === currentUserId)
  }

  foundArticles = foundArticles.map((a) => ({
    value: a.id,
    text: (a.titles && a.titles.short) || a.slug,
  }))

  return (
    <StyledColumn>
      <Navbar
        title={title}
        showLogo
        hideTabs={showSearchbar}
        toggleSidemenu={() => setIsDrawerOpen((p) => !p)}
        searchBar={
          <Searchbar
            toggleSearchbar={() => toggleSearchbar(showSearchbar)}
            showSearchbar={showSearchbar}
            label="Search by article ID, title, slug or URL"
            dataSource={foundArticles}
            dataSourceConfig={{ label: 'text', value: 'value' }}
            loading={articlesLoading}
            fetchSuggestions={searchArticles}
          />
        }
      />
      <SideMenu open={isDrawerOpen} toggleSidemenu={() => setIsDrawerOpen((p) => !p)} />
      {showCreateArticleMenu && grantedByRole(userRoles, CREATE_ARTICLE) && <TemplateIconMenu />}
      <ContentWapper>{children}</ContentWapper>
    </StyledColumn>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  showSearchbar: PropTypes.bool,
  toggleSearchbar: PropTypes.func,
  showCreateArticleMenu: PropTypes.bool,
  searchArticles: PropTypes.func,
  articlesLoading: PropTypes.bool,
  articles: PropTypes.object,
  userRoles: PropTypes.string,
  currentUserId: PropTypes.number,
}

const mapStateToProps = (state) => ({
  title: state.navbar.getIn(['navbarPrefs', 'title']),
  showSearchbar: state.navbar.getIn(['navbarPrefs', 'showSearchbar']),
  showCreateArticleMenu: state.articleTemplate.getIn(['templateMenuOptions', 'showCreateArticleMenu']),
  articlesLoading: state.articleSearch.get('loading'),
  articles: state.articleSearch.get('articles'),
  userRoles: state.profile.getIn(['profile', 'roles']),
  currentUserId: state.profile.getIn(['profile', 'userId']),
})

const mapDispatchToProps = (dispatch) => ({
  toggleSearchbar(isVisible) {
    isVisible = !isVisible
    dispatch(toggleSearchbar(isVisible))
  },
  searchArticles: (value) => {
    try {
      new URL(value) // eslint-disable-line
      dispatch(searchArticleByUrl(value))
    } catch (e) {
      if (!isNaN(parseInt(value))) {
        dispatch(searchArticleById(parseInt(value)))
      } else {
        dispatch(searchArticleByTitle(value))
      }
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout)
