import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { DebounceInput } from 'react-debounce-input'
import Alert from '@mui/material/Alert'

import imageUrl from 'utils/imageUrl'
import { useStore } from 'store/mobx'
import Loader from 'components/new-pages/Loader'

const Image = styled.img`
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const SearchLibrary = ({ goBack, setImage }) => {
  const { images, loading, error, fetchImages, hasMore } = useStore('imageLibraryStore')

  const [from, setFrom] = useState(0)
  const [size] = useState(10)
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearchChange = (event) => {
    const term = event.target.value
    if (term.length > 2) {
      fetchImages({ term, from: 0, size })
      setFrom(10)
      setSearchTerm(term)
    }
  }

  const loadMore = () => {
    fetchImages({ term: searchTerm, from, size })
    setFrom(from + 10)
  }

  return (
    <>
      <DialogContent>
        {loading && <Loader />}
        <Grid container spacing={3} direction="column">
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error.message}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <DebounceInput
              autoFocus
              element={TextField}
              minLength={3}
              debounceTimeout={800}
              onChange={handleSearchChange}
              label="Search images by title or tags"
              data-testid="searchImagesField"
              fullWidth
            />
          </Grid>
          <Grid container item spacing={2} direction="row" data-testid="searchImageLibraryList">
            {images.map((img) => (
              <Grid item xs={3} key={img.id}>
                <Image style={{ width: '100%' }} src={imageUrl(img.path)} onClick={() => setImage(img.path)} />
              </Grid>
            ))}
          </Grid>
          {hasMore ? (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button type="button" onClick={loadMore}>
                Load More
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={goBack}>
          Go Back
        </Button>
      </DialogActions>
    </>
  )
}

SearchLibrary.propTypes = {
  goBack: PropTypes.func,
  setImage: PropTypes.func,
}

export default observer(SearchLibrary)
