import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { toggleInboundLinkUpdateDialog } from 'actions/createArticleActions'
import { updateInboundLinks, publishMultiple } from 'actions/createArticleApiActions'
import Checkbox from '@mui/material/Checkbox'
import { IconButton, Icon, DialogActions, DialogContent, Dialog, Button } from '@mui/material'
import WithProgress from 'ui/WithProgress'
import StyledFlex from '../../../layouts/StyledLayouts'
import CommonDialogTitle from '../../Common/DialogComponents/CommonDialogTitle'
import { LoaderContainer } from '../../../ui/LoaderContainer'

class InboundLinkUpdateDialog extends Component {
  static propTypes = {
    closeInboundLinkUpdateDialog: PropTypes.func,
    updateInboundLinks: PropTypes.func,
    inboundLinks: PropTypes.object,
    articleUrl: PropTypes.string,
    inboundLinksUpdated: PropTypes.bool,
    publishMultiple: PropTypes.func,
    publishingMultiple: PropTypes.bool,
  }

  state = {
    checked: {},
  }

  constructor(props) {
    super(props)

    const { updateInboundLinks, inboundLinks, articleUrl } = props

    updateInboundLinks({
      ...inboundLinks.toJS(),
      newUrl: articleUrl,
    })
  }

  componentDidUpdate(prevProps) {
    const { publishingMultiple, closeInboundLinkUpdateDialog } = prevProps
    if (publishingMultiple && !this.props.publishingMultiple) closeInboundLinkUpdateDialog()
  }

  render() {
    const { closeInboundLinkUpdateDialog, inboundLinksUpdated, inboundLinks, publishMultiple, publishingMultiple } =
      this.props
    const { checked } = this.state

    const anyChecked = Object.values(checked).reduce((agg, next) => agg || next, false)

    const articles = inboundLinks.get('articles')

    return (
      <Dialog open fullWidth>
        <CommonDialogTitle>
          <div>Please confirm each article below has been updated correctly</div>
        </CommonDialogTitle>

        <DialogContent>
          <LoaderContainer isLoading={!inboundLinksUpdated}>
            {articles &&
              articles.map((article, idx) => (
                <StyledFlex key={idx} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <StyledFlex style={{ flex: '0 0 10%', alignItems: 'center' }}>
                    <Checkbox
                      checked={checked[article.get('id')]}
                      onChange={(e) => {
                        checked[article.get('id')] = e.target.checked
                        this.setState({ checked })
                      }}
                    />
                  </StyledFlex>
                  <StyledFlex style={{ flex: '0 0 70%', alignItems: 'center' }}>
                    {`#${article.get('id')}: ${article.get('title')}`}
                  </StyledFlex>
                  <StyledFlex style={{ flex: '0 0 10%', alignItems: 'center' }}>
                    <IconButton
                      onClick={() => {
                        const url = article.get('url') || article.get('legacyUrl')
                        window.open(`${url}?preview=true`, '_blank')
                      }}
                    >
                      <Icon>open_in_new</Icon>
                    </IconButton>
                  </StyledFlex>
                </StyledFlex>
              ))}
          </LoaderContainer>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeInboundLinkUpdateDialog}>Cancel</Button>
          <WithProgress isLoading={publishingMultiple}>
            <Button
              color="secondary"
              disabled={!anyChecked || publishingMultiple}
              onClick={() => {
                const ids = Object.keys(checked).filter((key) => checked[key])
                publishMultiple(ids)
              }}
            >
              Publish selected
            </Button>
          </WithProgress>
          <WithProgress isLoading={publishingMultiple}>
            <Button
              color="secondary"
              disabled={!anyChecked || publishingMultiple}
              onClick={() => {
                const ids = Object.keys(checked).filter((key) => checked[key])
                publishMultiple(ids)
              }}
            >
              Publish selected
            </Button>
          </WithProgress>
        </DialogActions>
      </Dialog>
    )
  }
}

const selector = formValueSelector('createArticleMetadataForm')
const mapStateToProps = (state) => ({
  inboundLinks: state.createArticleInteractions.get('inboundLinks'),
  articleUrl: selector(state, 'url'),
  inboundLinksUpdated: state.createArticleInteractions.get('inboundLinksUpdated'),
  publishingMultiple: state.createArticleInteractions.get('publishingMultiple'),
})

const mapDispatchToProps = (dispatch) => ({
  closeInboundLinkUpdateDialog: () => dispatch(toggleInboundLinkUpdateDialog(false)),
  updateInboundLinks: (data) => dispatch(updateInboundLinks(data)),
  publishMultiple: (ids) => dispatch(publishMultiple(ids)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InboundLinkUpdateDialog)
