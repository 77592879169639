import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Button from '@mui/material/Button'
import styled from 'styled-components'

import { getNyTime, getUTCTime } from '../../utils/formatDate'
import { startOfDay } from 'date-fns'

const Wrapper = styled.div`
  position: relative;
  float: left;
`

const InnerWrapper = styled.div`
  top: -100%;
  right: 125%;

  position: absolute;
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0 9.5px 19px 0 rgba(0, 0, 0, 0.3), 0 7.5px 6px 0 rgba(0, 0, 0, 0.22);
  :after {
    content: ' ';
    top: 9%;
    left: 100%;
    border: solid transparent;
    width: 0;
    height: 0;
    position: absolute;
    border-color: rgba(250, 250, 250, 0);
    border-left-color: #fff;
    border-width: 10px;
    margin-top: -10px;
  }
  :before {
    content: ' ';
    top: 9%;
    left: 100%;
    border: solid transparent;
    width: 0;
    height: 0;
    position: absolute;
    border-color: rgba(143, 143, 143, 0);
    border-left-color: lightgrey;
    border-width: 12px;
    margin-top: -12px;
  }
`

const StyledButton = styled(Button)`
  float: right;
  margin: 1rem 0.1rem 0.5rem;
`

const PublishTimeSelector = (props) => {
  const [show, setShow] = useState(false)

  const [dateTime, setDateTime] = useState(props.dateTime ? new Date(props.dateTime) : startOfDay(new Date()))

  const wrapperRef = useRef()

  const toggle = () => {
    setShow((p) => !p)
  }

  return (
    <Wrapper ref={wrapperRef}>
      {show && (
        <InnerWrapper>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDateTimePicker
              displayStaticWrapperAs="desktop"
              openTo="day"
              label={'Publish date'}
              ampm
              value={getNyTime(dateTime)}
              onChange={(date) => setDateTime(getUTCTime(new Date(date)))}
              renderInput={(params) => <TextField fullWidth variant="standard" {...params} />}
            />
          </LocalizationProvider>
          <StyledButton
            variant="text"
            onClick={() => {
              props.setDateTime(dateTime.toISOString())
              toggle()
            }}
          >
            Ok
          </StyledButton>
          <StyledButton color="secondary" variant="text" onClick={toggle}>
            Cancel
          </StyledButton>
        </InnerWrapper>
      )}
      <div onClick={toggle}>{props.children}</div>
    </Wrapper>
  )
}

PublishTimeSelector.propTypes = {
  children: PropTypes.element,
  dateTime: PropTypes.string,
  setDateTime: PropTypes.func,
}

export default PublishTimeSelector
