import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

const DefaultCheckbox = ({ input, label, uncheckedIcon, checkedIcon, ...custom }) => (
  <FormControlLabel
    control={<Checkbox checked={!!input.value} onChange={input.onChange} disabled={custom.disabled} {...custom} />}
    label={label}
  />
)

DefaultCheckbox.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
}

export default DefaultCheckbox
