import { makeAutoObservable, runInAction } from 'mobx'
import { fetchVideos, loadVideoPlayers } from 'api/videos'

const store = makeAutoObservable({
  videos: [],
  loading: false,
  error: null,

  players: [],
  playersLoading: false,
  playersError: null,

  fetchVideos: async (params) => {
    store.loading = true
    try {
      const data = await fetchVideos(params)
      runInAction(() => {
        store.videos = data
        store.loading = false
        store.error = null
      })
    } catch (error) {
      runInAction(() => {
        store.error = error
        store.loading = false

        setTimeout(() => {
          store.error = null
        }, 3000)
      })
    }
  },

  loadVideoPlayers: async () => {
    store.playersLoading = true
    store.playersError = null
    try {
      const data = await loadVideoPlayers()
      runInAction(() => {
        store.players = data
        store.playersLoading = false
        store.playersError = null
      })
    } catch (error) {
      store.playersLoading = false
      store.playersError = error
    }
  },

  resetList: async () => {
    store.videos = []
  },
})

export default store
