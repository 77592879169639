import { Map, fromJS } from 'immutable'
import {
  ARTICLES_SET_PUBLISH_DT,
  ARTICLES_UPDATE_EDITORS_NOTES,
  ARTICLES_UPDATE_URL,
  ARTICLES_UPDATE_TITLE,
  LOAD_DEFAULT_EDITORS_QUEUE,
} from 'utils/constants/actions'

const editorsQueueReducer = (state = Map(), action) => {
  switch (action.type) {
    case LOAD_DEFAULT_EDITORS_QUEUE:
      return state.set('articles', action.collection)
    case ARTICLES_UPDATE_EDITORS_NOTES:
      return state.setIn(
        ['articles', action.dateKey, action.articleIdx, 'editorsNotes'],
        fromJS([{ comment: action.editorsNotes }])
      )
    case ARTICLES_UPDATE_URL:
      return state.setIn(['articles', action.dateKey, action.articleIdx, 'link'], action.link)
    case ARTICLES_UPDATE_TITLE:
      return state.setIn(['articles', action.dateKey, action.articleIdx, 'title'], action.title)
    case ARTICLES_SET_PUBLISH_DT:
      return state.setIn(['articles', action.dateKey, action.articleIdx, 'publish_time'], action.dateTime)
    default:
      return state
  }
}

export default editorsQueueReducer
