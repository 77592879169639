import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

import { toggleShowVersionNotice } from 'actions/navbarActions'

import OfflineNotifier from 'components/OfflineNotifier'
import { StyledColumn } from './StyledLayouts'

export const CoreLayout = ({ children, showVersionNotice, toggleVersionNotice }) => (
  <StyledColumn>
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      sx={{ cursor: 'pointer' }}
      message="A new version of wave is available! Please refresh your browser to upgrade."
      onClick={() => window.location.reload()}
      onClose={() => toggleVersionNotice(false)}
      open={showVersionNotice}
    >
      <MuiAlert elevation={6} severity="info" variant="filled">
        A new version of wave is available! Please refresh your browser to upgrade.
      </MuiAlert>
    </Snackbar>
    <OfflineNotifier />
    <div>{children}</div>
  </StyledColumn>
)

CoreLayout.propTypes = {
  children: PropTypes.element.isRequired,
  showVersionNotice: PropTypes.bool,
  toggleVersionNotice: PropTypes.func,
}

const mapStateToProps = (state) => ({
  showVersionNotice: state.navbar.getIn(['navbarPrefs', 'showVersionNotice']),
})

const mapDispatchToProps = (dispatch) => ({
  toggleVersionNotice: (show) => dispatch(toggleShowVersionNotice(show)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CoreLayout)
