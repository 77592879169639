import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import { PublishTimeSelector } from './PublishTimeSelector'

import formatDate from '../utils/formatDate'
import CommonDialogTitle from '../components/Common/DialogComponents/CommonDialogTitle'

const PublishTimePicker = (props) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [newValue, setNewValue] = useState(null)

  const handleSetDateTime = (dateTime) => {
    if (moment(Date.now()).isAfter(moment(dateTime))) {
      setIsConfirmDialogOpen(true)
      setNewValue(dateTime)
    } else {
      props.onDateChange(dateTime)
    }
  }

  const handleDeclinePastDate = () => {
    setIsConfirmDialogOpen(false)
    setNewValue(null)
  }

  const handleConfirmPastDate = () => {
    props.onDateChange(newValue)
    setIsConfirmDialogOpen(false)
    setNewValue(null)
  }

  return (
    <>
      <Dialog open={isConfirmDialogOpen} onClose={handleDeclinePastDate}>
        <CommonDialogTitle onClose={handleDeclinePastDate}>The publish date selected is in the past</CommonDialogTitle>
        <DialogContent>
          <div>Are you sure you want to do this?</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeclinePastDate}>No</Button>
          <Button color="secondary" onClick={handleConfirmPastDate}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <PublishTimeSelector dateTime={props.value} setDateTime={handleSetDateTime}>
        <span>{props.value ? formatDate(props.value, 'h:mm a') : 'no date'}</span>
      </PublishTimeSelector>
    </>
  )
}

PublishTimePicker.propTypes = {
  onDateChange: PropTypes.func,
  value: PropTypes.string,
}

export default PublishTimePicker
