import { fromJS } from 'immutable'

export const initialCreateArticleInteractionsState = fromJS({
  template: 'standard',
  focusedComponent: {
    index: 0,
    cursorPosition: 0,
    length: 0,
  },
  drop: {
    draggedItemIndex: 0,
    targetItemIndex: 0,
  },
  edit: {
    show: false,
    componentIndex: null,
    componentType: '',
  },
  split: false,
  focusTo: null,
  focusGoingDown: false,
  deleteComponent: null,
  loading: false,
  saving: false,
  publishing: false,
  published: false,
  unpublishing: false,
  unpublished: false,
  showUnpublishBtn: false,
  error: false,
  flashMessage: '',
  shouldSaveDraft: false,
  saved: false,
  submitArticleId: null,
  showPublishDialog: false,
  startedTyping: false,
  insertionTarget: null,
  showRestoreVersionDialog: false,
  inboundLinks: null,
  inboundLinksLoading: false,
  showInboundLinkUpdateDialog: false,
  inboundLinksUpdated: false,
  publishingMultiple: false,
  highlightedSameLinks: false,
  lastSavedState: null,
  userSubmit: true,
  autoSave: true,
  showUnarchiveDialog: false,
  unarchiving: false,
  showErrorDialog: false,
  submitError: null,
  activeSearchLink: 0,
  linksFound: 0,
  versionLoaded: false,
  linkSearchPattern: null,
})
