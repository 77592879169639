import { Quill } from 'react-quill'

const Inline = Quill.import('blots/inline')

class LinkBlot extends Inline {
  static blotName = 'link'
  static tagName = 'a'

  static create(value) {
    const node = super.create()
    const rel = []
    value.nofollow && rel.push('nofollow')
    value.sponsored && rel.push('sponsored')
    rel.length && node.setAttribute('rel', rel.join(' '))

    node.setAttribute('href', value.url)
    return node
  }

  static formats(node) {
    return {
      url: node.getAttribute('href'),
      nofollow: (node.getAttribute('rel') || '').includes('nofollow'),
      sponsored: (node.getAttribute('rel') || '').includes('sponsored'),
    }
  }

  static value(node) {
    return {
      url: node.getAttribute('href'),
      nofollow: (node.getAttribute('rel') || '').includes('nofollow'),
      sponsored: (node.getAttribute('rel') || '').includes('sponsored'),
    }
  }
}

export default LinkBlot
