import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import CommonDialogTitle from '../../Common/DialogComponents/CommonDialogTitle'
import { DialogActions, DialogContent } from '@mui/material'
import { FirebaseDocModule } from '../../../types/FirebaseDocModule'
import { StyledGrid } from '../../../layouts/StyledLayouts'

interface Props {
  users: FirebaseDocModule.UserSession[]
  isOpen: boolean
  onClose(): void
  kickUser(sessionKey: string): void
}

const ConfirmMultipleKickDialog = (props: Props) => {
  const handleKickOne = async (sessionKey: string) => {
    props.kickUser(sessionKey)
  }

  const handleKickAll = async () => {
    await Promise.all(props.users.map((user) => props.kickUser(user.sessionKey)))
    props.onClose()
  }

  return (
    <Dialog fullWidth open={props.isOpen} onClose={props.onClose}>
      <CommonDialogTitle onClose={props.onClose}>Remove editors</CommonDialogTitle>
      <DialogContent>
        {props.users.length === 0 && 'No active editors found'}
        {props.users.map((user) => {
          return (
            <StyledGrid
              $gap
              key={user.id}
              style={{ width: '100%', alignItems: 'center', gridTemplateColumns: '1fr auto' }}
            >
              <div>{user.name}</div>
              <Button onClick={() => handleKickOne(user.sessionKey)}>Kick</Button>
            </StyledGrid>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.onClose}>
          {props.users.length ? 'Cancel' : 'Close'}
        </Button>
        {props.users.length > 0 && <Button onClick={handleKickAll}>Kick all</Button>}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmMultipleKickDialog
