const image = {
  path: 'img/org/mp169qjvkjkns2kq6.jpg',
  width: 1200,
  height: 800,
  crops: [],
  id: '98b77bf0-23b0-4594-8b7f-3f632996b45e',
  seoImageName: 'Test image',
  tags: ['Cliff'],
}

export default [
  {
    type: 'header',
    title: '<h2>Your shoppable article title</h2>',
    subtitle: '<h4>Your subtitle here</h4>',
    dek: '',
    image,
    dummy: {
      title: true,
      subtitle: true,
    },
  },
  {
    type: 'text',
    text:
      'Online shopping is a form of electronic commerce which allows ' +
      'consumers to directly buy goods or services from a seller over the ' +
      'Internet using a web browser.',
  },
  {
    type: 'heading',
    level: 1,
    text: 'Your first heading here',
  },
  {
    type: 'text',
    text:
      'Consumers find a product of interest by visiting the website of ' +
      'the retailer directly or by searching among alternative vendors using ' +
      "a shopping search engine, which displays the same product's availability " +
      'and pricing at different e-retailers. As of 2016, customers can shop ' +
      'online using a range of different computers and devices, including ' +
      'desktop computers, laptops, tablet computers and smartphones.',
  },
  {
    type: 'heading',
    level: 1,
    text: 'Your second heading here',
  },
  {
    type: 'text',
    text:
      'An online shop evokes the physical analogy of buying products ' +
      'or services at a regular "bricks-and-mortar" retailer or shopping ' +
      'center; the process is called business-to-consumer (B2C) online ' +
      'shopping. When an online store is set up to enable businesses to buy ' +
      'from another businesses, the process is called business-to-business ' +
      '(B2B) online shopping. A typical online store enables the customer ' +
      "to browse the firm's range of products and services, view photos or " +
      'images of the products, along with information about the product ' +
      'specifications, features and prices.',
  },
  {
    type: 'heading',
    level: 1,
    text: 'Your third heading here',
  },
  {
    type: 'image',
    image,
  },
  {
    type: 'text',
    text:
      'Online stores typically enable shoppers to use "search" features ' +
      'to find specific models, brands or items. Online customers must have ' +
      'access to the Internet and a valid method of payment in order to ' +
      'complete a transaction, such as a credit card, an Interac-enabled ' +
      'debit card, or a service such as PayPal. For physical products ' +
      '(e.g., paperback books or clothes), the e-tailer ships the products ' +
      'to the customer; for digital products, such as digital audio files ' +
      'of songs or software, the e-tailer typically sends the file to the ' +
      'customer over the Internet.',
  },
  {
    type: 'shop',
    title: 'Your first shop',
    products: [
      {
        productName: 'Item one',
        url: 'https://en.wikipedia.org/wiki/Online_shopping',
        image,
      },
      {
        productName: 'Item two',
        url: 'https://en.wikipedia.org/wiki/Online_shopping',
        image,
      },
      {
        productName: 'Item three',
        url: 'https://en.wikipedia.org/wiki/Online_shopping',
        image,
      },
    ],
  },
  {
    type: 'text',
    text:
      'Customers are attracted to online shopping not only because of ' +
      'high levels of convenience, but also because of broader selections, ' +
      'competitive pricing, and greater access to information.',
  },
].map((o) => ({ ...o, dummy: o.dummy || true, id: Math.random().toString(36).substring(7) }))
