import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

const DefaultTextField = ({ input, label, meta: { touched, error, warning }, type, ...custom }) => {
  return (
    <TextField
      label={label}
      error={!!(error || warning)}
      color={warning ? 'orange' : undefined}
      variant="standard"
      helperText={error || warning}
      type={type}
      {...input}
      {...custom}
    />
  )
}

DefaultTextField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  type: PropTypes.string,
}

export default DefaultTextField
