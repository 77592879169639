import React, { useRef } from 'react'
import convertTextToFraction from 'utils/convertTextToFraction'

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import SubscriptIcon from '@mui/icons-material/VerticalAlignBottom'
import FractionConversionIcon from '@mui/icons-material/SwapVert'

const scriptToolbarButton = (
  <Tooltip title="Subscript">
    <IconButton className="ql-script">
      <SubscriptIcon />
    </IconButton>
  </Tooltip>
)

const fractionToolbarButton = (
  <Tooltip title="Fraction">
    <IconButton className="ql-fraction">
      <FractionConversionIcon />
    </IconButton>
  </Tooltip>
)

const QuillMiscFormats = ({ children }) => {
  const quill = useRef()

  const fractionHandler = () => {
    const editor = quill.current

    const { index, length } = editor.getSelection()
    const selectedText = editor.getText(index, length)
    const fraction = convertTextToFraction(selectedText)

    if (fraction !== selectedText) {
      editor.deleteText(index, length)
      editor.clipboard.dangerouslyPasteHTML(index, fraction)
    }
  }

  return children({
    handlers: { fraction: fractionHandler },
    formats: ['script', 'fraction'],
    scriptToolbarButton,
    fractionToolbarButton,
    onEditorRef: (el) => (quill.current = el),
  })
}

export default QuillMiscFormats
