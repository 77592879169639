import { Map, fromJS } from 'immutable'
import {
  LOAD_PROFILE_SUCCESS,
  CREATE_ARTICLE_FETCH_ARTICLE_SUCCESS,
  CREATE_ARTICLE_RESET_STATE,
  CREATE_ARTICLE_SHOULD_SAVE_DRAFT,
  CREATE_ARTICLE_DISCARD_DRAFT,
  METADATA_TOGGLE_IMAGE_DIALOG,
  CREATE_ARTICLE_SAVE_TRACKED_TIME_START,
  CREATE_ARTICLE_ADD_KEY_QUOTE,
  CREATE_ARTICLE_PUBLISH_ARTICLE_SUCCESS,
  CREATE_ARTICLE_UNPUBLISH_ARTICLE_SUCCESS,
  CREATE_ARTICLE_SAVE_IDLE_TIME,
  CREATE_ARTICLE_UPDATE_TIME_SPENT,
  METADATA_TOGGLE_URL_DIALOG,
  METADATA_PREVENT_URL_DIALOG,
  ANALYSE_TEXT_SUCCESS,
  CREATE_ARTICLE_LOAD_VERSION,
  CREATE_ARTICLE_SEND_ARTICLE_SUCCESS,
  UNARCHIVE_ARTICLE_SUCCESS,
  PUBLISH_PODCAST_SUCCESS,
  UNPUBLISH_PODCAST_SUCCESS,
} from 'utils/constants/actions'

import {
  getNewInitialCreateArticleMetadataState,
  initialCreateArticleMetadataState,
  initialCreateArticleMetadataStateObject,
} from 'store/initialState/initialCreateArticleMetadataState'
import { queryClient } from '../App'
import { QueryKeys } from '../utils/QueryKeys'
import { MANAGE_FEATURES } from '../utils/constants/privileges'

const createArticleMetadataReducer = (state = Map(), action) => {
  switch (action.type) {
    case LOAD_PROFILE_SUCCESS:
      if (!action.payload.privileges || !action.payload.privileges.includes(MANAGE_FEATURES)) {
        /* logged in user has no privileges or doesn't have manage features privilege */
        return state
      }

      const loggedInUserId = action.payload.userId

      /* set editor as signer-in user for future blank blueprints */
      initialCreateArticleMetadataStateObject.metaData.assignedEditorId = loggedInUserId

      const currentAssignedEditorId = state.getIn(['metaData', 'assignedEditorId'])
      /* set editor as signed-in user for current article if correct permissions and no editor is set yet */
      if (!currentAssignedEditorId) {
        return state.setIn(['metaData', 'assignedEditorId'], loggedInUserId)
      }

      return state
    case CREATE_ARTICLE_FETCH_ARTICLE_SUCCESS:
    case CREATE_ARTICLE_LOAD_VERSION:
      return state
        .set('metaData', fromJS({ ...action.payload.metaData }))
        .set('timeSpent', fromJS(action.payload.timeSpent))
        .set('currentVersion', action.payload.currentVersion || state.get('currentVersion'))
        .set('loadedVersionNumber', action.payload.metaData.version)
    case CREATE_ARTICLE_RESET_STATE:
      return (state = initialCreateArticleMetadataState)
    case CREATE_ARTICLE_SHOULD_SAVE_DRAFT:
      if (
        (action.draftMetadata && action.contentType === 'article') ||
        (action.draftMetadata && action.contentType === 'recipe')
      ) {
        return state.set('metaData', fromJS(action.draftMetadata))
      } else return state
    case CREATE_ARTICLE_DISCARD_DRAFT:
      return state.set('metaData', fromJS(getNewInitialCreateArticleMetadataState().get('metaData')))
    case METADATA_TOGGLE_IMAGE_DIALOG:
      return state.set('imageDialogOpen', action.open).set('imageDialogType', action.imageType)
    case CREATE_ARTICLE_SAVE_TRACKED_TIME_START:
      return state.set('timeTrackingStart', new Date()).set('timeTrackingIdleTime', 0)
    case CREATE_ARTICLE_SAVE_IDLE_TIME:
      return state.set('timeTrackingIdleTime', state.get('timeTrackingIdleTime') + action.time)
    case CREATE_ARTICLE_UPDATE_TIME_SPENT:
      return state.set('timeSpent', fromJS(action.timeSpent))
    case CREATE_ARTICLE_ADD_KEY_QUOTE:
      const currentKeyQuotes = state.getIn(['metaData', 'keyQuotes'])
      if (currentKeyQuotes) {
        return state.updateIn(['metaData', 'keyQuotes'], (keyQuotes) => keyQuotes.push(action.quote))
      }
      return state.setIn(['metaData', 'keyQuotes'], [action.quote])
    case CREATE_ARTICLE_PUBLISH_ARTICLE_SUCCESS:
    case PUBLISH_PODCAST_SUCCESS:
      return state
        .setIn(['metaData', 'active'], true)
        .set('liveVersionNumber', state.get('currentVersion'))
        .updateIn(['metaData', 'statusFlags'], (statusFlags) => {
          statusFlags = statusFlags.toJS()
          const hasPublicationFlag = !!statusFlags.filter((flag) => flag && flag.type === 'publication').length
          if (hasPublicationFlag) {
            statusFlags = statusFlags.map((flag) => {
              if (flag && flag.type === 'publication') {
                flag.status = 'published'
              }
              return flag
            })
          } else {
            statusFlags.push({
              type: 'publication',
              status: 'published',
            })
          }
          return fromJS(statusFlags.filter((flag) => !!flag))
        })
    case CREATE_ARTICLE_UNPUBLISH_ARTICLE_SUCCESS:
    case UNPUBLISH_PODCAST_SUCCESS:
      return state.setIn(['metaData', 'active'], false).updateIn(['metaData', 'statusFlags'], (statusFlags) => {
        statusFlags = statusFlags.toJS()
        const hasPublicationFlag = !!statusFlags.filter((flag) => flag && flag.type === 'publication').length
        if (hasPublicationFlag) {
          statusFlags = statusFlags.map((flag) => {
            if (flag && flag.type === 'publication') {
              flag.status = 'draft'
            }
            return flag
          })
        } else {
          statusFlags.push({
            type: 'publication',
            status: 'draft',
          })
        }
        return fromJS(statusFlags.filter((flag) => !!flag))
      })
    case METADATA_TOGGLE_URL_DIALOG:
      return state.set('showUrlDialog', !state.get('showUrlDialog'))

    case METADATA_PREVENT_URL_DIALOG:
      return state.set('preventUrlDialog', true)
    case ANALYSE_TEXT_SUCCESS:
      return state.set('keywordsInArticle', fromJS(action.keywords))
    case CREATE_ARTICLE_SEND_ARTICLE_SUCCESS:
      const newVersion = state.get('currentVersion') ? state.get('currentVersion') + 1 : 0

      // re-fetch article version & live version
      queryClient.invalidateQueries([QueryKeys.articleVersions])
      queryClient.invalidateQueries([QueryKeys.liveArticleVersion])

      return state
        .setIn(['metaData', 'version'], newVersion)
        .set('currentVersion', newVersion)
        .setIn(['metaData', 'redirectUrl'], action.payload.redirectUrl)
        .setIn(['metaData', 'titles', 'body'], action.payload.titles ? action.payload.titles.body : null)
        .setIn(['metaData', 'titles', 'site'], action.payload.titles ? action.payload.titles.site : null)

    case UNARCHIVE_ARTICLE_SUCCESS:
      return state
        .setIn(['metaData', 'archiveStatus'], null)
        .setIn(['metaData', 'active'], false)
        .updateIn(['metaData', 'statusFlags'], (statusFlags) => {
          statusFlags = statusFlags.toJS()
          const hasPublicationFlag = !!statusFlags.filter((flag) => flag && flag.type === 'publication').length
          if (hasPublicationFlag) {
            statusFlags = statusFlags.map((flag) => {
              if (flag && flag.type === 'publication') {
                flag.status = 'draft'
              }
              return flag
            })
          } else {
            statusFlags.push({
              type: 'publication',
              status: 'draft',
            })
          }
          return fromJS(statusFlags.filter((flag) => !!flag))
        })

    default:
      return state
  }
}

export default createArticleMetadataReducer
