import { object, string, number, array } from 'yup'

const versionValidationSchema = object().shape({
  title: string().required('Required'),
  image: string(),
  terms: array().required('Required'),
  video: object().required('Required'),
  instructorId: number().notRequired(),
})

export default versionValidationSchema
