import { endOfDay, startOfDay, intervalToDuration } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import moment from 'moment'

export const NYTZ = 'America/New_York'

export const getNyTime = (date) => utcToZonedTime(date, NYTZ)
export const getUTCTime = (date) => zonedTimeToUtc(date, NYTZ)
export const getNyStartOfDay = (date) => getUTCTime(startOfDay(getNyTime(date)))
export const getNyEndOfDay = (date) => getUTCTime(endOfDay(getNyTime(date)))

export const dateFormats = {
  dateOnlyFns: 'MMM d, yyyy',
}

export default function formatDate(date, format, altFormat) {
  const d = moment(date)
  if (d.isValid()) {
    if (altFormat && d.year() < moment().year()) {
      return d.format(altFormat)
    } else {
      return d.format(format)
    }
  } else {
    return 'No Publish Date'
  }
}

export const formatSecondsInMinutes = (seconds) => {
  const zeroPad = (num) => String(num).padStart(2, '0')
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
  return `${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`
}
