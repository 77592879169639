import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { connect } from 'react-redux'

import grantedByRole from 'utils/grantedByRole'
import { KICK_USERS } from 'utils/constants/privileges'
import { FirebaseDocModule } from '../../types/FirebaseDocModule'
import { cerulean } from '../../styles/colors'
import { RootState } from '../../App'

const styles = {
  float: 'left',
  padding: 0,
  height: '25px',
  width: '30px',
  marginTop: '1px',
}

interface Props extends ReturnType<typeof mapStateToProps> {
  userSessions: FirebaseDocModule.UserSession[]
  handleDialogOpen(): void
}

export const ArticleViewing = ({ userSessions: usersViewing, userRoles, handleDialogOpen }: Props) => {
  const onDialogOpen = () => {
    if (grantedByRole(userRoles, KICK_USERS)) {
      handleDialogOpen()
    }
  }

  // const numOfUsers = usersViewing.length
  const tooltip = `
  There ${usersViewing.length === 1 ? 'is' : 'are'}
  currently ${usersViewing.length} user${usersViewing.length === 1 ? '' : 's'} viewing this article:
  ${usersViewing.map((u) => u.name).join(', ')}`

  if (!usersViewing.length) {
    return null
  }

  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton disableRipple sx={styles} onClick={onDialogOpen}>
          <AccountCircleIcon sx={{ color: cerulean }} />
        </IconButton>
      </Tooltip>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userRoles: state.profile.getIn(['profile', 'roles']),
    profile: state.profile.get('profile'),
  }
}

export default connect(mapStateToProps)(ArticleViewing)
