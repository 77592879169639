const removeBodyIfEmpty = (article) => {
  const body = article.body

  if (!body[0].title) body.shift()

  article.body = body
    .map((c) => {
      if (c.type === 'text' && !c.text) return ''
      else return c
    })
    .filter((c) => !!c)

  return article
}

export default removeBodyIfEmpty
