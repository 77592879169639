import { Suspense, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'

import CoreLayout from 'layouts/CoreLayout'
import AuthLayout from 'layouts/AuthLayout'
import LoginContainer from 'containers/LoginContainer'
import { lessonTypes } from 'containers/new-pages/ClassLessons'
import withRequiredPrivilege from 'containers/withRequiredPrivilege'
import {
  CREATE_ARTICLE,
  MANAGE_SERIES,
  EDIT_SERIES,
  CREATE_TAGS,
  VIEW_QUEUE,
  MANAGE_PODCASTS,
  MANAGE_PRODUCTS,
  MANAGE_PARTNERS,
} from 'utils/constants/privileges'
import { ProtectedRoute } from './containers/ProtectedRoute'
import Loader from 'components/new-pages/Loader'
import RecipesLayout from 'layouts/RecipesLayout'
import DashboardLayout from 'layouts/DashboardLayout'
import PodcastsLayout from 'layouts/PodcastsLayout'
import ProductsLayout from 'layouts/ProductsLayout'
import SeriesLayout from 'layouts/SeriesLayout'
import AdvancedPagesLayout from 'layouts/AdvancedPagesLayout'
import ArticleRedirectLayout from './layouts/ArticleRedirectLayout'
import NewAppLayout from 'layouts/NewAppLayout'
import SponsoredFlightsLayout from 'layouts/SponsoredFlightsLayout'
import ArticlesContainer from 'containers/ArticlesContainer'

const SeriesContainer = lazy(() => import('containers/SeriesContainer'))
const CreateSerieLayout = lazy(() => import('layouts/CreateSerieLayout'))
const CreateSerieContainer = lazy(() => import('containers/CreateSerieContainer'))
const CreateProductContainer = lazy(() => import('containers/CreateProductContainer'))
const CreateProductLayout = lazy(() => import('layouts/CreateProductLayout'))
const PodcastsContainer = lazy(() => import('containers/PodcastsContainer'))
const ProductsContainer = lazy(() => import('containers/ProductsContainer'))
const TagsContainer = lazy(() => import('containers/TagsContainer'))
const PartnersContainer = lazy(() => import('containers/PartnersContainer'))
const ProductUnitContainer = lazy(() => import('containers/ProductUnitContainer'))
const ClassSubscriptionsContainer = lazy(() => import('containers/new-pages/ClassSubscriptions'))
const ProgressTrackingContainer = lazy(() => import('containers/new-pages/ProgressTracking'))
const OrdersContainer = lazy(() => import('containers/new-pages/Orders'))
const ClassPromotionsContainer = lazy(() => import('containers/new-pages/ClassPromotions'))
const ClassesContainer = lazy(() => import('containers/new-pages/Classes'))
const ClassVideoAssignmentsContainer = lazy(() => import('containers/new-pages/ClassVideoAssignments'))
const ClassVideoAssignmentSubmissionsContainer = lazy(() =>
  import('containers/new-pages/ClassVideoAssignmentSubmissions')
)
const ClassModulesContainer = lazy(() => import('containers/new-pages/ClassModules'))
const ClassDetailsContainer = lazy(() => import('containers/new-pages/ClassDetails'))
const ClassLessonsContainer = lazy(() => import('containers/new-pages/ClassLessons'))
const ClassToolsContainer = lazy(() => import('containers/new-pages/ClassTools'))
const ClassTermsContainer = lazy(() => import('containers/new-pages/ClassTerms'))
const SponsoredFlightsContainer = lazy(() => import('containers/SponsoredFlightsContainer'))
const CreateArticleLayout = lazy(() => import('layouts/CreateArticleLayout'))

const EnsureCanManageSeries = withRequiredPrivilege(MANAGE_SERIES)
const EnsureCanEditSeries = withRequiredPrivilege(EDIT_SERIES)
const EnsureCanCreateTags = withRequiredPrivilege(CREATE_TAGS)
const EnsureCanViewQueue = withRequiredPrivilege(VIEW_QUEUE, '/my-articles')
const EnsureCanCreateArticle = withRequiredPrivilege(CREATE_ARTICLE, '/my-articles')
const EnsureCanManagePodcasts = withRequiredPrivilege(MANAGE_PODCASTS, '/')
const EnsureCanManageProducts = withRequiredPrivilege(MANAGE_PRODUCTS, '/')
const EnsureCanManagePartners = withRequiredPrivilege(MANAGE_PARTNERS, '/')

const Routes = () => {
  return (
    <Switch>
      <CoreLayout>
        <>
          <ProtectedRoute>
            <Route
              exact
              path="/"
              render={() => (
                <DashboardLayout>
                  <EnsureCanViewQueue>
                    <ArticlesContainer />
                  </EnsureCanViewQueue>
                </DashboardLayout>
              )}
            />
            <Route
              path="/my-articles"
              render={() => (
                <DashboardLayout>
                  <Suspense fallback={<Loader />}>
                    <ArticlesContainer />
                  </Suspense>
                </DashboardLayout>
              )}
            />
            <Route
              path="/article/edit/:id?"
              render={(props) => (
                <EnsureCanCreateArticle>
                  <Suspense fallback={<Loader />}>
                    <CreateArticleLayout {...props} articleId={props.match.params.id} />
                  </Suspense>
                </EnsureCanCreateArticle>
              )}
            />
            <Route exact path="/article/edit" render={() => <ArticleRedirectLayout />} />
            <Route
              path="/recipe-queue"
              render={() => (
                <Suspense fallback={<Loader />}>
                  <RecipesLayout>
                    <ArticlesContainer />
                  </RecipesLayout>
                </Suspense>
              )}
            />
            <Route
              path="/my-recipes"
              render={() => (
                <Suspense fallback={<Loader />}>
                  <RecipesLayout>
                    <ArticlesContainer />
                  </RecipesLayout>
                </Suspense>
              )}
            />
            <Route
              path="/podcasts"
              render={(props) => (
                <EnsureCanManagePodcasts>
                  <PodcastsLayout {...props}>
                    <Suspense fallback={<Loader />}>
                      <PodcastsContainer />
                    </Suspense>
                  </PodcastsLayout>
                </EnsureCanManagePodcasts>
              )}
            />
            <Route
              path="/podcast/edit/:id?"
              render={(props) => (
                <EnsureCanManagePodcasts>
                  <Suspense fallback={<Loader />}>
                    <CreateArticleLayout {...props} articleId={props.match.params.id} />
                  </Suspense>
                </EnsureCanManagePodcasts>
              )}
            />
            <Route
              path="/products"
              render={(props) => (
                <EnsureCanManageProducts>
                  <ProductsLayout {...props}>
                    <Suspense fallback={<Loader />}>
                      <ProductsContainer />
                    </Suspense>
                  </ProductsLayout>
                </EnsureCanManageProducts>
              )}
            />
            <Route
              path="/products/edit/:id?"
              render={(props) => (
                <EnsureCanManageProducts>
                  <Suspense fallback={<Loader />}>
                    <CreateProductLayout {...props}>
                      <CreateProductContainer />
                    </CreateProductLayout>
                  </Suspense>
                </EnsureCanManageProducts>
              )}
            />
            <Route
              path="/recipe/edit/:id?"
              render={(props) => (
                <EnsureCanCreateArticle>
                  <Suspense fallback={<Loader />}>
                    <CreateArticleLayout {...props} articleId={props.match.params.id} />
                  </Suspense>
                </EnsureCanCreateArticle>
              )}
            />
            <Route
              exact
              path="/series"
              render={() => (
                <EnsureCanManageSeries>
                  <SeriesLayout>
                    <Suspense fallback={<Loader />}>
                      <SeriesContainer />
                    </Suspense>
                  </SeriesLayout>
                </EnsureCanManageSeries>
              )}
            />
            <Route
              path="/series/edit/:id?"
              render={() => (
                <EnsureCanEditSeries>
                  <Suspense fallback={<Loader />}>
                    <CreateSerieLayout>
                      <CreateSerieContainer />
                    </CreateSerieLayout>
                  </Suspense>
                </EnsureCanEditSeries>
              )}
            />
            <Route
              path="/tags"
              render={() => (
                <EnsureCanCreateTags>
                  <AdvancedPagesLayout>
                    <Suspense fallback={<Loader />}>
                      <TagsContainer />
                    </Suspense>
                  </AdvancedPagesLayout>
                </EnsureCanCreateTags>
              )}
            />
            <Route
              path="/sponsored"
              render={(props) => (
                <SponsoredFlightsLayout {...props}>
                  <Suspense fallback={<Loader />}>
                    <SponsoredFlightsContainer />
                  </Suspense>
                </SponsoredFlightsLayout>
              )}
            />
            <Route
              exact
              path="/partners"
              render={() => (
                <EnsureCanManagePartners>
                  <AdvancedPagesLayout>
                    <Suspense fallback={<Loader />}>
                      <PartnersContainer />
                    </Suspense>
                  </AdvancedPagesLayout>
                </EnsureCanManagePartners>
              )}
            />
            <Route
              path="/partners/product-unit"
              render={() => (
                <EnsureCanManagePartners>
                  <AdvancedPagesLayout>
                    <Suspense fallback={<Loader />}>
                      <ProductUnitContainer />
                    </Suspense>
                  </AdvancedPagesLayout>
                </EnsureCanManagePartners>
              )}
            />
            <Route
              exact
              path="/class-subscriptions"
              render={(props) => (
                <NewAppLayout {...props}>
                  <Suspense fallback={<Loader />}>
                    <ClassSubscriptionsContainer />
                  </Suspense>
                </NewAppLayout>
              )}
            />
            <Route
              path="/class-subscriptions/progress"
              render={(props) => (
                <NewAppLayout {...props}>
                  <Suspense fallback={<Loader />}>
                    <ProgressTrackingContainer />
                  </Suspense>
                </NewAppLayout>
              )}
            />
            <Route
              path="/orders"
              render={(props) => (
                <NewAppLayout {...props}>
                  <Suspense fallback={<Loader />}>
                    <OrdersContainer />
                  </Suspense>
                </NewAppLayout>
              )}
            />
            <Route
              path="/class-promotions"
              render={(props) => (
                <NewAppLayout {...props}>
                  <Suspense fallback={<Loader />}>
                    <ClassPromotionsContainer />
                  </Suspense>
                </NewAppLayout>
              )}
            />
            <Route
              exact
              path="/classes"
              render={(props) => (
                <NewAppLayout {...props}>
                  <Suspense fallback={<Loader />}>
                    <ClassesContainer {...props} />
                  </Suspense>
                </NewAppLayout>
              )}
            />
            <Route
              exact
              path="/classes/edit/:id?"
              render={(props) => (
                <NewAppLayout {...props}>
                  <Suspense fallback={<Loader />}>
                    <ClassDetailsContainer {...props} />
                  </Suspense>
                </NewAppLayout>
              )}
            />
            <Route
              exact
              path="/classes/:classId/video-assignments"
              render={(props) => (
                <NewAppLayout {...props}>
                  <Suspense fallback={<Loader />}>
                    <ClassVideoAssignmentsContainer {...props} />
                  </Suspense>
                </NewAppLayout>
              )}
            />
            <Route
              exact
              path="/classes/:classId/video-assignments/:videoAssignmentId/submissions"
              render={(props) => (
                <NewAppLayout {...props}>
                  <Suspense fallback={<Loader />}>
                    <ClassVideoAssignmentSubmissionsContainer {...props} />
                  </Suspense>
                </NewAppLayout>
              )}
            />
            <Route
              exact
              path="/classes/:id/modules"
              render={(props) => (
                <NewAppLayout {...props}>
                  <Suspense fallback={<Loader />}>
                    <ClassModulesContainer {...props} />
                  </Suspense>
                </NewAppLayout>
              )}
            />
            <Route
              exact
              path="/classes/:id/modules/:moduleId"
              render={(props) => (
                <NewAppLayout {...props}>
                  <Suspense fallback={<Loader />}>
                    <ClassLessonsContainer {...props} />
                  </Suspense>
                </NewAppLayout>
              )}
            />
            {lessonTypes.map((lesson) => (
              <Route
                key={lesson.id}
                path={`/classes/:id/modules/:moduleId/${lesson.id}/:lessonId?`}
                render={(props) => (
                  <NewAppLayout {...props}>
                    <Suspense fallback={<Loader />}>
                      <lesson.component {...props} />
                    </Suspense>
                  </NewAppLayout>
                )}
              />
            ))}
            <Route
              exact
              path="/classes/:id/tools"
              render={(props) => (
                <NewAppLayout {...props}>
                  <Suspense fallback={<Loader />}>
                    <ClassToolsContainer {...props} />
                  </Suspense>
                </NewAppLayout>
              )}
            />
            <Route
              exact
              path="/classes/:id/terms"
              render={(props) => (
                <NewAppLayout {...props}>
                  <Suspense fallback={<Loader />}>
                    <ClassTermsContainer {...props} />
                  </Suspense>
                </NewAppLayout>
              )}
            />
          </ProtectedRoute>

          <Route
            path="/login"
            render={(props) => (
              <AuthLayout>
                <LoginContainer match={props.match} />
              </AuthLayout>
            )}
          />
        </>
      </CoreLayout>
    </Switch>
  )
}

export default Routes
