import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const useGetLessonDetails = (params, fetchClass, modules, getVersions) => {
  const history = useHistory()
  const classId = params.id ? Number(params.id) : null
  const moduleId = params.moduleId
  const lessonId = params.lessonId

  useEffect(() => {
    if (!Number.isNaN(parseInt(classId))) {
      fetchClass &&
        fetchClass(classId).then(() => {
          moduleId && lessonId && getVersions && getVersions(classId, moduleId, lessonId)
        })
    } else history.push('/classes')
  }, [classId, fetchClass, history, getVersions, lessonId, moduleId])

  const module = modules?.find((m) => m.id === moduleId)
  const allLessons = module?.sessions
    ? module?.sessions
    : module?.modules?.reduce((agg, next) => agg.concat(next.lessons), [])

  const lessonIndex = allLessons?.findIndex((l) => l.id === lessonId)

  return {
    moduleId,
    module: module || {},
    activeLesson: lessonIndex > -1 ? allLessons[lessonIndex] : null,
    activeLessonIdx: lessonIndex || 0,
  }
}

export default useGetLessonDetails
