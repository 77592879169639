import { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Router } from 'react-router'
import { Provider, connect } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import CssBaseline from '@mui/material/CssBaseline'
import { init as initSentry } from '@sentry/browser'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { observer } from 'mobx-react-lite'
import { StoreProvider, stores as mobxStore, useStore } from 'store/mobx'

import Routes from './routes'
import store from 'store/createStore'
import { useQueryParams } from './hooks/useQueryParams'
import customHistory from './utils/customHistory'
import { setDisableAutosave, setEnableAutosave } from './utils/autosaveControl'
import { darkTheme, lightTheme } from './utils/theme'

export type RootDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

const { REACT_APP_ENVIRONMENT, REACT_APP_SENTRY_DSN } = process.env

export const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 60 * 15, refetchOnWindowFocus: false } },
})

interface Props extends ReturnType<typeof mapStateToProps> {}

const mapStateToProps = (state: RootState) => {
  return {
    appVersion: state.navbar.getIn(['navbarPrefs', 'currentVersion']),
  }
}

const ThemedApp: any = connect(mapStateToProps)(
  observer(({ appVersion }: Props) => {
    const queryParams = useQueryParams()

    const { isDarkMode } = useStore('appLayoutStore')

    useEffect(() => {
      if (appVersion && REACT_APP_ENVIRONMENT?.toUpperCase() !== 'DEV') {
        initSentry({
          dsn: REACT_APP_SENTRY_DSN,
          release: appVersion,
          environment: REACT_APP_ENVIRONMENT?.toUpperCase(),
        })
      }
    }, [appVersion])

    useEffect(() => {
      if (queryParams) {
        const autosaveParam = queryParams.get('autosave')

        if (autosaveParam === 'false') {
          setDisableAutosave()
        } else if (autosaveParam === 'true') {
          setEnableAutosave()
        }
      }
    }, [queryParams])

    return (
      <StyledThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
          <CssBaseline />
          <Routes />
        </ThemeProvider>
      </StyledThemeProvider>
    )
  })
)

const App = () => (
  <Router history={customHistory as any}>
    <DndProvider backend={HTML5Backend}>
      {/* ⬇️ Redux store */}
      <Provider store={store}>
        {/* ⬇️ MOBX store */}
        <StoreProvider value={mobxStore}>
          <QueryClientProvider client={queryClient}>
            <ThemedApp />
          </QueryClientProvider>
        </StoreProvider>
      </Provider>
    </DndProvider>
  </Router>
)

export default App
