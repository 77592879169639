export const insertQuillLink = (editor, selection, link) => {
  const { index, length } = selection
  editor.removeFormat(index, length)
  editor.formatText(index, length, { link })
}

export const updateQuillLink = (editor, selection, link) => {
  const { index, length } = selection
  let newIndex = index
  let newLength = length

  while (newIndex > -1 && editor.getFormat(--newIndex, length).link) {}
  newIndex++

  while (newLength < editor.getLength() && editor.getFormat(newIndex, ++newLength).link) {}
  newLength--

  return insertQuillLink(editor, { index: newIndex, length: newLength }, link)
}

export const sliceFromLastWhitespace = (str) => {
  const whitespaceI = str.lastIndexOf(' ')
  const sliceI = whitespaceI === -1 ? 0 : whitespaceI + 1
  return str.slice(sliceI)
}
