import { v4 as uuidv4 } from 'uuid'
import {
  CREATE_ARTICLE_INSERT_COMPONENT,
  CREATE_ARTICLE_INSERT_COMPONENT_FROM_PLACEHOLDER,
  CREATE_ARTICLE_INSERT_SPLITTED_COMPONENT,
  CREATE_ARTICLE_INSERT_DRAGGED_COMPONENT,
  CREATE_ARTICLE_INSERT_COMPONENT_FROM_MODAL,
} from 'utils/constants/actions'

import { ALTERNATE_HERO, INTRODUCTION, FOOTER } from 'utils/componentTypes'

const insertComponent = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_INSERT_COMPONENT) {
    const { createArticleInteractions, createArticleBody } = store.getState()
    const focusedComponentIndex = createArticleInteractions.getIn(['focusedComponent', 'index'])
    const focusedComponent = createArticleBody.getIn(['present', 'components', focusedComponentIndex])

    if (focusedComponent) {
      const focusedComponentType = focusedComponent.get('type')
      const focusedComponentCursorPosition = createArticleInteractions.getIn(['focusedComponent', 'cursorPosition'])
      const focusedComponentLength = createArticleInteractions.getIn(['focusedComponent', 'length'])
      action.split = focusedComponentCursorPosition < focusedComponentLength && focusedComponentType === 'text'
    }

    const componentsCount = createArticleBody.getIn(['present', 'components']).count()
    const activeComponentIndex = createArticleBody.get('activeComponentIndex')
    action.toIndex =
      (activeComponentIndex !== null && activeComponentIndex + 1) ||
      (componentsCount === 0 ? componentsCount : focusedComponentIndex + 1)

    action.newComponent = {
      id: uuidv4(),
      priority: 2,
      type: action.componentType,
      text: '',
    }
  }

  if (action.type === CREATE_ARTICLE_INSERT_COMPONENT_FROM_PLACEHOLDER) {
    action.newComponent = {
      id: uuidv4(),
      priority: 2,
      type: 'text',
      text: '',
    }
  }

  if (action.type === CREATE_ARTICLE_INSERT_SPLITTED_COMPONENT) {
    action.newComponent = {
      id: uuidv4(),
      priority: 2,
      type: action.componentType,
      text: action.text,
    }
  }

  if (action.type === CREATE_ARTICLE_INSERT_DRAGGED_COMPONENT) {
    const { createArticleInteractions, createArticleBody } = store.getState()
    const draggedItemIndex = createArticleInteractions.getIn(['drop', 'draggedItemIndex'])

    action.draggedComponent = createArticleBody.getIn(['present', 'components', draggedItemIndex])
    action.draggedItemIndex = draggedItemIndex
  }

  if (action.type === CREATE_ARTICLE_INSERT_COMPONENT_FROM_MODAL) {
    const { createArticleInteractions, createArticleBody } = store.getState()

    let componentExists = false

    if (
      action.componentType === ALTERNATE_HERO ||
      action.componentType === INTRODUCTION ||
      action.componentType === FOOTER
    ) {
      const bodyComponents = createArticleBody.getIn(['present', 'components'])
      componentExists = bodyComponents.some((c) => c.get('type') === action.componentType)
    }

    if (componentExists) {
      action.flashMessage = 'Article can have only one component of this type.'
    } else {
      const focusedComponentIndex = createArticleInteractions.getIn(['focusedComponent', 'index'])
      const componentsCount = createArticleBody.getIn(['present', 'components']).count()

      const newComponent = {
        id: uuidv4(),
        type: action.componentType,
      }

      const activeComponentIndex = createArticleBody.get('activeComponentIndex')
      action.toIndex =
        (activeComponentIndex !== null && activeComponentIndex + 1) ||
        (componentsCount === 0 ? componentsCount : focusedComponentIndex + 1)
      action.newComponent = Object.assign(newComponent, action.content)
    }
  }

  return next(action)
}

export default insertComponent
