import { reset } from 'redux-form'
import { CREATE_ARTICLE_DISCARD_DRAFT } from 'utils/constants/actions'

const discardDraft = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_DISCARD_DRAFT) {
    const key = window.location.pathname.split('/')[1]
    sessionStorage.removeItem(`${key}.draftArticleComponents`)
    sessionStorage.removeItem(`${key}.draftArticleMetadata`)
    store.dispatch(reset('createArticleMetadataForm'))
  }
  return next(action)
}

export default discardDraft
