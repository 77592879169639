import { Map, fromJS } from 'immutable'
import {
  LOAD_PARTNERS_REQUEST,
  LOAD_PARTNERS_SUCCESS,
  LOAD_PARTNERS_FAILURE,
  SAVE_PARTNER_REQUEST,
  SAVE_PARTNER_SUCCESS,
  SAVE_PARTNER_FAILURE,
  PARTNERS_PAGE_HIDE_FLASH_MESSAGE,
  GET_PRODUCT_UNIT_CODE_REQUEST,
  GET_PRODUCT_UNIT_CODE_SUCCESS,
  GET_PRODUCT_UNIT_CODE_FAILURE,
} from 'utils/constants/actions'

const partnersReducer = (state = Map(), action) => {
  switch (action.type) {
    case LOAD_PARTNERS_REQUEST:
      return state.set('loading', true)
    case LOAD_PARTNERS_FAILURE:
      return state.set('loading', false).set('error', action.payload)
    case LOAD_PARTNERS_SUCCESS:
      return state.set('loading', false).set('partners', fromJS(action.payload))
    case SAVE_PARTNER_REQUEST:
      return state.set('saving', true).set('error', false).set('loading', true)
    case SAVE_PARTNER_SUCCESS:
      if (!action.payload.partnerIndex) {
        return state
          .update('partners', (partners) => partners.push(fromJS(action.payload.partner)))
          .set('flashMessage', 'Partner has been created successfully')
          .set('saving', false)
          .set('loading', false)
      }

      return state
        .update('partners', (partners) =>
          partners.splice(action.payload.partnerIndex, 1, fromJS(action.payload.partner))
        )
        .set('flashMessage', 'Partner has been updated successfully')
        .set('saving', false)
        .set('loading', false)

    case SAVE_PARTNER_FAILURE:
      return state
        .set('saving', false)
        .set('flashMessage', action.payload.response.message || 'There was a problem saving this partner')
        .set('loading', false)
        .set('error', true)
    case PARTNERS_PAGE_HIDE_FLASH_MESSAGE:
      return state.set('flashMessage', '')
    case GET_PRODUCT_UNIT_CODE_REQUEST:
      return state.set('loading', true).set('productUnitData', fromJS(action.payload.data))
    case GET_PRODUCT_UNIT_CODE_SUCCESS:
      return state.set('loading', false).set('productUnit', action.payload)
    case GET_PRODUCT_UNIT_CODE_FAILURE:
      return state.set('loading', false)

    default:
      return state
  }
}

export default partnersReducer
