import styled from 'styled-components'

export const CTAWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1200;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 24px;
`

export const CenterWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1200;
  height: 64px;
  display: flex;
  align-items: center;
`
