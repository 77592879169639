import styled from 'styled-components'

export const DraggableContainerTaskbar = styled.div`
  opacity: 0;
  display: flex;
  justify-content: space-between;
  transition: 0.25s;
`

export const DraggableContainer = styled.div`
  width: 200px;
  height: 234px; // square + taskbar

  padding: 0 0 8px;
  border-radius: 8px;
  transform: translate3d(0, 0, 0);

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);

    ${DraggableContainerTaskbar} {
      opacity: 1;
    }
  }
`

export const Image = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
`

export const Container = styled.div`
  width: 100%;
  padding: 12px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, 200px);
`
