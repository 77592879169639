import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export const priorityOptions = ['Enhanced', 'Standard', 'Supplemental']

const PriorityDropdown = ({ priority, updatePriority }) => (
  <FormControl sx={{ marginTop: '10px' }}>
    <InputLabel id="dropdown-simple-select-label" sx={{ marginLeft: '-13px' }}>
      Context
    </InputLabel>
    <Select
      labelId="dropdown-simple-select-label"
      value={priority}
      onChange={(e) => updatePriority(e.target.value)}
      variant="standard"
    >
      {priorityOptions.map((v, i) => (
        <MenuItem key={v} value={i + 1}>
          {v}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

PriorityDropdown.propTypes = {
  priority: PropTypes.number,
  updatePriority: PropTypes.func,
}

export default PriorityDropdown
