import { Map, fromJS } from 'immutable'
import {
  CREATE_PODCAST_FETCH_PODCAST_SUCCESS,
  CREATE_ARTICLE_RESET_STATE,
  CREATE_ARTICLE_SHOULD_SAVE_DRAFT,
  CREATE_ARTICLE_DISCARD_DRAFT,
  GET_SUBJECT_BY_ID_REQUEST,
  GET_SUBJECT_BY_ID_SUCCESS,
  GET_SUBJECT_BY_ID_FAILURE,
} from 'utils/constants/actions'

const createPodcastMetadataReducer = (state = Map(), action) => {
  switch (action.type) {
    case CREATE_PODCAST_FETCH_PODCAST_SUCCESS:
      return state.set('metaData', fromJS(action.payload.metaData))
    case CREATE_ARTICLE_RESET_STATE:
      return state.set('metaData', fromJS({}))
    case CREATE_ARTICLE_SHOULD_SAVE_DRAFT:
      if (action.draftMetadata && action.contentType === 'podcast') {
        return state.set('metaData', fromJS(action.draftMetadata))
      } else return state
    case CREATE_ARTICLE_DISCARD_DRAFT:
      return state.set('metaData', fromJS({}))
    case GET_SUBJECT_BY_ID_REQUEST:
      return state.set('subjectLoading', true)
    case GET_SUBJECT_BY_ID_SUCCESS:
      const idx = state.getIn(['metaData', 'subjects']).findIndex((s) => s === action.payload.id)
      return state.setIn(['metaData', 'subjects', idx], fromJS(action.payload)).set('subjectLoading', false)
    case GET_SUBJECT_BY_ID_FAILURE:
      return state.set('subjectLoading', false)
    default:
      return state
  }
}

export default createPodcastMetadataReducer
