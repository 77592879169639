import styled from 'styled-components'
import { cerulean } from 'styles/colors'

export const Source = styled.p`
  text-transform: uppercase;
  margin: 0;
  font-size: 13px;
  display: flex;
  gap: 6px;
`

export const Title = styled.p`
  cursor: pointer;
  opacity: 0.9;

  margin: ${(props) => (props.isCompressed ? 0 : '0.7rem 0 0 0')};

  :hover {
    color: ${cerulean};
  }
`

export const Link = styled.p`
  opacity: 0.7;
  font-size: 13px;

  :hover {
    color: ${cerulean};
  }
`
