import { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'

import { setNavbarTitle } from 'actions/navbarActions'
import { loadProfile } from 'actions/profileActions'
import initCognitoSdk from 'auth/cognito'
import initCognitoSdkQA from 'auth/cognitoQA'
import { useHistory } from 'react-router-dom'
import StyledFlex from '../layouts/StyledLayouts'

const styles = {
  loginButton: {
    marginTop: 50,
  },
}

const LoginContainer = (props) => {
  const history = useHistory()

  const QALogin = useCallback(() => {
    const getQaCredentials = () => {
      const params = new URLSearchParams(history.location.search)

      const email = params.get('email')
      const password = params.get('password')
      if (!email?.length || !password?.length) {
        return
      }
      return { email, password }
    }

    const credentials = getQaCredentials()
    if (!credentials) {
      return
    }
    initCognitoSdkQA(credentials.email, credentials.password, props.loadProfile, history)
  }, [history, props.loadProfile])

  useEffect(() => {
    props.setNavbarTitle('Log In')
    if (process.env.REACT_APP_ENVIRONMENT?.toUpperCase() === 'QA') {
      QALogin()
    }

    if (initCognitoSdk().isUserSignedIn() && process.env.REACT_APP_ENVIRONMENT?.toUpperCase() !== 'QA') {
      history.replace('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initCognitoSdk().isUserSignedIn()])

  return (
    <StyledFlex style={{ alignContent: 'start' }}>
      <Button
        onClick={() => initCognitoSdk({ loadProfile: props.loadProfile, history }).getSession()}
        color="primary"
        variant="contained"
        style={styles.loginButton}
      >
        Login with Google
      </Button>
    </StyledFlex>
  )
}

LoginContainer.propTypes = {
  setNavbarTitle: PropTypes.func,
  loadProfile: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return {
    setNavbarTitle: (title) => dispatch(setNavbarTitle(title)),
    loadProfile: () => dispatch(loadProfile()),
  }
}

export default connect(undefined, mapDispatchToProps)(LoginContainer)
