import { initializeApp } from 'firebase/app'
import { Database, getDatabase } from 'firebase/database'

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DB_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_MSG_SENDER_ID,
} = process.env

let app, database
if (process.env.NODE_ENV !== 'test') {
  app = initializeApp({
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: REACT_APP_FIREBASE_DB_URL,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: '',
    messagingSenderId: REACT_APP_FIREBASE_MSG_SENDER_ID,
  })
  database = getDatabase(app)
}

const firebase = { database } as { database: Database }

export default firebase
