import { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { setLastInsertedLink } from 'actions/createArticleActions'

const HighlightLinks = ({ setLastInsertedLink, lastInsertedLink, linkSearchPattern, children }) => {
  const quill = useRef()

  // notify other quill fields to check for duplicate links, if a link was inserted
  const handleChange = (content, delta, source, editor) => {
    const newLink = delta.ops.find((op) => op.attributes && op.attributes.link)
    if (newLink) setLastInsertedLink(newLink.attributes.link.url)
  }

  // highlight duplicate links when a link is inserted anywhere in the body
  useEffect(() => {
    if (!quill.current || !lastInsertedLink) return

    const anchors = Array.from(quill.current.root.querySelectorAll('a'))
    anchors.forEach((a) => {
      if (a.href === lastInsertedLink || a.href.slice(0, -1) === lastInsertedLink) {
        a.dataset.sameLink = true
      } else {
        a.removeAttribute('data-same-link')
      }
    })
  }, [quill, lastInsertedLink])

  // highlight found links
  useEffect(() => {
    if (!quill.current) return

    const anchors = Array.from(quill.current.root.querySelectorAll('a'))
    anchors.forEach((a) => {
      if (linkSearchPattern.length > 3 && a.href.includes(linkSearchPattern)) {
        a.dataset.type = 'search'
      } else {
        a.removeAttribute('data-type')
      }
    })
  }, [quill, linkSearchPattern])

  return children({
    onChange: handleChange,
    onEditorRef: (el) => (quill.current = el),
  })
}

const mapStateToProps = (state) => ({
  lastInsertedLink: state.createArticleBody.get('lastInsertedLink'),
  linkSearchPattern: state.createArticleInteractions.get('linkSearchPattern') || '',
})

const mapDispatchToProps = (dispatch) => ({
  setLastInsertedLink: (link) => dispatch(setLastInsertedLink(link)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HighlightLinks)
