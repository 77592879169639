import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import PaletteIcon from '@mui/icons-material/Palette'

const tooltipCreativeApproval = 'Needs creative approval'
const tooltipEditorialApproval = 'Needs editorial approval'

const StatusIcons = ({ creativeFlag, copyFlag }) => (
  <>
    {creativeFlag && (
      <Tooltip title={tooltipCreativeApproval}>
        <PaletteIcon color="pinkRed" />
      </Tooltip>
    )}
    {copyFlag && (
      <Tooltip title={tooltipEditorialApproval}>
        <ChatBubbleIcon color="pinkRed" />
      </Tooltip>
    )}
  </>
)

StatusIcons.propTypes = {
  creativeFlag: PropTypes.bool,
  copyFlag: PropTypes.bool,
}

export default StatusIcons
