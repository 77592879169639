type CRUDObject<K> = {
  write: Partial<K>
  remove: (keyof Partial<K>)[]
}

export const createCRUDObject = <K extends object>(data: K) =>
  (Object.entries(data) as [keyof K, any]).reduce<CRUDObject<K>>(
    (acc, [key, val]) => {
      if (val !== undefined) {
        // @ts-expect-error
        acc.write[key] = val
      } else {
        acc.remove.push(key)
      }
      return acc
    },
    { write: {}, remove: [] }
  )
