import { forwardRef } from 'react'
import DragIndicator from '@mui/icons-material/DragIndicator'
import IconButton from '@mui/material/IconButton'
import styled from 'styled-components'
import CSS from 'csstype'

const StyledIconButton = styled(IconButton)`
  cursor: grab;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`

interface Props {
  style?: CSS.Properties
  className?: string
}

const DraggableButton = forwardRef<HTMLButtonElement, Props>((props, ref) => (
  <StyledIconButton className={props.className} style={props.style} ref={ref} disableRipple title="Drag" size="small">
    <DragIndicator />
  </StyledIconButton>
))

export default DraggableButton
