import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import StyledFlex from 'layouts/StyledLayouts'
import { Link, Source, Title } from './styles'
import { dustyOrange, greenApple, purple30, skyBlue, squash } from 'styles/colors'
import CopyButton from '../../../CopyButton'

const TitleColumn = ({
  articleId,
  category,
  template,
  roles,
  influencer,
  title,
  url,
  compressed,
  userCreatedUrl,
  legacyUrl,
  partnerIds,
  pathname,
}) => {
  const history = useHistory()

  const articleUrl = userCreatedUrl
    ? `https://www.mindbodygreen.com/articles/${url}`
    : `https://www.mindbodygreen.com/${legacyUrl}`

  let isAdmin = roles ? roles.indexOf('administrator') > -1 : false
  let isFeatureTemplate = template ? template === 'feature' : false
  const isPartnerPost = partnerIds && partnerIds.toJS().length

  return (
    <div>
      {!compressed && (
        <Source>
          <span style={{ color: dustyOrange }}>{category || 'no category'}</span>
          {isFeatureTemplate ? <span style={{ color: squash }}>feature</span> : null}
          {isAdmin && !isFeatureTemplate ? <span style={{ color: greenApple }}>MBG</span> : null}
          {isPartnerPost ? <span style={{ color: purple30 }}>partner</span> : null}
          {influencer && !isFeatureTemplate && !isAdmin && <span style={{ color: skyBlue }}>influencer</span>}
        </Source>
      )}

      <Title isCompressed={compressed} onClick={() => history.push(`${pathname}/${articleId}`)}>
        {title}
      </Title>

      {!compressed && (
        <StyledFlex style={{ gap: '4px' }}>
          <Link>{url || 'no URL'}</Link>
          {url && <CopyButton tooltip="Copy Link" text={articleUrl} />}
        </StyledFlex>
      )}
    </div>
  )
}

TitleColumn.propTypes = {
  articleId: PropTypes.string,
  category: PropTypes.string,
  template: PropTypes.string,
  roles: PropTypes.string,
  influencer: PropTypes.bool,
  partnerIds: PropTypes.object,
  url: PropTypes.string,
  title: PropTypes.string,
  compressed: PropTypes.bool,
  userCreatedUrl: PropTypes.bool,
  legacyUrl: PropTypes.string,
  pathname: PropTypes.string,
}

export default TitleColumn
