import { Map, fromJS } from 'immutable'
import { SET_COGNITO_USER, REMOVE_COGNITO_USER } from 'utils/constants/actions'

const cognitoUserReducer = (state = Map(), action) => {
  switch (action.type) {
    case SET_COGNITO_USER:
      return state.set('cognitoUser', action.payload)
    case REMOVE_COGNITO_USER:
      return state.set('cognitoUser')
    default:
      return state
  }
}

export default cognitoUserReducer
