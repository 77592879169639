import { ARTICLES_UPDATE_PREVIEW_IMAGE } from 'utils/constants/actions'

import { updateArticle } from 'actions/articlesActions'

const articlePreviewImage = (store) => (next) => (action) => {
  if (action.type === ARTICLES_UPDATE_PREVIEW_IMAGE) {
    const res = next(action)
    const { articles } = store.getState()

    let article = articles.getIn(['articles', action.dateKey, action.articleIdx]).toJS()
    article = Object.assign({}, article)
    delete article.viewing
    delete article.usersViewing
    delete article.author
    delete article.editor

    let articleUpdateRoute

    if (window.location.pathname.includes('recipe')) {
      articleUpdateRoute = 'recipes'
    } else {
      articleUpdateRoute = 'articles'
    }

    store.dispatch(updateArticle({ route: articleUpdateRoute, id: article.id, article }))
    return res
  }

  return next(action)
}

export default articlePreviewImage
