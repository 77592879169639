import { makeAutoObservable, runInAction } from 'mobx'
import { getClassPromotions, save, update } from 'api/classPromotions'

export default class ClassPromotionsStore {
  classPromotions = []
  lastEvaluatedKey = null
  loading = true
  error = null
  usingSearchForm = false
  lastFormSearchData = null
  saving = false
  savingError = null
  savedPromo = false

  constructor() {
    makeAutoObservable(this)
  }

  get count() {
    return this.classPromotions.length
  }

  fetchClassPromotions = async (data, reset, searchForm) => {
    this.loading = true
    let requestData = data || this.lastFormSearchData

    // if new search empty the list
    if (searchForm) {
      this.classPromotions = []
    }
    // if reset empty the list and don't send the form data
    if (reset) {
      this.classPromotions = []
      requestData = null
    }
    this.usingSearchForm = requestData ? Object.keys(requestData).length !== 0 : false

    try {
      const { classPromotions, lastEvaluatedKey } = await getClassPromotions({
        requestData,
        // don't send lastEvaluatedKey if new search or reset
        lastEvaluatedKey: !searchForm && !reset ? this.lastEvaluatedKey : null,
      })
      runInAction(() => {
        // if searching by class (ID) - replace the list of promotions
        // do the same if resetting
        this.classPromotions = searchForm || reset ? classPromotions : this.classPromotions.concat(classPromotions)

        this.lastEvaluatedKey = lastEvaluatedKey
        this.loading = false
        this.error = null
        this.lastFormSearchData = requestData
      })
    } catch (error) {
      runInAction(() => {
        this.error = error
        this.loading = false
      })
    }
  }

  saveClassPromotion = async (data, shouldUpdate) => {
    if (!this.validateFormData(data)) return

    this.savedPromo = false

    this.saving = true
    this.savingError = null

    const body = data
    if (shouldUpdate) {
      delete body.classTitles
    }

    try {
      const newPromotion = await (!shouldUpdate ? save(body) : update(body))
      runInAction(() => {
        if (shouldUpdate) {
          this.updateClassPromotion(newPromotion)
        } else {
          this.classPromotions.unshift(newPromotion)
        }

        this.savedPromo = true
        // this.sortClassPromotions()
        this.saving = false
      })
    } catch (error) {
      this.saving = false
      this.savingError = error
      this.savedPromo = false
    }
  }

  updateClassPromotion(updatedPromo) {
    const index = this.classPromotions.findIndex((cP) => cP.code === updatedPromo.code)
    this.classPromotions.splice(index, 1, updatedPromo)
  }

  sortClassPromotions() {
    this.classPromotions.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
  }

  validateFormData(data) {
    const { title, code, type, discountType, classIds, percentSavings, salePrice, classDiscounts, dollarSavings } = data
    const percentSavingsInPercentage = percentSavings * 100
    let errorMessage = null

    if (!title || !code || !type || !discountType) {
      errorMessage = 'Please fill out all required fields.'
    }

    if (!classIds.length && (type === 'classLimited' || type === 'trainingLimited' || type === 'specificClass')) {
      errorMessage = 'This type of promotion should have one class/training.'
    }

    if (percentSavings > 0 && salePrice > 0) {
      errorMessage = 'The promotion can have either a sale price or percent savings.'
    }

    const isChangedFromPercentToSavings = percentSavings === 0 && (salePrice > 0 || dollarSavings > 0)

    if (
      percentSavings !== undefined &&
      !isChangedFromPercentToSavings &&
      type !== 'multiClassPurchase' &&
      (percentSavingsInPercentage < 1 || percentSavingsInPercentage > 100)
    ) {
      errorMessage = 'Percent Savings should be between 1 and 100.'
    }

    if (type !== 'multiClassPurchase' && !percentSavings && !salePrice && !dollarSavings) {
      errorMessage = 'Please fill out either percent savings, sale price or dollar savings.'
    }

    if ((type === 'totalCurriculum' || type === 'totalCurriculumAutoApply') && !percentSavings) {
      errorMessage =
        'Percent Savings is required when the promotion type is "Any Class" or "Any Class Automatically Applied"'
    }

    if (type === 'multiClassPurchase') {
      const invalidDiscountItem = classDiscounts.some(
        (d) =>
          (d.percentSavings > 0 && typeof d.salePrice === 'number') ||
          (d.percentSavings < 0.05 && typeof d.salePrice !== 'number')
      )
      const invalidPercentSaving = classDiscounts.some((d) => {
        if (!d.salePrice) {
          const percentSavingsInPercentage = d.percentSavings * 100
          return percentSavingsInPercentage < 1 || percentSavingsInPercentage > 100
        }
        return false
      })

      if (invalidPercentSaving) {
        errorMessage = 'Percent Savings should be between 1 and 100.'
      }

      if (invalidDiscountItem) {
        errorMessage = 'The discounted item should have either a sale price or percent savings.'
      }
    }

    this.savingError = { message: errorMessage }

    setTimeout(() => {
      runInAction(() => {
        this.savingError = null
      })
    }, 5000)

    return errorMessage ? false : true
  }
}
