import { fromJS } from 'immutable'
import { ARTICLES_TOGGLE_COMPRESSED_VIEW } from 'utils/constants/actions'

const compressedView = (store) => (next) => (action) => {
  if (action.type === ARTICLES_TOGGLE_COMPRESSED_VIEW) {
    const { articles } = store.getState()
    localStorage.setItem('wave_cview', !articles.get('compressedView'))
    action.updatedArticles = articles.get('articles').map((group) => {
      return fromJS(
        group.toJS().map((a, i) => {
          a.compressed = !articles.get('compressedView')
          return a
        })
      )
    })
  }
  next(action)
}

export default compressedView
