import {
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  SAVE_IMAGE_DATA_REQUEST,
  SAVE_IMAGE_DATA_SUCCESS,
  SAVE_IMAGE_DATA_FAILURE,
  SEARCH_IMAGES_REQUEST,
  SEARCH_IMAGES_SUCCESS,
  SEARCH_IMAGES_FAILURE,
  REMOVE_UPLOADED_IMAGE,
} from 'utils/constants/actions'

import { RSAA, getJSON } from 'redux-api-middleware'

export function uploadImage(namespace, data) {
  return {
    [RSAA]: {
      method: 'POST',
      endpoint: 'image-file',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      types: [
        {
          type: UPLOAD_IMAGE_REQUEST,
          meta: { namespace },
        },
        {
          type: UPLOAD_IMAGE_SUCCESS,
          meta: { namespace },
        },
        {
          type: UPLOAD_IMAGE_FAILURE,
          meta: { namespace },
        },
      ],
    },
  }
}

export function saveImageData(namespace, data) {
  return {
    [RSAA]: {
      method: data.id ? 'PUT' : 'POST',
      endpoint: data.id ? `images/${data.id}` : 'images',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      types: [
        {
          type: SAVE_IMAGE_DATA_REQUEST,
          meta: { namespace },
        },
        {
          type: SAVE_IMAGE_DATA_SUCCESS,
          meta: { namespace },
        },
        {
          type: SAVE_IMAGE_DATA_FAILURE,
          meta: { namespace },
        },
      ],
    },
  }
}

export function searchImages({ term, from, size }) {
  return {
    [RSAA]: {
      method: 'GET',
      endpoint: `images?term=${term}&from=${from || 0}&size=${size || 10}`,
      types: [
        SEARCH_IMAGES_REQUEST,
        {
          type: SEARCH_IMAGES_SUCCESS,
          payload: (action, state, res) => getJSON(res).then((images) => ({ images, from })),
        },
        SEARCH_IMAGES_FAILURE,
      ],
    },
  }
}

export function removeUploadedImage(namespace) {
  return {
    type: REMOVE_UPLOADED_IMAGE,
    meta: { namespace },
  }
}
