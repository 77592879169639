import { object, string, array, number } from 'yup'

const lessonValidationSchema = object().shape({
  title: string().required('Required'),
  image: string(),
  terms: array().required('Required'),
  video: object().required('Required'),
  instructorId: number().notRequired(),
})

export default lessonValidationSchema
