import moment from 'moment'

import { SUBMIT_RECIPE } from 'utils/constants/actions'
import { sendArticle } from 'actions/createArticleApiActions'

import removeHtmlTags from 'utils/removeHtmlTags'
import removeEmptyFromObject from 'utils/removeEmptyFromObject'
import removeBodyIfEmpty from 'utils/removeBodyIfEmpty'
import deltaToHTML from 'utils/deltaToHTML'
import removeEmptyLinesInQuill from 'utils/removeEmptyLinesInQuill'
// import joinComponentsByType from 'utils/joinComponentsByType'

import customHistory from '../../utils/customHistory'

const submitRecipe = (store) => (next) => (action) => {
  if (action.type === SUBMIT_RECIPE) {
    const { createArticleBody, profile, createArticleMetadata } = store.getState()
    const route = customHistory.location.pathname.split('/')[1]
    const results = { ...action.results }
    if (!results.titles) results.titles = {}

    const userId = profile.getIn(['profile', 'userId'])

    results.timeSpent = createArticleMetadata.get('timeSpent').toJS()

    delete results.flags
    delete results.synd
    delete results.userCreatedUrl
    delete results.nsfw
    delete results.template

    if (!results.id) {
      results.createDate = moment().format()
      results.assignedEditorId = userId
    }

    let componentWithNoContent = false

    const bodyComponents = createArticleBody.getIn(['present', 'components']).toJS()

    let wordCount = 0

    // move header and footer to right positions
    bodyComponents.forEach((c, i) => {
      if (c.type === 'introduction') bodyComponents.splice(1, 0, bodyComponents.splice(i, 1)[0])
      if (c.type === 'footer') bodyComponents.splice(bodyComponents.length, 0, bodyComponents.splice(i, 1)[0])
    })

    results.body = bodyComponents
      .filter((c) => {
        return c.type !== 'text' || !(removeHtmlTags(c.text) === '' || !c.text)
      })
      .map((c) => {
        delete c.id
        delete c.fieldEmpty
        delete c.preserve
        delete c.active
        // for now
        wordCount += c.wordCount || 0
        delete c.wordCount

        // this will be removed, output can be handled in Quill component
        if (c.type === 'header') {
          c.title = removeHtmlTags(c.title)
          results.titles.body = c.title
          c.subtitle = removeHtmlTags(c.subtitle)
          c.dek = removeHtmlTags(c.dek)
        } else if (c.type === 'list' || c.type === 'recipeSteps') {
          c.items.forEach((i) => {
            if (i) {
              i.text = removeHtmlTags(removeEmptyLinesInQuill(i.text))
            }
          })
        } else if (c.type === 'text') {
          // remove frontend-only formatting
          if (c.delta) {
            c.delta
              .filter((op) => op.attributes && op.attributes.link)
              .forEach((op) => (op.attributes.link.sameLink = false))
            c.delta
              .filter((op) => op.attributes && op.attributes.searchHighlight)
              .forEach((op) => (op.attributes.searchHighlight = false))
            c.text = deltaToHTML(c.delta)
            delete c.delta
          }
          // remove empty lines in text components
          c.text = removeEmptyLinesInQuill(c.text)
        }

        if (c.text) {
          if (c.text === '' || !c.text) componentWithNoContent = true
          if (c.text) {
            c.text = removeEmptyLinesInQuill(c.text)
            c.text = removeHtmlTags(c.text)
          }
        }

        return c
      })

    // merge components
    // results.body = joinComponentsByType(results.body, 'text', 'text')
    // store.dispatch(mergeComponents())

    let finalResults = removeBodyIfEmpty(results)
    finalResults = removeEmptyFromObject(finalResults)

    if (!finalResults.metaData) finalResults.metaData = {}
    finalResults.metaData['word count'] = wordCount
    finalResults.metaData = Object.keys(finalResults.metaData).map((key) => {
      return {
        property: key,
        value: finalResults.metaData[key],
      }
    })

    if (!componentWithNoContent) {
      const id = finalResults.id

      action.error = false
      action.flashMessage = ''
      store.dispatch(sendArticle({ route, id, data: finalResults }))
    } else {
      action.error = true
      action.flashMessage = 'Body components cannot be empty'
    }

    action.id = finalResults.id
  }

  return next(action)
}

export default submitRecipe
