import { isUrlValid } from 'utils/validators'

export const getIsMissingHttps = (text: string) => isUrlValid(text) && text.startsWith('http://')

export const addHttpsToLink = (link: string) => {
  if (getIsMissingHttps(link)) {
    link = link.replace('http://', 'https://')
  }
  return link
}
