import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import Chip from '@mui/material/Chip'
import SaveClassCTAs from '../SaveClassCTAs'
import { useStore } from '../../../../../store/mobx'
import { CenterWrapper, CTAWrapper } from '../../../../NavBar/CTAWrapper'

export const warningTitles = {
  save: "Don't forget to save your changes.",
}

const SaveModuleChanges = (props) => {
  const { classes } = useStore('classesStore')
  const classItem = classes?.find((item) => item.id === props.classId)

  const handleSave = async () => {
    await classItem.noResponseSaveToServer(classItem)

    if (props.handleDismissWarning) {
      props.handleDismissWarning()
    }
  }

  return (
    <>
      {typeof props.warningTitle === 'string' && (
        <CenterWrapper>
          <Chip color="warning" label={props.warningTitle} />
        </CenterWrapper>
      )}
      <CTAWrapper>
        <SaveClassCTAs handleSave={handleSave} backUrl={props.backUrl} />
      </CTAWrapper>
    </>
  )
}

SaveModuleChanges.propTypes = {
  classId: PropTypes.number,
  warningTitle: PropTypes.string,
  handleDismissWarning: PropTypes.func,
  backUrl: PropTypes.string,
}

export default observer(SaveModuleChanges)
