import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { waveLogoSmall } from 'styles/assets'
import Tabs from './Tabs'
import styled from 'styled-components'

const LogoImageStyle = styled.img`
  width: 70px;
`

interface Props {
  title?: string
  showLogo?: boolean
  toggleSidemenu(): void
  hideTabs?: boolean
  searchBar: React.ReactNode
}

const Navbar = ({ title, toggleSidemenu, showLogo, hideTabs, searchBar }: Props) => (
  <AppBar position="fixed">
    <Toolbar sx={{ gap: '12px' }}>
      {toggleSidemenu && (
        <IconButton onClick={toggleSidemenu}>
          <MenuIcon sx={{ color: '#fff' }} />
        </IconButton>
      )}

      {showLogo ? (
        <LogoImageStyle src={waveLogoSmall} alt="logoSmall" />
      ) : (
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
      )}

      {!hideTabs && <Tabs />}
      {searchBar}
    </Toolbar>
  </AppBar>
)

export default Navbar
