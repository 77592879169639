import { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import formatAffiliateLink, { getIsNonAffiliateAmazonLink } from './utils/formatAffiliateLink'
import PasteConversionDialog, {
  PasteConversionEnum,
  PasteConversionInfo,
} from '../../components/CreateArticle/Dialogs/PasteConversionDialog'
import { addHttpsToLink, getIsMissingHttps } from './utils/formatNonHttpsLinks'

interface Props {
  children: any
}

const QuillLinkConversion = ({ children }: Props) => {
  const quill = useRef<any>()

  const [conversionInfo, setConversionInfo] = useState<PasteConversionInfo>({})

  const getIsDialogOpen = () => {
    const amazonInfo = conversionInfo[PasteConversionEnum.amazon]
    const noHttpsInfo = conversionInfo[PasteConversionEnum.noHttps]

    if (amazonInfo && amazonInfo.linksFound > 0) {
      return true
    }
    if (noHttpsInfo && noHttpsInfo.linksFound > 0) {
      return true
    }
    return false
  }

  const convertToAffiliateLinks = () => {
    ;(Array.from(quill.current.root.querySelectorAll('a')) as HTMLLinkElement[]).forEach((el) => {
      if (getIsNonAffiliateAmazonLink(el.href)) {
        el.href = formatAffiliateLink(el.href)
        el.rel = 'nofollow'
      }
    })

    setConversionInfo((p) => ({ ...p, [PasteConversionEnum.amazon]: undefined }))
  }

  const addHttps = () => {
    ;(Array.from(quill.current.root.querySelectorAll('a')) as HTMLLinkElement[]).forEach((el) => {
      const httpsLink = addHttpsToLink(el.href)
      el.href = httpsLink
      if (getIsMissingHttps(el.innerHTML)) {
        el.innerHTML = el.innerHTML.replace('http://', 'https://')
      }
    })

    setConversionInfo((p) => ({ ...p, [PasteConversionEnum.noHttps]: undefined }))
  }

  const handleChange = (content: any, delta: any) => {
    const opContent: { amazonLinksCount: number; linksMissingHttpsCount: number; smartQuotes: string[] } =
      delta.ops.reduce(
        (acc: { amazonLinksCount: number; linksMissingHttpsCount: number; smartQuotes: string[] }, op: any) => {
          const link = op.attributes?.link
          if (link) {
            const isCandidate = getIsNonAffiliateAmazonLink(link.url)
            if (isCandidate) {
              acc.amazonLinksCount += 1
            }

            const startsWithHttp = getIsMissingHttps(link.url)
            if (startsWithHttp) {
              acc.linksMissingHttpsCount += 1
            }
          }

          return acc
        },
        { amazonLinksCount: 0, linksMissingHttpsCount: 0, smartQuotes: [] }
      )

    if (Object.values(opContent).some((c) => Number(c) > 0)) {
      const currentState: PasteConversionInfo = { ...conversionInfo }
      if (opContent.amazonLinksCount > 0) {
        currentState[PasteConversionEnum.amazon] = { linksFound: opContent.amazonLinksCount }
      }
      if (opContent.linksMissingHttpsCount) {
        currentState[PasteConversionEnum.noHttps] = { linksFound: opContent.linksMissingHttpsCount }
      }

      setConversionInfo(currentState)
    }
  }

  const handleLinkConvert = (type: PasteConversionEnum) => {
    switch (type) {
      case PasteConversionEnum.amazon:
        convertToAffiliateLinks()
        break
      case PasteConversionEnum.noHttps:
        addHttps()
        break
      default:
        console.error('No type passed')
    }
  }

  const toggleDialog = () => {
    setConversionInfo({})
  }

  return (
    <div>
      <PasteConversionDialog
        isOpen={getIsDialogOpen()}
        toggleDialog={toggleDialog}
        handleConvert={handleLinkConvert}
        info={conversionInfo}
      />
      {children({
        onEditorRef: (el: any) => (quill.current = el),
        onChange: handleChange,
      })}
    </div>
  )
}

QuillLinkConversion.propTypes = {
  children: PropTypes.func,
}

export default QuillLinkConversion
