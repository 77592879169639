import { Quill } from 'react-quill'
import { CREATE_ARTICLE_UNHIGHLIGHT_KEYWORDS } from 'utils/constants/actions'
import findOccurrencesInString from 'utils/findOccurrencesInString'

const unhighlightKeywords = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_UNHIGHLIGHT_KEYWORDS) {
    const { createArticleBody } = store.getState()
    let bodyComponents = createArticleBody.getIn(['present', 'components']).toJS()
    const keyword = action.keyword

    // search for a keyword in body components
    bodyComponents = bodyComponents.map((component) => {
      if (component.text && component.type === 'text') {
        // initiate Quill - helps with formatting
        const editorEl = document.createElement('div')
        const editor = new Quill(editorEl)
        editor.setContents(component.delta)
        const editorText = editor.getText()
        const keywordIndices = findOccurrencesInString(editorText, keyword)
        if (keywordIndices.length) {
          keywordIndices.forEach((index) => {
            const keywordUrl = editor.getFormat(index, keyword.length).link
              ? editor.getFormat(index, keyword.length).link.url
              : 'no url'
            // if url is not set clear formatting
            if (keywordUrl === 'no url' || !keywordUrl) {
              editor.formatText(index, keyword.length, {
                link: false,
              })
              // if URL is set for keyword, remove only highlighting
            } else if (keywordUrl && keywordUrl !== 'no url') {
              editor.formatText(index, keyword.length, {
                link: {
                  url: keywordUrl,
                  keyword: false,
                  sameLink: false,
                },
              })
            }
          })
        }
        component.text = editor.root.innerHTML
        editorEl.remove()
      }
      return component
    })
    action.updatedBodyComponents = bodyComponents
  }
  return next(action)
}

export default unhighlightKeywords
