import { useRef, useEffect } from 'react'

const useOutsideAlerter = (ref, onOutsideClick) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onOutsideClick])
}

const OutsideClick = ({ children, onOutsideClick }) => {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, onOutsideClick)

  return <div ref={wrapperRef}>{children}</div>
}

export default OutsideClick
