import { makeAutoObservable, runInAction } from 'mobx'

import { createLiveLesson, updateLiveLesson } from 'api/classes'

import { stores } from './index'
// import { browserHistory } from '../..'

const store = makeAutoObservable({
  error: null,
  loading: false,
  activeLesson: null,
  saveLiveLesson: async (formData, shouldUpdate) => {
    if (!store.validateFormData(formData)) return

    try {
      store.loading = true
      store.error = null

      await (!shouldUpdate
        ? createLiveLesson(formData.classId, formData)
        : updateLiveLesson(formData.classId, formData.id, formData))

      runInAction(() => {
        store.loading = false
        store.error = null

        // re-fetch class data and redirect
        stores.classStore.fetchClass(formData.classId)
        // browserHistory.push(`/classes/${formData.classId}/modules/${formData.moduleId}`)
      })
    } catch (error) {
      runInAction(() => {
        store.loading = false
        store.error = error
      })
    }
  },

  validateFormData: (formData) => {
    let errorMessage = null
    if (!formData) errorMessage = 'The form is empty.'

    const { title, image, instances } = formData

    if (title?.length < 3) errorMessage = 'Title is too short or invalid.'

    if (!image) errorMessage = 'Image is required.'

    if (!instances.length) errorMessage = 'Live Lesson should have at least one instance.'

    const noInstanceStartDate = instances.some((ins) => !ins.startDate)
    if (noInstanceStartDate) errorMessage = 'At least one instance is missing the start date.'

    store.error = { message: errorMessage }

    setTimeout(() => {
      runInAction(() => {
        store.error = null
      })
    }, 5000)

    return errorMessage ? false : true
  },
})

export default store
