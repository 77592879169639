import { useState, useCallback, useMemo, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import Table from '@mui/material/Table'
import PropTypes from 'prop-types'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import BackupIcon from '@mui/icons-material/Backup'
import { Link } from 'react-router-dom'
import { useStore } from 'store/mobx'
import styled from 'styled-components'

import UpdateAttendance from './UpdateAttendance'
import SaveLessonDragChanges from '../ClassDetails/Form/SaveLessonDragChanges'
import { lessonComponents, lessonTypes } from '../../../../containers/new-pages/ClassLessons'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'

import { warningTitles } from '../ClassDetails/Form/SaveModuleCanges'
import { ClassModule } from '../../../../types/ClassModule'
import { DragTypesEnum } from '../../../../utils/constants/dragTypes'
import { StyledDragCell, StyledDragTableRow } from '../Modules/List'
import StyledFlex from '../../../../layouts/StyledLayouts'
import DraggableButton from '../../../Common/DraggableButton'

const ButtonPlaceholder = styled.div`
  display: inline;
  padding: 20px;
`

const BooleanIcon = ({ isTrue }: { isTrue: boolean }) =>
  isTrue ? <CheckIcon color="primary" /> : <CloseIcon color="secondary" />
BooleanIcon.propTypes = { isTrue: PropTypes.bool }

interface SessionItemProps {
  index: number
  classId: number
  activeModule: ClassModule.Module
  session: ClassModule.Session
  updateLiveSessionAttendance(session: ClassModule.Session): void
  showTermColumn?: boolean
  termName?: ClassModule.Term
  isLiveLessonInList: boolean
  handleDrag(dragIndex: number, hoverIndex: number): void
}

const SessionItem = (props: SessionItemProps) => {
  const dragRef = useRef<HTMLButtonElement>(null)
  const dropRef = useRef<HTMLTableRowElement>(null)

  const [_collector, drop] = useDrop(
    () => ({
      accept: DragTypesEnum.classLesson,
      hover: (item: any, monitor) => {
        if (!dragRef.current || !dropRef.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = props.index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }

        // Determine rectangle on screen
        const hoverBoundingRect = dropRef.current.getBoundingClientRect()
        // Get vertical middle

        const hoverMiddleY = hoverBoundingRect.top + hoverBoundingRect.height / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        if (!clientOffset) {
          return
        }

        // Get pixels to the top
        const hoverClientY = ~~clientOffset.y

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        // Dragging upwards

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        props.handleDrag(dragIndex, hoverIndex)
        item.index = hoverIndex
      },
    }),
    [props]
  )

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: DragTypesEnum.classLesson,
      item: () => ({ index: props.index }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [props]
  )

  drag(dragRef)
  drop(preview(dropRef))

  const lessonType = props.session.type.toLowerCase().split(' ').join('-')
  return (
    <>
      <StyledDragTableRow ref={dropRef} style={{ opacity: isDragging ? 0 : 1 }}>
        <StyledDragCell align="left">
          <StyledFlex
            className="draggableButtonWrapper"
            style={{ alignItems: 'center', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}
          >
            <DraggableButton ref={dragRef} />
          </StyledFlex>
          <span>{props.session.id}</span>
        </StyledDragCell>
        <TableCell align="left">{props.session.title}</TableCell>
        {props.showTermColumn && <TableCell align="left">{props.termName?.title}</TableCell>}
        <TableCell align="left">{props.session.type}</TableCell>
        <TableCell align="left">
          <BooleanIcon isTrue={props.session.active} />
        </TableCell>
        <TableCell align="left">
          <BooleanIcon isTrue={!props.session.optional} />
        </TableCell>
        <TableCell align="left" style={{ display: 'flex' }}>
          {props.session.type === lessonComponents.liveSession.title && (
            <IconButton
              aria-label="update attendance"
              color="primary"
              onClick={() => props.updateLiveSessionAttendance(props.session)}
            >
              <BackupIcon />
            </IconButton>
          )}
          {props.session.type !== lessonComponents.liveSession.title && props.isLiveLessonInList && (
            <ButtonPlaceholder />
          )}
          <Link
            to={{
              // eslint-disable-next-line
              pathname: `/classes/${props.classId}/modules/${props.activeModule.id}/${lessonType}/${props.session.id}`,
            }}
          >
            <IconButton
              disabled={!lessonTypes.find((type) => type.id === lessonType)}
              aria-label="edit session"
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Link>
        </TableCell>
      </StyledDragTableRow>
    </>
  )
}

interface LessonsProps {
  activeModule: ClassModule.Module
  terms: ClassModule.Term[]
  classId: number
}

const Lessons = (props: LessonsProps) => {
  const [showUpdateAttendanceDialog, toggleUpdateAttendanceDialog] = useState(false)
  const [activeLiveSession, setActiveLiveSession] = useState<ClassModule.Session | undefined>(undefined)
  const [wasModified, setWasModified] = useState(false)

  const { classes } = useStore('classesStore')
  const data = classes.find((item: ClassModule.Data) => item.id === props.classId)

  const isLiveLessonInList = useMemo(() => {
    return !!(
      props.activeModule && props.activeModule.sessions?.find((s) => s.type === lessonComponents.liveSession.title)
    )
  }, [props.activeModule])

  const handleDrag = (dragIndex: number, hoverIndex: number) => {
    const moduleIndex = data.modules.findIndex((m: ClassModule.Module) => m.id === props.activeModule.id)

    const sessions = [...props.activeModule.sessions]
    const item = sessions.splice(dragIndex, 1)[0]
    sessions.splice(hoverIndex, 0, item)

    console.log('[POST MERGE]🚀 Sessions being updated correctly?', sessions)

    data.updateFromJson({
      ...data,
      modules: [
        ...data.modules.slice(0, moduleIndex),
        { ...data.modules[moduleIndex], sessions },
        ...data.modules.slice(moduleIndex + 1),
      ],
    })

    setWasModified(true)
  }

  const updateLiveSessionAttendance = useCallback(
    (session: ClassModule.Session) => {
      setActiveLiveSession(session)
      toggleUpdateAttendanceDialog((p) => !p)
    },
    [toggleUpdateAttendanceDialog]
  )

  return (
    <>
      <SaveLessonDragChanges
        classId={props.classId}
        activeModuleId={props.activeModule.id}
        warningTitle={wasModified ? warningTitles.save : undefined}
        handleDismissWarning={() => setWasModified(false)}
        backUrl={`/classes/${props.classId}/modules`}
      />
      <TableContainer component={Paper}>
        <Table stickyHeader style={{ position: 'relative', width: '100%' }}>
          <TableHead>
            <TableRow>
              <StyledDragCell align="left">ID</StyledDragCell>
              <TableCell align="left">Title</TableCell>
              {props.terms && Boolean(props.terms.length) && <TableCell align="left">Term</TableCell>}
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Active</TableCell>
              <TableCell align="left">Required</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="classSessionsList">
            {props.activeModule?.sessions?.map((s, index) => (
              <SessionItem
                key={s.id}
                index={index}
                session={s}
                classId={props.classId}
                activeModule={props.activeModule}
                showTermColumn={props.terms && Boolean(props.terms.length)}
                termName={props.terms?.find((item) => item.id === s.termId)}
                updateLiveSessionAttendance={updateLiveSessionAttendance}
                handleDrag={handleDrag}
                isLiveLessonInList={isLiveLessonInList}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showUpdateAttendanceDialog && (
        <UpdateAttendance
          open={showUpdateAttendanceDialog}
          classId={props.classId}
          activeLiveSession={activeLiveSession}
          handleClose={toggleUpdateAttendanceDialog}
        />
      )}
    </>
  )
}

export default Lessons
