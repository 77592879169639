import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getKeywords } from 'actions/createArticleKeywordsActions'

import KeywordListDialog from './KeywordListDialog'
import KeywordSearchDialog from './KeywordSearchDialog'

class InsertKeyword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchRequested: false,
      searchTerm: props.searchTerm || '',
    }
  }

  componentDidMount() {
    const { searchTerm } = this.props
    searchTerm && this.handleSearchRequest(searchTerm)
  }

  handleSearchRequest(searchTerm) {
    const { getKeywords } = this.props
    this.setState({ searchRequested: false, searchTerm })
    getKeywords(searchTerm)
  }

  render() {
    const { keywords, searching, onUrlSelected, onCancel } = this.props
    const { searchRequested, searchTerm } = this.state

    return (
      <div>
        <KeywordSearchDialog
          dialogOpen={searchRequested}
          onCancel={() => this.setState({ searchRequested: false })}
          onSearch={(term) => this.handleSearchRequest(term)}
        />
        <KeywordListDialog
          dialogOpen={!searchRequested}
          searchTerm={searchTerm}
          searching={searching}
          keywords={keywords}
          onChangeSearchTerm={() => this.setState({ searchRequested: true })}
          onClose={onCancel}
          onSubmit={onUrlSelected}
        />
      </div>
    )
  }
}

InsertKeyword.propTypes = {
  getKeywords: PropTypes.func,
  keywords: PropTypes.object,
  searching: PropTypes.bool,
  searchTerm: PropTypes.string,
  onUrlSelected: PropTypes.func,
  onCancel: PropTypes.func,
}

const mapStateToProps = (state) => ({
  keywords: state.createArticleKeywords.get('keywords'),
  searching: state.createArticleKeywords.get('loading'),
})

const mapDispatchToProps = (dispatch) => ({
  getKeywords: (keyword) => dispatch(getKeywords(keyword)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InsertKeyword)
