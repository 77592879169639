import { Paper } from '@mui/material'
import styled from 'styled-components'

export const EditorsNotesTooltip = styled(Paper)`
  display: none;
  position: absolute;
  top: 100%;
  margin: 0;
  padding: 1.5rem;
  overflow: visible;
  width: 380px;
  left: -166px;
  z-index: 2;

  &:after {
    content: ' ';
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: solid transparent;
    width: 0;
    height: 0;
    position: absolute;
  }

  &:before {
    content: ' ';
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: solid transparent;
    width: 0;
    height: 0;
    position: absolute;
    border-bottom-color: #fff;
    border-width: 12px;
    margin-left: -12px;
  }
`

export const EditorsNotesWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    ${EditorsNotesTooltip} {
      display: block;
    }
  }
`
