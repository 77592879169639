import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { toggleConfirmDialog } from 'actions/createArticleActions'
import { DialogActions } from '@mui/material'
import { RootDispatch, RootState } from '../../../App'

interface OwnProps {
  namespace: string
  description: string | JSX.Element
  cancelText: string
  confirmText: string
  title?: string
  confirmCallback?(): void
  cancelCallback?(): void
}

interface Props extends OwnProps, ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {}

const ConfirmDialog = ({
  open,
  toggleDialog,
  confirmCallback,
  cancelCallback,
  cancelText,
  confirmText,
  description,
  title,
}: Props) => {
  return (
    <Dialog open={open} title={title} onClose={toggleDialog}>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (cancelCallback) {
              cancelCallback()
            }
            toggleDialog()
          }}
        >
          {cancelText}
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            if (confirmCallback) {
              confirmCallback()
            }
            toggleDialog()
          }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const context = ownProps.namespace
  return {
    open: state.confirmDialog[context].get('open'),
  }
}

const mapDispatchToProps = (dispatch: RootDispatch, ownProps: OwnProps) => {
  const context = ownProps.namespace
  return {
    toggleDialog: () => dispatch(toggleConfirmDialog(context)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog)
