import axios from 'axios'
import initCognitoSdk from 'auth/cognito'

const { REACT_APP_API_URL, REACT_APP_ENVIRONMENT } = process.env

const instance = axios.create({
  baseURL: REACT_APP_API_URL,
})

instance.interceptors.request.use((config) => {
  config.headers.Authorization = sessionStorage.getItem('id_token')
  return config
})

instance.interceptors.response.use(
  (response) => {
    return response
  },
  ({ response: { config, status, data } }) => {
    // copied from authErrorMiddleware.js
    if (status === 401) {
      const auth = initCognitoSdk()
      const session = auth.getCachedSession()
      // refresh the session?
      if (session.getRefreshToken() && session.getRefreshToken().getToken()) {
        auth.getSession()
        // retry original request
        config.headers.Authorization = sessionStorage.getItem('id_token')
        return axios(config).then((data) => data)
      }
    }

    return Promise.reject({
      status,
      ...data,
    })
  }
)

export default instance
