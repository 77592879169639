import { useMutation, useQuery } from 'react-query'
import { createTag, getTags, updateTag } from '../api/tags'
import { queryClient } from '../App'
import { QueryKeys } from '../utils/QueryKeys'

const useTags = () => {
  const query = useQuery([QueryKeys.tags], getTags, {
    staleTime: 1000 * 60 * 15,
  })

  return query
}

export const useMutateTags = () => {
  const mutation = useMutation((args) => (args.id ? updateTag(args) : createTag(args)), {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(QueryKeys.tags, (oldTags) => {
        if (!variables.id) {
          // a new tag was created
          oldTags.push({ ...variables.tag, id: data.id })
          oldTags.sort((a, b) => a.tag_name.localeCompare(b.tag_name))
        } else {
          // tag was modified, update local data
          const index = oldTags.findIndex((t) => t.id === variables.tag.id)
          if (index !== -1) {
            oldTags[index] = variables.tag
          }
        }
        return oldTags
      })
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        error.message = error.response.data.message
      }
      return error
    },
  })

  return mutation
}

export default useTags
