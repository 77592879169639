import { Suspense, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Autocomplete from '@mui/material/Autocomplete'

import { Form } from '../styles'
import VideoDialog from './VideoDialog'
import SaveLessonChanges from '../SaveLessonChanges'
import SelectedVideo from '../FormComponents/SelectedVideo'
import StyledFlex, { StyledColumn } from '../../../../../layouts/StyledLayouts'
import Loader from 'components/new-pages/Loader'

import ToolsForm from '../FormComponents/ToolsForm'
import PhaseDropdown from '../FormComponents/PhaseDropdown'
import InstructorsDropdown from '../FormComponents/InstructorsDropdown'
import LessonImageComponent from '../FormComponents/LessonImageComponent'

const defaultFormData = {
  title: '',
  active: true,
  optional: false,
  image: '',
  phase: '',
  tools: [],
}

const EditVideoLesson = ({ cls, moduleId, activeLesson, activeLessonIdx, allTermsUsedInVersions, termsInUse }) => {
  const [formData, setFormData] = useState({ ...defaultFormData, ...activeLesson })
  const [isVideoInsertOpen, setIsVideoInsertOpen] = useState(false)

  const [termsInputVal, setTermsInputVal] = useState(
    activeLesson?.termId ? cls.terms?.find((t) => t.id === activeLesson.termId)?.title : ''
  )

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value })
  }

  const handleChangeNegatedBooolean = (event) => {
    const { name, value, type, checked } = event.target
    setFormData({ ...formData, [name]: type === 'checkbox' ? !checked : value })
  }

  const handleAutocompleteFieldChange = (event, value, fieldName) => {
    setFormData((p) => ({ ...p, [fieldName]: value }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  const toggleIsVideoInsertOpen = () => {
    setIsVideoInsertOpen((p) => !p)
  }

  const handleVideoConfirm = (video) => {
    setFormData((p) => ({
      ...p,
      video: {
        duration: parseInt(video.duration, 10),
        mediaId: video.key,
        thumbnail:
          typeof video.custom.thumbnailURL === 'string' && video.custom.thumbnailURL.length
            ? video.custom.thumbnailURL
            : undefined,
        title: video.title,
      },
    }))

    setIsVideoInsertOpen(false)
  }

  const termsAvailable = cls.terms?.filter((item) => !termsInUse?.includes(item.id))

  return (
    <>
      <SaveLessonChanges
        classId={cls.id}
        moduleId={moduleId}
        lessonId={activeLesson?.id}
        formData={{ ...formData, type: 'Video', moduleId, classId: cls.id, versions: activeLesson?.versions || [] }}
      />
      <Form component="form" id="video-form" onSubmit={handleSubmit}>
        <StyledColumn style={{ gap: '12px' }}>
          <StyledFlex style={{ gap: '16px' }}>
            <StyledFlex style={{ flex: 1 }}>
              <LessonImageComponent
                image={formData.image}
                setImage={(image) => setFormData((p) => ({ ...p, image }))}
              />
            </StyledFlex>

            <StyledFlex style={{ flex: 1 }}>
              <SelectedVideo video={formData.video} handleOpenVideoInsert={toggleIsVideoInsertOpen} />
            </StyledFlex>
          </StyledFlex>

          <StyledFlex style={{ gap: '16px' }}>
            <TextField
              variant="standard"
              value={formData.title}
              fullWidth
              name="title"
              type="text"
              label="Title"
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Title' }}
              sx={{ flex: 1 / 3 }}
            />
            {termsAvailable?.length > 0 && (
              <Autocomplete
                data-testid="videoLessontermsDropdown"
                name="termId"
                value={termsAvailable.find((t) => t.id === formData.termId) || null}
                options={termsAvailable}
                getOptionLabel={(option) => option?.title || ''}
                renderInput={(params) => <TextField variant="standard" {...params} label="Select Term" />}
                inputValue={termsInputVal}
                onChange={(e, v) => setFormData({ ...formData, termId: v?.id })}
                onInputChange={(e, v) => setTermsInputVal(v)}
                sx={{ flex: 1 / 3 }}
              />
            )}
          </StyledFlex>
          <StyledFlex style={{ gap: '16px' }}>
            <FormControlLabel
              control={<Switch checked={formData.active} onChange={handleChange} name="active" color="primary" />}
              label="Active"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={!formData.optional}
                  onChange={handleChangeNegatedBooolean}
                  name="optional"
                  color="primary"
                />
              }
              label="Required for completion"
            />
          </StyledFlex>

          <StyledFlex style={{ gap: '16px' }}>
            <Suspense fallback={<Loader />}>
              <PhaseDropdown
                cls={cls}
                moduleId={moduleId}
                formData={formData}
                handleAutocompleteFieldChange={handleAutocompleteFieldChange}
              />
              <InstructorsDropdown
                selectedInstructorId={formData.instructorId}
                classInstructorIds={cls.instructors}
                handleChange={handleAutocompleteFieldChange}
              />
              <ToolsForm
                selectedValues={formData.tools}
                options={cls.tools}
                handleChange={handleAutocompleteFieldChange}
                isSingleSelect
              />
            </Suspense>
          </StyledFlex>
        </StyledColumn>

        <Grid container spacing={3} direction="row" alignItems="flex-start">
          <Grid item xs={6}></Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}></Grid>
        </Grid>

        {isVideoInsertOpen && <VideoDialog onClose={toggleIsVideoInsertOpen} onConfirm={handleVideoConfirm} />}
      </Form>
    </>
  )
}

EditVideoLesson.propTypes = {
  cls: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
  activeLesson: PropTypes.object,
  activeLessonIdx: PropTypes.number,
  lessons: PropTypes.array,
}

export default EditVideoLesson
