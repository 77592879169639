function copyToClipboard(text: string) {
  const type = 'text/plain'
  const blob = new Blob([text], { type })

  if (typeof navigator !== 'undefined' && typeof ClipboardItem !== 'undefined') {
    const data = [new ClipboardItem({ [type]: Promise.resolve(blob) })]

    navigator.clipboard.write(data).catch((err) => {
      console.error('Could not copy text: ', err)
    })
  } else {
    console.error('Browser does not support the Clipboard API')
  }
}

export default copyToClipboard
