import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import styled from 'styled-components'
import DialogContent from '@mui/material/DialogContent'
import { SectionHeader } from '../ImageGallery'
import StyledFlex from '../../../../../layouts/StyledLayouts'
import { FormControl, InputLabel, Select } from '@mui/material'

const StyledTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
`

const ShopData = ({ onSubmit, data, closeDialog }) => {
  const [formData, setFormData] = useState({ title: '', priority: 2 })

  useEffect(() => {
    if (data) setFormData(data)
    setTimeout(() => window.dispatchEvent(new Event('resize'))) // making the repositionOnUpdate wait
  }, [data])

  return (
    <>
      <StyledTitle>
        <div>Shop information</div>
        <IconButton onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </StyledTitle>
      <DialogContent>
        <SectionHeader>Basic data</SectionHeader>
        <StyledFlex style={{ justifyContent: 'space-between', marginBottom: '20px' }}>
          <TextField
            label="Shop Title"
            variant="standard"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
        </StyledFlex>
        <StyledFlex style={{ justifyContent: 'space-between', marginBottom: '20px' }}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id={'priority-label'}>Priority</InputLabel>
            <Select
              labelId="Context"
              value={formData.priority}
              onChange={(e) => setFormData({ ...formData, priority: e.target.value })}
            >
              <MenuItem value={1}>Enhanced</MenuItem>
              <MenuItem value={2}>Standard</MenuItem>
              <MenuItem value={3}>Supplemental</MenuItem>
            </Select>
          </FormControl>
        </StyledFlex>
        <StyledFlex style={{ justifyContent: 'flex-end' }}>
          <Button onClick={() => onSubmit(formData)}>{data?.title ? 'Continue' : 'Add product'}</Button>
        </StyledFlex>
      </DialogContent>
    </>
  )
}

ShopData.propTypes = {
  onSubmit: PropTypes.func,
  closeDialog: PropTypes.func,
  data: PropTypes.object,
}

export default ShopData
