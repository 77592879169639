import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CommonDialogTitle from '../../Common/DialogComponents/CommonDialogTitle'
import { StyledColumn } from '../../../layouts/StyledLayouts'
import styled from 'styled-components'

const HttpsPrefix = styled.span`
  color: #ff5900d3;
`

const AffiliateLink = styled.span`
  color: #42a247;
`

export enum PasteConversionEnum {
  amazon = 1,
  noHttps,
}

export interface PasteConversionInfo {
  [PasteConversionEnum.amazon]?: {
    linksFound: number
  }
  [PasteConversionEnum.noHttps]?: {
    linksFound: number
  }
}

interface Props {
  isOpen: boolean
  toggleDialog(): void
  handleConvert(type: PasteConversionEnum): void
  info: PasteConversionInfo
}

const PasteConversionDialog = (props: Props) => {
  return (
    <Dialog open={props.isOpen} fullWidth onClose={props.toggleDialog}>
      <CommonDialogTitle onClose={props.toggleDialog}>Paste conversion</CommonDialogTitle>
      <DialogContent>
        <StyledColumn $gap>
          <AmazonInfo
            info={props.info[PasteConversionEnum.amazon]}
            onConfirm={() => props.handleConvert(PasteConversionEnum.amazon)}
          />
          <NoHttpsInfo
            info={props.info[PasteConversionEnum.noHttps]}
            onConfirm={() => props.handleConvert(PasteConversionEnum.noHttps)}
          />
        </StyledColumn>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

interface InfoComponentProps<T extends PasteConversionEnum> {
  info: PasteConversionInfo[T]
  onConfirm(): void
}

const AmazonInfo = (props: InfoComponentProps<PasteConversionEnum.amazon>) => {
  if (!props.info?.linksFound) {
    return null
  }

  return (
    <div>
      <div>
        There {props.info.linksFound > 1 ? 'are' : 'is'} {props.info.linksFound} Amazon link
        {props.info.linksFound > 1 && 's'}
        &nbsp;found in the text you just added. Do you want to convert {props.info.linksFound > 1 ? 'them' : 'it'} to
        <AffiliateLink> affiliate link{props.info.linksFound > 1 && 's'}</AffiliateLink>?
      </div>
      <Button onClick={props.onConfirm}>Yes</Button>
    </div>
  )
}

const NoHttpsInfo = (props: InfoComponentProps<PasteConversionEnum.noHttps>) => {
  if (!props.info?.linksFound) {
    return null
  }

  return (
    <div>
      <div>
        {/* eslint-disable max-len */}
        There {props.info.linksFound > 1 ? 'are' : 'is'} {props.info.linksFound} link{props.info.linksFound > 1 && 's'}{' '}
        missing a <HttpsPrefix>https</HttpsPrefix> prefix in the text. Do you want to convert{' '}
        {props.info.linksFound > 1 ? 'them' : 'it'} to a https-prefixed link
        {props.info.linksFound > 1 && 's'}?
      </div>
      <Button onClick={props.onConfirm}>Yes</Button>
    </div>
  )
}

export default PasteConversionDialog
