import { ENTER } from 'utils/keyCodes'

const PreventNewlines = ({ children }) =>
  children({
    keyboardBindings: {
      handleEnter: {
        key: ENTER,
        handler: () => null,
      },
      'header enter': {
        key: ENTER,
        handler: () => null,
      },
    },
  })

export default PreventNewlines
