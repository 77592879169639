import { onValue, remove, child } from 'firebase/database'
import { useEffect, useState } from 'react'

import { FirebaseDocModule } from '../../types/FirebaseDocModule'
import { getPresenceRef } from './presenceHelpers'

interface Args {
  presenceKey?: string
}
export const useUserSessions = (args: Args) => {
  const [userSessions, setUserSessions] = useState<FirebaseDocModule.UserSession[]>([])

  useEffect(() => {
    if (process.env.NODE_ENV === 'test' || !args.presenceKey) {
      return
    }

    const unsub = onValue(getPresenceRef(args.presenceKey), (snapshot) => {
      const sessions = snapshot.val() || []

      setUserSessions(
        (Object.entries(sessions) as [any, Omit<FirebaseDocModule.UserSession, 'sessionKey'>][])
          .map(([sessionKey, val]) => ({
            sessionKey,
            ...val,
          }))
          .filter((s) => s.user && s.sessionStart && s.id)
      )
    })

    return () => {
      unsub()
    }
  }, [args.presenceKey])

  const kickUser = (sessionKey?: string) => {
    if (process.env.NODE_ENV === 'test' || !sessionKey || !args.presenceKey) {
      return
    }

    return remove(child(getPresenceRef(args.presenceKey), sessionKey))
  }

  return { userSessions, kickUser }
}
