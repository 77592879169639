import { REFRESH_ARTICLE_SUCCESS, LOAD_ARTICLES_SUCCESS } from 'utils/constants/actions'

const articleRefreshed = (store) => (next) => (action) => {
  if (action.type === REFRESH_ARTICLE_SUCCESS) {
    const refreshedArticle = action.payload

    const { articles } = store.getState()

    if (articles.get('articles')) {
      const groupArticles = articles.get('articles').toJS()
      let allArticles = []
      Object.keys(groupArticles).map((group) => (allArticles = allArticles.concat(groupArticles[group])))
      const idx = allArticles.findIndex((a) => a.id === refreshedArticle.id)
      allArticles[idx] = refreshedArticle

      store.dispatch({
        type: LOAD_ARTICLES_SUCCESS,
        payload: allArticles,
      })
    }
  }

  return next(action)
}

export default articleRefreshed
