import { CREATE_ARTICLE_SET_ACTIVE } from 'utils/constants/actions'

const setActive = (store) => (next) => (action) => {
  if (action.type === CREATE_ARTICLE_SET_ACTIVE) {
    const componentIndex = action.index
    const { createArticleBody } = store.getState()
    const components = createArticleBody.getIn(['present', 'components'])

    action.updatedComponents = components.toJS().map((c, i) => {
      if (c.type === 'text' && componentIndex === i) c.active = true
      else if (c.type === 'text' && componentIndex !== i && !c.preserve) c.active = false
      return c
    })
  }

  next(action)
}

export default setActive
