import { Map, fromJS } from 'immutable'
import {
  CREATE_ARTICLE_KEYWORDS_REQUEST,
  CREATE_ARTICLE_KEYWORDS_SUCCESS,
  CREATE_ARTICLE_KEYWORDS_FAILURE,
} from 'utils/constants/actions'

const createArticleKeywordsReducer = (state = Map(), action) => {
  switch (action.type) {
    case CREATE_ARTICLE_KEYWORDS_REQUEST:
      return state.set('loading', true)
    case CREATE_ARTICLE_KEYWORDS_SUCCESS:
      return state.set('loading', false).set('keywords', fromJS(action.payload))
    case CREATE_ARTICLE_KEYWORDS_FAILURE:
      return state.set('loading', false)
    default:
      return state
  }
}

export default createArticleKeywordsReducer
