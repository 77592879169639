import PropTypes from 'prop-types'
import styled from 'styled-components'

import { cerulean } from 'styles/colors'

import formatDate from 'utils/formatDate'
import { StyledColumn } from 'layouts/StyledLayouts'
import { EditorsNotesTooltip } from './styles'

const Title = styled.h4`
  margin: 0;
`

const Note = styled.div`
  margin: 1rem 0 0 0;

  &:hover {
    color: ${cerulean};
  }
`

const Author = styled.div`
  font-size: 11px;
  color: gray;
  font-weight: 700;
`

interface Props {
  editorsNotes: any[]
}

const EditorsNotes = ({ editorsNotes }: Props) => (
  <EditorsNotesTooltip>
    <Title>Editors Notes</Title>

    <StyledColumn>
      {editorsNotes &&
        editorsNotes.map((note, idx) => (
          <Note key={idx}>
            <Author>
              {note.get('userName') || 'Anonymous'}
              &nbsp; ({formatDate(note.get('date'), 'MM/DD/YY hh:mm a')})
            </Author>
            {note.get('comment')}
          </Note>
        ))}
    </StyledColumn>
  </EditorsNotesTooltip>
)

EditorsNotes.propTypes = {
  articleIdx: PropTypes.number,
  editorsNotes: PropTypes.object,
  updateEditorsNotes: PropTypes.func,
  updateArticle: PropTypes.func,
}

export default EditorsNotes
