import { ref } from 'firebase/database'

import firebase from '../../utils/firebase'

export const getPresenceRef = (presenceKey: string) => ref(firebase.database, presenceKey)

export const getOnlineSubRef = () => ref(firebase.database, '.info/connected')

export const getArticleLayoutPresenceKey = (pathname: string, articleId: string) => {
  let prefix = 'articles'
  if (pathname.includes('recipe')) {
    prefix = 'recipes'
  } else if (pathname.includes('podcast')) {
    prefix = 'podcasts'
  }
  return `/${prefix}/${articleId}`
}
