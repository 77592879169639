import { TOGGLE_RANGE_PICKER_WITH_NO_PUBLISH_DATE, RANGE_PICKER_ON_DATE_CHANGE } from 'utils/constants/actions'

export function toggleWithNoPublishDate(value) {
  return {
    type: TOGGLE_RANGE_PICKER_WITH_NO_PUBLISH_DATE,
    value,
  }
}

export function onDateChange({ from, to }) {
  return {
    type: RANGE_PICKER_ON_DATE_CHANGE,
    from,
    to,
  }
}
