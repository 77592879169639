import PropTypes from 'prop-types'
import React from 'react'
import Button from '@mui/material/Button'
import styled from 'styled-components'
import StyledFlex, { StyledColumn } from '../../../../../layouts/StyledLayouts'

const Wrapper = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
`

const Small = styled.span`
  font-size: 0.9em;
`

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
`

const StyledThumbnailContainer = styled(StyledFlex)`
  position: relative;

  .thumbnail {
    transition: 0.25s;
  }

  :hover {
    .thumbnail {
      opacity: 0.3;
    }
  }
`

const AdjustButtonContainer = styled(StyledFlex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.25s;
  transition-delay: 0.1s;
  :hover {
    opacity: 1;
  }
`

const Crops = ({ onChooseDifferentImage, onCropsDone, uploadedImageCrops, adjustCrop, crops, insertComponent }) => {
  return (
    <StyledColumn>
      <StyledFlex style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Wrapper>
          {uploadedImageCrops.map((crop, i) => (
            <StyledColumn key={i} style={{ gap: '12px' }}>
              <div>
                <div>
                  Ratio {crops[i].aspect}, Crop w: {crop.width}, h: {crop.height}
                </div>
                <Small>
                  (min w: {crops[i].width}, h: {crops[i].height})
                </Small>
              </div>
              <StyledThumbnailContainer>
                <Thumbnail className="thumbnail" src={crop.path} alt="crop preview" />
                <AdjustButtonContainer>
                  <Button variant="contained" onClick={() => adjustCrop(crop, i)}>
                    Adjust
                  </Button>
                </AdjustButtonContainer>
              </StyledThumbnailContainer>
            </StyledColumn>
          ))}
        </Wrapper>
      </StyledFlex>
      <StyledFlex style={{ justifyContent: 'flex-end' }}>
        <Button onClick={onChooseDifferentImage}>Choose a different image</Button>
        <Button onClick={onCropsDone}>Save all</Button>
      </StyledFlex>
    </StyledColumn>
  )
}

Crops.propTypes = {
  onChooseDifferentImage: PropTypes.func,
  onCropsDone: PropTypes.func,
  uploadedImageCrops: PropTypes.array,
  adjustCrop: PropTypes.func,
  crop: PropTypes.array,
  insertComponent: PropTypes.func,
}

export default Crops
