import { useState } from 'react'
import { connect } from 'react-redux'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import debounce from 'lodash/debounce'
import styled from 'styled-components'

import 'styles/search_library.scss'

import { searchImages } from 'actions/imageActions'
import imageUrl from 'utils/imageUrl'
import StyledFlex from '../../../../../layouts/StyledLayouts'
import { RootDispatch, RootState } from '../../../../../App'

const StyledImage = styled.img`
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Label = styled.div`
  cursor: pointer;
  text-align: center;
`

interface Props extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {
  onCancel(): void
  onImageSelected(image: any): void
}

/**
 * A searchable library of images. Displays the search results in a grid.
 * Allows selecting a single image.
 */
const SearchLibrary = (props: Props) => {
  const [input, setInput] = useState('')
  const [from, setFrom] = useState(0)

  const hasMore = props.foundImages.count() > 0 && props.foundImages.count() - from >= 0

  const handleSearchChange = debounce((value) => {
    if (value.length < 3) {
      return
    }

    props.searchImages({ term: value, from: 0 })
    setFrom(10)
    setInput(value)
  }, 800)

  const loadMore = () => {
    const { searchImages } = props

    searchImages({ term: input, from })
    setFrom((p) => p + 10)
  }

  return (
    <div>
      <StyledFlex
        className={props.searching ? 'loader' : 'loadedContent'}
        style={{ gap: '12px', alignItems: 'center', position: 'relative' }}
      >
        <SearchIcon />
        <TextField
          variant="standard"
          label="Search images by title or tags"
          onChange={(e) => handleSearchChange(e.target.value)}
          fullWidth
        />
        <div style={{ width: '28px' }}>{props.searching && <CircularProgress size={24} />}</div>
      </StyledFlex>
      <StyledFlex style={{ justifyContent: 'space-around', flexWrap: 'wrap' }} data-testid="search-library-results">
        {props.foundImages &&
          props.foundImages.map((image: any, idx: number) => (
            <div className="search-image-item" key={idx}>
              {image.get('credit') || image.get('source') || image.get('notes') ? (
                <div className="search-image-item-tooltip">
                  {image.get('credit') ? (
                    <p>
                      <span>Credit:</span> {image.get('credit')}
                    </p>
                  ) : null}
                  {image.get('source') ? (
                    <p>
                      <span>Source:</span> {image.get('source')}
                    </p>
                  ) : null}
                  {image.get('notes') ? (
                    <p>
                      <span>Notes:</span> {image.get('notes')}
                    </p>
                  ) : null}
                </div>
              ) : null}
              <StyledImage
                src={imageUrl(image.get('path'))}
                alt={`preview_${idx}`}
                onClick={() => props.onImageSelected(image.toJS())}
              />
              <Label onClick={() => props.onImageSelected(image.toJS())}>{image.get('seoImageName')}</Label>
            </div>
          ))}
      </StyledFlex>
      {hasMore && (
        <StyledFlex style={{ justifyContent: 'center', padding: '2rem 0' }}>
          <Button onClick={loadMore}>Load More</Button>
        </StyledFlex>
      )}
      <StyledFlex style={{ marginTop: '20px' }}>
        <Button onClick={props.onCancel}>Go back</Button>
      </StyledFlex>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  foundImages: state.images.get('images'),
  searching: state.images.get('searching'),
})

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  // @ts-expect-error
  searchImages: (query: { term: string; from: number }) => dispatch(searchImages(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchLibrary)
