import React, { useState, useRef, useEffect } from 'react'
import shortid from 'shortid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import LinkIcon from '@mui/icons-material/InsertLink'

import { MBGLinkWarningDialog } from 'components/CreateArticle/Dialogs'
import { insertQuillLink, updateQuillLink, sliceFromLastWhitespace } from './utils/linkUtils'
import { isUrlValid } from 'utils/validators'

import { Quill } from 'react-quill'
import LinkBlot from './blots/LinkBlot'
Quill.register(LinkBlot)

const linkToolbarButton = (
  <Tooltip title="Link">
    <IconButton className="ql-link">
      <LinkIcon />
    </IconButton>
  </Tooltip>
)

const addKeyListener = (editor) => {
  editor.keyboard.addBinding({
    collapsed: true,
    key: ' ',
    prefix: /(?:\s|^)(https?:\/\/[^\s]+)/,
    handler: (range, context) => {
      const url = sliceFromLastWhitespace(context.prefix)
      editor.formatText(range.index - url.length, range.index, { link: { url } })
      return true
    },
  })
}

const patchLinkTooltip = (editor, id) => {
  const tooltip = editor.theme.tooltip
  const tooltipEditor = tooltip.root.querySelector('.ql-tooltip-editor')
  const tooltipInput = tooltip.root.querySelector('input[data-link]')
  tooltipInput.dataset.link = 'https://www.mindbodygreen.com/'

  const checkboxNofollow = document.createElement('input')
  checkboxNofollow.type = 'checkbox'
  checkboxNofollow.name = 'no-follow'
  checkboxNofollow.className = 'ql-tooltip-editor-checkbox'
  checkboxNofollow.id = `nofollow${id}`

  const labelNofollow = document.createElement('label')
  labelNofollow.htmlFor = `nofollow${id}`
  labelNofollow.className = 'ql-tooltip-editor-label'
  labelNofollow.appendChild(document.createTextNode('nofollow'))

  const checkboxSponsored = document.createElement('input')
  checkboxSponsored.type = 'checkbox'
  checkboxSponsored.name = 'sponsored'
  checkboxSponsored.className = 'ql-tooltip-editor-checkbox'
  checkboxSponsored.id = `sponsored${id}`

  const labelSponsored = document.createElement('label')
  labelSponsored.htmlFor = `sponsored${id}`
  labelSponsored.className = 'ql-tooltip-editor-label'
  labelSponsored.appendChild(document.createTextNode('Sponsored?'))

  const button = document.createElement('input')
  button.type = 'button'
  button.value = 'SAVE LINK'
  button.className = 'ql-tooltip-editor-button'

  tooltipEditor.appendChild(checkboxNofollow)
  tooltipEditor.appendChild(labelNofollow)
  tooltipEditor.appendChild(checkboxSponsored)
  tooltipEditor.appendChild(labelSponsored)
  tooltipEditor.appendChild(button)
}

const QuillLinkFormat = ({ selectionOnly, children, showFlashMessage }) => {
  const quill = useRef()
  const [id] = useState(shortid.generate())
  const [showMBGLinkWarningDialog, toggleMBGLinkWarningDialog] = useState(false)

  useEffect(() => {
    if (!selectionOnly) {
      patchLinkTooltip(quill.current, id)
    }
    addKeyListener(quill.current)
    addPasteListener(quill.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selection = useRef()

  const addPasteListener = (editor) => {
    editor.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
      if (typeof node.data !== 'string') return

      const matches = node.data.match(/https?:\/\/[^\s]+/g)
      let mbgLinksWithParamsFound = 0
      if (matches && matches.length > 0) {
        const ops = []
        let str = node.data
        matches.forEach((match) => {
          if (/mindbodygreen.com(?=.*\?)/g.test(match)) {
            if (match.includes('?')) mbgLinksWithParamsFound = mbgLinksWithParamsFound + 1
          }
          const split = str.split(match)
          const beforeLink = split.shift()
          ops.push({ insert: beforeLink })
          ops.push({ insert: match, attributes: { link: { url: match } } })
          str = split.join(match)
        })
        ops.push({ insert: str })
        delta.ops = ops
      }

      toggleMBGLinkWarningDialog(mbgLinksWithParamsFound > 0)

      return delta
    })
  }

  const saveSelection = (range) => {
    if (range && range.length) selection.current = range
  }

  const toggleLinkDialog = (inactiveLink) => {
    const editor = quill.current
    const tooltip = editor.theme.tooltip
    const tooltipEditor = tooltip.root.querySelector('.ql-tooltip-editor')
    const tooltipInput = tooltipEditor.querySelector('input[type=text]')
    const checkboxNofollow = tooltipEditor.querySelector(`#nofollow${id}`)
    const checkboxSponsored = tooltipEditor.querySelector(`#sponsored${id}`)

    if (!inactiveLink) {
      const links = editor.getFormat().link
      const link = Array.isArray(links) ? links[0] : links
      setTimeout(() => {
        tooltipInput.value = link.url
      }, 50)
      checkboxNofollow.checked = link.nofollow
      checkboxSponsored.checked = link.sponsored
    }

    const button = tooltipEditor.querySelector('.ql-tooltip-editor-button')
    button.value = `${inactiveLink ? 'SAVE' : 'UPDATE'} LINK`

    // submit link function
    const submitLink = (e) => {
      const link = {
        url: tooltipInput.value,
        nofollow: checkboxNofollow.checked,
        sponsored: checkboxSponsored.checked,
      }

      // check if it contains a preview link
      const pastingPreviewLink = link.url.includes('preview?id=') || link.url.includes('preview.html?preview=true')
      if (pastingPreviewLink) {
        showFlashMessage('You cannot use the article preview link.')
        e.preventDefault()
        return
      }

      if (/mindbodygreen.com(?=.*\?)/g.test(link.url)) {
        toggleMBGLinkWarningDialog(true)
      }

      if (isUrlValid(link.url)) {
        !inactiveLink && updateQuillLink(editor, selection.current, link)
        inactiveLink && insertQuillLink(editor, selection.current, link)
        tooltipInput.value = ''
        tooltip.hide()
      }
    }

    // override save triggered on enter key press
    tooltip.save = submitLink

    // attach submit func to save button
    button.onclick = submitLink

    tooltip.edit()
  }

  return (
    <>
      {showMBGLinkWarningDialog && (
        <MBGLinkWarningDialog
          open={showMBGLinkWarningDialog}
          toggleDialog={() => toggleMBGLinkWarningDialog(!showMBGLinkWarningDialog)}
        />
      )}
      {children({
        linkHandler: { link: toggleLinkDialog },
        linkFormat: 'link',
        linkToolbarButton,
        onEditorRef: (el) => (quill.current = el),
        onChangeSelection: saveSelection,
      })}
    </>
  )
}

QuillLinkFormat.propTypes = {
  selectionOnly: PropTypes.bool,
  showFlashMessage: PropTypes.func,
  children: PropTypes.func,
}

export default QuillLinkFormat
