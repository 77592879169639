import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import Alert from '@mui/material/Alert'
import AddIcon from '@mui/icons-material/Add'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import { useStore } from 'store/mobx'
import ClassVersions from 'components/new-pages/Classes/Versions'
import styled from 'styled-components'
import CreateVersionDialog from 'components/new-pages/Classes/Lessons/Versions/createVersion'
import EditVersionDialog from 'components/new-pages/Classes/Lessons/Versions/updateVersion'
import ErrorBoundary from 'components/ErrorBoundary'
import { ClassModule } from 'types/ClassModule'
import DeleteAlert from 'components/Common/DeleteAlert'

const CreateBtn = styled(Fab)`
  position: fixed;
  right: 2rem;
  bottom: 2rem;
`

interface Props {
  moduleId: string
  classItem: ClassModule.Data
  activeLesson: ClassModule.Session
}

const ClassLessonVersions = ({ classItem, activeLesson, moduleId }: Props) => {
  const { error } = useStore('classesStore')
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [editDialogData, setEditDialogData] = useState({})
  const [deleteID, setDeleteID] = useState('')

  if (!classItem) {
    return null
  }

  const onDelete = async () => {
    await classItem.deleteVersion(classItem.id, moduleId, activeLesson.id, deleteID)
    setDeleteID('')
  }

  const onClose = () => {
    setDeleteID('')
  }

  return (
    <>
      {error?.message ? <Alert severity="error">{error.message}</Alert> : null}
      {classItem?.id && classItem.terms && (
        <ClassVersions
          terms={classItem.terms}
          versions={activeLesson?.versions}
          classData={classItem}
          classId={classItem.id}
          setEditDialogData={setEditDialogData}
          setEditDialogOpen={setEditDialogOpen}
          setDeleteID={setDeleteID}
        />
      )}
      <Tooltip title="Create version">
        <CreateBtn color="primary" aria-label="create version" onClick={() => setCreateDialogOpen(true)}>
          <AddIcon />
        </CreateBtn>
      </Tooltip>
      {classItem.id && classItem.terms && (
        <ErrorBoundary message={'Something went wrong with the Lesson Version Dialog!'}>
          <CreateVersionDialog
            activeLesson={activeLesson}
            moduleId={moduleId}
            classItem={classItem}
            setOpen={setCreateDialogOpen}
            open={createDialogOpen}
          />
        </ErrorBoundary>
      )}
      {activeLesson &&
        activeLesson.versions &&
        activeLesson.versions.length > 0 &&
        editDialogOpen &&
        classItem.terms && (
          <ErrorBoundary message={'Something went wrong with the Tool form!'}>
            <EditVersionDialog
              editDialogData={editDialogData}
              activeLesson={activeLesson}
              moduleId={moduleId}
              classItem={classItem}
              open={editDialogOpen}
              setOpen={setEditDialogOpen}
            />
          </ErrorBoundary>
        )}
      <DeleteAlert onDelete={onDelete} onClose={onClose} open={Boolean(deleteID)} />
    </>
  )
}

export default observer(ClassLessonVersions)
