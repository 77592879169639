import { fromJS } from 'immutable'

export const initialNavbarState = fromJS({
  navbarPrefs: {
    title: '',
    showSearchbar: false,
    showVersionNotice: false,
    currentVersion: null,
  },
})
