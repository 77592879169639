import { makeAutoObservable, runInAction } from 'mobx'

import {
  getProgressTracking,
  updateSession,
  activateAssignment,
  activateFeedack,
  resetSessionProgress,
  completeSessionWithoutTrackingData,
} from 'api/progressTracking'

export default class ProgressTrackingStore {
  clsTracking = null
  assignments = []
  assessments = []
  loading = true
  error = null

  constructor() {
    makeAutoObservable(this)
  }

  fetchProgressTracking = async ({ userId, classId, termId }) => {
    this.loading = true
    this.clsTracking = null
    this.assignments = []
    this.beforeApiAction()
    try {
      const data = await getProgressTracking({ userId, classId, termId })
      runInAction(() => {
        this.clsTracking = data.clsTracking
        this.assignments = data.assignments
        this.assessments = data.assessments
        this.loading = false
      })
    } catch (error) {
      runInAction(() => {
        this.failureApiAction(error)
      })
    }
  }

  updateSessionRequest = async ({ progressTrackingId, userId, sessionIndex, session }) => {
    this.beforeApiAction()
    try {
      const updatedProgressTracking = await updateSession({ progressTrackingId, userId, sessionIndex, session })
      runInAction(() => {
        this.progressTracking = updatedProgressTracking
        this.loading = false
      })
    } catch (error) {
      runInAction(() => {
        this.failureApiAction(error)
      })
    }
  }

  resetSessionProgress = async ({ sessionId, body, versionId }) => {
    this.beforeApiAction()
    try {
      const resetResponse = await resetSessionProgress({ sessionId, body })
      runInAction(() => {
        if (resetResponse) this.updateSessionData(resetResponse, versionId)
        this.loading = false
      })
    } catch (error) {
      runInAction(() => {
        this.failureApiAction(error)
      })
    }
  }

  completeSessionWithoutTrackingData = async ({ sessionId, body }) => {
    this.beforeApiAction()
    try {
      const completeResponse = await completeSessionWithoutTrackingData({ sessionId, body })
      runInAction(() => {
        if (completeResponse) this.updateSessionData(completeResponse)
        this.loading = false
      })
    } catch (error) {
      runInAction(() => {
        this.failureApiAction(error)
      })
    }
  }

  activateAssignment = async ({ assignmentId, userId, active }) => {
    this.beforeApiAction()
    try {
      const updatedAssignment = await activateAssignment({ assignmentId, userId, active })
      runInAction(() => {
        const index = this.assignments.findIndex((a) => a.id === assignmentId)
        const assignment = this.assignments.find((a) => a.id === assignmentId)
        this.assignments.splice(index, 1, { ...assignment, active: updatedAssignment.active })
        this.loading = false
      })
    } catch (error) {
      runInAction(() => {
        this.failureApiAction(error)
      })
    }
  }

  activateFeedback = async ({ assignmentId, userId, feedbackIndex, feedback }) => {
    this.beforeApiAction()
    try {
      const updatedFeedback = await activateFeedack({ assignmentId, userId, feedbackIndex, feedback })
      runInAction(() => {
        const assignment = this.assignments.find((a) => a.id === assignmentId)
        const index = this.assignments.findIndex((a) => a.id === assignmentId)
        assignment.feedback[feedbackIndex] = updatedFeedback
        this.assignments.splice(index, 1, assignment)
        this.loading = false
      })
    } catch (error) {
      runInAction(() => {
        this.failureApiAction(error)
      })
    }
  }

  setProgressTracking(data) {
    this.progressTracking = data
  }

  setAssignments(data) {
    this.assignments = data
  }

  beforeApiAction() {
    this.loading = true
    this.error = null
  }

  failureApiAction(error) {
    this.error = error
    this.loading = false
  }

  updateSessionData(updatedSession, versionId) {
    // find session
    const updatedClsTracking = { ...this.clsTracking }
    updatedClsTracking.modules = updatedClsTracking.modules?.map((module) => {
      if (module.modules?.length) {
        module.modules = module.modules.map((module) => {
          const sessionIdx = module.sessions.findIndex((s) => s.id === updatedSession.sessionId)
          if (sessionIdx !== -1) {
            // update session progressData and completed falg
            const session = { ...module.sessions[sessionIdx] }

            session.progressData = {
              ...(session.progressData || {}),
              ...updatedSession,
            }

            session.completed = false
            if (
              session.progressData?.progress >= 100 ||
              session.progressData.assignmentSubmitted ||
              session.progressData.assessmentPassed
            ) {
              session.completed = true
            }

            module.sessions[sessionIdx] = session
          }

          module.completed = module.sessions?.length ? module.sessions.every((s) => s.completed) : false

          return module
        })
      } else {
        if (module.sessions?.length) {
          const sessionIdx = module.sessions.findIndex((s) => s.id === updatedSession.sessionId)
          let versionIdx = -1

          // The progressdata for the version is stored in its own session
          if (versionId) {
            versionIdx = module.sessions.findIndex((s) => s.id === versionId)
          }

          if (versionIdx !== -1) {
            const session = { ...module.sessions[versionIdx] }

            session.progressData = {
              ...(module.sessions[versionIdx].progressData || {}),
              ...updatedSession,
            }

            session.completed = false
            if (
              session.progressData?.progress >= 100 ||
              session.progressData.assignmentSubmitted ||
              session.progressData.assessmentPassed
            ) {
              session.completed = true
            }

            module.sessions[versionIdx] = session
          }

          if (sessionIdx !== -1) {
            const session = { ...module.sessions[sessionIdx] }

            session.progressData = {
              ...(module.sessions[sessionIdx].progressData || {}),
              ...updatedSession,
            }

            session.completed = false
            if (
              session.progressData?.progress >= 100 ||
              session.progressData.assignmentSubmitted ||
              session.progressData.assessmentPassed
            ) {
              session.completed = true
            }

            module.sessions[sessionIdx] = session
          }

          module.completed = module.sessions?.length ? module.sessions.every((s) => s.completed) : false
        }
      }

      return module
    })

    // update total progress
    if (!updatedSession.progress) {
      updatedClsTracking.completed = updatedClsTracking.completed - 1
    } else {
      updatedClsTracking.completed = updatedClsTracking.completed + 1
    }

    // update cls tracking data
    this.clsTracking = updatedClsTracking
  }
}
