import { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import CopyIcon from '@mui/icons-material/ContentCopy'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import MUITooltip from '@mui/material/Tooltip'

import copyToClipboard from '../../utils/copyToClipboard'

interface Props {
  text: string
  tooltip: string
}

const CopyButton = (props: Props) => {
  const [wasSuccessful, setWasSuccessful] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    const timer = setTimeout(() => setWasSuccessful(undefined), 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [wasSuccessful])

  const handleButtonPress = () => {
    try {
      copyToClipboard(props.text)
      setWasSuccessful(true)
    } catch (error) {
      console.error("Copy to clipboard unsuccessful, check you browser's permissions.")
      setWasSuccessful(false)
    }
  }

  return (
    <MUITooltip title={props.tooltip}>
      <IconButton onClick={handleButtonPress} disabled={wasSuccessful}>
        <RenderIcon wasSuccessful={wasSuccessful} />
      </IconButton>
    </MUITooltip>
  )
}

const RenderIcon = (props: { wasSuccessful?: boolean }) => {
  if (props.wasSuccessful === true) {
    return <DoneIcon />
  }
  if (props.wasSuccessful === false) {
    return <CloseIcon color="secondary" />
  }

  return <CopyIcon color="primary" />
}

export default CopyButton
