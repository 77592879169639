import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

interface DeleteAlertProps {
  open: boolean // determines whether the alert is visible or not
  onClose?: () => void // function to handle closing the alert
  onDelete: () => void // function to handle deleting the item
}

const DeleteAlert: React.FC<DeleteAlertProps> = ({ open, onClose, onDelete }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Item</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this item? This action cannot be undone.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose || undefined} color="primary">
          Cancel
        </Button>
        <Button onClick={onDelete} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteAlert
