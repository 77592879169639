import styled from 'styled-components'
import Paper from '@mui/material/Paper'
import ImageIcon from '@mui/icons-material/Image'
import { Breadcrumbs, TableCell, TextField } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { green, red } from '@mui/material/colors'

export const LessonImageStyle = styled.img`
  cursor: pointer;
  max-width: 500px;
  max-height: 500px;
  object-fit: cover;
`

export const ImagePlaceholder = styled(Paper)<{ height?: string }>`
  cursor: pointer;
  height: ${({ height }) => height || '12rem'};
  text-align: center;
  padding: 1rem 2rem;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  h3 {
    margin: 0.2rem auto;
  }
`

export const ImagePlaceholderIcon = styled(ImageIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ fontSize }) => fontSize || '5rem'};
`

export const MainCell = styled(TableCell)`
  max-width: 290px;
`

export const ActiveIcon = styled(FiberManualRecordIcon)<{ active?: boolean | number }>`
  color: ${({ active }) => (active ? green[500] : red[500])};
  display: inherit;
`

export const SearchField = styled(TextField)`
  margin: 0.5rem auto 2rem auto;
`

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  padding: 0 0 12px;
  li {
    cursor: pointer;
  }
`

export const ClassMessageContainer = styled.div`
  text-align: center;
  margin-top: 2rem;

  & p {
    font-size: 1.4rem;
  }
`
export const SubmissionContainer = styled.div`
  margin-top: 2rem;

  & p {
    text-align: center;
    font-size: 1.6rem;
    margin-top: 1rem;
  }
`

export const CustomCoachEmail = styled.span`
  font-size: 0.7rem;
  display: block;
`

export const CoachEmailFilter = styled.span`
  font-size: 0.7rem;
  margin-left: 0.2rem;
`
