import { CREATE_ARTICLE_SPLIT_WITH_DRAGGED_COMPONENT } from 'utils/constants/actions'

import deltaToHTML from 'utils/deltaToHTML'

const splitDraggedComponent = (store) => (next) => (action) => {
  if (action.type !== CREATE_ARTICLE_SPLIT_WITH_DRAGGED_COMPONENT) {
    return next(action)
  }

  const { createArticleBody, createArticleInteractions } = store.getState()
  const dropIndex = createArticleInteractions.getIn(['drop', 'targetItemIndex'])
  const sourceIndex = createArticleInteractions.getIn(['drop', 'draggedItemIndex'])

  const bodyComponents = createArticleBody.getIn(['present', 'components'])
  action.newComponent = bodyComponents.get(sourceIndex)
  action.index = dropIndex
  action.sourceIndex = sourceIndex

  // split the current component at selection
  const { index, length } = action.selection
  const before = action.editor.getContents(0, index)
  const after = action.editor.getContents(index + length, action.editor.getLength())

  if (index > 0) {
    // selection not reaching start of text
    action.beforeComponent = {
      id: Math.random().toString(36).substring(7),
      priority: bodyComponents.getIn([dropIndex, 'priority']),
      type: 'text',
      text: deltaToHTML(before),
      delta: before,
    }
  }

  if (index + length < action.editor.getLength() - 1) {
    // selection not reaching end of text
    action.afterComponent = {
      id: Math.random().toString(36).substring(7),
      priority: bodyComponents.getIn([dropIndex, 'priority']),
      type: 'text',
      text: deltaToHTML(after),
      delta: after,
    }
  }

  return next(action)
}

export default splitDraggedComponent
