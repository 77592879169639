import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'

import TextField from '@mui/material/TextField'
import CommonDialogTitle from '../../components/Common/DialogComponents/CommonDialogTitle'

class KeywordSearchDialog extends Component {
  state = {
    value: '',
  }

  render() {
    const { dialogOpen, onCancel, onSearch } = this.props
    const { value } = this.state

    const invalid = value.length < 3

    return (
      <Dialog open={dialogOpen} onClose={onCancel}>
        <CommonDialogTitle onClose={onCancel}>
          <div>Search for keywords</div>
        </CommonDialogTitle>
        <TextField
          variant="standard"
          label="Keyword"
          value={value}
          onChange={(e) => this.setState({ value: e.target.value })}
          fullWidth
        />
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button disabled={invalid} onClick={() => !invalid && onSearch(value)}>
            Search
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

KeywordSearchDialog.propTypes = {
  dialogOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSearch: PropTypes.func,
}

export default KeywordSearchDialog
