import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import TransformIcon from '@mui/icons-material/Transform'
import ConvertDialog from 'ui/ConvertToComponent'

const convertToolbarButton = (
  <Tooltip title="Convert">
    <IconButton className="ql-convert">
      <TransformIcon />
    </IconButton>
  </Tooltip>
)

const QuillConvertToComponent = ({ children, convertToComponent }) => {
  const quill = useRef()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selection, setSelection] = useState(null)

  const handleConvert = (value) => {
    convertToComponent(value, selection, quill.current)
  }

  const handleSelectionChange = (range, source, editor) => {
    if (range && range.length) {
      setSelection(range)
    }
  }

  return (
    <>
      <ConvertDialog open={dialogOpen} onCloseDialog={() => setDialogOpen(false)} onConvert={handleConvert} />
      {children({
        convertHandler: { convert: () => setDialogOpen(true) },
        convertFormat: 'convert',
        convertToolbarButton,
        onChangeSelection: handleSelectionChange,
        onEditorRef: (el) => (quill.current = el),
      })}
    </>
  )
}

QuillConvertToComponent.propTypes = {
  children: PropTypes.func,
  convertToComponent: PropTypes.func,
}

export default QuillConvertToComponent
