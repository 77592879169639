import {
  SEARCH_VIDEOS_REQUEST,
  SEARCH_VIDEOS_SUCCESS,
  SEARCH_VIDEOS_FAIL,
  LOAD_VIDEO_PLAYERS_REQUEST,
  LOAD_VIDEO_PLAYERS_SUCCESS,
  LOAD_VIDEO_PLAYERS_FAILURE,
} from 'utils/constants/actions'
import { Map, fromJS } from 'immutable'

const videosReducer = (state = Map(), action) => {
  switch (action.type) {
    case SEARCH_VIDEOS_REQUEST:
      return state.set('searching', true)

    case SEARCH_VIDEOS_SUCCESS:
      return state.set('searching', false).set('videos', fromJS(action.payload))

    case SEARCH_VIDEOS_FAIL:
      return state.set('searching', false).set('error', action.error)

    case LOAD_VIDEO_PLAYERS_REQUEST:
      return state.set('loadingPlayers', true)

    case LOAD_VIDEO_PLAYERS_SUCCESS:
      return state.set('loadingPlayers', false).set('players', fromJS(action.payload))

    case LOAD_VIDEO_PLAYERS_FAILURE:
      return state.set('loadingPlayers', false).set('error', action.error)

    default:
      return state
  }
}

export default videosReducer
