import { Map } from 'immutable'
import {
  TOGGLE_RANGE_PICKER_WITH_NO_PUBLISH_DATE,
  RANGE_PICKER_ON_DATE_CHANGE,
  ARTICLES_RELOAD,
} from 'utils/constants/actions'

import { getNyStartOfDay } from '../utils/formatDate'
import { addMonths } from 'date-fns'

const articlesRangePickerReducer = (state = Map(), action) => {
  switch (action.type) {
    case TOGGLE_RANGE_PICKER_WITH_NO_PUBLISH_DATE:
      return state.set('withNoPublishDate', action.value)
    case RANGE_PICKER_ON_DATE_CHANGE:
      return state.set('from', action.from).set('to', action.to)
    case ARTICLES_RELOAD:
      return state
        .set('from', getNyStartOfDay(new Date()))
        .set('to', addMonths(getNyStartOfDay(new Date()), 1))
        .set('withNoPublishDate', true)
    default:
      return state
  }
}

export default articlesRangePickerReducer
