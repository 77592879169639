import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'

interface Props {
  open: boolean
  title: string
  text: string
  handleOk: () => void
}

const Confirmation = ({ open, title, text, handleOk }: Props) => (
  <Dialog open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{text}</DialogContent>
    <DialogActions>
      <Button onClick={handleOk} color="primary">
        OK
      </Button>
    </DialogActions>
  </Dialog>
)

export default Confirmation
